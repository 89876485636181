import { IUser } from "shared/model/user.model";
import { IEdoQuestionChoice } from "./edo-question-choice.model";
import { EdoQuestionTypeEnum } from "./enumerations/edo-question-type.mode";

export interface IEdoQuestion {
  id?: number;
  description?: string;
  type?: EdoQuestionTypeEnum;
  author?: IUser;
  choices?: IEdoQuestionChoice[];
  explanationRequired?: boolean;
  totalScore?: number;
  repository?: boolean;
  updatedAt?: string;
  createdAt?: string;
}

export const defaultValue: Readonly<IEdoQuestion> = {
  explanationRequired: false,
  totalScore: 0,
};
