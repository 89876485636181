export const AUTHORITIES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_USER: "ROLE_USER",
  ROLE_STUDENT: "ROLE_STUDENT",
  ROLE_TEACHER: "ROLE_TEACHER",
  ROLE_VICE: "ROLE_VICE",
  ROLE_SUPPORT_TEACHER: "ROLE_SUPPORT_TEACHER",
  ROLE_PARENT: "ROLE_PARENT",
};

export const messages = {
  DATA_ERROR_ALERT: "Internal Error",
};

export const APP_DATE_FORMAT = "DD/MM/YY HH:mm";
export const APP_DATE_FORMAT_EXPANDED = "DD MMMM YYYY [alle ore] HH:mm";
export const APP_DATE_FORMAT_MID = "DD MMMM YYYY";
export const APP_DATE_FORMAT_SHORT = "DD MMM";
export const APP_TIMESTAMP_FORMAT = "DD/MM/YY HH:mm:ss";
export const APP_LOCAL_DATE_FORMAT = "DD/MM/YYYY";
export const APP_LOCAL_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const APP_WHOLE_NUMBER_FORMAT = "0,0";
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = "0,0.[00]";
