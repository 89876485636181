import { Face, People, SupervisedUserCircle } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid, Paper, Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import NavTabs from "components/NavTabs/NavTabs";
import { AUTHORITIES } from "config/constants";
import {
  Navigate,
  Route, Routes
} from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import FriendsList from "./FriendsList";

const useStyles = makeStyles()(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden"
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    textAlign: "left",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    minHeight: "calc(100vh - 64px)",
    overflow: "auto",
  },
}));

const AmiciRoutes = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const isMediaSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box component="main" className={classes.content}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ overflow: "hidden" }}>
              <Box p={4} sx={{
                backgroundImage: "linear-gradient(to right top, rgba(100, 115, 201, 0.7), rgba(25, 32, 72, 0.7)), url(\"/class.jpg\");",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Typography gutterBottom variant="h4" mb={1}>
                  I miei amici
                </Typography>
                <Typography variant="h6">
                  Studenti e docenti con cui hai stretto amicizia
                </Typography>
              </Box>
              <NavTabs
                centered={isMediaSm ? false : true}
                variant={isMediaSm ? "scrollable" : "standard"}
                scrollButtons="auto"
                tabs={[{
                  label: "Tutti",
                  icon: <People />,
                  iconPosition: "top",
                  href: "/friends/all"
                }, {
                  label: "Studenti",
                  icon: <Face />,
                  iconPosition: "top",
                  href: "/friends/students"
                }, {
                  label: "Docenti",
                  icon: <SupervisedUserCircle />,
                  iconPosition: "top",
                  href: "/friends/teachers"
                }]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Routes>
              <Route index element={<Navigate replace to="all" />} />
              <Route path="all" element={<FriendsList roleFilter={null} title="Tutti i miei amici" />} />
              <Route
                path="teachers"
                element={
                  <FriendsList
                    roleFilter={AUTHORITIES.ROLE_TEACHER}
                    title="Docenti"
                  />}
              />
              <Route
                path="students"
                element={
                  <FriendsList
                    roleFilter={AUTHORITIES.ROLE_STUDENT}
                    title="Studenti"
                  />}
              />
            </Routes>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AmiciRoutes;
