import {
    Delete as DeleteIcon, Edit as EditIcon, MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, ListItemIcon, Menu, MenuItem, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { IEdoQuestionChoice } from "shared/model/edo-question-choice.model";
import { IEdoQuestion } from 'shared/model/edo-question.model';
import { EdoQuestionTypeEnum } from "shared/model/enumerations/edo-question-type.mode";
import { getQuestionType } from 'shared/util/question-utils';

interface ICourseQuestionProps {
    question: IEdoQuestion;
    className?: string;
    onEdit?: (question: IEdoQuestion) => void;
    onDelete?: (question: IEdoQuestion) => void;
}

const CourseQuestion = (props: ICourseQuestionProps) => {
    const [form, setForm] = useState<{ description: string; }>({
        description: "",
    });
    const [choices, setChoices] = useState<IEdoQuestionChoice[]>([]);
    const [anchorMoreQuestion, setAnchorMoreQuestion] =
        useState<null | HTMLElement>(null);

    const { question, className, onEdit: handleOnEdit, onDelete: handleOnDelete } = props;


    const handleMoreQuestionButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorMoreQuestion(event.currentTarget);
    };

    const handleCloseMoreQuestionMenu = () => {
        setAnchorMoreQuestion(null);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
    };

    const handleGetChoices = async () => {
        const response = await axios.get<{ data: IEdoQuestionChoice[] }>(`/questions/${question.id}/choices`);
        setChoices(response.data.data);
    };

    useEffect(() => {
        if (question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE || question.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question.type === EdoQuestionTypeEnum.FILL_BLANK) {
            handleGetChoices();
        }
    }, [question]);

    const getMultipleChoiceOptions = () => {

        if (!choices?.length) {
            return (
                <Box p={2}>
                    <Typography variant="body1" align="center">
                        <Translate contentKey="edocendoApp.edoQuestionChoice.home.notFound">
                            No Edo Question Choices found
                        </Translate>
                    </Typography>
                </Box>
            );
        }

        if (question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE) {

            return (
                <FormControl disabled={true}>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ mb: 1 }}>Risposte disponibili</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        {choices.map(choice => <FormControlLabel key={choice.id} value={choice.id} control={<Radio />} checked={choice.isCorrect} label={choice.description} />)}
                    </RadioGroup>
                </FormControl>
            );

        } else {
            return (
                <FormControl sx={{ mt: 2, mb: 1 }} component="fieldset" variant="standard" disabled={true}>
                    <FormLabel component="legend">Risposte disponibili</FormLabel>
                    <FormGroup>
                        {choices.map(choice => <FormControlLabel key={choice.id} value={choice.id} control={<Checkbox />} checked={choice.isCorrect} label={choice.description} />)}
                    </FormGroup>
                </FormControl>
            );
        }
    };


    return (
        <Paper className={className}>
            <Box p={3}>
                <Box mb={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                    <Box>
                        <Box mb={1}>
                            <Typography variant="body1" color="text.primary">
                                {question.description}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                {getQuestionType(question.type)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box >
                        <IconButton
                            edge="end"
                            onClick={handleMoreQuestionButtonClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id="more-question-menu"
                        anchorEl={anchorMoreQuestion}
                        keepMounted
                        open={Boolean(anchorMoreQuestion)}
                        onClose={handleCloseMoreQuestionMenu}
                        disableScrollLock={true}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleCloseMoreQuestionMenu();
                            handleOnEdit(question);
                        }}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Modifica domanda</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleCloseMoreQuestionMenu();
                            handleOnDelete(question);
                        }
                        }>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                Elimina domanda
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>

                <Box flexGrow={1}>
                    {question.type === EdoQuestionTypeEnum.FREE_TEXT ? (
                        <Box mb={1}>
                            <TextField
                                variant="filled"
                                multiline
                                rows={4}
                                // size="small"
                                fullWidth
                                required
                                disabled
                                label={translate("edocendoApp.edoQuestion.description")}
                                onChange={handleChange}
                                name="description"
                                value={form.description || ""}
                            />
                        </Box>
                    ) : null}
                    {question.explanationRequired ? (
                        <Box mb={1}>
                            <TextField
                                variant="filled"
                                multiline
                                rows={4}
                                // size="small"
                                fullWidth
                                required
                                disabled
                                label={translate("edocendoApp.edoQuestion.description")}
                                onChange={handleChange}
                                name="description"
                                value={form.description || ""}
                            />
                        </Box>
                    ) : null}
                    {question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE || question.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question.type === EdoQuestionTypeEnum.FILL_BLANK ? (
                        <Box mt={2}>
                            {getMultipleChoiceOptions()}
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Paper>
    );
};

export default CourseQuestion;