import { IEdoQuestion } from "./edo-question.model";
import { IEdoQuiz } from "./edo-quiz.model";

export interface IEdoQuizQuestion {
  id?: number;
  updatedAt?: string;
  createdAt?: string;
  quiz?: IEdoQuiz;
  question?: IEdoQuestion;
}

export const defaultValue: Readonly<IEdoQuizQuestion> = {};
