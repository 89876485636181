import {
    Assignment as AssignmentIcon,
    Dashboard as DashboardIcon,
    Event as EventIcon,
    Face as FaceIcon,
    Folder as FolderIcon,
    SupervisedUserCircle as SupervisedUserCircleIcon
} from "@mui/icons-material";
import {
    Avatar,
    Box, Container,
    Grid, Paper, Typography,
    decomposeColor,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import NavTabs from "components/NavTabs/NavTabs";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import BoxEventiInsegnamento from "pages/courses/components/BoxEventiInsegnamento";
import ZoomLinksBox from "pages/courses/components/ZoomLinksBox";
import { useEffect, useMemo } from "react";
import {
    Outlet, useParams
} from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoCourse } from "shared/model/edo-course.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getEntity } from "shared/reducers/entities/edo-course.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme =>
({
    paper: {
        color: theme.palette.text.secondary,
        overflow: "hidden",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        minHeight: 128,
        alignItems: "flex-start",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        alignSelf: "flex-end",
        textAlign: "left",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        minHeight: "100vh",
        overflow: "auto",
    },
})
);

export const CourseDetailRoutes = () => {
    const theme = useTheme();
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const isMediaSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { entity: course } = useAppSelector<EntityState<IEdoCourse>>((state) => state.edoCourse);
    const { account } = useAppSelector<AuthenticationState>(
        (state) => state.authentication
    );

    useEffect(() => {
        dispatch(getEntity(params.id));
    }, []);

    const backgroundImage = useMemo(() => {
        return course?.subject?.picPath ? `linear-gradient(to right top, rgba(${decomposeColor(theme.palette.primary.main).values}, 0.7), rgba(${decomposeColor(theme.palette.secondary.main).values}, 0.7)), url(/api/subjects/${course?.subject?.id}/pic/main.jpeg)` : `linear-gradient(to right top, rgba(${decomposeColor(theme.palette.primary.main).values}, 0.7), rgba(${decomposeColor(theme.palette.secondary.main).values}, 0.7))`;
    }, [course?.subject?.picPath, theme.palette.primary.main, theme.palette.secondary.main]);

    return (
        <Box component="main" className={classes.content}>
            {/* <div className={classes.appBarSpacer} /> */}
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ overflow: "hidden" }}>
                            <Box sx={theme => ({
                                // backgroundImage: "url(\"/class.jpg\");",
                                backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(\"/class.jpg\");",
                                // backgroundImage: "linear-gradient(to right top, rgba(100, 115, 201, 0.7), rgba(25, 32, 72, 0.7)), url(\"/class.jpg\");",
                                // backgroundColor: "#ed7c23",
                                backgroundPosition: "center 86.5%",
                                backgroundSize: "cover",
                                height: "200px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                // backgroundImage: backgroundImage,
                                // backgroundPosition: "center center",
                                // backgroundSize: "120%",
                            })}>
                                <Box p={4} sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "100%",
                                    // backdropFilter: "blur(15px)",
                                }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginRight: 3 }}>
                                        {course?.id ? (
                                            <Avatar
                                                sx={{ width: 100, height: 100 }}
                                                alt={course.subject?.name}
                                                src={`/package/logo.png`}
                                            />
                                        ) : (
                                            <Skeleton variant="circular" width={100} height={100} />
                                        )}
                                        {/* {course?.subject?.picPath ? (
                                            <Avatar
                                                sx={{ width: 100, height: 100 }}
                                                alt={course.subject?.name}
                                                src={`/api/subjects/${course?.subject?.id}/pic/main.jpeg`}
                                            />
                                        ) : course?.subject?.name ? (
                                            <Avatar
                                                sx={{ width: 100, height: 100 }}
                                                    alt={course?.subject?.name}
                                            >
                                                {course?.subject?.name[0]}
                                            </Avatar>
                                        ) : (
                                            <Skeleton variant="circular" width={100} height={100} />
                                        )} */}
                                    </Box>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}>
                                        {course?.subject?.name ? (
                                            <Typography gutterBottom variant={isMediaSm ? "body1" : "h4"} mb={1} color="white">
                                                {course.subject.name}
                                            </Typography>)
                                            : (
                                                <Skeleton animation="wave" height={42} width="20%" sx={{ marginBottom: 1 }} />
                                            )
                                        }
                                        {course?.class && course.class.year ? (
                                            <Typography variant={isMediaSm ? "body2" : "h6"} color="white">
                                                {
                                                    String(course.class.year.year).concat("° ")
                                                        .concat(course.class.section)
                                                        .concat(" - ")
                                                        .concat(course.class.school.type)
                                                        .concat(" ")
                                                        .concat(course.class.school.subtype)
                                                        .concat(course.class.school.name ? " " + course.class.school.name : "")
                                                }
                                            </Typography>
                                        ) : (
                                            <Skeleton animation="wave" height={32} width="50%" />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <NavTabs
                                centered={isMediaSm ? false : true}
                                variant={isMediaSm ? "scrollable" : "standard"}
                                scrollButtons="auto"
                                tabs={[{
                                    label: "Bacheca",
                                    icon: <DashboardIcon />,
                                    iconPosition: "start",
                                    href: `/courses/${params.id}/`,
                                }, {
                                    label: "Materiale",
                                    icon: <FolderIcon />,
                                    iconPosition: "start",
                                    href: `/courses/${params.id}/materials`,
                                    pattern: `/courses/${params.id}/materials/*`,
                                }, {
                                    label: "Test",
                                    icon: <AssignmentIcon />,
                                    iconPosition: "start",
                                    href: `/courses/${params.id}/quizzes`,
                                    pattern: `/courses/${params.id}/quizzes/*`,
                                }, {
                                    label: "Eventi",
                                    icon: <EventIcon />,
                                    iconPosition: "start",
                                    href: `/courses/${params.id}/events`,
                                    pattern: `/courses/${params.id}/events/*`,
                                    hidden: !hasAnyAuthority(account.roles, [
                                        AUTHORITIES.ROLE_TEACHER,
                                        AUTHORITIES.ROLE_ADMIN,
                                    ])
                                }, {
                                    label: "Studenti",
                                    icon: <FaceIcon />,
                                    iconPosition: "start",
                                    href: `/courses/${params.id}/students`,
                                    pattern: `/courses/${params.id}/students/*`,
                                    hidden: !hasAnyAuthority(account.roles, [
                                        AUTHORITIES.ROLE_TEACHER,
                                        AUTHORITIES.ROLE_ADMIN,
                                    ])
                                }, {
                                    label: "Docenti",
                                    icon: <SupervisedUserCircleIcon />,
                                    iconPosition: "start",
                                    href: `/courses/${params.id}/teachers`,
                                    pattern: `/courses/${params.id}/teachers/*`,
                                    hidden: !hasAnyAuthority(account.roles, [
                                        AUTHORITIES.ROLE_TEACHER,
                                        AUTHORITIES.ROLE_ADMIN,
                                    ])
                                }]}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        <Outlet />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Box mb={2}>
                            <ZoomLinksBox class={course?.class} />
                        </Box>
                        <Box>
                            <BoxEventiInsegnamento insegnamento={course} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default CourseDetailRoutes;
