import { Close as CloseIcon } from "@mui/icons-material";
import {
  AppBar,
  Box, CircularProgress,
  Dialog, DialogContent,
  IconButton,
  List,
  Tab,
  Tabs, Toolbar,
  Typography
} from "@mui/material";
import axios from "axios";
import MaterialRow from "components/MaterialRow/MaterialRow";
import { ViewChapter } from "components/ViewChapter/ViewChapter";
import { ViewUnit } from "components/ViewUnit/ViewUnit";
import { useAppSelector } from "config/store";
import "moment/locale/it";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { IUser } from "shared/model/user.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { ViewDialogType } from "shared/util/dialog-utils";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box pt={2}>{children}</Box>}
    </div>
  );
}

type ViewTeacherMaterialProps = {
  teacher: IUser | null;
  open: boolean;
  onClose: () => void;
};

const ViewTeacherMaterial = (props: ViewTeacherMaterialProps) => {
  const { entities: edoFileList, loading } = useAppSelector<
    EntityState<IEdoFile>
  >((state) => state.edoFile);
  const [tabValue, setTabValue] = useState<number | false>(false);
  const [courses, setCourses] = useState<IEdoCourse[]>([]);
  const [teacherMaterials, setTeacherMaterials] = useState<IEdoCourseMaterial[]>([]);
  const [viewUnitDialog, setViewUnitDialog] = useState<boolean>(false);
  const [unitToView, setUnitToView] = useState<IEdoUnit | null>(null);
  const [viewChapterDialog, setViewChapterDialog] = useState <ViewDialogType<IEdoChapter>>({ open: false, entity: null });

  const { teacher, open, onClose: handleClose } = props;

  const handleChangeTab = (event: ChangeEvent<any>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpenViewUnitDialog = (unit: IEdoUnit) => {
    setUnitToView(unit);
    setViewUnitDialog(true);
  };

  const handleCloseViewUnitDialog = () => {
    setViewUnitDialog(false);
    setUnitToView(null);
  };

  const openFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  const downloadFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/download`, "_blank");
  };

  useEffect(() => {

    const handleGetCoursesByTeacher = async () => {
      if (!teacher?.id) return;
      const response = await axios.get<{ data: IEdoCourse[] }>(`/teachers/${teacher.id}/courses`);
      setCourses(response.data.data);
    };

    if (open) {
      handleGetCoursesByTeacher();
    }
  }, [open]);

  useEffect(() => {
    const handleGetMaterialsByCourse = async (insegnamentoId: number) => {
      const response = await axios.get<{ data: IEdoCourseMaterial[] }>(`/courses/${insegnamentoId}/course-materials`, {
        params: {
          page: 0,
          limit: 1000,
          sortBy: "order:ASC",
          "filter.author.id": '$eq:' + teacher.id,
        },
      });
      setTeacherMaterials(response.data.data);
    };
      
    if (teacher?.id && tabValue !== false) {
      handleGetMaterialsByCourse(tabValue);
    }

  }, [tabValue, teacher]);

  useEffect(() => {
    if (courses.length > 0) {
      setTabValue(courses[0].id);
    }
  }, [courses]);

  const getCourseMaterials = () => {
    if (!teacherMaterials.length) {
      return (
        <Box p={2}>
          <Typography variant="body1" align="center">
            <Translate contentKey="edocendoApp.edoCourseMaterial.home.notFound" />
          </Typography>
        </Box>
      );
    }

    return (
      <List dense disablePadding>
        {teacherMaterials.map((teacherMaterial, index, list) => (
          <MaterialRow
            key={teacherMaterial.id}
            courseMaterial={teacherMaterial}
            isLastElement={list.length - 1 === index}
            onOpenViewChapter={() => setViewChapterDialog({ open: true, entity: teacherMaterial.chapter })}
          />
        ))}
      </List>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} fullScreen={true}>
        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        ) : (
          <>
            <AppBar position="relative">
              <Toolbar>
                <Typography variant="h6" flexGrow={1}>
                  Materiale docente
                </Typography>
                <IconButton edge="end" color="inherit" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Toolbar>
              {courses.length ? (
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                  {courses.map(course => (
                    <Tab
                      key={course.id}
                      label={`${course.subject.name} - ${course.class.year.year}°${course.class.section} (${course.class.school.code})`}
                      value={course.id}
                    />
                  ))}
                </Tabs>
              ) : null}
            </AppBar>

            <DialogContent sx={{ padding: 0 }}>
              {courses.map((course, courseIndex) => {
                return (
                  <TabPanel
                    value={tabValue}
                    index={course.id}
                    key={courseIndex}
                  >
                    {getCourseMaterials()}
                  </TabPanel>
                );
              })}
              <ViewUnit
                unit={unitToView}
                open={viewUnitDialog}
                handleClose={handleCloseViewUnitDialog}
              />
            </DialogContent>
          </>
        )}
      </Dialog>
      <ViewChapter
        open={viewChapterDialog.open}
        chapter={viewChapterDialog.entity}
        onClose={() => setViewChapterDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default ViewTeacherMaterial;