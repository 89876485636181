import { Grid, ListItem, ListItemText } from "@mui/material";
import { APP_DATE_FORMAT } from "config/constants";
import { TextFormat } from "react-jhipster";
import { IEdoChatMessage } from "shared/model/edo-chat-message.model";

export interface MyMessageProps {
  messaggio: IEdoChatMessage;
}

export const MyMessage = (props: MyMessageProps) => {
  const { messaggio } = props;

  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12}>
          <ListItemText
            primaryTypographyProps={{ align: "right" }}
            primary={messaggio.message}
          ></ListItemText>
        </Grid>
        <Grid item xs={12}>
          <ListItemText
            secondaryTypographyProps={{ align: "right" }}
            secondary={
              <TextFormat
                type="date"
                value={messaggio.createdAt}
                format={APP_DATE_FORMAT}
                locale="it"
              />
            }
          ></ListItemText>
        </Grid>
      </Grid>
    </ListItem>
  );
};
