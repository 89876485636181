import {
    Box,
    Divider,
    Grid,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper, Skeleton, Typography
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IEdoClassZoomLink } from "shared/model/edo-class-zoom-link.model";
import { IEdoClass } from "shared/model/edo-class.model";
import { getEntity as getClass } from "shared/reducers/entities/edo-class.reducer";

import { Videocam as VideocamIcon } from "@mui/icons-material";
import { EntityState } from "shared/reducers/reducer.utils";
import CoursesGroup from "./components/CoursesGroup";

const ClassDetail = () => {
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const { entity: edoClass } = useAppSelector<EntityState<IEdoClass>>(
        (state) => state.edoClass
    );
    const [classZoomLinksMenu, setClassZoomLinksMenu] = useState<{ anchorEl: null | HTMLElement, links: IEdoClassZoomLink[] }>({ anchorEl: null, links: [] });

    const handleOpenZoomLink = (zoomLink: IEdoClassZoomLink) => {
        window.open(zoomLink.zoomInviteLink, "_blank");
        setClassZoomLinksMenu({ anchorEl: null, links: [] });
    };

    useEffect(() => {
        if (params.id) {
            dispatch(getClass(params.id));
        }
    }, [params.id]);

    return (
        <>
            <Grid item xs={12}>
                <Paper style={{ overflow: "hidden" }}>
                    <Box p={2} display="flex" justifyContent="start" alignItems="center">
                        <Typography variant="h6">
                            {edoClass.id ? `${String(edoClass.year.year).concat("° ")
                                .concat(edoClass.section)
                                .concat(" - ")
                                .concat(edoClass.school.type)
                                .concat(" ")
                                .concat(edoClass.school.subtype)
                                .concat(edoClass.school.name ? " " + edoClass.school.name : "")
                                }` : (
                                <Skeleton animation="wave" height={32} width="50%" />
                            )}
                        </Typography>
                    </Box>
                    <Divider />
                    <CoursesGroup
                        class={edoClass}
                        onOpenClassZoomLinksMenu={(anchorEl, links) => setClassZoomLinksMenu({ anchorEl, links })}
                    />
                </Paper>
            </Grid>
            <Menu
                id="class-zoom-links-menu"
                anchorEl={classZoomLinksMenu.anchorEl}
                open={classZoomLinksMenu.anchorEl !== null && classZoomLinksMenu.links.length > 0}
                onClose={() => setClassZoomLinksMenu({ anchorEl: null, links: [] })}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                disableScrollLock={true}
            >
                {classZoomLinksMenu?.links?.map(classZoomLink => (
                    <MenuItem key={classZoomLink.id} onClick={() => handleOpenZoomLink(classZoomLink)}>
                        <ListItemIcon>
                            <VideocamIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            {classZoomLink.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ClassDetail;
