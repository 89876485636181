import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { EdoStudentState, getStudent } from "shared/reducers/entities/edo-student.reducer";
import NewCourseMaterials from "./components/NewCourseMaterials/NewCourseMaterials";
import QuizAttempts from "./components/QuizAttempts/QuizAttempts";
import TimingChart from "./components/TimingChart";

const ChildDetail = () => {
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();

    const { entity: { entity: student } } = useAppSelector<EdoStudentState>(
        (state) => state.edoStudent
    );

    useEffect(() => {
        dispatch(getStudent(params.id));
    }, [params.id]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {student?.user?.firstName + " " + student?.user?.lastName}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {student?.user?.email}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <QuizAttempts student={student} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <NewCourseMaterials student={student} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TimingChart student={student} />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
                <CourseMaterials student={student} />
            </Grid> */}
        </Grid>
    );
};

export default ChildDetail;
