import {
    Bookmark as BookmarkIcon, ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    GetApp as GetAppIcon, Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
    Avatar,
    Box, CircularProgress,
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar, ListItemText, Typography
} from "@mui/material";
import axios from "axios";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import React, { useEffect, useState } from "react";
import { TextFormat } from "react-jhipster";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";

interface UnitRowProps {
    unit?: IEdoUnit;
    isLastElement?: boolean;
}

export const UnitRow = (props: UnitRowProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [files, setFiles] = useState<IEdoFile[]>([]);
    const [anchorEditChapterButton, setAnchorEditChapterButton] =
        useState<null | HTMLElement>(null);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);

    const {
        unit,
        isLastElement,
    } = props;

    const handleEditChapterButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEditChapterButton(event.currentTarget);
    };

    const handleCloseEditChapterMenu = () => {
        setAnchorEditChapterButton(null);
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    const openFile = (file: IEdoFile) => {
        window.open(`/api/files/${file.id}/view`, "_blank");
    };

    const downloadFile = (file: IEdoFile) => {
        window.open(`/api/files/${file.id}/download`, "_blank");
    };

    useEffect(() => {
        const loadFiles = async (unitItem: IEdoUnit) => {
            setLoadingFiles(true);
            const response = await axios.get(`/units/${unitItem.id}/files`);
            setFiles(response.data.data);
            setLoadingFiles(false);
        };

        if (unit && open) {
            loadFiles(unit);
        }
    }, [unit, open]);

    useEffect(() => {
        if (!unit || loadingFiles) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [unit, loadingFiles]);

    const getContent = () => {
        if (loading) {
            return (<Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <CircularProgress />
            </Box>);
        }

        if (!files.length) {
            return (<Box p={2}>
                <Typography variant="body1" align="center">
                    Nessun materiale contenuto nell'unità
                </Typography>
            </Box>);
        }

        return (<>
            {files.length ? (
                <List component="div" disablePadding>
                    {files.map((file) => (
                        <ListItem key={file.id} sx={(theme) => ({
                            paddingLeft: 4,
                            [theme.breakpoints.down("sm")]: {
                                paddingLeft: 2,
                            },
                        })} button>
                            <ListItemAvatar>
                                {getFileAvatar(file.fileType)}
                            </ListItemAvatar>
                            <ListItemText
                                primary={file.originalFileName}
                                primaryTypographyProps={{ variant: "body2", noWrap: true }}
                                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                                secondary={
                                    <TextFormat
                                        value={file.createdAt}
                                        type="date"
                                        format={APP_LOCAL_DATE_FORMAT}
                                    />
                                }
                            />
                                <IconButton
                                    onClick={() => openFile(file)}
                                    edge="end"
                                    aria-label="Open file"
                                    sx={{ marginRight: 0.5 }}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => downloadFile(file)}
                                    edge="end"
                                    aria-label="Download file"
                                    sx={{ marginRight: 0.75 }}
                                >
                                    <GetAppIcon />
                                </IconButton>
                        </ListItem>
                    ))}
                </List>
            ) : null}
        </>);
    };

    return (
        <>
            <ListItem
                button
                onClick={handleOpen}
            >
                <ListItemAvatar>
                    <Avatar>
                        <BookmarkIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={unit.title}
                    primaryTypographyProps={{ variant: "body2" }}
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    secondary={
                        <TextFormat
                            value={unit.createdAt}
                            type="date"
                            format={APP_LOCAL_DATE_FORMAT}
                        />
                    }
                />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>

                {getContent()}

              
          
                {!isLastElement ? <Divider /> : null}
            </Collapse>
        </>
    );
};
