import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { AppDispatch, AppThunk } from "config/store";
import { IUser } from "shared/model/user.model";
import { serializeAxiosError } from "./reducer.utils";

export const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  account: {} as IUser,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  logoutSuccess: false,
  accounts: { loading: false, success: false, users: [] as IUser[], error: false, errorMessage: null as unknown as string }
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => (dispatch, getState) => {
  dispatch(getAccount());
};

export const getAccount = createAsyncThunk(
  "authentication/get_account",
  async () => axios.get<any>("/account"),
  {
    serializeError: serializeAxiosError,
  }
);

interface IAuthParams {
  id: number;
  password: string;
  rememberMe?: boolean;
}

export const getAccounts = createAsyncThunk(
  "authentication/accounts",
  async (auth: { username: string, password: string, rememberMe: boolean }) =>
    axios.post<{ data: IUser[] }>("/auth/accounts", { email: auth.username, password: auth.password, rememberMe: auth.rememberMe || false }),
  {
    serializeError: serializeAxiosError,
  }
);

export const signin = createAsyncThunk(
  "authentication/login",
  async (auth: IAuthParams) =>
    axios.post<{ access_token: string }>("/auth/login", auth),
  {
    serializeError: serializeAxiosError,
  }
);

export const signout = createAsyncThunk(
  "authentication/logout",
  async () => axios.post("/auth/logout"),
  {
    serializeError: serializeAxiosError,
  }
);

export const login: (
  id: number,
  password: string,
  rememberMe?: boolean
) => AppThunk =
  (id, password, rememberMe = false) =>
    async (dispatch) => {
      const result = await dispatch(signin({ id, password, rememberMe }));

      const response = result.payload as AxiosResponse;
      const jwt = response?.data?.access_token;
      if (jwt) {
        dispatch(getSession());
      }
    };

export const logout: () => AppThunk = () => async (dispatch) => {
  await dispatch(signout());
};

export const clearAuthentication = (messageKey: string) => (dispatch: AppDispatch) => {
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState as AuthenticationState,
  reducers: {
    authError(state, action) {
      return {
        ...state,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    },
    clearAccounts(state) {
      return {
        ...state,
        accounts: {
          ...state.accounts,
          users: []
        }
      };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAccounts.rejected, (state, action) => ({
        ...initialState,
        loginError: true,
        accounts: {
          ...state.accounts,
          error: true,
          errorMessage: action.error.message,
          loading: false,
        }
      }))
      .addCase(getAccounts.fulfilled, (state, action) => ({
        ...state,
        accounts: {
          ...state.accounts,
          users: action.payload.data.data,
          loading: false,
          success: true
        }
      }))
      .addCase(signin.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        loginError: true,
      }))
      .addCase(signin.fulfilled, (state) => ({
        ...state,
        loading: false,
        loginError: false,
        loginSuccess: true,
        accounts: {
          ...state.accounts,
        }
      }))
      .addCase(signout.rejected, (state, action) => ({
        ...state,
        loading: false,
        errorMessage: action.error.message,
        loginError: true,
        logoutSuccess: false,
      }))
      .addCase(signout.fulfilled, (state) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        account: {},
        logoutSuccess: true,
      }))
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data;
        // const isAuthenticated =
        //   action.payload &&
        //   action.payload.data &&
        //   action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data.data,
        };
      })
      .addCase(signout.pending, (state) => {
        state.loading = true;
      })
      .addCase(signin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccounts.pending, (state) => {
        state.accounts.loading = true;
      });
  },
});

export const { authError, clearAuth, clearAccounts } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
