import { IUser } from "shared/model/user.model";
import { IEdoCourse } from "./edo-course.model";
import { IEdoQuiz } from "./edo-quiz.model";

export interface IEdoCourseQuiz {
  id?: number;
  author?: IUser;
  quiz?: IEdoQuiz;
  course?: IEdoCourse;
  availableAttempts?: number;
  duration?: number;
  timeOpen?: string;
  timeClose?: string;
  allowReview?: boolean;
  updatedAt?: string;
  createdAt?: string;
}

export const defaultValue: Readonly<IEdoCourseQuiz> = {
  timeOpen: null,
  timeClose: null,
  allowReview: true,
};
