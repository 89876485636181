import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import axios from "axios";
import { IEdoClass } from "shared/model/edo-class.model";
import { IEdoStudent } from "shared/model/edo-student.model";
import { IUserBase } from "shared/model/user.model";
import { IPaginationResponse } from "shared/util/pagination.constants";
import {
    IQueryParamsWithFilters,
    serializeAxiosError
} from "../reducer.utils";

export const initialState: {
    count: number | null;
    loadingCount: boolean;
    classes: IEdoClass[];
    loadingClasses: boolean;
    errorMessage: string | null;
    totalItems: number;
    entity: { entity: IEdoStudent; loading: boolean; updating: boolean; updateSuccess: boolean };
} = {
    count: null,
    loadingCount: false,
    classes: [],
    loadingClasses: false,
    errorMessage: null,
    totalItems: 0,
    entity: {
        entity: null,
        loading: false,
        updating: false,
        updateSuccess: false
    }
};

export type EdoStudentState = Readonly<typeof initialState>;

const apiUrl = "/students";

// Actions

export const createStudent = createAsyncThunk(
    "edoStudent/create_student",
    async (user: IUserBase, thunkAPI) => {
        const result = await axios.post<{ data: IEdoStudent }>(apiUrl, user);
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const getStudent = createAsyncThunk(
    "edoStudent/get_student",
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<{ data: IEdoStudent }>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);


export const getStudentClasses = createAsyncThunk(
    "edoStudent/fetch_student_classes",
    async ({ id, page, size, sort, filters }: { id: number } & IQueryParamsWithFilters) => {
        const params = new URLSearchParams(filters);
        if (sort) {
            params.append("page", String(page));
            params.append("limit", String(size));
            if (typeof sort === "string") {
                params.append("sortBy", sort.replace(",", ":"));
            } else {
                for (let s of sort) {
                    params.append("sortBy", s.replace(",", ":"));
                }
            }
        }
        let requestUrl;
        if (params.toString() === "") {
            requestUrl = `${apiUrl}/${id}/classes`;
        } else {
            requestUrl = `${apiUrl}/${id}/classes?${params.toString()}`;
        }
        return axios.get<IPaginationResponse<IEdoClass>>(requestUrl);
    }
);

export const getCount = createAsyncThunk(
    "edoStudent/fetch_count",
    async () => {
        const requestUrl = `${apiUrl}/count`;
        return axios.get<{ count: number }>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

// slice

export const EdoStudentSlice = createSlice({
    name: "edoStudent",
    initialState: initialState as EdoStudentState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(
                getStudentClasses.fulfilled,
                (state, action) => {
                    const {
                        data: {
                            data,
                            meta: { totalItems },
                        },
                        headers,
                    } = action.payload;

                    return {
                        ...state,
                        loadingClasses: false,
                        classes: data,
                        totalItems,
                    };
                }
            )
            .addCase(getCount.fulfilled, (state, action) => ({
                ...state,
                loadingCount: false,
                count: action.payload.data.count
            }))
            .addCase(
                getCount.pending,
                (state) => {
                    state.errorMessage = null;
                    state.loadingCount = true;
                }
            )
            .addMatcher(
                isPending(getStudentClasses),
                (state) => {
                    state.errorMessage = null;
                    state.loadingClasses = true;
                }
            )
            .addMatcher(isFulfilled(createStudent), (state, action) => {
                state.entity.updating = false;
                state.entity.loading = false;
                state.entity.updateSuccess = true;
                state.entity.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getStudent), (state, action) => {
                state.entity.loading = false;
                state.entity.entity = action.payload.data.data;
            })
            .addMatcher(isPending(createStudent), (state) => {
                state.errorMessage = null;
                state.entity.updateSuccess = false;
                state.entity.updating = true;
            })
            .addMatcher(isPending(getStudent), (state) => {
                state.errorMessage = null;
                state.entity.updateSuccess = false;
                state.entity.loading = true;
            })
            .addMatcher(
                isRejected(
                    createStudent,
                ),
                (state, action) => {
                    state.entity.loading = false;
                    state.entity.updating = false;
                    state.entity.updateSuccess = false;
                    state.errorMessage = action.error.message;
                }
            );
    },
});

// Reducer
const EdoStudentReducer = EdoStudentSlice.reducer;
export default EdoStudentReducer;
