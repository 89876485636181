import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from "@reduxjs/toolkit";
import axios from "axios";
import { IUser, defaultValue } from "shared/model/user.model";
import {
  IQueryParamsWithFilters,
  serializeAxiosError
} from "shared/reducers/reducer.utils";
import { IPaginationResponse } from "shared/util/pagination.constants";

const initialState = {
  loading: false,
  errorMessage: null as string | null,
  users: [] as ReadonlyArray<IUser>,
  roles: [] as any[],
  user: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

const apiUrl = "/users";
const adminUrl = "/admin/users";

// Async Actions

export const getUsers = createAsyncThunk(
  "userManagement/fetch_users",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `${apiUrl}`;
    } else {
      requestUrl = `${apiUrl}?${params.toString()}`;
    }

    return axios.get<IPaginationResponse<IUser>>(requestUrl);
  }
);

export const getRoles = createAsyncThunk(
  "userManagement/fetch_roles",
  async () => {
    return axios.get<any[]>(`/roles`);
  }
);

export const getUser = createAsyncThunk(
  "userManagement/fetch_user",
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<{ data: IUser }>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createUser = createAsyncThunk(
  "userManagement/create_user",
  async (user: IUser, thunkAPI) => {
    const result = await axios.post<{ data: IUser }>(adminUrl, user);
    // thunkAPI.dispatch(getUsers({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUser = createAsyncThunk(
  "userManagement/update_user",
  async (user: IUser, thunkAPI) => {
    const requestUrl = `${adminUrl}/${user.id}`;
    const result = await axios.put<{ data: IUser }>(requestUrl, user);
    // thunkAPI.dispatch(getUsers({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteUser = createAsyncThunk(
  "userManagement/delete_user",
  async (id: string, thunkAPI) => {
    const requestUrl = `${adminUrl}/${id}`;
    const result = await axios.delete<IUser>(requestUrl);
    // thunkAPI.dispatch(getUsers({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export type UserManagementState = Readonly<typeof initialState>;

export const UserManagementSlice = createSlice({
  name: "userManagement",
  initialState: initialState as UserManagementState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload.data;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data.data;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.user = defaultValue;
      })
      .addMatcher(isFulfilled(getUsers), (state, action) => {
        state.loading = false;
        state.users = action.payload.data.data;
        state.totalItems = action.payload.data.meta.totalItems;
      })
      .addMatcher(isFulfilled(createUser, updateUser), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.user = action.payload.data.data;
      })
      .addMatcher(isPending(getUsers, getUser), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createUser, updateUser, deleteUser), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(
        isRejected(
          getUsers,
          getUser,
          getRoles,
          createUser,
          updateUser,
          deleteUser
        ),
        (state, action) => {
          state.loading = false;
          state.updating = false;
          state.updateSuccess = false;
          state.errorMessage = action.error.message;
        }
      );
  },
});

export const { reset } = UserManagementSlice.actions;

// Reducer
export default UserManagementSlice.reducer;
