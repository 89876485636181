import {
  GetApp as GetAppIcon, Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText, Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { APP_DATE_FORMAT_MID } from "config/constants";
import "moment/locale/it";
import { TextFormat } from "react-jhipster";
import { IEdoCircular } from "shared/model/edo-circular.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";


interface ViewCircularProps {
  open: boolean;
  circular: IEdoCircular;
  onClose: () => void;
}

const ViewCircular = (props: ViewCircularProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { circular, open, onClose: handleClose } = props;

  const openFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  const downloadFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/download`, "_blank");
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullScreen={fullScreen}
      fullWidth
    >
      {!circular?.id ? (
        <CircularProgress
          size={24}
          style={{ marginLeft: 15, position: "relative", top: 4 }}
        />
      ) : (
        <>
          <DialogTitle>
            {"Circolare del "}{" "}
            <TextFormat
              type="date"
              value={circular.date}
              format={APP_DATE_FORMAT_MID}
              locale="it"
            />
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography gutterBottom variant="h5" component="h2">
                {circular.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {circular.description}
              </Typography>
            </Box>
            {circular.files.length ? (
              <Box pt={2}>
                <Divider />
                <Box pt={2}>
                  <List disablePadding dense>
                    {circular.files.map((file) => (
                      <ListItem key={file.id} disableGutters>
                        <ListItemAvatar>
                          {getFileAvatar(file.fileType)}
                        </ListItemAvatar>
                        <ListItemText
                          primary={file.originalFileName}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => openFile(file)}
                            edge="end"
                            aria-label="Open file"
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => downloadFile(file)}
                            edge="end"
                            aria-label="Download file"
                          >
                            <GetAppIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            ) : null}
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewCircular;