import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoWikiPost } from "shared/model/edo-wiki-post.model";
import { EntityState } from "shared/reducers/reducer.utils";
import {
  deleteEntity
} from "../../../shared/reducers/entities/edo-wiki-post.reducer";

interface DeleteWikiPostProps {
  wikiPost: IEdoWikiPost;
  open: boolean;
  onClose: () => void;
}

const DeleteWikiPost = (props: DeleteWikiPostProps) => {
  const dispatch = useAppDispatch();
  const { updateSuccess } = useAppSelector<EntityState<IEdoWikiPost>>(
    (state) => state.edoWikiPost
  );

  const { wikiPost, open, onClose: handleClose } = props;

  const confirmDelete = () => {
    dispatch(deleteEntity(wikiPost.id));
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      {!wikiPost ? (
        <CircularProgress
          size={24}
          style={{ marginLeft: 15, position: "relative", top: 4 }}
        />
      ) : (
        <DialogContent>
          <DialogContentText>
            <Translate
              contentKey="edocendoApp.edoWikiPost.delete.question"
              interpolate={{ nome: wikiPost.title }}
            >
              Are you sure you want to delete this EdoPostWiki?
            </Translate>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button onClick={confirmDelete} color="primary">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWikiPost;