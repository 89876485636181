import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    MoreVert as MoreVertIcon
} from "@mui/icons-material";
import {
    Avatar, Box, IconButton, ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem, Typography
} from "@mui/material";
import { APP_DATE_FORMAT_MID } from "config/constants";
import React, { useEffect, useState } from 'react';
import { TextFormat } from "react-jhipster";
import { useNavigate } from 'react-router-dom';
import { IEdoQuestion } from "shared/model/edo-question.model";
import { getQuestionIcon } from "shared/util/question-utils";

interface IQuestionRowProps {
    question: IEdoQuestion;
    onView: (question: IEdoQuestion) => void
    onEdit: (question: IEdoQuestion) => void
    onDelete: (question: IEdoQuestion) => void;
}

export const QuestionRow = (props: IQuestionRowProps) => {
    const navigate = useNavigate();
    const [localQuestion, setLocalQuestion] = useState<IEdoQuestion>(props.question);
    const [anchorMoreQuestion, setAnchorMoreQuestion] =
        useState<null | HTMLElement>(null);

    const { question, onView: handleView, onEdit: handleEdit, onDelete: handleDelete } = props;

    const handleOpenQuestion = () => {
        navigate(`/questions/${question.id}`);
    };

    const handleMoreQuestionButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorMoreQuestion(event.currentTarget);
    };

    const handleCloseMoreQuestionMenu = () => {
        setAnchorMoreQuestion(null);
    };

    useEffect(() => {
        if (question) {
            setLocalQuestion(question);
        }
    }, [question]);

    return (
        <ListItem button onClick={() => handleView(localQuestion)}>
            <ListItemAvatar>
                <Avatar alt={localQuestion.description}>
                    { getQuestionIcon(question.type) }
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={localQuestion.description}
                secondary={<TextFormat
                    type="date"
                    value={localQuestion.createdAt}
                    format={APP_DATE_FORMAT_MID}
                    locale="it"
                />}
            />
            <ListItemSecondaryAction>
                <Box display="flex" flexDirection="row">
                    <Box mr={2}>
                        <IconButton
                            edge="end"
                            onClick={handleMoreQuestionButtonClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id="more-question-menu"
                        anchorEl={anchorMoreQuestion}
                        keepMounted
                        open={Boolean(anchorMoreQuestion)}
                        onClose={handleCloseMoreQuestionMenu}
                        disableScrollLock={true}
                    >
                        <MenuItem onClick={() => {
                            handleCloseMoreQuestionMenu();
                            handleEdit(localQuestion);
                        }}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Modifica domanda</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleCloseMoreQuestionMenu();
                            handleDelete(localQuestion);
                        }
                        }>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                Elimina domanda
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default QuestionRow;

