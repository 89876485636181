import { Class as ClassIcon } from "@mui/icons-material";
import { Box, Chip, Paper, Typography } from "@mui/material";
import { APP_DATE_FORMAT_EXPANDED } from "config/constants";
import { TextFormat } from "react-jhipster";
import { useNavigate } from "react-router-dom";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";

interface ICourseMaterialProps {
    courseMaterial: IEdoCourseMaterial;
}

const CourseMaterial = (props: ICourseMaterialProps) => {
    const navigate = useNavigate();

    const { courseMaterial } = props;

    const handleOpenCourse = () => {
        navigate(`/courses/${courseMaterial.course.id}/materials`);
    };

    return (
        <Paper
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "85px",
                width: "100%",
                borderRadius: 5,
                cursor: "pointer",
            }}
            onClick={handleOpenCourse}
        >
            <Box sx={{ height: "100%", paddingTop: 1.5, paddingLeft: 1.5, paddingBottom: 1.5 }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ borderRadius: 3, backgroundColor: "#c5c4c4", height: "100%", aspectRatio: "1", color: "white", fontSize: "25px" }}>
                    <ClassIcon />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" p={2}>
                <Typography variant="h6" component="h2">
                    {courseMaterial.title}
                    <Chip sx={{ marginLeft: 1 }} size="small" label={courseMaterial.course.subject.name + " - " + courseMaterial.course.class.year.year + "°" + courseMaterial.course.class.section} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <TextFormat
                        type="date"
                        value={courseMaterial.createdAt}
                        format={APP_DATE_FORMAT_EXPANDED}
                        locale="it"
                    />
                </Typography>
            </Box>
        </Paper>
    );
};

export default CourseMaterial;