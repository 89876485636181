import { IUser } from "shared/model/user.model";

export interface IEdoQuiz {
  id?: number;
  title?: string;
  description?: string;
  author?: IUser;
  originalQuiz?: IEdoQuiz;
  repository?: boolean;
  updatedAt?: string;
  createdAt?: string;
}

export const defaultValue: Readonly<IEdoQuiz> = {};
