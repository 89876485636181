import { Box, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState } from "react-jhipster";
import { Navigate } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getCurrentUserWallPosts } from "../../shared/reducers/entities/edo-wall-post.reducer";
import Circolari from "./../home/components/Circolari";
import Eventi from "./../home/components/Eventi";
import Course from "./components/Course";
import CourseMaterial from "./components/CourseMaterial";
import CourseSkeleton from "./components/CourseSkeleton";

const Dashboard = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isMediaSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { account, isAuthenticated } = useAppSelector<AuthenticationState>((state) => state.authentication);
    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        activePage: 1,
        itemsPerPage: 10,
        sort: "data",
        order: "DESC",
    });
    const [courseMaterials, setCourseMaterials] = useState<IEdoCourseMaterial[]>([]);
    const [courses, setCourses] = useState<{ entities: IEdoCourse[]; loading: boolean; }>({ entities: [], loading: false });

    const getAllEntities = () => {
        dispatch(
            getCurrentUserWallPosts({
                page: paginationState.activePage,
                size: paginationState.itemsPerPage,
                sort: `dataCreation:DESC`,
            })
        );
    };

    const sortEntities = () => {
        getAllEntities();
        const endURL = `?page=${paginationState.activePage}&sort=dataCreation:DESC`;
    };

    useEffect(() => {
        sortEntities();
    }, [paginationState.activePage, paginationState.order, paginationState.sort]);

    const handlePagination = (currentPage: number) => {
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });
    };

    // useEffect(() => {
    //     if (updateSuccess) {
    //         getAllEntities()
    //     }
    // }, [updateSuccess]);

    const getLastCourseMaterials = async () => {
        const response = await axios.get<{ data: IEdoCourseMaterial[] }>(`/students/${account.id}/course-materials`, {
            params: {
                page: 0,
                limit: 5,
                sortBy: "dataCreation,desc"
            }
        });
        setCourseMaterials(response.data.data);
    };

    const getStudentCourses = async () => {
        setCourses({ entities: [], loading: true });
        const response = await axios.get<{ data: IEdoCourse[] }>(`/students/${account.id}/courses`, {
            params: {
                page: 0,
                limit: 1000,
                sortBy: "subject.name,desc"
            }
        });
        setCourses({ entities: response.data.data, loading: false });
    };

    useEffect(() => {
        if (account.id) {
            getLastCourseMaterials();
            getStudentCourses();
        }
    }, [account?.id]);

    const getGreetingsByTime = () => {
        const date = new Date();
        const hours = date.getHours();
        if (hours >= 5 && hours < 12) {
            return "Buongiorno";
        } else if (hours >= 12 && hours < 18) {
            return "Buon pomeriggio";
        } else {
            return "Buonasera";
        }
    };

    if (isAuthenticated && account) {
        if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_PARENT])) {
            return <Navigate to="/parents" />;
        } else if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_VICE, AUTHORITIES.ROLE_TEACHER])) {
            return <Navigate to="/feed" />;
        }
    }

    return (
        <>
            {/* <Box flexGrow={1} minHeight="100vh" overflow="auto"> */}
            <Container maxWidth="xl" sx={(theme) => ({
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(4),
            })}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={9}>
                        <Box pb={2}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                {getGreetingsByTime()} {account.firstName}!
                            </Typography>
                        </Box>

                        <Box pb={2}>
                            <Box pb={2}>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    I tuoi Corsi
                                </Typography>
                            </Box>
                            <Grid container spacing={2} mb={2}>
                                {courses.loading && !courses.entities.length ?
                                    [...Array(13).keys()].map((t, index) => (
                                        <Grid key={index} item xs={isMediaSm ? 12 : 4}>
                                            <CourseSkeleton />
                                        </Grid>
                                    )) : courses.entities.map(course => (
                                        <Grid key={course.id} item xs={isMediaSm ? 12 : 4}>
                                            <Course
                                                course={course}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Box>
                        <Box pb={2}>
                            <Box pb={2}>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    Materiale aggiunto di recente
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" mb={2}>
                                {courseMaterials.map(courseMaterial => (
                                    <Box key={courseMaterial.id} mb={2} sx={{ width: "100%" }}>
                                        <CourseMaterial courseMaterial={courseMaterial} />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Box pt={2}>
                            <Box mb={2}>
                                <Circolari />
                            </Box>
                            <Box>
                                <Eventi />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            {/* </Box> */}
        </>
    );
};

export default Dashboard;
