import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { IEdoQuestionAnswer, defaultValue } from "shared/model/edo-question-answer.model";
import { IEdoQuestionChoice } from "shared/model/edo-question-choice.model";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { IEdoQuizQuestion } from "shared/model/edo-quiz-question.model";
import { EdoQuestionTypeEnum } from "shared/model/enumerations/edo-question-type.mode";
import { getQuestionType } from 'shared/util/question-utils';

interface ICourseQuestionProps {
    quizQuestion: IEdoQuizQuestion;
    quizAttempt: IEdoQuizAttempt;
    className?: string;
}

const CourseQuestion = (props: ICourseQuestionProps) => {
    const [form, setForm] = useState<IEdoQuestionAnswer>({ ...defaultValue, question: props.quizQuestion.question, quizAttempt: props.quizAttempt });
    const [choices, setChoices] = useState<IEdoQuestionChoice[]>([]);
    const { quizQuestion: { question }, quizAttempt, className } = props;

    const isNew = form?.id === null || form?.id === undefined;

    const autoSaveTimer = useRef(null);

    const autoSave = (change: IEdoQuestionAnswer) => {

        // if (!change.description && !change.explanation) return;

        if (autoSaveTimer.current) clearTimeout(autoSaveTimer.current);
        // setSaving("saving")
        autoSaveTimer.current = setTimeout(() => {
            saveAnswer(change);
        }, 3000);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const change = {
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        };
        autoSave(change);
        setForm(change);
    };

    const getUserAnswer = async () => {
        const response = await axios.get(`/quiz-attempts/${quizAttempt.id}/questions/${question.id}/answer`);
        if (response.data.data) {
            setForm(response.data.data);
        }
    };

    const saveAnswer = async (change: IEdoQuestionAnswer) => {
        if (isNew) {
            const response = await axios.post(`question-answers`, change);
            setForm(response.data.data);
            // setChoices(response.data.data.choices)
        } else {
            const response = await axios.put(`question-answers/${change.id}`, change);
            setForm(response.data.data);
            // setChoices(response.data.data.choices)
        }
    };

    const handleGetChoices = async () => {
        // const response = await axios.get<{ data: IEdoQuestionChoice[] }>(`/quizzes/${quizAttempt.courseQuiz.id}/questions/${question.id}/choices`);
        const response = await axios.get<{ data: IEdoQuestionChoice[] }>(`/questions/${question.id}/choices`);
        setChoices(response.data.data);
    };

    useEffect(() => {
        if (quizAttempt?.courseQuiz?.id && (question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE || question.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question.type === EdoQuestionTypeEnum.FILL_BLANK)) {
            handleGetChoices();
        }

        getUserAnswer();
    }, [question, quizAttempt?.courseQuiz?.id]);


    const getMultipleChoiceOptions = () => {

        if (!choices?.length) {
            return (
                <Box p={2}>
                    <Typography variant="body1" align="center">
                        <Translate contentKey="edocendoApp.edoQuestionChoice.home.notFound">
                            No Edo Question Choices found
                        </Translate>
                    </Typography>
                </Box>
            );
        }

        if (question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE) {

            return (
                <FormControl sx={{ mt: 2 }}>
                    <FormLabel id="question-choices-label" sx={{ mb: 1 }}>Risposte disponibili</FormLabel>
                    <RadioGroup
                        aria-labelledby="question-choices-label"
                        name="question-choices-group"
                    >
                        {choices.map((choice, index) => (
                            <FormControlLabel
                                key={choice.id}
                                value={choice.id}
                                control={<Radio />}
                                checked={form?.choices?.findIndex(c => c.id === choice.id) !== -1}
                                label={choice.description}
                                onChange={(event, checked) => {
                                    setChoices([...choices]);
                                    const change = { ...form, choices: [choice] };
                                    saveAnswer(change);
                                    setForm(change);
                                }} />
                        ))}
                    </RadioGroup>
                </FormControl>
            );

        } else {
            return (
                <FormControl sx={{ mt: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend" sx={{ mt: 2, mb: 1 }}>Risposte disponibili</FormLabel>
                    <FormGroup>
                        {choices.map((choice, index) => (
                            <FormControlLabel
                                key={choice.id}
                                value={choice.id}
                                control={<Checkbox />}
                                checked={form?.choices?.findIndex(c => c.id === choice.id) !== -1}
                                label={choice.description}
                                onChange={(event, checked) => {
                                    let choicesCopy = Array.from(form.choices);
                                    if (checked) {
                                        choicesCopy.push(choice);
                                    } else {
                                        const choiceIndex = choicesCopy.findIndex(c => c.id === choice.id);
                                        choicesCopy.splice(choiceIndex, 1);
                                    }
                                    const change = { ...form, choices: choicesCopy };
                                    saveAnswer(change);
                                    setForm(change);
                                }}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            );
        }
    };


    return (
        <Paper className={className}>
            <Box p={3}>
                <Box mb={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                    <Box>
                        <Box mb={1}>
                            <Typography variant="body1" color="text.primary">
                                {question.description}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                {getQuestionType(question.type)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box flexGrow={1}>
                    {question.type === EdoQuestionTypeEnum.FREE_TEXT ? (
                        <Box mb={1}>
                            <TextField
                                variant="filled"
                                multiline
                                rows={4}
                                // size="small"
                                fullWidth
                                required
                                label={translate("edocendoApp.edoQuestionAnswer.description")}
                                onChange={handleChange}
                                name="description"
                                value={form?.description || ""}
                            />
                        </Box>
                    ) : null}
                    {question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE || question.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question.type === EdoQuestionTypeEnum.FILL_BLANK ? (
                        <Box mt={2}>
                            {getMultipleChoiceOptions()}
                        </Box>
                    ) : null}
                    {question.explanationRequired ? (
                        <Box mt={2}>
                            <TextField
                                variant="filled"
                                multiline
                                rows={4}
                                // size="small"
                                fullWidth
                                required
                                label={translate("edocendoApp.edoQuestionAnswer.explanation")}
                                onChange={handleChange}
                                name="explanation"
                                value={form?.explanation || ""}
                            />
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Paper>
    );
};

export default CourseQuestion;