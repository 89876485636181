import {
  AppBar,
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TablePagination, Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { IEdoFriendship } from "shared/model/edo-friendship.model";
import { getCurrentUserFriendships } from "shared/reducers/entities/edo-friendship.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: "grid",
    gridTemplateRows: "min-content auto min-content",
    height: "100%",
  },
  appbar: {
    boxShadow: "none",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

interface FriendsListProps {
  roleFilter: string;
  title: string;
}

const FriendsList = (props: FriendsListProps) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "createdAt",
    order: "ASC",
  });
  const { entities: friendships, totalItems } = useAppSelector<EntityState<IEdoFriendship>>(state => state.edoFriendship);

  const { roleFilter, title } = props;

  const handleGetFriendships = async () => {
    dispatch(getCurrentUserFriendships({
      page: paginationState.activePage,
      size: paginationState.itemsPerPage,
      sort: `${paginationState.sort},${paginationState.order}`,
      ...(roleFilter ? { role: roleFilter } : {})
    }));
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  useEffect(() => {
    handleGetFriendships();
  }, [roleFilter,
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
  ]);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.root}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.appbar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography variant="h5">{title}</Typography>
          </Toolbar>
        </AppBar>
        {friendships && friendships.length > 0 ? (
          <List dense>
            {friendships.map(({ friend }) => (
              <ListItem key={friend.id}>
                <ListItemAvatar>
                  <Avatar
                    alt={
                      friend.firstName + " " + friend.lastName
                    }
                  >
                    {friend.firstName[0] + friend.lastName[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    friend.firstName + " " + friend.lastName
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box p={2}>
            <Typography variant="body1" align="center">
              <Translate contentKey="edocendoApp.edoUser.home.notFound" />
            </Typography>
          </Box>
        )}
        <Divider />
        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={paginationState.itemsPerPage}
          page={paginationState.activePage - 1}
          onPageChange={(event, page) => handlePagination(page + 1)}
          onRowsPerPageChange={(event) =>
            handleRowsPerPage(parseInt(event.target.value, 10))
          }
          labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
          }
        />
      </Box>
    </Paper>
  );
};

export default FriendsList;
