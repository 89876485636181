import {
  Avatar, ListItem,
  ListItemAvatar,
  ListItemButton, ListItemText
} from "@mui/material";
import axios from "axios";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { IUser } from "shared/model/user.model";

interface TeacherRowProps {
  teacher: IUser;
  viewTeacher: (teacher: IUser) => void;
}

const TeacherRow = (props: TeacherRowProps) => {
  const [countInsegnamenti, setCountInsegnamenti] = useState<number>(0);
  const [countMateriale, setCountMateriale] = useState<number>(0);

  const { teacher, viewTeacher: handleViewTeacher } = props;

  const getCountInsegnamenti = async () => {
    const response = await axios.get<{ count: number }>(
      `/teachers/${teacher.id}/courses/count`
    );
    setCountInsegnamenti(response.data.count);
  };

  const getCountMateriale = async () => {
    const response = await axios.get<{ count: number }>(
      `/teachers/${teacher.id}/course-materials/count`
    );
    setCountMateriale(response.data.count);
  };

  useEffect(() => {
    if (teacher) {
      getCountInsegnamenti();
      getCountMateriale();
    }
  }, [teacher]);

  return (
    <ListItem disableGutters disablePadding>
      <ListItemButton onClick={() => handleViewTeacher(teacher)}>
        <ListItemAvatar>
          <Avatar alt={teacher.firstName}>{teacher.firstName ? teacher.firstName[0] : "U"}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={teacher.firstName + " " + teacher.lastName}
          secondary={`Insegnamenti: ${countInsegnamenti}, Materiale: ${countMateriale}`}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default TeacherRow;