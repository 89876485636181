import { Videocam as VideocamIcon } from "@mui/icons-material";
import { TabPanelProps } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { AUTHORITIES } from "config/constants";
import { useAppSelector } from "config/store";
import { SyntheticEvent, useMemo, useState } from "react";
import { Translate } from "react-jhipster";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoClassZoomLink } from "shared/model/edo-class-zoom-link.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EdoStudentState } from "shared/reducers/entities/edo-student.reducer";
import { EdoTeacherState } from "shared/reducers/entities/edo-teacher.reducer";
import CoursesGroup from "./components/CoursesGroup";

function TabPanel(props: TabPanelProps & { index: string;  }) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const Classes = () => {
  const [tab, setTab] = useState<number>(0);
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const { classes: teacherClasses } = useAppSelector<EdoTeacherState>(state => state.edoTeacher);
  const { classes: studentClasses } = useAppSelector<EdoStudentState>(state => state.edoStudent);
  const [classZoomLinksMenu, setClassZoomLinksMenu] = useState<{ anchorEl: null | HTMLElement, links: IEdoClassZoomLink[] }>({ anchorEl: null, links: [] });

  const handleChangeTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setTab(newValue);
  };

  let classes = useMemo(() => {
    if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_TEACHER])) {
      return teacherClasses;
    } else if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_STUDENT])) {
      return studentClasses;
    }
    return [];
  }, [account, teacherClasses, studentClasses]);

  const handleOpenZoomLink = (zoomLink: IEdoClassZoomLink) => {
    window.open(zoomLink.zoomInviteLink, "_blank");
    setClassZoomLinksMenu({ anchorEl: null, links: [] });
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper style={{ overflow: "hidden" }}>
          <Box p={2} display="flex" justifyContent="start" alignItems="center">
            <Typography variant="h6">
              <Translate contentKey="edocendoApp.edoClass.home.title" />
            </Typography>
          </Box>
          <Divider />
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {classes.length > 0 &&
              classes.map(edoClass => (
                <Tab
                  key={edoClass.id}
                  label={`${edoClass.year?.year}°${edoClass.section} (${edoClass.school.code})`}
                />
              ))}
          </Tabs>
          <Divider />
          {classes.length > 0 ? classes.map((edoClass, index) => (
            <TabPanel key={edoClass.id} value={String(tab)} index={String(index)}>
              <CoursesGroup
                class={edoClass}
                onOpenClassZoomLinksMenu={(anchorEl, links) => setClassZoomLinksMenu({ anchorEl, links })}
              />
            </TabPanel>
          )) : (
            <Box p={2}>
              <Typography variant="body1" align="center">
                <Translate contentKey="edocendoApp.edoCourse.home.notFound" />
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
      <Menu
        id="class-zoom-links-menu"
        anchorEl={classZoomLinksMenu.anchorEl}
        open={classZoomLinksMenu.anchorEl !== null && classZoomLinksMenu.links.length > 0}
        onClose={() => setClassZoomLinksMenu({ anchorEl: null, links: [] })}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock={true}
      >
        {classZoomLinksMenu.links.map(classZoomLink => (
          <MenuItem key={classZoomLink.id} onClick={() => handleOpenZoomLink(classZoomLink)}>
            <ListItemIcon>
              <VideocamIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {classZoomLink.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Classes;
