import { Tabs } from "@mui/material";
import { JSXElementConstructor, ReactElement, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import LinkTab from "./LinkTab";


interface Tab {
    label: string;
    icon: string | ReactElement<any, string | JSXElementConstructor<any>>;
    iconPosition: "bottom" | "top" | "end" | "start";
    href: string;
    hidden?: boolean;
    pattern?: string;
}

interface NavTabsProps {
    tabs: Tab[];
    centered?: boolean;
    variant?: "standard" | "scrollable" | "fullWidth";
    scrollButtons?: boolean | "auto";
}

const NavTabs = (props: NavTabsProps) => {
    const location = useLocation();
    const [tab, setTab] = useState<number>(0);

    const { tabs, centered, variant, scrollButtons } = props;

    useEffect(() => {
        const match = tabs.findIndex((tab) => {
            return matchPath(tab.pattern ? tab.pattern : tab.href, location.pathname);
        });
        if (match !== -1) {
            setTab(match);
        }
    }, [location]);

    return (
        <Tabs
            // centered={centered}
            
            value={tab}
            variant={variant || "standard"}
            scrollButtons={scrollButtons || "auto"}
        >
            {tabs.map((tab, index) => {
                if (tab.hidden) {
                    return null;
                }

                return (<LinkTab key={index} label={tab.label} icon={tab.icon} iconPosition={tab.iconPosition} href={tab.href} />);
            })}
        </Tabs>
    );
};

export default NavTabs;