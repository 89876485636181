import { Box, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export const CourseSkeleton = () => {

    return (
        <Paper sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "85px",
            width: "100%",
            borderRadius: 5,
            cursor: "pointer",
        }}
        >
            <Box sx={{ height: "100%", paddingTop: 1.5, paddingLeft: 1.5, paddingBottom: 1.5 }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%", aspectRatio: "1" }}>
                    <Skeleton variant="circular" width="100%" height="100%" />
                </Box>
            </Box>
            <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" p={2}>
                <Skeleton animation="wave" height="40px" width="100%" />
                <Skeleton animation="wave" height="30px" width="80%" />
            </Box>
        </Paper>
    );
};

export default CourseSkeleton;