import { Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useAppDispatch } from "config/store";
import { useEffect, useRef, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { NumericFormat } from "react-number-format";
import { IEdoQuestionAnswer, defaultValue } from "shared/model/edo-question-answer.model";
import { IEdoQuestionChoice } from "shared/model/edo-question-choice.model";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { IEdoQuizQuestion } from "shared/model/edo-quiz-question.model";
import { EdoQuestionTypeEnum } from "shared/model/enumerations/edo-question-type.mode";
import { createScore, updateScore } from "shared/reducers/entities/edo-question-answer.reducer";
import { getQuestionType } from 'shared/util/question-utils';

interface IQuestionAnswerProps {
    quizQuestion: IEdoQuizQuestion;
    quizAttempt: IEdoQuizAttempt;
    className?: string;
    onQuestionAnswer: (questionAnswer: IEdoQuestionAnswer) => void;
}

const QuestionAnswer = (props: IQuestionAnswerProps) => {
    const dispatch = useAppDispatch();
    const [questionAnswer, setQuestionAnswer] = useState<IEdoQuestionAnswer>({
        ...defaultValue, question: props.quizQuestion.question,
        quizAttempt: props.quizAttempt
    });
    const [choices, setChoices] = useState<IEdoQuestionChoice[]>([]);

    const { quizQuestion: { question }, quizAttempt, className, onQuestionAnswer: handleOnQuestionAnswer } = props;

    const isNew = questionAnswer?.id === null || questionAnswer?.id === undefined;

    const autoSaveTimer = useRef(null);

    const autoSave = (change: IEdoQuestionAnswer) => {

        // if (!change.description && !change.explanation) return;

        if (autoSaveTimer.current) clearTimeout(autoSaveTimer.current);
        // setSaving("saving")
        autoSaveTimer.current = setTimeout(() => {
            saveAnswer(change);
        }, 3000);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const change = {
            ...questionAnswer,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        };
        autoSave(change);
        setQuestionAnswer(change);
    };

    const getUserAnswer = async () => {
        const response = await axios.get<{ data: IEdoQuestionAnswer }>(`/quiz-attempts/${quizAttempt.id}/questions/${question.id}/answer`);
        if (response.data.data) {
            setQuestionAnswer(response.data.data);
            handleOnQuestionAnswer(response.data.data);
        }
    };

    const saveAnswer = async (change: IEdoQuestionAnswer) => {
        if (isNew) {
            dispatch(createScore({
                score: change.score,
                question: question,
                quizAttempt: quizAttempt,
            })).then((response) => {
                const payload = response.payload as AxiosResponse<{ data: IEdoQuestionAnswer }>;
                setQuestionAnswer(payload.data.data);
                handleOnQuestionAnswer(payload.data.data);
            });
        } else {
            dispatch(updateScore({
                id: change.id,
                score: change.score
            })).then((response) => {
                const payload = response.payload as AxiosResponse<{ data: IEdoQuestionAnswer }>;
                setQuestionAnswer({ ...payload.data.data });
                handleOnQuestionAnswer({ ...payload.data.data });
            });
        }
    };

    const handleGetChoices = async () => {
        const response = await axios.get<{ data: IEdoQuestionChoice[] }>(`/questions/${question.id}/choices`);
        setChoices(response.data.data);
    };

    useEffect(() => {
        if (quizAttempt?.courseQuiz?.id && (question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE || question.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question.type === EdoQuestionTypeEnum.FILL_BLANK)) {
            handleGetChoices();
        }

        getUserAnswer();
    }, [question, quizAttempt?.courseQuiz?.id]);

    const isCorrectChoice = (choice: IEdoQuestionChoice) => {
        return choices?.findIndex(c => c.id === choice.id && choice.isCorrect) !== -1;
    };

    const getChoiceIsDisabled = (choice: IEdoQuestionChoice) => {
        const isUserChoice = questionAnswer?.choices?.findIndex(c => c.id === choice.id) !== -1;
        if (!isCorrectChoice(choice) && !isUserChoice) {
            return true;
        }

        return isCorrectChoice(choice) && !(isUserChoice);
    };

    const getChoiceIsChecked = (choice: IEdoQuestionChoice) => {
        const isUserChoice = questionAnswer?.choices?.findIndex(c => c.id === choice.id) !== -1;

        return isCorrectChoice(choice) || isUserChoice;
    };

    const getChoiceLabel = (choice: IEdoQuestionChoice) => {
        const isUserChoice = questionAnswer?.choices?.findIndex(c => c.id === choice.id) !== -1;

        if (!isCorrectChoice(choice) && !isUserChoice) {
            return choice.description;
        }

        if (isCorrectChoice(choice) && isUserChoice) {
            return (<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                <Typography marginRight={2}>{choice.description}</Typography>
                <Chip label="Corretta" size="small" color="success" />
            </Box>);
        }

        if (!isCorrectChoice(choice) && isUserChoice) {
            return (<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                <Typography marginRight={2}>{choice.description}</Typography>
                <Chip label="Non corretta" size="small" color="error" />
            </Box>);
        }

        if (isCorrectChoice(choice) && !isUserChoice) {
            return choice.description;
        }

    };


    const getMultipleChoiceOptions = () => {

        if (!choices?.length) {
            return (
                <Box p={2}>
                    <Typography variant="body1" align="center">
                        <Translate contentKey="edocendoApp.edoQuestionChoice.home.notFound" />
                    </Typography>
                </Box>
            );
        }

        if (question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE) {

            return (
                <FormControl sx={{ mt: 2 }}>
                    <FormLabel id="question-choices-label" sx={{ mb: 1 }}>Risposte disponibili</FormLabel>
                    <RadioGroup
                        aria-labelledby="question-choices-label"
                        name="question-choices-group"
                    >
                        {choices.map((choice, index) => (
                            <FormControlLabel
                                key={choice.id}
                                value={choice.id}
                                control={<Radio color={isCorrectChoice(choice) ? "success" : "error"} />}
                                checked={getChoiceIsChecked(choice)}
                                label={getChoiceLabel(choice)}
                                disabled={getChoiceIsDisabled(choice)}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            );

        } else {
            return (
                <FormControl sx={{ mt: 2 }} component="fieldset" variant="standard">
                    <FormLabel component="legend" sx={{ mt: 2, mb: 1 }}>Risposte disponibili</FormLabel>
                    <FormGroup>
                        {choices.map((choice, index) => (
                            <FormControlLabel
                                key={choice.id}
                                value={choice.id}
                                control={<Checkbox color={isCorrectChoice(choice) ? "success" : "error"} />}
                                checked={getChoiceIsChecked(choice)}
                                label={getChoiceLabel(choice)}
                                disabled={getChoiceIsDisabled(choice)}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            );
        }
    };


    return (
        <Paper className={className}>
            <Box p={3}>
                <Box mb={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                    <Box>
                        <Box mb={1}>
                            <Typography variant="body1" color="text.primary">
                                {question.description}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                {getQuestionType(question.type)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box flexGrow={1}>
                    {question.type === EdoQuestionTypeEnum.FREE_TEXT ? (
                        <Box mb={1}>
                            <TextField
                                variant="filled"
                                multiline
                                rows={4}
                                // size="small"
                                fullWidth
                                required
                                disabled
                                label={translate("edocendoApp.edoQuestionAnswer.description")}
                                name="description"
                                value={questionAnswer?.description || ""}
                            />
                        </Box>
                    ) : null}
                    {question.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question.type === EdoQuestionTypeEnum.TRUE_FALSE || question.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question.type === EdoQuestionTypeEnum.FILL_BLANK ? (
                        <Box mt={2}>
                            {getMultipleChoiceOptions()}
                        </Box>
                    ) : null}
                    {question.explanationRequired ? (
                        <Box mt={2}>
                            <TextField
                                variant="filled"
                                multiline
                                rows={4}
                                // size="small"
                                fullWidth
                                required
                                label={translate("edocendoApp.edoQuestionAnswer.explanation")}
                                disabled
                                name="explanation"
                                value={questionAnswer?.explanation || ""}
                            />
                        </Box>
                    ) : null}
                </Box>

                <Box mt={1}>
                    <Divider sx={{ marginTop: 2, marginBottom: 2 }} variant="fullWidth" />
                    <NumericFormat
                        onValueChange={(values) => {
                            const change = {
                                ...questionAnswer,
                                score: values.floatValue
                            };
                            autoSave(change);
                            setQuestionAnswer(change);
                        }}
                        label={translate("edocendoApp.edoQuestionAnswer.score")}
                        placeholder={translate("edocendoApp.edoQuestionAnswer.score")}
                        name="score"
                        value={questionAnswer.score}
                        required={true}
                        customInput={TextField}
                        allowNegative
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        decimalScale={2}
                        size="small"
                        variant="outlined"
                        isAllowed={(values) => {
                            return values.floatValue <= question.totalScore;
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                {`max. ${question.totalScore} ${question.totalScore === 1 ? "punto" : "punti"}`}
                            </InputAdornment>,
                        }}
                        disabled={[EdoQuestionTypeEnum.SINGLE_CHOICE, EdoQuestionTypeEnum.TRUE_FALSE, EdoQuestionTypeEnum.MULTIPLE_CHOICE].includes(question?.type)}
                    />
                </Box>
            </Box>
        </Paper>
    );
};

export default QuestionAnswer;