import {
    CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box, Card, CardContent, CardHeader, Divider, Grid,
    List, ListItem, ListItemIcon, ListItemText,
    Link as MaterialLink,
    Typography
} from "@mui/material";
import axios, { AxiosError } from "axios";
import { APP_DATE_FORMAT_EXPANDED } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { IEdoQuestionAnswer } from "shared/model/edo-question-answer.model";
import { IEdoQuizAttempt } from 'shared/model/edo-quiz-attempt.model';
import { IEdoQuizQuestion } from "shared/model/edo-quiz-question.model";
import { getEntity } from "shared/reducers/entities/edo-quiz-attempt.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';
import QuestionAnswer from "./component/QuestionAnswer";


const useStyles = makeStyles()(theme =>
    ({
        question: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(4),
            },
        }
    })
);


const QuizAttemptReviewRoute = () => {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();
    const { updating } = useAppSelector<EntityState<IEdoQuestionAnswer>>(state => state.edoQuestionAnswer);
    const { entity: quizAttempt } = useAppSelector<EntityState<IEdoQuizAttempt>>(state => state.edoQuizAttempt);
    const { updateSuccess: updateSuccessQuizQuestion } = useAppSelector<EntityState<IEdoQuizQuestion>>(state => state.edoQuizQuestion);
    const [questions, setQuestions] = useState<{ quizQuestion: IEdoQuizQuestion, questionAnswer?: IEdoQuestionAnswer }[]>([]);
    const [timeLeft, setTimeLeft] = useState<number>(0);
    const [score, setScore] = useState<{ score: number, totalScore: number }>({ score: 0, totalScore: 0 });
    const [questionAnswer, setQuestionAnswer] = useState<{ quizQuestion: IEdoQuizQuestion, questionAnswer: IEdoQuestionAnswer }>();

    const getQuizQuestions = async () => {
        const response = await axios.get<{ data: IEdoQuizQuestion[] }>(`/quiz-attempts/${params.id}/questions`, {
            params: {
                sort: "updatedAt,ASC",
            }
        });
        setQuestions(response.data.data.map((quizQuestion) => ({ quizQuestion })));
    };

    const getQuizAttemptScore = async () => {
        const response = await axios.get<{ data: { score: number, totalScore: number } }>(`/quiz-attempts/${params.id}/score`);
        setScore(response.data.data);
    };

    useEffect(() => {
        if (params.id) {
            dispatch(getEntity(params.id));
            getQuizQuestions();
            getQuizAttemptScore();
        }
    }, [params.id]);

    const handleTerminateReview = async () => {
        try {
            const response = await axios.patch(`/quiz-attempts/${params.id}/review`);
            toast.success("Revisione terminata con successo.");
            navigate(`/courses/${quizAttempt?.courseQuiz?.course?.id}/quizzes/${quizAttempt?.courseQuiz?.id}/results`);
        } catch (e) {
            if (e instanceof AxiosError) {
                toast.error(e.response.data.message);
                return;
            } 
            toast.error("Errore durante la terminazione della revisione.");
        }
        
    };

    useEffect(() => {
        if (quizAttempt.timeFinish) {
            const timeStart = new Date(quizAttempt.timeStart).getTime();
            const timeFinish = new Date(quizAttempt.timeFinish).getTime();
            const timeLeftLocal = (timeFinish - timeStart) / 1000;
            setTimeLeft(timeLeftLocal);
        }
    }, [quizAttempt?.id]);

    useEffect(() => {
        const questionAnswerIndex = questions.findIndex((question) => question?.quizQuestion?.id === questionAnswer?.quizQuestion?.id);
        if (questionAnswerIndex !== -1) {
            const newQuestions = [...questions];
            newQuestions[questionAnswerIndex].questionAnswer = questionAnswer.questionAnswer;
            setQuestions(newQuestions);
            getQuizAttemptScore();
        }
    }, [questionAnswer]);

    const handleUpdateAnswer = (quizQuestion: IEdoQuizQuestion, questionAnswer: IEdoQuestionAnswer) => {
        setQuestions(prevQuestions => {
            const questionAnswerIndex = prevQuestions.findIndex((question) => question?.quizQuestion?.id === quizQuestion?.id);
            if (questionAnswerIndex !== -1) {
                const newQuestions = [...prevQuestions];
                newQuestions[questionAnswerIndex].questionAnswer = questionAnswer;
                return newQuestions;
            }
                return prevQuestions;
            });
            getQuizAttemptScore();
    };

    return (
        <Grid container spacing={3}>
            <Grid item md={8}>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Card>
                            <CardContent>
                                <Box p={2}>
                                    <Box mb={1}>
                                        <Typography gutterBottom variant="h4" component="div">
                                            {quizAttempt?.courseQuiz?.quiz.title}
                                        </Typography>
                                    </Box>
                                    <Box >
                                        <Typography variant="body1" color="text.secondary">
                                            {quizAttempt?.courseQuiz?.quiz.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12}>
                        {questions.length ? questions.map((question, index) => (
                            <QuestionAnswer
                                key={question.quizQuestion?.id}
                                className={classes.question}
                                quizQuestion={question.quizQuestion}
                                quizAttempt={quizAttempt}
                                onQuestionAnswer={(questionAnswer) => handleUpdateAnswer(question.quizQuestion, questionAnswer)}
                            />
                        )) : (
                            <Box p={2}>
                                <Typography variant="body1" align="center">
                                    <Translate contentKey="edocendoApp.edoQuestion.home.notFound" />
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>


            <Grid item md={4}>
                <Box mb={3}>
                    <Card sx={(theme) => ({ color: theme.palette.text.secondary })}>
                        <CardHeader subheader="Riepilogo" sx={(theme) => ({ backgroundColor: theme.palette.background.paper })} />
                        <Divider />
                        <CardContent>
                            <Box mb={2}>
                                <Typography variant="body1" color="text.primary">Consegnato il</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <TextFormat
                                        value={quizAttempt.timeFinish}
                                        type="date"
                                        format={APP_DATE_FORMAT_EXPANDED}
                                    />
                                </Typography>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="body1" color="text.primary">Durata tentativo</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${Math.trunc(timeLeft / 60 / 60)} ore, ${Math.trunc(timeLeft / 60 % 60)} minuti, ${Math.trunc(timeLeft % 60)} secondi`}
                                </Typography>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="body1" color="text.primary">Punteggio</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${score.score} / ${score.totalScore} ${score.totalScore === 1 ? "punto" : "punti"}`}
                                </Typography>
                            </Box>
                            <Box mb={3}>
                                <Typography variant="body1" color="text.primary">Domande revisionate</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${questions.reduce((acc, cur) => cur.questionAnswer && cur.questionAnswer?.lastReview !== null ? acc + 1 : acc, 0)} / ${questions.length} ${questions.length === 1 ? "domanda" : "domande"}`}
                                </Typography>
                            </Box>
                            <Box>
                                <LoadingButton
                                    onClick={() => handleTerminateReview()}
                                    disabled={questions.reduce((acc, cur) => cur.questionAnswer && cur.questionAnswer?.score !== null ? acc + 1 : acc, 0) !== questions.length}
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    loading={updating}>
                                    Termina revisione
                                </LoadingButton>
                            </Box>
                            <Box mt={2} mb={-1} display="flex" justifyContent="center">
                                <MaterialLink
                                    to={`/courses/${quizAttempt?.courseQuiz?.course?.id}/quizzes/${quizAttempt?.courseQuiz?.id}/results`}
                                    color="secondary"
                                    component={Link}
                                    textAlign="center"
                                >
                                    Torna ai risultati
                                </MaterialLink>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box>
                    <Card sx={(theme) => ({ color: theme.palette.text.secondary })}>
                        <CardHeader subheader="Domande" sx={(theme) => ({ backgroundColor: theme.palette.background.paper })} />
                        <Divider />
                        <CardContent sx={{
                            padding: 0,
                            "&:last-child": {
                                paddingBottom: 0
                            }
                        }}>
                            <List>
                                {questions?.length ? (
                                    questions.map((question, index) => (
                                        <ListItem
                                            button
                                            key={question.quizQuestion?.id}
                                            onClick={() => { }}
                                        >
                                            <ListItemIcon>
                                                {!question.questionAnswer || question.questionAnswer?.lastReview == null ? (
                                                    <CheckBoxOutlineBlankIcon />
                                                ) : (
                                                    <CheckBoxIcon />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={`Domanda ${index + 1}`}
                                            // secondary={
                                            //     <TextFormat
                                            //         value={evento.startDate}
                                            //         type="date"
                                            //         format={APP_DATE_FORMAT}
                                            //     />
                                            // }
                                            />
                                        </ListItem>
                                    ))
                                ) : (
                                    <Box p={2}>
                                        <Typography variant="body1" align="center">
                                            <Translate contentKey="edocendoApp.edoCourseQuestion.home.notFound" />
                                        </Typography>
                                    </Box>
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};

export default QuizAttemptReviewRoute;