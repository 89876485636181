import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid, TextField,
    TextFieldProps, useMediaQuery,
    useTheme
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useAppDispatch, useAppSelector } from "config/store";
import { Moment } from 'moment';
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import {
    TextValidator,
    ValidatorForm
} from "react-material-ui-form-validator";
import { IEdoCourseQuiz, defaultValue } from "shared/model/edo-course-quiz.model";
import {
    createEntity,
    updateEntity
} from "shared/reducers/entities/edo-course-quiz.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
interface IEditCourseQuizProps {
    courseQuiz?: IEdoCourseQuiz;
    open: boolean;
    onClose: () => void;
}

const EditCourseQuiz = (props: IEditCourseQuizProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { loading, updating, updateSuccess } = useAppSelector<
        EntityState<IEdoCourseQuiz>
    >((state) => state.edoCourseQuiz);

    const [form, setForm] = useState<IEdoCourseQuiz>({ ...defaultValue, ...props.courseQuiz });

    const { courseQuiz, open, onClose: handleClose } = props;

    const isNew = courseQuiz === null || courseQuiz === undefined;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
    };

    useEffect(() => {
        if (courseQuiz) {
            setForm({ ...defaultValue, ...courseQuiz });
        } else {
            setForm({ ...defaultValue });
        }
    }, [courseQuiz]);

    useEffect(() => {
        if (open && updateSuccess) {
            handleClose();
        }
    }, [updateSuccess]);

    const saveEntity = () => {
        if (isNew) {
            dispatch(createEntity({ ...form }));
        } else {
            dispatch(updateEntity({ ...form }));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle>
                {translate("edocendoApp.edoCourseQuiz.home.createOrEditLabel")}
            </DialogTitle>
            <DialogContent>
                {loading && !courseQuiz ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                    <ValidatorForm
                        onSubmit={saveEntity}
                        onError={(errors) => false}
                        sx={{ flexGrow: 1 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label={translate('edocendoApp.edoCourseQuiz.availableAttempts')}
                                    placeholder={translate('edocendoApp.edoCourseQuiz.availableAttempts')}
                                    value={form.availableAttempts || ""}
                                    onChange={handleChange}
                                    name="availableAttempts"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextValidator
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label={translate('edocendoApp.edoCourseQuiz.duration') + " (min)"}
                                    placeholder={translate('edocendoApp.edoCourseQuiz.duration')}
                                    value={form.duration || ""}
                                    onChange={handleChange}
                                    name="duration"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                    <MobileDateTimePicker
                                        // cancelText="Annulla"
                                        renderInput={(inputProps: TextFieldProps) => (
                                            <TextField
                                                {...inputProps}
                                                name="timeOpen"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                        inputFormat="DD/MM/YYYY HH:mm"
                                        label={translate(
                                            "edocendoApp.edoCourseQuiz.timeOpen"
                                        )}
                                        value={form.timeOpen}
                                        onChange={(date: Moment) => {
                                            if (!date) {
                                                setForm({
                                                    ...form,
                                                    timeOpen: null,
                                                });
                                                return;
                                            }
                                            const tzoffset = new Date().getTimezoneOffset() * 60000;
                                            return setForm({
                                                ...form,
                                                timeOpen: date
                                                    .subtract(tzoffset)
                                                    .toISOString()
                                                    .slice(0, -1),
                                            });
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                    <MobileDatePicker
                                        // cancelText="Annulla"
                                        renderInput={(inputProps: TextFieldProps) => (
                                            <TextField
                                                {...inputProps}
                                                name="timeClose"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                        inputFormat="DD/MM/YYYY HH:mm"
                                        label={translate(
                                            "edocendoApp.edoCourseQuiz.timeClose"
                                        )}
                                        value={form.timeClose}
                                        onChange={(date: Moment) => {
                                            if (!date) {
                                                setForm({
                                                    ...form,
                                                    timeClose: null,
                                                });
                                                return;
                                            }
                                            const tzoffset = new Date().getTimezoneOffset() * 60000;
                                            return setForm({
                                                ...form,
                                                timeClose: date
                                                    .subtract(tzoffset)
                                                    .toISOString()
                                                    .slice(0, -1),
                                            });
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button onClick={saveEntity} color="primary" disabled={updating}>
                    <Translate contentKey="entity.action.edit">Modifica</Translate>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCourseQuiz;