import {
    Add as AddIcon
} from "@mui/icons-material";
import {
    AppBar, Box, Divider, IconButton,
    List, Paper, TablePagination, Toolbar, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { IEdoQuiz } from "shared/model/edo-quiz.model";
import { getQuizzesByAccount } from "shared/reducers/entities/edo-quiz.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import DeleteQuiz from "./components/DeleteQuiz";
import EditQuiz from "./components/EditQuiz";
import QuizRow from "./components/QuizRow";

const QuizzesRoute = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { entities: quizzes, loading, totalItems, updateSuccess } = useAppSelector<EntityState<IEdoQuiz>>(state => state.edoQuiz);
    const [quizToEdit, setQuizToEdit] = useState<IEdoQuiz>();
    const [editQuizDialog, setEditQuizDialog] = useState<boolean>(false);
    const [deleteQuizDialog, setDeleteQuizDialog] = useState<boolean>(false);
    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: "updatedAt",
        order: "DESC",
    });

    const handleOpenEditQuizDialog = (quiz: IEdoQuiz) => {
        setQuizToEdit({ ...quiz });
        setEditQuizDialog(true);
    };

    const handleCloseEditQuizDialog = () => {
        setEditQuizDialog(false);
        setQuizToEdit(null);
    };

    const handleOpenDeleteQuizDialog = (quiz: IEdoQuiz) => {
        setQuizToEdit(quiz);
        setDeleteQuizDialog(true);
    };

    const handleCloseDeleteQuizDialog = () => {
        setDeleteQuizDialog(false);
        setQuizToEdit(null);
    };

    const getAllQuizzes = async () => {
        dispatch(getQuizzesByAccount({
            page: paginationState.activePage,
            size: paginationState.itemsPerPage,
            sort: `${paginationState.sort},${paginationState.order}`,
        }));
    };

    const handlePagination = (currentPage: number) =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const handleRowsPerPage = (rowsPerPage: number) =>
        setPaginationState({
            ...paginationState,
            itemsPerPage: rowsPerPage,
            activePage: 1,
        });

    useEffect(() => {
        getAllQuizzes();
    }, [
        paginationState.activePage,
        paginationState.order,
        paginationState.sort,
        paginationState.itemsPerPage,
    ]);

    useEffect(() => {
        if (updateSuccess) {
            getAllQuizzes();
        }
    }, [updateSuccess]);


    return (
        <>
            <Paper style={{ overflow: "hidden" }}>
                <AppBar position="relative">
                    <Toolbar>
                        <Typography variant="h6" flexGrow={1}>
                            Test
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={() => handleOpenEditQuizDialog(null)}>
                            <AddIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {quizzes?.length > 0 ? (
                    <List dense>
                        {quizzes.map((quiz) => (
                            <QuizRow key={quiz.id} quiz={quiz} openEditDialog={handleOpenEditQuizDialog} openDeleteDialog={handleOpenDeleteQuizDialog} />
                        ))}
                    </List>
                ) : (
                    !loading && (
                        <Box p={2}>
                            <Typography variant="body1" align="center">
                                <Translate contentKey="edocendoApp.edoQuiz.home.notFound" />
                            </Typography>
                        </Box>
                    )
                )}
                <Divider />
                <TablePagination
                    component="div"
                    count={totalItems}
                    rowsPerPage={paginationState.itemsPerPage}
                    page={paginationState.activePage - 1}
                    onPageChange={(event, page) => handlePagination(page + 1)}
                    onRowsPerPageChange={(event) =>
                        handleRowsPerPage(parseInt(event.target.value, 10))
                    }
                    labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
                    }
                />
            </Paper>
            <EditQuiz
                open={editQuizDialog}
                quiz={quizToEdit}
                onClose={handleCloseEditQuizDialog}
            />
            <DeleteQuiz
                open={deleteQuizDialog}
                quiz={quizToEdit}
                onClose={handleCloseDeleteQuizDialog}
            />
        </>
    );
};

export default QuizzesRoute;
