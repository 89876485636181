import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoWallPost } from "shared/model/edo-wall-post.model";
import { deleteEntity } from "shared/reducers/entities/edo-wall-post.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

interface IDeleteWallPostProps {
    post: IEdoWallPost;
    open: boolean;
    onClose: () => void;
}

const DeleteWallPostDialog = (props: IDeleteWallPostProps) => {
    const dispatch = useAppDispatch();
    const { updateSuccess } = useAppSelector<EntityState<IEdoWallPost>>((state) => state.edoWallPost);

    const { open, onClose: handleClose, post } = props;

    const confirmDelete = () => {
        dispatch(deleteEntity(post.id));
    };

    useEffect(() => {
        if (updateSuccess) {
            handleClose();
        }
    }, [updateSuccess]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Translate contentKey="entity.delete.title" />
            </DialogTitle>
            {!post ? (
                <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
            ) : (
                <DialogContent>
                    <DialogContentText>
                        <Translate contentKey="edocendoApp.edoWallPost.delete.question" />
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    <Translate contentKey="entity.action.cancel" />
                </Button>
                <Button onClick={confirmDelete} color="primary">
                    <Translate contentKey="entity.action.delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteWallPostDialog;