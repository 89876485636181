import {
  Article as ArticleIcon, Bookmark as BookmarkIcon, Delete as DeleteIcon, Edit as EditIcon, ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  GetApp as GetAppIcon, Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar, ListItemIcon, ListItemSecondaryAction,
  ListItemText, Menu,
  MenuItem, Typography
} from "@mui/material";
import axios from "axios";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import React, { useEffect, useState } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";
import { UnitRow } from "./UnitRow";
export interface CapitoloRowProps {
  chapter?: IEdoChapter;
  isLastElement?: boolean;
  handleOpenEditCapitoloDialog?: (entity: IEdoChapter) => any;
  handleOpenEditChapterUnitsDialog?: (entity: IEdoChapter) => any;
  handleOpenDeleteCapitoloDialog?: (entity: IEdoChapter) => any;
  handleOpenViewUnitDialog: (unit: IEdoUnit) => void;
}

export const CapitoloRow = (props: CapitoloRowProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [units, setUnits] = useState<IEdoUnit[]>([]);
  const [files, setFiles] = useState<IEdoFile[]>([]);
  const [anchorEditChapterButton, setAnchorEditChapterButton] =
    useState<null | HTMLElement>(null);
  const [loadingUnits, setLoadingUnits] = useState<boolean>(true);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    chapter,
    isLastElement,
    handleOpenEditCapitoloDialog,
    handleOpenDeleteCapitoloDialog,
    handleOpenViewUnitDialog,
    handleOpenEditChapterUnitsDialog
  } = props;

  const handleEditChapterButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEditChapterButton(event.currentTarget);
  };

  const handleCloseEditChapterMenu = () => {
    setAnchorEditChapterButton(null);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const openFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  const downloadFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/download`, "_blank");
  };

  useEffect(() => {
    const loadUnits = async (chapterItem: IEdoChapter) => {
      setLoadingUnits(true);
      const response = await axios.get(`/chapters/${chapterItem.id}/units`);
      setUnits(response.data.data);
      setLoadingUnits(false);
    };
    const loadFiles = async (chapterItem: IEdoChapter) => {
      setLoadingFiles(true);
      const response = await axios.get(`/chapters/${chapterItem.id}/files`);
      setFiles(response.data.data);
      setLoadingFiles(false);
    };

    if (chapter && open) {
      loadUnits(chapter);
      loadFiles(chapter);
    }
  }, [chapter, open]);

  useEffect(() => {
    if (!chapter || loadingUnits || loadingFiles) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [chapter, loadingUnits, loadingFiles]);

  const getContent = () => {
    if (loading) {
      return (<Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
        <CircularProgress />
      </Box>);
    }

    if (!files.length && !units.length) {
      return (<Box p={2}>
        <Typography variant="body1" align="center">
          Nessun materiale contenuto nel chapter
        </Typography>
      </Box>);
    }

    return (<>
      {files.length ? (
        <List component="div" disablePadding>
          {files.map((file) => (
            <ListItem key={file.id} sx={(theme) => ({
              paddingLeft: 4,
              [theme.breakpoints.down("sm")]: {
                paddingLeft: 2,
              },
            })} button>
              <ListItemAvatar>
                {getFileAvatar(file.fileType)}
              </ListItemAvatar>
              <ListItemText
                primary={file.originalFileName}
                primaryTypographyProps={{ variant: "body2" }}
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                secondary={
                  <TextFormat
                    value={file.createdAt}
                    type="date"
                    format={APP_LOCAL_DATE_FORMAT}
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => openFile(file)}
                  edge="end"
                  aria-label="Open file"
                  sx={{ marginRight: 0.5 }}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  onClick={() => downloadFile(file)}
                  edge="end"
                  aria-label="Download file"
                  sx={{ marginRight: 0.75 }}
                >
                  <GetAppIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : null}
      {units.length ? (
        <List component="div" disablePadding>
          {units.map((unit) => (
            <UnitRow
              nested={true}
              unit={unit}
              key={unit.id}
              viewUnit={() => handleOpenViewUnitDialog(unit)}
              editUnit={null}
              deleteUnit={null}
            />
          ))}
        </List>
      ) : null}
    </>);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleOpen}
      >
        <ListItemText
          primary={chapter?.title}
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          secondary={
            <TextFormat
              value={chapter?.createdAt}
              type="date"
              format={APP_LOCAL_DATE_FORMAT}
            />
          }
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>

        { getContent()}

        {/* <Box>
          <Button size="large" startIcon={<AddIcon />} onClick={() => handleOpenEditUnit(null)} fullWidth>
            Aggiungi unità didattica
          </Button>
        </Box> */}
        {handleOpenEditCapitoloDialog || handleOpenDeleteCapitoloDialog ? (
          <>
            <Divider />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
            >
              {handleOpenEditCapitoloDialog !== null ? (
                <Box >
                  <Box >
                    <Button
                      color="primary"
                      size="small"
                      startIcon={<EditIcon />}
                      fullWidth
                      onClick={handleEditChapterButtonClick}
                    >
                      <Translate contentKey="entity.action.edit" />
                    </Button>
                    <Menu
                      id="more-chapter-menu"
                      anchorEl={anchorEditChapterButton}
                      keepMounted
                      open={Boolean(anchorEditChapterButton)}
                      onClose={handleCloseEditChapterMenu}
                      disableScrollLock={true}
                    >
                      <MenuItem onClick={() => {
                        handleCloseEditChapterMenu();
                        handleOpenEditCapitoloDialog(chapter);
                      }}>
                        <ListItemIcon>
                          <ArticleIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Modifica informazioni e documenti</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => {
                        handleCloseEditChapterMenu();
                        handleOpenEditChapterUnitsDialog(chapter);
                      }
                      }>
                        <ListItemIcon>
                          <BookmarkIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          Modifica unità didattiche
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              ) : null}
              {handleOpenDeleteCapitoloDialog !== null ? (
                <Box>
                  <Button
                    size="small"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleOpenDeleteCapitoloDialog(chapter)}
                  >
                    Elimina
                  </Button>
                </Box>
              ) : null}
            </Box>{" "}
          </>
        ) : null}
        {!isLastElement ? <Divider /> : null}
      </Collapse>
    </>
  );
};
