import axios from "axios";
import {
  defaultValue as defaultValueComment,
  IEdoCourseComment
} from "shared/model/edo-course-comment.model";
import { IEdoCoursePost } from "shared/model/edo-course-post.model";
import Post from "./Post";

interface CoursePostProps {
  post: IEdoCoursePost;
  onEdit: (post: IEdoCoursePost) => void;
  onDelete: (post: IEdoCoursePost) => void;
}

const CoursePost = (props: CoursePostProps) => {

  const { post, onEdit: handleEdit, onDelete: handleDelete } = props;

  const getCommentList = async () => {
    const response = await axios.get('/course-comments',
      {
        params: {
          sortBy: 'data:ASC',
          'filter.post.id': '$eq:' + post.id
        }
      }
    );
    return response.data.data;
  };

  const saveComment = async (comment: IEdoCourseComment) => {
    const response = await axios.post("/course-comments/", {
      ...comment,
      post: { id: post.id },
    });
    return response.data;
  };

  return (
    <Post
      post={props.post}
      getCommentList={getCommentList}
      saveComment={saveComment}
      defaultValueComment={defaultValueComment}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
};

export default CoursePost;
