import {
  DarkMode as DarkModeIcon,
  Group as GroupIcon,
  LightMode as LightModeIcon,
  Logout as LogoutIcon,
  Mail as MailIcon,
  Menu as MenuIcon
} from "@mui/icons-material";
import { Avatar, Badge, Box, Button, CssBaseline, Divider, ListItemIcon, Theme, Tooltip, styled, useMediaQuery, useScrollTrigger } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ListItemText from '@mui/material/ListItemText';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ChatDrawer from "components/ChatDrawer/ChatDrawer";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import { PropsWithChildren, default as React, useContext, useEffect, useMemo, useState } from "react";
import { Translate } from "react-jhipster";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoChatMessage } from "shared/model/edo-chat-message.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getUnseenMessagesCount } from "shared/reducers/entities/edo-chat-message.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { SettingsState, invertTheme } from "shared/reducers/settings.reducer";
import Drawer, { DRAWER_WIDTH } from "./drawer";


const ProfileButton = styled(Button)({
  textTransform: "none",
  textAlign: "left",
  paddingRight: "15px",
  paddingLeft: "15px",
  borderRadius: "5px",
  '& .MuiButton-startIcon': {
    marginRight: "15px"
  }
});

function ElevationScroll(props: PropsWithChildren<any>) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Header = (props: PropsWithChildren<any>) => {
  // const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const match = useMatch('/chat/:id');
  const socket = useContext(SocketContext);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [chatDrawer, setChatDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );

  const { unseenMessages } = useAppSelector<EntityState<IEdoChatMessage>>(state => state.edoChatMessage);
  const isMediaSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { theme: themeMode } = useAppSelector<SettingsState>(state => state.settings);

  const isMenuOpen = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/logout");
  };

  const handleOnMessageEvent = () => {
    dispatch(getUnseenMessagesCount());
  };

  useEffect(() => {
    dispatch(getUnseenMessagesCount());
    socket.on('message', handleOnMessageEvent);
    return function cleanup() {
      socket.off('message', handleOnMessageEvent);
    };
  }, []);

  const roleLabel = useMemo(() => {
    if (account.roles.findIndex(role => role.name === AUTHORITIES.ROLE_ADMIN) !== -1) {
      return "Amministratore";
    }
    if (account.roles.findIndex(role => role.name === AUTHORITIES.ROLE_VICE) !== -1) {
      return "Vicepreside";
    }
    if (account.roles.findIndex(role => role.name === AUTHORITIES.ROLE_TEACHER) !== -1) {
      return "Docente";
    }
    if (account.roles.findIndex(role => role.name === AUTHORITIES.ROLE_STUDENT) !== -1) {
      return "Studente";
    }
    if (account.roles.findIndex(role => role.name === AUTHORITIES.ROLE_PARENT) !== -1) {
      return "Genitore";
    }
  }, [account.roles]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      disableScrollLock={true}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem component={Link} to={"/account/settings"} onClick={handleMenuClose}>
        <Avatar style={{ backgroundColor: "primary" }} src="/profile_neutral.svg" /> {account.firstName} {account.lastName}
      </MenuItem>
      <Divider />
      {hasAnyAuthority(account.roles, [
        AUTHORITIES.ROLE_ADMIN,
        AUTHORITIES.ROLE_SUPPORT_TEACHER,
        AUTHORITIES.ROLE_TEACHER,
        AUTHORITIES.ROLE_VICE,
        AUTHORITIES.ROLE_STUDENT,
      ]) ? (
        <MenuItem component={Link} to={"/friends"} onClick={handleMenuClose}>
          <ListItemIcon>
            <GroupIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Amici
          </ListItemText>
        </MenuItem>
      ) : null}
      <MenuItem onClick={() => {
        dispatch(invertTheme());
      }}>
        <ListItemIcon>
          {themeMode === "light" ? <LightModeIcon /> : <DarkModeIcon />}
        </ListItemIcon>
        <ListItemText>
          Dark mode
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        handleLogout();
        handleMenuClose();
      }}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Translate contentKey="global.menu.account.logout">Sign out</Translate>
        </ListItemText>
      </MenuItem>
    </Menu>
  );

  
  return (
    <>
      <ChatDrawer open={chatDrawer} onClose={() => setChatDrawer(false)} />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <ElevationScroll {...props}>
          <AppBar
            sx={(theme) => ({
              zIndex: theme.zIndex.drawer - 1,
              // boxShadow: "none",
              backgroundColor: theme.palette.background.default,
              backgroundImage: "none",
              paddingRight: "0px!important",
              // background: "rgba(255, 255, 255, 0.2)",
              // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              // backdropFilter: "blur(5px)",
              // border: "1px solid rgba(255, 255, 255, 0.3)"
            })}
            color="inherit"
          >
            <Toolbar>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  height: "74px"
                }}>
                <Box sx={{
                  width: DRAWER_WIDTH,
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }} />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
                <Box flexGrow={1}></Box>
                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                  <Box>
                    {hasAnyAuthority(account.roles, [
                      AUTHORITIES.ROLE_ADMIN,
                      AUTHORITIES.ROLE_SUPPORT_TEACHER,
                      AUTHORITIES.ROLE_TEACHER,
                      AUTHORITIES.ROLE_VICE,
                      AUTHORITIES.ROLE_STUDENT,
                    ]) ? (
                      <IconButton onClick={() => setChatDrawer(true)} color="inherit" aria-label={"show" + (unseenMessages || "0") + " new notifications"} >
                        <Badge
                          badgeContent={unseenMessages ? unseenMessages : null}
                          color="secondary"
                        >
                          <MailIcon />
                        </Badge>
                      </IconButton>
                    ) : null}
                    <Tooltip title="Account">
                      <ProfileButton
                        onClick={handleProfileMenuOpen}
                        size="medium"
                        aria-controls={isMenuOpen ? 'menuId' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isMenuOpen ? 'true' : undefined}
                        startIcon={
                          <Avatar style={{ backgroundColor: "primary", width: 32, height: 32 }} src="/profile_neutral.svg" />
                        }
                      >
                        <Box>
                          <Typography variant="body2" color="text.primary">{account.firstName.concat(" ").concat(account.lastName)}</Typography>
                          <Typography variant="caption" color="text.secondary">{roleLabel}</Typography>
                        </Box>
                      </ProfileButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Drawer mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle }/>
        <Box
          component="main"
          sx={{ flexGrow: 1, maxWidth: `calc(100% - ${isMediaSm ? 0 : DRAWER_WIDTH}px)` }}
        >
          <Toolbar sx={{ height: "74px" }} />
          {props.children}
        </Box>
      </Box>

      {renderMenu}
    </>
  );
};

export default Header;
