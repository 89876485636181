import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "config/store";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { TranslatorContext } from "react-jhipster";

const initialState = {
  currentLocale: "",
};

export type LocaleState = Readonly<typeof initialState>;

export const setLocale: (locale: string) => AppThunk =
  (locale) => async (dispatch) => {
    if (!Object.keys(TranslatorContext.context.translations).includes(locale)) {
      const response = await axios.get(`/i18n/${locale}.json?_=${I18N_HASH}`, {
        baseURL: "",
      });
      TranslatorContext.registerTranslations(locale, response.data);
    }
    dispatch(updateLocale(locale));
  };

export const LocaleSlice = createSlice({
  name: "locale",
  initialState: initialState as LocaleState,
  reducers: {
    updateLocale(state, action) {
      const currentLocale = action.payload;
      if (state.currentLocale !== currentLocale) {
        dayjs.locale(currentLocale);
        TranslatorContext.setLocale(currentLocale);
      }
      state.currentLocale = currentLocale;
    },
  },
});

export const { updateLocale } = LocaleSlice.actions;

// Reducer
export default LocaleSlice.reducer;
