import { Storage, TranslatorContext } from "react-jhipster";
import { setLocale } from "shared/reducers/locale";

TranslatorContext.setDefaultLocale("it");
TranslatorContext.setRenderInnerTextForMissingKeys(false);

export const languages: any = {
  it: { name: "Italiano" },
};

export const locales = Object.keys(languages).sort();

export const registerLocale = (store: any) => {
  store.dispatch(setLocale(Storage.session.get("locale", "it")));
};
