import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    useTheme
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import {
    TextValidator,
    ValidatorForm
} from "react-material-ui-form-validator";
import { IEdoFile, defaultValue } from "shared/model/edo-file.model";
import { EntityState } from "shared/reducers/reducer.utils";
import {
    partialUpdateEntity as partialUpdateFile
} from "../../../../shared/reducers/entities/edo-file.reducer";

interface IEditFileProps {
    file?: IEdoFile;
    open: boolean;
    onClose: () => void;
}

const EditFile = (props: IEditFileProps) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { loading, updating, updateSuccess } = useAppSelector<
        EntityState<IEdoFile>
    >((state) => state.edoFile);
    const [form, setForm] = useState<IEdoFile>({ ...defaultValue, ...props.file });

    const { file, open, onClose: handleClose } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
    };

    useEffect(() => {
        setForm({ ...defaultValue, ...file });
    }, [file]);

    useEffect(() => {
        if (updateSuccess) {
            handleClose();
            setForm({ ...defaultValue });
        }
    }, [updateSuccess]);

    const saveEntity = () => {
            dispatch(partialUpdateFile({ ...form }));
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>
                {translate("edocendoApp.edoFile.home.editLabel")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={2}>Modifica le informazionid del file.</DialogContentText>
                {loading || !file ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                    <ValidatorForm
                        onSubmit={saveEntity}
                        onError={(errors) => false}
                        sx={{ flexGrow: 1 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label={translate("edocendoApp.edoFile.originalFileName")}
                                    onChange={handleChange}
                                    name="originalFileName"
                                    value={form.originalFileName || ""}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button onClick={saveEntity} color="primary" disabled={updating}>
                    {translate("entity.action.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditFile;