import { Add as AddIcon, Sync as SyncIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  Paper, Toolbar,
  Typography
} from "@mui/material";
import axios from "axios";
import { AUTHORITIES } from "config/constants";
import { useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from "react-beautiful-dnd";
import { Translate } from "react-jhipster";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';
import { UnitRow } from "../CapitoloRow/UnitRow";

const useStyles = makeStyles()(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
  appbar: {
    boxShadow: "none",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
  },
  toolbar: {
    justifyContent: "space-between",
  },
}));

interface UnitsProps {
  chapter?: IEdoChapter;
  handleOpenViewUnitDialog: (unit: IEdoUnit) => void;
  handleOpenEditUnitDialog: (unit: IEdoUnit) => void;
  handleOpenDeleteUnitDialog: (unit: IEdoUnit) => void;
}

const Units = (props: UnitsProps) => {
  const { classes } = useStyles();
  const [units, setUnits] = useState<IEdoUnit[]>(props.chapter.units);
  const [loadingUnits, setLoadingUnits] = useState<boolean>(false);
  const [unitsLocal, setUnitsLocal] = useState<IEdoUnit[]>(units);
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const { updateSuccess } = useAppSelector<EntityState<IEdoUnit>>(
    (state) => state.edoUnit
  );

  const {
    chapter,
    handleOpenViewUnitDialog,
    handleOpenEditUnitDialog,
    handleOpenDeleteUnitDialog,
  } = props;

  const getEdoUnitList = async () => {
    setLoadingUnits(true);
    const response = await axios.get("/units", {
      params: {
        page: 0,
        limit: 100,
        sortBy: "order:ASC",
        "filter.chapter.id": '$eq:' + chapter.id,
      },
    });
    setUnits(response.data.data);
    setLoadingUnits(false);
  };

  useEffect(() => {
    if (chapter) {
      getEdoUnitList();
    }
  }, [chapter]);

  useEffect(() => {
    setUnitsLocal(units);
  }, [units]);

  useEffect(() => {
    if (updateSuccess) {
      getEdoUnitList();
    }
  }, [updateSuccess]);

  const handleSyncList = () => {
    getEdoUnitList();
  };

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(unitsLocal);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setUnitsLocal(items);
    try {
      const response = await axios.patch(
        `/units/${result.draggableId}/order`,
        {
          order: result.destination.index + 1,
        }
      );
    } catch (e) {
      getEdoUnitList();
    }
  };

  const isAdmin = useMemo(
    () =>
      hasAnyAuthority(account.roles, [
        AUTHORITIES.ROLE_ADMIN,
        AUTHORITIES.ROLE_VICE,
        AUTHORITIES.ROLE_TEACHER,
      ]),
    [account.roles]
  );

  return (
    <Paper className={classes.paper}>
      <Box flexGrow={1}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.appbar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography variant="h5">
              <Translate contentKey="edocendoApp.edoUnit.home.title">
                Edo Unit
              </Translate>
            </Typography>
            <Box>

              <IconButton
                color="inherit"
                onClick={handleSyncList}
                disabled={loadingUnits}
                sx={(theme) => ({
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  }, marginRight: 1
                })}
              >
                <SyncIcon />
              </IconButton>
              <Button
                color="inherit"
                onClick={handleSyncList}
                disabled={loadingUnits}
                startIcon={<SyncIcon />}
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  }
                })}
              >
                <Translate contentKey="edocendoApp.edoUnit.home.refreshListLabel">
                  Refresh List
                </Translate>
              </Button>

              {isAdmin ? (
                <>
                  <IconButton
                    color="inherit"
                    disabled={loadingUnits}
                    onClick={() => handleOpenEditUnitDialog(null)}
                    sx={(theme) => ({
                      [theme.breakpoints.up("sm")]: {
                        display: "none",
                      },
                    })}
                  >
                    <AddIcon />
                  </IconButton>
                  <Button
                    color="inherit"
                    disabled={loadingUnits}
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenEditUnitDialog(null)}
                    sx={(theme) => ({
                      [theme.breakpoints.down("sm")]: {
                        display: "none",
                      }
                    })}
                  >
                    Aggiungi unit
                  </Button>

                </>
              ) : null}
            </Box>
          </Toolbar>
        </AppBar>

        <>
          {loadingUnits ? (
            <Box position="relative" display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {!units || !units.length ? (
                <Box p={2}>
                  <Typography variant="body1" align="center">
                    <Translate contentKey="edocendoApp.edoUnit.home.notFound">
                      No Edo Units found
                    </Translate>
                  </Typography>
                </Box>
              ) : (
                <List>
                  <DragDropContext
                    onDragEnd={(result) => {
                      handleOnDragEnd(result);
                    }}
                  >
                    <Droppable
                      droppableId="materiale"
                      isDropDisabled={!isAdmin}
                    >
                      {(provided) => (
                        <div
                          className="materiale"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {unitsLocal.map((unit, index) => {
                            return (
                              <Draggable
                                key={unit.id}
                                draggableId={String(unit.id)}
                                index={index}
                                isDragDisabled={!isAdmin}
                              >
                                {(providedEl) => (
                                  <div
                                    ref={providedEl.innerRef}
                                    {...providedEl.draggableProps}
                                    {...providedEl.dragHandleProps}
                                  >
                                    <UnitRow
                                      key={unit.id}
                                      unit={unit}
                                      viewUnit={() =>
                                        handleOpenViewUnitDialog(unit)
                                      }
                                      editUnit={() =>
                                        handleOpenEditUnitDialog(unit)
                                      }
                                      deleteUnit={() =>
                                        handleOpenDeleteUnitDialog(unit)
                                      }
                                      nested={false}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </List>
              )}
            </Box>
          )}
        </>
      </Box>
    </Paper>
  );
};

export default Units;