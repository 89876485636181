import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoVideostorePost } from "shared/model/edo-videostore-post.model";
import { EntityState } from "shared/reducers/reducer.utils";
import {
  deleteEntity
} from "../../../shared/reducers/entities/edo-videostore-post.reducer";

interface IDeleteVideostorePostProps {
  videostorePost: IEdoVideostorePost;
  open: boolean;
  onClose: () => void;
}

const DeleteVideostorePost = (props: IDeleteVideostorePostProps) => {
  const dispatch = useAppDispatch();
  const { updateSuccess } = useAppSelector<EntityState<IEdoVideostorePost>>(
    (state) => state.edoVideostorePost
  );

  const { videostorePost, open, onClose: handleClose } = props;

  const confirmDelete = () => {
    dispatch(deleteEntity(videostorePost.id));
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      {!videostorePost ? (
        <CircularProgress
          size={24}
          style={{ marginLeft: 15, position: "relative", top: 4 }}
        />
      ) : (
        <DialogContent>
          <DialogContentText>
            <Translate
              contentKey="edocendoApp.edoVideostorePost.delete.question"
              interpolate={{ nome: videostorePost.title }}
            >
              Are you sure you want to delete this EdoPostVideostore?
            </Translate>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <LoadingButton
          onClick={confirmDelete}
          color="primary"
        >
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteVideostorePost;