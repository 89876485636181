import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { serializeAxiosError } from "shared/reducers/reducer.utils";

const initialState = {
  loading: false,
  errorMessage: null as string | null,
  successMessage: null as string | null,
  updateSuccess: false,
  updateFailure: false,
};

export type PasswordState = Readonly<typeof initialState>;

const apiUrl = "/account";

interface IPassword {
  currentPassword: string;
  newPassword: string;
}

// Actions

export const savePassword = createAsyncThunk(
  "password/update_password",
  async (password: IPassword) =>
    axios.post(`${apiUrl}/change-password`, password),
  { serializeError: serializeAxiosError }
);

export const PasswordSlice = createSlice({
  name: "password",
  initialState: initialState as PasswordState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(savePassword.pending, (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addCase(savePassword.rejected, (state) => {
        state.loading = false;
        state.updateSuccess = false;
        state.updateFailure = true;
        state.errorMessage =
          "An error has occurred! The password could not be changed.";
      })
      .addCase(savePassword.fulfilled, (state) => {
        state.loading = false;
        state.updateSuccess = true;
        state.updateFailure = false;
        state.successMessage = "Password changed!";
      });
  },
});

export const { reset } = PasswordSlice.actions;

// Reducer
export default PasswordSlice.reducer;
