import {
  defaultValue as defaultValueClass,
  IEdoClass
} from "shared/model/edo-class.model";
import {
  defaultValue as defaultValueSubject,
  IEdoSubject
} from "shared/model/edo-subject.model";
import { IEdoTeacher } from "./edo-teacher.model";

export interface IEdoCourse {
  id?: number;
  teachers?: IEdoTeacher[] | null;
  class?: IEdoClass;
  subject?: IEdoSubject;
}

export const defaultValue: Readonly<IEdoCourse> = {
  teachers: [],
  subject: defaultValueSubject as IEdoSubject,
  class: defaultValueClass as IEdoClass,
};
