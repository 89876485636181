import axios from "axios";
import {
  defaultValue as defaultValueComment,
  IEdoWallComment
} from "shared/model/edo-wall-comment.model";
import { IEdoWallPost } from "shared/model/edo-wall-post.model";
import Post from "./Post";

interface WallPostProps {
  post: IEdoWallPost;
  onEdit: (post: IEdoWallPost) => void;
  onDelete: (post: IEdoWallPost) => void;
}

const WallPost = (props: WallPostProps) => {

  const { post, onEdit: handleEdit, onDelete: handleDelete } = props;

  const getCommentList = async () => {
    const response = await axios.get<{ data: IEdoWallComment[] }>(
      `/wall-comments`, {
      params: {
        sortBy: 'createdAt:ASC',
        'filter.post.id': '$eq:' + post.id
      }
    }
    );
    return response.data.data;
  };

  const saveComment = async (comment: IEdoWallComment) => {
    const response = await axios.post("/wall-comments/", {
      ...comment,
      post: { id: post.id },
    });
    return response.data;
  };

  return (
    <Post
      post={props.post}
      getCommentList={getCommentList}
      saveComment={saveComment}
      defaultValueComment={defaultValueComment}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
};

export default WallPost;