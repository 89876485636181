import {
    Add as AddIcon,
    FormatAlignLeft as FormatAlignLeftIcon, LibraryAddCheck as LibraryAddCheckIcon,
    RadioButtonChecked as RadioButtonCheckedIcon, TextFormat as TextFormatIcon,
    ToggleOff as ToggleOffIcon
} from "@mui/icons-material";
import {
    Box, Button, Card, CardContent, Grid, ListItemIcon, Menu,
    MenuItem, Typography
} from "@mui/material";
import axios from "axios";
import EditQuestion from "components/EditQuestion/EditQuestion";
import SelectQuestionsDialog from "components/SelectQuestions/SelectQuestionsDialog";
import { useAppDispatch, useAppSelector } from "config/store";
import React, { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { useParams } from "react-router-dom";
import { IEdoCourseQuiz } from "shared/model/edo-course-quiz.model";
import { IEdoQuestion } from "shared/model/edo-question.model";
import { IEdoQuizQuestion } from "shared/model/edo-quiz-question.model";
import { EdoQuestionTypeEnum } from "shared/model/enumerations/edo-question-type.mode";
import { getEntity } from "shared/reducers/entities/edo-course-quiz.reducer";
import { createEntity as createQuizQuestion } from 'shared/reducers/entities/edo-quiz-question.reducer';
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';
import CourseQuestion from "./components/CourseQuestion";
import RemoveQuestion from "./components/RemoveQuestion";

const useStyles = makeStyles()(theme =>
    ({
        buttons: {
            display: "flex",
            flexDirecton: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            [theme.breakpoints.down("sm")]: {
                marginBottom: theme.spacing(2),
                "& > *": {
                    width: "100%",
                },
            },
        },
        question: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(4),
            },
        }
    })
);


const QuizDetailRoute = () => {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const { entity: courseQuiz } = useAppSelector<EntityState<IEdoCourseQuiz>>(state => state.edoCourseQuiz);
    const { updateSuccess: updateSuccessQuizQuestion } = useAppSelector<EntityState<IEdoQuizQuestion>>(state => state.edoQuizQuestion);
    const [questions, setQuestions] = useState<IEdoQuizQuestion[]>([]);
    const [anchorCreateQuestionButton, setAnchorCreateQuestionButton] =
        useState<null | HTMLElement>(null);
    const [editQuestionDialog, setEditQuestionDialog] = useState<{ open: boolean, entity: IEdoQuestion, type: EdoQuestionTypeEnum }>({ open: false, entity: null, type: null });
    const [questionsToAdd, setQuestionsToAdd] = useState<IEdoQuestion[]>([]);
    const [selectQuestionsDialog, setSelectQuestionsDialog] = useState<{ open: boolean }>({ open: false });
    const [removeQuestionDialog, setRemoveQuestionDialog] = useState<{ open: boolean, entity: IEdoQuizQuestion }>({ open: false, entity: null });

    const handleCreateQuestionButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorCreateQuestionButton(event.currentTarget);
    };

    const handleCloseCreateQuestionMenu = () => {
        setAnchorCreateQuestionButton(null);
    };

    useEffect(() => {
        if (editQuestionDialog.open || selectQuestionsDialog.open) {
            handleCloseCreateQuestionMenu();
        }
    }, [editQuestionDialog.open, selectQuestionsDialog.open]);

    const getQuizQuestions = async (quizId: number) => {
        const response = await axios.get<{ data: IEdoQuizQuestion[] }>(`/quizzes/${quizId}/questions`, {
            params: {
                sort: "updatedAt,ASC",
            }
        });
        setQuestions(response.data.data);
    };

    const handleQuestionCreated = async (question: IEdoQuestion) => {
        // await axios.post(`/quizzes/${quiz.id}/questions`, { question });
        getQuizQuestions(courseQuiz?.quiz?.id);
    };

    const handleAddQuestionsToQuiz = async (questions: IEdoQuestion[]) => {
        const requests = questions.map(question => {
            return dispatch(createQuizQuestion({ quiz: courseQuiz.quiz, question: question }));
        });
        await Promise.all(requests);
        setQuestionsToAdd([]);
        getQuizQuestions(courseQuiz?.quiz?.id);
    };

    useEffect(() => {
        dispatch(getEntity(params.id));
    }, []);

    useEffect(() => {
        if (courseQuiz?.quiz?.id) {
            getQuizQuestions(courseQuiz?.quiz?.id);
        }
    }, [courseQuiz]);

    useEffect(() => {
        if (updateSuccessQuizQuestion) {
            getQuizQuestions(courseQuiz?.quiz?.id);
        }
    }, [updateSuccessQuizQuestion]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Card>
                        <CardContent>
                            <Box p={2}>
                                <Box mb={1}>
                                    <Typography gutterBottom variant="h4" component="div">
                                        {courseQuiz?.quiz?.title}
                                    </Typography>
                                </Box>
                                <Box >
                                    <Typography variant="body1" color="text.secondary">
                                        {courseQuiz?.quiz?.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <Box className={classes.buttons}>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleCreateQuestionButtonClick}
                            >
                                Aggiungi domanda
                            </Button>
                        </Box>
                            <Menu
                                id="create-question-menu"
                                anchorEl={anchorCreateQuestionButton}
                                keepMounted
                                open={Boolean(anchorCreateQuestionButton)}
                                onClose={handleCloseCreateQuestionMenu}
                                disableScrollLock={true}
                                // elevation={0}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                            <MenuItem onClick={() => setEditQuestionDialog({ open: true, entity: null, type: EdoQuestionTypeEnum.SINGLE_CHOICE })}>
                                    <ListItemIcon>
                                        <RadioButtonCheckedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Crea domanda a risposta singola</Typography>
                                </MenuItem>
                            <MenuItem onClick={() => setEditQuestionDialog({ open: true, entity: null, type: EdoQuestionTypeEnum.MULTIPLE_CHOICE })}>
                                    <ListItemIcon>
                                        <LibraryAddCheckIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Crea domanda a risposta multipla</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => setEditQuestionDialog({ open: true, entity: null, type: EdoQuestionTypeEnum.TRUE_FALSE })}>
                                    <ListItemIcon>
                                        <ToggleOffIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Crea domanda Vero/Falso</Typography>
                            </MenuItem>
                            {false ? <MenuItem onClick={() => setEditQuestionDialog({ open: true, entity: null, type: EdoQuestionTypeEnum.FILL_BLANK })}>
                                <ListItemIcon>
                                    <TextFormatIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Crea domanda a completamento</Typography>
                            </MenuItem> : null}
                            <MenuItem onClick={() => setEditQuestionDialog({ open: true, entity: null, type: EdoQuestionTypeEnum.FREE_TEXT })}>
                                    <ListItemIcon>
                                        <FormatAlignLeftIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Crea domanda a risposta scritta</Typography>
                                </MenuItem>
                                {/* <MenuItem onClick={() => setSelectQuestionsDialog({ open: true })}>
                                    <ListItemIcon>
                                        <InventoryIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Seleziona domande dall'archivio</Typography>
                                </MenuItem> */}
                            </Menu>
                    </Box>
                </Grid>
                <Grid item md={12}>
                    {questions.length ? questions.map(quizQuestion => (
                        <CourseQuestion
                            key={quizQuestion.id}
                            onEdit={(question: IEdoQuestion) => setEditQuestionDialog({ open: true, entity: question, type: null })}
                            onDelete={() => setRemoveQuestionDialog({
                                open: true,
                                entity: quizQuestion
                            })}
                            className={classes.question}
                            question={quizQuestion.question} />
                    )) : (
                        <Box p={2}>
                            <Typography variant="body1" align="center">
                                <Translate contentKey="edocendoApp.edoQuestion.home.notFound"/>
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <EditQuestion
                open={editQuestionDialog.open}
                question={editQuestionDialog.entity}
                onClose={() => setEditQuestionDialog({ open: false, entity: null, type: null })}
                onSuccess={handleQuestionCreated}
                type={editQuestionDialog.type}
                quiz={courseQuiz.quiz}
            />
            <SelectQuestionsDialog
                open={selectQuestionsDialog.open}
                onSuccess={handleAddQuestionsToQuiz}
                onClose={() => setSelectQuestionsDialog({ open: false })}
                selectedQuestions={questionsToAdd}
                setSelectedQuestions={(questions) => setQuestionsToAdd(questions)}
            />
            <RemoveQuestion
                open={removeQuestionDialog.open}
                question={removeQuestionDialog.entity}
                onClose={() => setRemoveQuestionDialog({
                    open: false,
                    entity: null
                })}
            />
        </>
    );
};

export default QuizDetailRoute;