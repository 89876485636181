import { IEdoFile } from "shared/model/edo-file.model";
import { IUser } from "shared/model/user.model";

export interface IEdoWallPost {
  id?: number;
  description?: string;
  author?: IUser;
  files?: IEdoFile[] | null;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IEdoWallPost> = {
  files: [],
};
