import { createAsyncThunk, createSlice, isFulfilled, isPending, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IEdoChatParticipation } from "shared/model/edo-chat-participation.model";
import { IPaginationResponse } from "shared/util/pagination.constants";
import {
    IQueryParamsWithFilters
} from "./reducer.utils";

export const initialState: {
    participations: IEdoChatParticipation[];
    loadingParticipations: boolean;
    totalItemsParticipations: number;
    selectedParticipation: IEdoChatParticipation | null;
    loadingParticipation: boolean;
    errorMessage: string | null;
} = {
    participations: [],
    loadingParticipations: false,
    totalItemsParticipations: 0,
    selectedParticipation: null,
    loadingParticipation: false,
    errorMessage: null,
};

export type ChatState = Readonly<typeof initialState>;

const apiUrl = "/students";

// Actions

export const getUserParticipations = createAsyncThunk(
    "chat/get_user_participations",
    async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
        const params = new URLSearchParams(filters);
        if (sort) {
            params.append("page", String(page));
            params.append("limit", String(size));
            if (typeof sort === "string") {
                params.append("sortBy", sort.replace(",", ":"));
            } else {
                for (let s of sort) {
                    params.append("sortBy", s.replace(",", ":"));
                }
            }
        }
        let requestUrl;
        if (params.toString() === "") {
            requestUrl = `/account/chat-participations`;
        } else {
            requestUrl = `/account/chat-participations?${params.toString()}`;
        }
        return axios.get<IPaginationResponse<IEdoChatParticipation>>(
            requestUrl
        );
    }
);

export const getUserParticipationByChat = createAsyncThunk(
    "chat/get_user_participation_by_chat",
    async (id: number | string) => {
        return axios.get<{ data: IEdoChatParticipation; }>(
            `/chats/${id}/participation`
        );
    }
);
// slice

export const ChatSlice = createSlice({
    name: "chat",
    initialState: initialState as ChatState,
    reducers: {
        setSelectedParticipation(state, action: PayloadAction<IEdoChatParticipation>) {
            state.selectedParticipation = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getUserParticipationByChat.fulfilled, (state, action) => {
                state.loadingParticipation = false;
                state.selectedParticipation = action.payload.data.data;
            })
            .addMatcher(
                isFulfilled(getUserParticipations),
                (state, action) => {
                    const {
                        data: {
                            data,
                            meta: { totalItems },
                        },
                        headers,
                    } = action.payload;

                    return {
                        ...state,
                        loadingParticipations: false,
                        participations: data,
                        totalItemsParticipations: totalItems,
                    };
                }
            )
            .addMatcher(
                isPending(getUserParticipations),
                (state) => {
                    state.errorMessage = null;
                    state.loadingParticipations = true;
                }
            )
            .addMatcher(
                isPending(getUserParticipationByChat),
                (state) => {
                    state.errorMessage = null;
                    state.loadingParticipation = true;
                }
            );
    },
});

export const { setSelectedParticipation } = ChatSlice.actions;

// Reducer
const ChatReducer = ChatSlice.reducer;
export default ChatReducer;
