import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import axios from "axios";
import { IEdoCourse } from "shared/model/edo-course.model";
import { IPaginationResponse } from "shared/util/pagination.constants";
import {
  IEdoCourseMaterial,
  defaultValue
} from "../../model/edo-course-material.model";
import { cleanEntity } from "../../util/entity-utils";
import {
  EntityState,
  IQueryParamsWithFilters,
  createEntitySlice,
  serializeAxiosError
} from "../reducer.utils";

const initialState: EntityState<IEdoCourseMaterial> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteSuccess: false,
};

const apiUrl = "/course-materials";

// Actions

export const getEntities = createAsyncThunk(
  "edoCourseMaterial/fetch_entity_list",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `${apiUrl}`;
    } else {
      requestUrl = `${apiUrl}?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoCourseMaterial>>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "edoCourseMaterial/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEdoCourseMaterial>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "edoCourseMaterial/create_entity",
  async (entity: IEdoCourseMaterial, thunkAPI) => {
    const result = await axios.post<IEdoCourseMaterial>(
      apiUrl,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createMany = createAsyncThunk(
  "edoCourseMaterial/create_many",
  async (entity: IEdoCourseMaterial | { courses: IEdoCourse[] }, thunkAPI) => {
    const result = await axios.post<{ data: IEdoCourseMaterial }>(
      apiUrl,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "edoCourseMaterial/update_entity",
  async (entity: IEdoCourseMaterial, thunkAPI) => {
    const result = await axios.put<IEdoCourseMaterial>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "edoCourseMaterial/partial_update_entity",
  async (entity: IEdoCourseMaterial, thunkAPI) => {
    const result = await axios.patch<IEdoCourseMaterial>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "edoCourseMaterial/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEdoCourseMaterial>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getCourseMaterialsByCourse = createAsyncThunk(
  "edoCourseMaterial/get_course_materials_by_course",
  async ({
    courseId,
    page,
    size,
    sort,
    filters,
  }: { courseId: string } & IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl = `/courses/${courseId}/course-materials`;
    if (params.toString() !== "") {
      requestUrl = `${requestUrl}?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoCourseMaterial>>(requestUrl);
  }
);

// slice

export const EdoCourseMaterialSlice = createEntitySlice({
  name: "edoCourseMaterial",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.deleteSuccess = true;
        state.entity = {};
      })
      .addMatcher(
        isFulfilled(getEntities, getCourseMaterialsByCourse),
        (state, action) => {
          const {
            data: {
              data,
              meta: { totalItems },
            },
            headers,
          } = action.payload;

          return {
            ...state,
            loading: false,
            entities: data,
            totalItems,
          };
        }
      )
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isFulfilled(createMany), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(
        isPending(getEntities, getCourseMaterialsByCourse, getEntity),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(createEntity, createMany, updateEntity, partialUpdateEntity),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      )
      .addMatcher(isPending(deleteEntity), (state) => {
        state.errorMessage = null;
        state.deleteSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = EdoCourseMaterialSlice.actions;

// Reducer
export default EdoCourseMaterialSlice.reducer;
