import { Alert, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { activateAction, reset } from "./activate.reducer";

const successAlert = (
  <Alert severity="success">
    <strong>Your user account has been activated.</strong> Please
    <Link to="/login" className="alert-link">
      sign in
    </Link>
    .
  </Alert>
);

const failureAlert = (
  <Alert severity="error">
    <strong>Your user could not be activated.</strong> Please use the
    registration form to sign up.
  </Alert>
);

export const ActivatePage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    const key = searchParams.get("key") || "";
    dispatch(activateAction(key));
    return () => {
      dispatch(reset());
    };
  }, []);

  const { activationSuccess, activationFailure } = useAppSelector(
    (state) => state.activate
  );

  return (
      <Box>
        <Box>
          <h1>Activation</h1>
          {activationSuccess ? successAlert : undefined}
          {activationFailure ? failureAlert : undefined}
        </Box>
      </Box>
  );
};

export default ActivatePage;
