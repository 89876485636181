import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoFile } from "shared/model/edo-file.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { deleteEntity } from "../../../../shared/reducers/entities/edo-file.reducer";

interface IDeleteFileProps {
  file: IEdoFile;
  open: boolean;
  onClose: () => void;
}

const DeleteFile = (props: IDeleteFileProps) => {
  const dispatch = useAppDispatch();
  const { updateSuccess } = useAppSelector<EntityState<IEdoFile>>(
    (state) => state.edoFile
  );

  const { open, onClose: handleClose, file } = props;

  const confirmDelete = () => {
    dispatch(deleteEntity(file.id));
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate
            contentKey="edocendoApp.edoFile.delete.question"
            interpolate={{ nome: file?.originalFileName }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button onClick={confirmDelete} color="primary">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFile;