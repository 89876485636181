import moment from 'moment';
export interface IEdoSubject {
  id?: number;
  name?: string;
  date?: string;
  picPath?: string;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoSubject> = {
  date: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
};
