import { useAppSelector } from "config/store";
import { IEdoWallPost } from "shared/model/edo-wall-post.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { EditPost } from "./EditPost";

interface IEditWallPostProps {
    open: boolean;
    post: IEdoWallPost;
    onClose: () => void;
    updatePost: (post: IEdoWallPost) => void;
}

const EditWallPost = (props: IEditWallPostProps) => {
    const {  loading, updating, updateSuccess } = useAppSelector<EntityState<IEdoWallPost>>((state) => state.edoWallPost);
    
    const { open, post, updatePost, onClose: handleClose } = props;

    return (
        <EditPost
            post={post}
            onClose={handleClose}
            open={open}
            loading={loading}
            updating={updating}
            updateSuccess={updateSuccess}
            updatePost={updatePost}
        />
    );
};

export default EditWallPost;