import { APP_LOCAL_DATETIME_FORMAT } from "config/constants";
import dayjs from "dayjs";

export const convertDateTimeFromServer = (date: string | number | Date | dayjs.Dayjs) =>
  date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null;

export const convertDateTimeToServer = (date: string | number | Date | dayjs.Dayjs) =>
  date ? dayjs(date).toDate() : null;

export const displayDefaultDateTime = () =>
  dayjs().startOf("day").format(APP_LOCAL_DATETIME_FORMAT);
