import {
    Box, Button, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useRef, useState } from "react";
import { Translate } from "react-jhipster";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { IEdoQuizQuestion } from "shared/model/edo-quiz-question.model";
import { getEntity as getQuizAttempt } from "shared/reducers/entities/edo-quiz-attempt.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { getQuestionIcon } from 'shared/util/question-utils';
import { makeStyles } from 'tss-react/mui';
import CourseQuestion from "./components/CourseQuestion";


const useStyles = makeStyles()(theme =>
    ({
        question: {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(4),
            },
        }
    })
);


const QuizAttemptRoute = () => {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();
    const { entity: quizAttempt } = useAppSelector<EntityState<IEdoQuizAttempt>>(state => state.edoQuizAttempt);
    const { updateSuccess: updateSuccessQuizQuestion } = useAppSelector<EntityState<IEdoQuizQuestion>>(state => state.edoQuizQuestion);
    const [questions, setQuestions] = useState<IEdoQuizQuestion[]>([]);
    const [timeLeft, setTimeLeft] = useState<number>(0);

    const interval = useRef(null);

    const getQuizQuestions = async () => {
        const response = await axios.get<{ data: IEdoQuizQuestion[] }>(`/quiz-attempts/${params.id}/questions`, {
            params: {
                sort: "updatedAt,ASC",
            }
        });
        setQuestions(response.data.data);
    };

    useEffect(() => {
        dispatch(getQuizAttempt(params.id));

        return () => {
            clearInterval(interval.current);
        };
    }, []);

    const handleTerminateAttempt = async () => {
        const response = await axios.patch(`/quiz-attempts/${quizAttempt.id}/end`);
        navigate(`/courses/${quizAttempt?.courseQuiz?.course?.id}/quizzes/${quizAttempt?.courseQuiz?.id}/start`);
    };


    useEffect(() => {
        if (quizAttempt?.id) {
            getQuizQuestions();
        }
        if (quizAttempt.timeFinish) {
            clearInterval(interval.current);
            interval.current = setInterval(() => {
                const timeFinish = new Date(quizAttempt.timeFinish).getTime();
                const timeLeftLocal = (timeFinish - new Date().getTime()) / 1000;
                setTimeLeft(timeLeftLocal);
                if (timeLeftLocal <= 0) {
                    handleTerminateAttempt();
                }
            }, 1000);
        }
    }, [quizAttempt?.id]);

   
    if (quizAttempt?.timeFinish !== null && quizAttempt?.courseQuiz?.course?.id && (new Date(quizAttempt?.timeFinish)).getTime() < (new Date().getTime())) {
        return <Navigate replace to={`/courses/${quizAttempt?.courseQuiz?.course?.id}/quizzes/${quizAttempt?.courseQuiz?.id}/start`} />;
    }

    return (
        <Grid container spacing={3}>
            <Grid item md={8}>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Card>
                            <CardContent>
                                <Box p={2}>
                                    <Box mb={1}>
                                        <Typography gutterBottom variant="h4" component="div">
                                            {quizAttempt?.courseQuiz?.quiz?.title}
                                        </Typography>
                                    </Box>
                                    <Box >
                                        <Typography variant="body1" color="text.secondary">
                                            {quizAttempt?.courseQuiz?.quiz?.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={12}>
                        {questions.length ? questions.map(question => (
                            <CourseQuestion
                                key={question.id}
                                className={classes.question}
                                quizQuestion={question}
                                quizAttempt={quizAttempt}
                            />
                        )) : (
                            <Box p={2}>
                                <Typography variant="body1" align="center">
                                    <Translate contentKey="edocendoApp.edoQuestion.home.notFound">
                                        No Edo Questions found
                                    </Translate>
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>


            <Grid item md={4}>
                <Box mb={3}>
                    <Card sx={(theme) => ({ color: theme.palette.text.secondary })}>
                        <CardHeader subheader="Informazioni" sx={(theme) => ({ backgroundColor: theme.palette.background.paper })} />
                        <Divider />
                        <CardContent sx={{
                        }}>
                            <Box mb={3}>
                                <Typography variant="body1" color="text.primary">Tempo rimasto</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${Math.trunc(timeLeft / 60 / 60)} ore, ${Math.trunc(timeLeft / 60 % 60)} minuti, ${Math.trunc(timeLeft % 60)} secondi`}
                                </Typography>
                            </Box>
                            <Button variant="contained" color="primary" fullWidth onClick={() => handleTerminateAttempt()}>
                                Consegna
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
                <Box>
                    <Card sx={(theme) => ({ color: theme.palette.text.secondary })}>
                        <CardHeader subheader="Domande" sx={(theme) => ({ backgroundColor: theme.palette.background.paper })} />
                        <Divider />
                        <CardContent sx={{
                            padding: 0,
                            "&:last-child": {
                                paddingBottom: 0
                            }
                        }}>
                            <List>
                                {questions ? (
                                    questions.map((quizQuestion, index) => (
                                        <ListItem
                                            button
                                            key={quizQuestion.id}
                                            onClick={() => { }}
                                        >
                                            <ListItemIcon>
                                                {getQuestionIcon(quizQuestion.question.type)}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={`Domanda ${index + 1}`}
                                            // secondary={
                                            //     <TextFormat
                                            //         value={evento.startDate}
                                            //         type="date"
                                            //         format={APP_DATE_FORMAT}
                                            //     />
                                            // }
                                            />
                                        </ListItem>
                                    ))
                                ) : (
                                    <Box p={2}>
                                        <Typography variant="body1" align="center">
                                            <Translate contentKey="edocendoApp.edoCourseQuestion.home.notFound" />
                                        </Typography>
                                    </Box>
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};

export default QuizAttemptRoute;