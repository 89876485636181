import {
    Add as AddIcon
} from "@mui/icons-material";
import {
    AppBar, Box, Divider, IconButton,
    List, Paper, TablePagination, Toolbar, Typography, useMediaQuery, useTheme
} from "@mui/material";
import ViewQuestion from "components/ViewQuestion/ViewQuestion";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { IEdoQuestion } from "shared/model/edo-question.model";
import { getQuestionsByAccount } from "shared/reducers/entities/edo-question.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import EditQuestion from "../../components/EditQuestion/EditQuestion";
import DeleteQuestion from "./components/DeleteQuestion";
import QuestionRow from "./components/QuestionRow";

const QuestionsRoute = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { entities: questions, loading, totalItems, updateSuccess } = useAppSelector<EntityState<IEdoQuestion>>(state => state.edoQuestion);
    const [viewQuestionDialog, setViewQuestionDialog] = useState<{ open: boolean; entity: IEdoQuestion }>({ open: false, entity: null });
    const [editQuestionDialog, setEditQuestionDialog] = useState<{ open: boolean; entity: IEdoQuestion }>({ open: false, entity: null });
    const [deleteQuestionDialog, setDeleteQuestionDialog] = useState<{ open: boolean; entity: IEdoQuestion }>({ open: false, entity: null });
    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: "updatedAt",
        order: "DESC",
    });

    const getAllQuestions = async () => {
        dispatch(getQuestionsByAccount({
            page: paginationState.activePage,
            size: paginationState.itemsPerPage,
            sort: `${paginationState.sort},${paginationState.order}`,
        }));
    };

    const handlePagination = (currentPage: number) =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const handleRowsPerPage = (rowsPerPage: number) => {
        setPaginationState({
            ...paginationState,
            itemsPerPage: rowsPerPage,
            activePage: 1,
        });
    };

    useEffect(() => {
        getAllQuestions();
    }, [
        paginationState.activePage,
        paginationState.order,
        paginationState.sort,
        paginationState.itemsPerPage,
    ]);

    useEffect(() => {
        if (updateSuccess) {
            getAllQuestions();
        }
    }, [updateSuccess]);

    return (
        <>
            <Paper style={{ overflow: "hidden" }}>
                <AppBar position="relative">
                    <Toolbar>
                        <Typography variant="h6" flexGrow={1}>
                            Domande
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={() => setEditQuestionDialog({ open: true, entity: null })}>
                            <AddIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {questions?.length > 0 ? (
                    <List dense>
                        {questions.map((question) => (
                            <QuestionRow
                                key={question.id}
                                question={question}
                                onView={() => setViewQuestionDialog({ open: true, entity: question })}
                                onEdit={() => setEditQuestionDialog({ open: true, entity: question })}
                                onDelete={() => setDeleteQuestionDialog({ open: true, entity: question })}
                            />
                        ))}
                    </List>
                ) : (
                    !loading && (
                        <Box p={2}>
                            <Typography variant="body1" align="center">
                                <Translate contentKey="edocendoApp.edoQuestion.home.notFound" />
                            </Typography>
                        </Box>
                    )
                )}
                <Divider />
                <TablePagination
                    component="div"
                    count={totalItems}
                    rowsPerPage={paginationState.itemsPerPage}
                    page={paginationState.activePage - 1}
                    onPageChange={(event, page) => handlePagination(page + 1)}
                    onRowsPerPageChange={(event) =>
                        handleRowsPerPage(parseInt(event.target.value, 10))
                    }
                    labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
                    }
                />
            </Paper>
            <ViewQuestion
                open={viewQuestionDialog.open}
                question={viewQuestionDialog.entity}
                onClose={() => setViewQuestionDialog({ open: false, entity: null })}
            />
            <EditQuestion
                open={editQuestionDialog.open}
                question={editQuestionDialog.entity}
                onClose={() => setEditQuestionDialog({ open: false, entity: null })}
            />
            <DeleteQuestion
                open={deleteQuestionDialog.open}
                question={deleteQuestionDialog.entity}
                onClose={() => setDeleteQuestionDialog({ open: false, entity: null })}
            />
        </>
    );
};

export default QuestionsRoute;
