import axios from "axios";
import { useAppDispatch, useAppSelector } from "config/store";
import { defaultValue as defaultValuePost, IEdoCoursePost } from "shared/model/edo-course-post.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { createEntity } from "shared/reducers/entities/edo-course-post.reducer";
import CreatePost from "./CreatePost";

interface ICreateCoursePostProps {
  courseId: number;
}

const CreateCoursePost = (props: ICreateCoursePostProps) => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector<AuthenticationState>((state) => state.authentication);

  const { courseId } = props;

  const createPost = (form: IEdoCoursePost) => {
    dispatch(
      createEntity({
        ...form,
        course: { id: courseId },
      })
    );
  };

  const handleUploadFiles = async (files: File[]) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i], files[i].name);
    }

    const response = await axios.post<{ data: IEdoFile[] }>(`/files/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.data;
  };

  return (
    <CreatePost defaultValuePost={defaultValuePost} createPost={createPost} uploadFiles={handleUploadFiles} user={account} />
  );
};

export default CreateCoursePost;