import {
    AppBar,
    Box, CircularProgress,
    Divider, List, Paper,
    TablePagination,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import MaterialRow from "components/MaterialRow/MaterialRow";
import { ViewChapter } from "components/ViewChapter/ViewChapter";
import { useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { IEdoStudent } from "shared/model/edo-student.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme =>
({
    paper: {
        color: theme.palette.text.secondary,
        overflow: "hidden",
        height: "100%",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    appbar: {
        boxShadow: "none",
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
    },
    toolbar: {
        justifyContent: "space-between",
    },
})
);

interface INewCourseMaterials {
    student: IEdoStudent;
}

const NewCourseMaterials = (props: INewCourseMaterials) => {
    const theme = useTheme();
    const { classes } = useStyles();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { account } = useAppSelector<AuthenticationState>((state) => state.authentication);
    const [courseMaterials, setCourseMaterials] = useState<{ entities: IEdoCourseMaterial[], loading: boolean; totalItems: number; }>({ entities: [], loading: false, totalItems: 0 });
    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: "createdAt",
        order: "ASC",
    });
    const [viewChapterDialog, setViewChapterDialog] = useState<{ entity: IEdoChapter; open: boolean; }>({ entity: null, open: false });

    const { student } = props;

    const getCourseMaterials = async () => {
        if (!student?.user?.id) return;
        setCourseMaterials(prevState => ({ ...prevState, loading: true }));
        const response = await axios.get<{ data: IEdoCourse[], meta: { totalItems: number; } }>(`/students/${student?.user.id}/course-materials`, {
            params: {
                page: paginationState.activePage,
                size: paginationState.itemsPerPage,
                sort: `${paginationState.sort},${paginationState.order}`
            }
        });
        setCourseMaterials({ entities: response.data.data, loading: false, totalItems: response.data.meta.totalItems });
    };

    useEffect(() => {
        if (account?.id && student?.userId) {
            getCourseMaterials();
        }
    }, [
        paginationState.activePage,
        paginationState.order,
        paginationState.sort,
        paginationState.itemsPerPage,
        student?.userId,
        account?.id,
    ]);

    const sort = (p: string) => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === "ASC" ? "DESC" : "ASC",
            sort: p,
        });
    };

    const handlePagination = (currentPage: number) =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const handleRowsPerPage = (rowsPerPage: number) =>
        setPaginationState({
            ...paginationState,
            itemsPerPage: rowsPerPage,
            activePage: 1,
        });

    const handleSyncList = () => {
        getCourseMaterials();
    };

    return (
        <>
            <Paper className={classes.paper}>
                <Box className={classes.root}>
                    <AppBar
                        position="static"
                        color="transparent"
                        className={classes.appbar}
                    >
                        <Toolbar className={classes.toolbar}>
                            <Typography variant="h5">
                                Materiale didattico
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box flexGrow={1}>
                        {courseMaterials.loading ? (
                            <Box position="relative" display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        ) : (!courseMaterials.entities.length ? (
                            <Box p={2}>
                                <Typography variant="body1" align="center">
                                    <Translate contentKey="edocendoApp.edoCourseMaterial.home.notFound" />
                                </Typography>
                            </Box>
                        ) : (
                            <List component="div" disablePadding>
                                {courseMaterials.entities.map((courseMaterial, i, arr) => (
                                    <MaterialRow
                                        key={courseMaterial.id}
                                        courseMaterial={courseMaterial}
                                        isLastElement={i === arr.length - 1}
                                        onOpenViewChapter={() => setViewChapterDialog({ entity: courseMaterial.chapter, open: true })}
                                        onOpenEditMaterial={null}
                                        onOpenDeleteMaterial={null}
                                        showSubject={true}
                                        extendedDate={true}
                                    />
                                ))}
                            </List>
                        )
                        )}
                    </Box>
                    <Divider />
                    <TablePagination
                        component="div"
                        count={courseMaterials.totalItems}
                        rowsPerPage={paginationState.itemsPerPage}
                        page={paginationState.activePage - 1}
                        onPageChange={(event, page) => handlePagination(page + 1)}
                        onRowsPerPageChange={(event) =>
                            handleRowsPerPage(parseInt(event.target.value, 10))
                        }
                        labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
                        }
                    />
                </Box>
            </Paper>
            <ViewChapter
                open={viewChapterDialog.open}
                chapter={viewChapterDialog.entity}
                onClose={() => setViewChapterDialog({ entity: null, open: false })}
            />
        </>
    );
};

export default NewCourseMaterials;