import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link as MaterialLink,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import { APP_DATE_FORMAT, APP_DATE_FORMAT_MID } from "config/constants";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { TextFormat } from "react-jhipster";
import { Link } from "react-router-dom";
import { IEdoCourseEvent } from "shared/model/edo-course-event.model";

export interface ViewEventoInsegnamentoProps {
  eventId: number;
  open: boolean;
  handleClose: any;
}

export const ViewEventoInsegnamento = (props: ViewEventoInsegnamentoProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState<boolean>(true);
  const [event, setEvent] = useState<IEdoCourseEvent | null>(null);

  const { eventId, open, handleClose } = props;

  useEffect(() => {
    const getEvent = async (id: number) => {
      setLoading(true);
      const response = await axios.get(`/course-events/${id}`);
      setEvent(response.data);
    };
    if (eventId) {
      getEvent(eventId);
    }
  }, [eventId]);
  
  useEffect(() => { 
    if (event) {
      setLoading(false);
    }
  }, [event]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullScreen={fullScreen}
      fullWidth
    >
      {loading ? (
        <CircularProgress
          size={24}
          style={{ marginLeft: 15, position: "relative", top: 4 }}
        />
      ) : (
        <>
          <DialogTitle>
            {"Evento del "}{" "}
            <TextFormat
              type="date"
              value={event.startDate}
              format={APP_DATE_FORMAT_MID}
              locale="it"
            />
          </DialogTitle>

          <DialogContent>
            <Box mb={2}>
              <Typography gutterBottom variant="h5" component="h2">
                {event.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {event.description}
              </Typography>
            </Box>
            <Divider />
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography variant="body1" color="textSecondary" component="p">
                {"Insegnamento: "}
                <MaterialLink
                  to={`/courses/${event.course.id}`}
                  color="primary"
                  component={Link}
                >
                  {event.course.subject.name +
                    " (" +
                    event.course.class.year.year +
                    event.course.class.section +
                    ")"}
                </MaterialLink>
              </Typography>
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography variant="body1" color="textSecondary" component="p">
                {"Data inizio: "}{" "}
                <TextFormat
                  type="date"
                  value={event.startDate}
                  format={APP_DATE_FORMAT}
                  locale="it"
                />
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p">
                {"Data fine: "}{" "}
                <TextFormat
                  type="date"
                  value={event.endDate}
                  format={APP_DATE_FORMAT}
                  locale="it"
                />
              </Typography>
            </Box>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};
