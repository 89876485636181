import {
    Movie as MovieIcon
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Box, Button,
    Card, CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel, MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import "moment/locale/it";
import React, { createRef, useContext, useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import {
    SelectValidator,
    ValidatorForm
} from "react-material-ui-form-validator";
import { IEdoCourseYear } from "shared/model/edo-course-year.model";
import { IEdoSchool } from "shared/model/edo-school.model";
import { IEdoSubject } from "shared/model/edo-subject.model";
import { IEdoVideo } from "shared/model/edo-video.model";
import { IEdoVideostorePost, defaultValue } from "shared/model/edo-videostore-post.model";
import { EdoEducationalStage } from "shared/model/enumerations/edo-educational-stage.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EntityState } from "shared/reducers/reducer.utils";
import { getEntities as getEdoClasseList } from "../../../shared/reducers/entities/edo-class.reducer";
import {
    updateEntity
} from "../../../shared/reducers/entities/edo-videostore-post.reducer";
import { getVideoStatusLabel } from "../videostore";

interface IMultiUploadProps {
    open: boolean;
    onClose: () => void;
}

const MultiUpload = (props: IMultiUploadProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const socket = useContext(SocketContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { loading, updating, updateSuccess } = useAppSelector<EntityState<IEdoVideostorePost>>((state) => state.edoVideostorePost);
    const { entities: edoSubjectList, loading: loadingSubject } = useAppSelector<EntityState<IEdoSubject>>((state) => state.edoSubject);
    const { entities: edoCourseYearList } = useAppSelector<EntityState<IEdoCourseYear>>((state) => state.edoCourseYear);
    const { entities: edoSchoolList } = useAppSelector<EntityState<IEdoSchool>>((state) => state.edoSchool);
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);
    const [form, setForm] = useState<IEdoVideostorePost>({ ...defaultValue });
    const [uploading, setUploading] = useState<boolean>(false);

    const fileInput = createRef<HTMLInputElement>();

    const { open, onClose: handleClose } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
    };

    useEffect(() => {
        dispatch(getEdoClasseList({ page: 0, size: 1000, sort: `id:ASC` }));
    }, []);

    useEffect(() => {
        if (!open) {
            setForm({ ...defaultValue });
            return;
        }
    }, [open]);

    useEffect(() => {
        if (updateSuccess) {
            handleClose();
        }
    }, [updateSuccess]);

    const saveEntity = async () => {
        dispatch(
            updateEntity({
                ...form,
            })
        );
    };

    const selectFiles = () => {
        fileInput.current.click();
    };

    const handleFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setUploading(true);
        // setFiles([...files, ...Array.from(event.target.files)]); // if multiple
        const files = [...Array.from(event.target.files)];

        if (files.length) {
            const fileResponses = [];
            const failedUploads = [];

            for (let file of files) {
                const formData = new FormData();
                formData.append("file", file, file.name);

                const { title, ...documentToSend } = form;

                formData.append("data", JSON.stringify(documentToSend));

                try {
                    const response = await axios.post<{ data: IEdoVideostorePost }>("/videostore-posts/multiple", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        timeout: 0,
                        maxBodyLength: Infinity,
                        maxContentLength: Infinity,
                        onUploadProgress: progressEvent => {
                            console.log(`${file.name}: ${Math.round((progressEvent.loaded * 100) / progressEvent.total)}%`);
                        }
                    });
                    fileResponses.push(response);
                } catch (e) {
                    failedUploads.push(file);
                    console.log(`Impossibile caricare il file ${file.name}.`);
                }
            }

            setUploading(false);
            handleClose();
        }
    };

    const handleOnVideoUpdate = (video: IEdoVideo) => {
        if (video.id === form?.video?.id) {
            setForm(prevState => ({
                ...prevState,
                video: { ...prevState.video, status: video.status }
            }));
        }
    };

    useEffect(() => {
        if (account?.id) {
            socket.on(`user:${account.id}:video-update`, handleOnVideoUpdate);
        }
        return function cleanup() {
            socket.off(`user:${account.id}:video-update`, handleOnVideoUpdate);
        };
    }, [account, form]); // Ho aggiunto form perchè altrimenti non viene aggiornato nell'handler

    return (
        <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} fullWidth>
            <DialogTitle>
                Caricamento multiplo
            </DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom={2}>Inserisci le informazioni dei contenuti videoteca.<br />Puoi selezionare uno o piu anni e uno o piu istituti.</DialogContentText>
                {loading ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                    <ValidatorForm
                        onSubmit={saveEntity}
                        onError={(errors) => false}
                        sx={{ flexGrow: 1 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <SelectValidator
                                    fullWidth
                                    label="Anni"
                                    name="year"
                                    size="small"
                                    required
                                    disabled={uploading}
                                    SelectProps={{ multiple: true }}
                                    variant="outlined"
                                    value={form.years?.map((el) => el.id) || []}
                                    onChange={(event: React.ChangeEvent<{ value: string[] }>) => {
                                        setForm({
                                            ...form,
                                            years: event.target.value.map((el) => {
                                                return {
                                                    id: el,
                                                };
                                            }) as any,
                                        });
                                    }}
                                >
                                    {/* <MenuItem value="" key="NESSUNO">
                    Nessuno
                  </MenuItem> */}
                                    {edoCourseYearList
                                        ? edoCourseYearList.map((annoCorso) => (
                                            <MenuItem value={annoCorso.id} key={annoCorso.id}>
                                                {annoCorso.year}
                                            </MenuItem>
                                        ))
                                        : null}
                                </SelectValidator>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    multiple={false}
                                    options={edoSubjectList}
                                    freeSolo={false}
                                    getOptionLabel={(option: IEdoSubject) =>
                                        option.name
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    loadingText="Ricerca..."
                                    noOptionsText="Nessun risultato"
                                    value={form.subject?.id ? form.subject : null}
                                    forcePopupIcon={false}
                                    size="small"
                                    disabled={uploading}
                                    loading={loadingSubject}
                                    onChange={(event, value: IEdoSubject, reason) => {
                                        if (reason === "selectOption") {
                                            setForm({
                                                ...form,
                                                subject: value,
                                            });
                                        } else if (reason === "clear") {
                                            setForm({
                                                ...form,
                                                subject: null,
                                            });
                                        }
                                    }}
                                    // onInputChange={(event, value, reason) => {
                                    //   if (reason === "input") {
                                    //     searchSubject(value);
                                    //   } else if (reason === "clear") {
                                    //     searchSubject();
                                    //   }
                                    // }}
                                    renderInput={(autocompleteParams) => (
                                        <TextField
                                            {...autocompleteParams}
                                            variant="outlined"
                                            required
                                            disabled={uploading}
                                            label={translate("edocendoApp.edoVideostorePost.subject")}
                                            placeholder={translate("edocendoApp.edoVideostorePost.subject")}
                                            InputProps={{
                                                ...autocompleteParams.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loadingSubject ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {autocompleteParams.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth size="small" required>
                                    <InputLabel id="educationalStage">{translate("edocendoApp.edoVideostorePost.educationalStage")}</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId='educationalStage'
                                        label={translate("edocendoApp.edoVideostorePost.educationalStage")}
                                        name="educationalStage"
                                        size="small"
                                        required
                                        variant="outlined"
                                        disabled={uploading}
                                        value={form.educationalStage || ""}
                                        onChange={(event) => {
                                            setForm((prevState) => ({
                                                ...prevState,
                                                educationalStage: event.target.value as EdoEducationalStage,
                                            }));
                                        }}
                                        renderValue={(value) => {
                                            return translate(`edocendoApp.EdoEducationalStage.${value}`);
                                        }}
                                    >
                                        <MenuItem value={EdoEducationalStage.INFANZIA}>
                                            <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.INFANZIA}`} />
                                        </MenuItem>
                                        <MenuItem value={EdoEducationalStage.PRIMARIA}>
                                            <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.PRIMARIA}`} />
                                        </MenuItem>
                                        <MenuItem value={EdoEducationalStage.SECONDARIA_I}>
                                            <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.SECONDARIA_I}`} />
                                        </MenuItem>
                                        <MenuItem value={EdoEducationalStage.SECONDARIA_II}>
                                            <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.SECONDARIA_II}`} />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                )}
                {form.video ? (
                    <Card sx={{ display: 'flex', height: 60, marginTop: 2 }}>
                        <CardMedia
                            sx={{ width: 60, bgcolor: "#e53935", display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <MovieIcon sx={{ fontSize: 30, color: "white" }} />
                        </CardMedia>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" pl={2}>
                            <Typography component="div" variant="subtitle2" color="text.primary">
                                {form.video.originalFileName}
                            </Typography>
                            <Typography component="div" variant="subtitle2" color="text.secondary">
                                {getVideoStatusLabel(form.video.status)}
                            </Typography>
                        </Box>
                    </Card>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"secondary"}>
                    <Translate contentKey="entity.action.cancel" />
                </Button>
                <LoadingButton
                    onClick={selectFiles}
                    // size="medium"
                    color="primary"
                    loading={uploading}
                    disabled={updating || !form.years?.length || !form.subject || !form.educationalStage}
                >
                    Aggiugi file video
                </LoadingButton>

                <input
                    type="file"
                    ref={fileInput}
                    name="files"
                    multiple={true}
                    hidden
                    onChange={handleFiles}
                    // accept="video/*"
                    accept="video/mp4,video/quicktime"
                />
            </DialogActions>
        </Dialog>
    );
};

export default MultiUpload;