import {
    Box, Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography, useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { IEdoQuestionChoice } from "shared/model/edo-question-choice.model";
import { IEdoQuestion } from "shared/model/edo-question.model";
import { EdoQuestionTypeEnum } from "shared/model/enumerations/edo-question-type.mode";
import { getQuestionType } from 'shared/util/question-utils';


interface ViewQuestionProps {
    question: IEdoQuestion;
    open: boolean;
    onClose: () => void;
}

const ViewQuestion = (props: ViewQuestionProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [loading, setLoading] = useState<boolean>(true);
    const [choices, setChoices] = useState<IEdoQuestionChoice[]>([]);

    const { question, open, onClose: handleClose } = props;

    useEffect(() => {
        if (question?.id) {
            setLoading(false);
        }
        if (question?.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question?.type === EdoQuestionTypeEnum.TRUE_FALSE || question?.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question?.type === EdoQuestionTypeEnum.FILL_BLANK) {
            handleGetChoices();
        }
    }, [question?.id]);

    const handleGetChoices = async () => {
        const response = await axios.get<{ data: IEdoQuestionChoice[] }>(`/questions/${question?.id}/choices`);
        setChoices(response.data.data);
    };

    const getMultipleChoiceOptions = () => {

        if (!choices?.length) {
            return (
                <Box p={2}>
                    <Typography variant="body1" align="center">
                        <Translate contentKey="edocendoApp.edoQuestionChoice.home.notFound">
                            No Edo Question Choices found
                        </Translate>
                    </Typography>
                </Box>
            );
        }

        if (question?.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question?.type === EdoQuestionTypeEnum.TRUE_FALSE) {

            return (
                <FormControl disabled={true}>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ mb: 1 }}>Risposte disponibili</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        {choices.map(choice => <FormControlLabel key={choice.id} value={choice.id} control={<Radio />} checked={choice.isCorrect} label={choice.description} />)}
                    </RadioGroup>
                </FormControl>
            );

        } else {
            return (
                <FormControl sx={{ mt: 2, mb: 1 }} component="fieldset" variant="standard" disabled={true}>
                    <FormLabel component="legend">Risposte disponibili</FormLabel>
                    <FormGroup>
                        {choices.map(choice => <FormControlLabel key={choice.id} value={choice.id} control={<Checkbox />} checked={choice.isCorrect} label={choice.description} />)}
                    </FormGroup>
                </FormControl>
            );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            fullScreen={fullScreen}
            fullWidth
        >
            {loading ? (
                <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
            ) : (
                    <DialogContent>
                        <Box p={3}>
                            <Box mb={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                <Box>
                                    <Box mb={1}>
                                        <Typography variant="body1" color="text.primary">
                                            {question?.description}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body2" color="text.secondary">
                                            {getQuestionType(question?.type)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box flexGrow={1}>
                                {question?.type === EdoQuestionTypeEnum.FREE_TEXT ? (
                                    <Box mb={1}>
                                        <TextField
                                            variant="filled"
                                            multiline
                                            rows={4}
                                            // size="small"
                                            fullWidth
                                            required
                                            disabled
                                            label={translate("edocendoApp.edoQuestion.description")}
                                            name="description"
                                        />
                                    </Box>
                                ) : null}
                                {question?.explanationRequired ? (
                                    <Box mb={1}>
                                        <TextField
                                            variant="filled"
                                            multiline
                                            rows={4}
                                            // size="small"
                                            fullWidth
                                            required
                                            disabled
                                            label={translate("edocendoApp.edoQuestion.description")}
                                            name="description"
                                        />
                                    </Box>
                                ) : null}
                                {question?.type === EdoQuestionTypeEnum.SINGLE_CHOICE || question?.type === EdoQuestionTypeEnum.TRUE_FALSE || question?.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || question?.type === EdoQuestionTypeEnum.FILL_BLANK ? (
                                    <Box mt={2}>
                                        {getMultipleChoiceOptions()}
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                    </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewQuestion;