import {
  People as PeopleIcon,
  Person as PersonIcon
} from "@mui/icons-material";
import {
  Avatar,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { translate } from "react-jhipster";
import { IEdoChatParticipation } from "shared/model/edo-chat-participation.model";
import { EdoChatType } from "shared/model/enumerations/edo-chat-type.model";
import { ChatState, setSelectedParticipation } from "shared/reducers/chat.reducer";

interface ChatParticipationProps {
  participation: IEdoChatParticipation;
  onOpen: (participation: IEdoChatParticipation) => void;
}

const ChatParticipation = (props: ChatParticipationProps) => {
  const dispatch = useAppDispatch();
  const { selectedParticipation } = useAppSelector<ChatState>(state => state.chat);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

  const { participation, onOpen } = props;

  const selected = selectedParticipation?.id === participation.id;


  const getUnreadMessagesCount = async () => {
    const response = await axios.get(
      `/chat-participations/${participation.id}/messages/unseen/count`
    );
    setUnreadMessagesCount(response.data.count);
  };


  useEffect(() => {
    if (participation) {
      getUnreadMessagesCount();
    }
  }, [participation]);
  
  const handleOpen = () => {
    dispatch(setSelectedParticipation(participation));
    onOpen(participation);
  };


  // const handleOnSelect = async () => {
  //   await checkAllChatMessages(partecipazione.chat.id);
  //   getUnreadMessagesCount();
  // }



  return (
    <ListItem
      button
      onClick={handleOpen}
      selected={selected}
    >
      <ListItemIcon>
        <Avatar alt={participation.chat.title}>
          {participation.chat.type === EdoChatType.CHAT ? (
            <PersonIcon />
          ) : (
            <PeopleIcon />
          )}
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={
          participation.chat.type === EdoChatType.CHAT
            ? participation.title
            : participation.chat.title
        }
        secondary={translate(
          "edocendoApp.EdoChatType." + participation.chat.type
        )}
      />
      <ListItemText
        primary={
          unreadMessagesCount > 0 && !selected ? (
            <Chip size="small" color="secondary" label={unreadMessagesCount} />
          ) : null
        }
        primaryTypographyProps={{ align: "right" }}
      />
    </ListItem>
  );
};

export default ChatParticipation;
