import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';

export const PostSkeleton = () => {

    return (
        <Card>
            <CardHeader
                avatar={
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                }
                title={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                    />
                }
                subheader={
                    <Skeleton animation="wave" height={10} width="40%" />
                }
            />
            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />

            <CardContent sx={{
                padding: 0,
                "&:last-child": {
                    paddingBottom: 0
                }
            }}>
                <Box sx={{
                    paddingTop: 1,
                    paddingRight: 2,
                    paddingBottom: 1,
                    paddingLeft: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Skeleton animation="wave" variant="circular" width={40} height={40} style={{ marginRight: 15 }} />
                    <Skeleton animation="wave" height="60px" style={{ flexGrow: 1, marginRight: 15 }} />
                    <Skeleton animation="wave" height="60px" width="100px" />
                </Box>


            </CardContent>
        </Card>
    );
};

export default PostSkeleton;