import {
    Cancel as CancelIcon,
    CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Check as CheckIcon,
    Close as CloseIcon
} from "@mui/icons-material";
import {
    AppBar,
    Autocomplete, Box, Checkbox, Chip, CircularProgress,
    Dialog, DialogContent,
    Grid,
    IconButton, List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader, Paper, Tab,
    Tabs, TextField, Toolbar,
    Typography, useTheme
} from "@mui/material";
import axios from "axios";
import { SelectCapitoli } from "components/SelectCapitoli/SelectCapitoli";
import { SelectFiles } from "components/SelectFiles/SelectFiles";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import React, { ChangeEvent, useEffect, useState } from "react";
import { TextFormat, translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoCourseMaterial, defaultValue } from "shared/model/edo-course-material.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { AuthenticationState } from "shared/reducers/authentication";
import {
    createMany,
    updateEntity
} from "shared/reducers/entities/edo-course-material.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { getFileAvatar } from "shared/util/file-avatar-utils";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box pt={2}>{children}</Box>}
        </Box>
    );
}

interface EditCourseMaterialProps {
    fixedCourses?: IEdoCourse[];
    courseMaterial?: IEdoCourseMaterial;
    open: boolean;
    onClose: () => void;
}

const EditCourseMaterial = (props: EditCourseMaterialProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { loading, updating, updateSuccess } = useAppSelector<
        EntityState<IEdoCourseMaterial>
    >((state) => state.edoCourseMaterial);
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);
    const [form, setForm] = useState<IEdoCourseMaterial>({ ...defaultValue, ...props.courseMaterial });
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedFiles, setSelectedFiles] = useState<IEdoFile[]>([]);
    const [selectedChapterLocal, setSelectedChapterLocal] = useState<IEdoChapter | null>(null);
    const [availableCourses, setAvailableCourses] = useState<IEdoCourse[]>([]);
    const [selectedCourses, setSelectedCourses] = useState<IEdoCourse[]>([]);

    const {
        courseMaterial,
        open,
        onClose: handleClose,
        fixedCourses
    } = props;

    const isNew = props.courseMaterial === null || props.courseMaterial === undefined;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
    };

    const handleSelectedFiles = (fileList: IEdoFile[]) => {
        setSelectedFiles(fileList);
    };

    const handleChangeTab = (event: ChangeEvent<any>, newValue: number) => {
        setTabValue(newValue);
    };

    const handleCloseDialog = () => {
        setForm({ ...defaultValue });
        handleClose();
    };

    useEffect(() => {
        setForm({ ...defaultValue, ...courseMaterial });
        if (courseMaterial?.chapter) setSelectedChapterLocal(courseMaterial?.chapter);
    }, [courseMaterial]);

    useEffect(() => {
        if (updateSuccess) {
            handleCloseDialog();
        }
    }, [updateSuccess]);

    useEffect(() => {
        const getTeachersCourses = async () => {
            const response = await axios.get<{ data: IEdoCourse[] }>(`/teachers/${account.id}/courses`);
            setAvailableCourses(response.data.data);
        };
        const getCourseMaterialFiles = async () => {
            const response = await axios.get(`/course-materials/${courseMaterial?.id}/files`);
            setSelectedFiles(response.data.data);
        };
        if (!open) {
            setForm({ ...defaultValue });
            setSelectedFiles([]);
            setTabValue(0);
            setSelectedChapterLocal(null);
        } else {
            getTeachersCourses();
            if (courseMaterial?.id) getCourseMaterialFiles();
        }
    }, [open]);

    const removeFile = (index: number) => {
        const selectedFilesLocal = Array.from(selectedFiles);
        selectedFilesLocal.splice(index, 1);
        setSelectedFiles([...selectedFilesLocal]);
    };

    const saveEntity = () => {
        if (isNew) {
            dispatch(
                createMany({
                    ...form,
                    files: selectedFiles,
                    chapter: selectedChapterLocal,
                    courses: selectedCourses,
                })
            );
        } else {
            dispatch(
                updateEntity({
                    ...form,
                    files: selectedFiles,
                    chapter: selectedChapterLocal
                })
            );
        }
    };

    useEffect(() => {
        if (fixedCourses && availableCourses) {
            const courses = availableCourses.filter(course => fixedCourses?.find(fixedCourse => fixedCourse.id === course.id && selectedCourses.find(selectedCourse => selectedCourse.id === course.id) === undefined));
            setSelectedCourses([...selectedCourses, ...courses]);
        }
    }, [fixedCourses, availableCourses]);

    return (
        <Dialog open={open} onClose={handleCloseDialog} fullScreen={true} fullWidth>
            <AppBar position="relative">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        disabled={updating}
                        sx={{ marginRight: theme.spacing(2) }}
                        onClick={() => handleCloseDialog()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" flexGrow={1}>
                        {isNew ? translate("edocendoApp.edoCourseMaterial.home.createLabel") : translate("edocendoApp.edoCourseMaterial.home.editLabel")}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        disabled={updating || !form.title || (isNew && !selectedCourses.length)}
                        onClick={saveEntity}
                    >
                        <CheckIcon />
                    </IconButton>
                </Toolbar>
                <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    textColor="inherit"
                    indicatorColor="secondary"
                >
                    <Tab label="Informazioni" />
                    <Tab label="Documenti" />
                    <Tab label="Capitolo" />
                </Tabs>
            </AppBar>
            <DialogContent
                style={{ backgroundColor: theme.palette.background.default }}
            >
                <TabPanel value={tabValue} index={0}>
                    {loading ? (
                        <CircularProgress
                            size={24}
                            style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                    ) : (
                        <>
                            <ValidatorForm
                                onSubmit={saveEntity}
                                onError={(errors) => false}
                                sx={{ flexGrow: 1 }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={isNew ? 6 : 12}>
                                        <TextValidator
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            required
                                            label={translate("edocendoApp.edoCourseMaterial.title")}
                                            onChange={handleChange}
                                            name="title"
                                            value={form.title || ""}
                                        />
                                    </Grid>
                                    {isNew ? <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            multiple
                                            options={availableCourses}
                                            disableCloseOnSelect
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => (
                                                    <Chip
                                                        label={option.subject.name + " - " + option.class.year.year + option.class.section}
                                                        {...getTagProps({ index })}
                                                        size="small"
                                                        disabled={!fixedCourses ? false : fixedCourses.findIndex(c => c.id === option.id) !== -1}
                                                    />
                                                ))
                                            }
                                            freeSolo={false}
                                            getOptionLabel={(option: IEdoCourse) =>
                                                `${option.subject.name} - ${option.class.year.year}°${option.class.section} (${option.class.school.name})`
                                            }
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                        disabled={!fixedCourses ? false : fixedCourses.findIndex(c => c.id === option.id) !== -1}
                                                    />
                                                    {`${option.subject.name} - ${option.class.year.year}°${option.class.section}`}
                                                </li>
                                            )}
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                            getOptionDisabled={(option: IEdoCourse) =>
                                                !fixedCourses ? false : fixedCourses.findIndex(c => c.id === option.id) !== -1
                                            }
                                            // defaultValue={form.utentes || []}
                                            loadingText="Ricerca..."
                                            noOptionsText="Nessun risultato"
                                            value={selectedCourses || []}
                                            size="small"
                                            onChange={(event, value, reason) => {
                                                setSelectedCourses(value as IEdoCourse[]);
                                            }}
                                            renderInput={(autocompleteParams) => (
                                                <TextField
                                                    {...autocompleteParams}
                                                    variant="outlined"
                                                    required
                                                    label={"Corsi"}
                                                    placeholder={"Corsi"}
                                                />
                                            )}
                                        />
                                    </Grid> : null}
                                </Grid>
                            </ValidatorForm>
                        </>
                    )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Box pb={2}>
                        <SelectFiles
                            selectedFiles={selectedFiles}
                            setSelectedFiles={handleSelectedFiles}
                            enableUpload={true}
                        />
                    </Box>
                    <Box pb={2}>
                        <Paper sx={{ color: "text.secondary", overflow: "hidden" }}>
                            <List
                                component="div"
                                disablePadding
                                subheader={<ListSubheader>Documenti selezionati</ListSubheader>}
                            >
                                {selectedFiles && selectedFiles.length ? (
                                    selectedFiles.map((file: IEdoFile, index: number) => (
                                        <ListItem key={file.id} button>
                                            <ListItemAvatar>
                                                {getFileAvatar(file.fileType)}
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={file.originalFileName}
                                                primaryTypographyProps={{ variant: "body2" }}
                                                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                                                secondary={
                                                    <TextFormat
                                                        value={file.createdAt}
                                                        type="date"
                                                        format={APP_LOCAL_DATE_FORMAT}
                                                    />
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    onClick={() => removeFile(index)}
                                                    edge="end"
                                                    aria-label="Open file"
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                ) : (
                                    <Box p={2}>
                                        <Typography variant="body1">
                                            Nessun file selezionato.
                                        </Typography>
                                    </Box>
                                )}
                            </List>
                        </Paper>
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <SelectCapitoli multiple={false} selectedChapter={selectedChapterLocal} setSelectedChapter={setSelectedChapterLocal} />
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
};

export default EditCourseMaterial;