

const PageNotFound = () =>  {

    return (
      <div>
        <div>The page does not exist.</div>
      </div>
    );
};

export default PageNotFound;
