import { Add as AddIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  LinearProgress,
  List,
  Paper,
  TablePagination, Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import SearchField from "components/SearchField/SearchField";
import { ViewUnit } from "components/ViewUnit/ViewUnit";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getChaptersByUser } from "shared/reducers/entities/edo-chapter.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import { DeleteUnit } from "../courses/components/DeleteUnit";
import { CapitoloRow } from "./components/CapitoloRow/CapitoloRow";
import DeleteChapter from "./components/DeleteChapter/DeleteChapter";
import { EditChapterInfo } from "./components/EditCapitolo/EditChapterInfo";
import { EditChapterUnits } from "./components/EditCapitolo/EditChapterUnits";
import { EditUnit } from "./components/EditUnit/EditUnit";

const useStyles = makeStyles()(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
}));

const ArchivioCapitoli = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const {
    entities: edoChapterList,
    loading,
    totalItems,
    updateSuccess,
  } = useAppSelector<EntityState<IEdoChapter>>((state) => state.edoChapter);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "updatedAt",
    order: "DESC",
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [editChapterInfoDialog, setEditChapterInfoDialog] = useState<{ open: boolean; entity: IEdoChapter }>({ open: false, entity: null });
  const [editChapterUnitsDialog, setEditChapterUnitsDialog] = useState<{ open: boolean; entity: IEdoChapter }>({ open: false, entity: null });
  const [deleteChapterDialog, setDeleteChapterDialog] = useState<{ open: boolean; entity: IEdoChapter }>({ open: false, entity: null });
  const [viewUnitDialog, setViewUnitDialog] = useState<{ open: boolean; entity: IEdoUnit }>({ open: false, entity: null });
  const [deleteUnitDialog, setDeleteUnitDialog] = useState<{ open: boolean; entity: IEdoUnit }>({ open: false, entity: null });
  const [editUnitDialog, setEditUnitDialog] = useState<{ open: boolean; entity: IEdoUnit }>({ open: false, entity: null });

  const handleCloseEditCapitoloDialog = (updated: boolean) => {
    setEditChapterInfoDialog({ open: false, entity: null });
    if (updated) {
      handleSyncList();
    }
  };

  const handleCloseEditChapterUnitsDialog = (updated: boolean) => {
    setEditChapterUnitsDialog({ open: false, entity: null });
    if (updated) {
      handleSyncList();
    }
  };

  const handleGetEdoCapitoloList = () => {
    const filters = new URLSearchParams();
    searchValue && filters.append("search", searchValue);
    dispatch(
      getChaptersByUser({
        userId: account.id,
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        ...(filters ? { filters: filters.toString() } : {}),
      })
    );
  };

  useEffect(() => {
    handleGetEdoCapitoloList();
  }, [
    searchValue,
    updateSuccess,
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
  ]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const handleSyncList = () => {
    handleGetEdoCapitoloList();
  };

  return (
    <>
      <Paper className={classes.paper}>
        <AppBar position="relative">
          <Toolbar>
            <Typography variant="h6" sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              },
              flexGrow: 1,
            })}>
              Capitoli
            </Typography>
            <Box mr={breakpointDownSm ? 1 : 2}>
              <SearchField
                placeholder="Cerca un capitolo"
                value={searchValue}
                setSearchValue={setSearchValue}
              />
            </Box>
            <Box>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setEditChapterInfoDialog({ open: true, entity: null })}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {loading && (
          <LinearProgress variant="indeterminate" color="secondary" />
        )}
        <Box pt={1}>
          {edoChapterList.length ? (
            <List dense disablePadding>
              {edoChapterList.map((chapter, index) => (
                <CapitoloRow
                  key={chapter.id}
                  chapter={chapter}
                  isLastElement={index === edoChapterList.length - 1}
                  handleOpenEditChapterUnitsDialog={(chapter) => setEditChapterUnitsDialog({ open: true, entity: chapter })}
                  handleOpenEditCapitoloDialog={(chapter) => setEditChapterInfoDialog({ open: true, entity: chapter })}
                  handleOpenViewUnitDialog={(unit) => setViewUnitDialog({ open: true, entity: unit })}
                  handleOpenDeleteCapitoloDialog={(chapter) => setDeleteChapterDialog({ open: true, entity: chapter })}
                />
              ))}
            </List>
          ) : (
            <Box p={2}>
              <Typography variant="body1" align="center">
                <Translate contentKey="edocendoApp.edoChapter.home.notFound">
                  No Edo Chapters found
                </Translate>
              </Typography>
            </Box>
          )}
        </Box>
        <Divider />
        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={paginationState.itemsPerPage}
          page={paginationState.activePage - 1}
          onPageChange={(event, page) => handlePagination(page + 1)}
          onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
          labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
          }
        />
      </Paper>
      <EditChapterInfo
        chapter={editChapterInfoDialog.entity}
        open={editChapterInfoDialog.open}
        handleClose={handleCloseEditCapitoloDialog}
      />
      <EditChapterUnits
        chapter={editChapterUnitsDialog.entity}
        open={editChapterUnitsDialog.open}
        handleClose={handleCloseEditChapterUnitsDialog}
        handleOpenViewUnitDialog={(unit) => setViewUnitDialog({ open: true, entity: unit })}
        handleOpenEditUnitDialog={(unit) => setEditUnitDialog({ open: true, entity: unit })}
        handleOpenDeleteUnitDialog={(unit) => setDeleteUnitDialog({ open: true, entity: unit })}
      />
      <DeleteChapter
        open={deleteChapterDialog.open}
        chapter={deleteChapterDialog.entity}
        onClose={() => setDeleteChapterDialog({ open: false, entity: null })}
      />
      <ViewUnit
        open={viewUnitDialog.open}
        unit={viewUnitDialog.entity}
        handleClose={() => setViewUnitDialog({ open: false, entity: null })}
      />
      <EditUnit
        open={editUnitDialog.open}
        unit={editUnitDialog.entity}
        handleClose={() => setEditUnitDialog({ open: false, entity: null })}
        chapter={editChapterUnitsDialog.entity}
      />
      <DeleteUnit
        open={deleteUnitDialog.open}
        unit={deleteUnitDialog.entity}
        handleClose={() => setDeleteUnitDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default ArchivioCapitoli;