import { IEdoCourse } from "shared/model/edo-course.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IUser } from "shared/model/user.model";
export interface IEdoCoursePost {
  id?: number;
  description?: string;
  author?: IUser;
  files?: IEdoFile[] | null;
  course?: IEdoCourse;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IEdoCoursePost> = {
  files: [],
};
