import {
  FormatAlignLeft as FormatAlignLeftIcon,
  LibraryAddCheck as LibraryAddCheckIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  TextFormat as TextFormatIcon,
  ToggleOff as ToggleOffIcon
} from "@mui/icons-material";
import { EdoQuestionTypeEnum } from "shared/model/enumerations/edo-question-type.mode";

export const getQuestionType = (type: EdoQuestionTypeEnum, short: boolean = false) => {
  switch (type) {
    case EdoQuestionTypeEnum.FREE_TEXT:
      return short ? "Risposta scritta" : "Domanda a risposta scritta";
    case EdoQuestionTypeEnum.MULTIPLE_CHOICE:
      return short ? "Risposta multipla" : "Domanda a risposta multipla";
    case EdoQuestionTypeEnum.SINGLE_CHOICE:
      return short ? "Risposta singola" : "Domanda a risposta singola";
    case EdoQuestionTypeEnum.TRUE_FALSE:
      return short ? "Risposta Vero/Falso" : "Domanda Vero/Falso";
    case EdoQuestionTypeEnum.FILL_BLANK:
      return short ? "Risposta a riempimento" : "Domanda a riempimento";
  }
};

export const getQuestionIcon = (type: EdoQuestionTypeEnum) => {
  switch (type) {
    case EdoQuestionTypeEnum.SINGLE_CHOICE:
      return (<RadioButtonCheckedIcon />);
    case EdoQuestionTypeEnum.MULTIPLE_CHOICE:
      return <LibraryAddCheckIcon />;
    case EdoQuestionTypeEnum.TRUE_FALSE:
      return <ToggleOffIcon />;
    case EdoQuestionTypeEnum.FILL_BLANK:
      return <TextFormatIcon />;
    case EdoQuestionTypeEnum.FREE_TEXT:
      return <FormatAlignLeftIcon />;
  }
};
