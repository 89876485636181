import {
    AppBar, Box,
    List, Paper, Toolbar,
    Typography
} from "@mui/material";
import axios from "axios";
import { useAppSelector } from "config/store";
import CourseQuizRow from "pages/courses/components/CourseQuizRow";
import DeleteCourseQuiz from "pages/courses/components/DeleteCourseQuiz";
import EditCourseQuiz from "pages/courses/components/EditCourseQuiz";
import { useEffect, useState } from "react";
import { translate } from "react-jhipster";
import { useNavigate } from "react-router-dom";
import { IEdoCourseQuiz } from "shared/model/edo-course-quiz.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme =>
    ({
        root: {
            display: "grid",
            gridTemplateRows: "min-content auto min-content",
            height: "100%",
        },
        appbar: {
            boxShadow: "none",
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.divider,
            borderBottomStyle: "solid",
        },
        toolbar: {
            justifyContent: "space-between",
        },
        paper: {
            color: theme.palette.text.secondary,
            overflow: "hidden",
        },
        button: {
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        buttonMobile: {
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
    })
);

const CourseQuizzes = () => {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const { entity: course } = useAppSelector<EntityState<IEdoCourse>>(
        (state) => state.edoCourse
    );
    const { updateSuccess } = useAppSelector<EntityState<IEdoCourseQuiz>>(
        (state) => state.edoCourseQuiz
    );
    const [quizzes, setQuizzes] = useState<IEdoCourseQuiz[]>([]);
    const [deleteQuizDialog, setDeleteQuizDialog] = useState <{ open: boolean, entity: IEdoCourseQuiz }>({ open: false, entity: null});
    const [editQuizDialog, setEditQuizDialog] = useState<{ open: boolean, entity: IEdoCourseQuiz }>({ open: false, entity: null});


    const getCourseQuizzes = async () => {
        const response = await axios.get<{
            data: IEdoCourseQuiz[];
        }>(`/courses/${course.id}/course-quizzes`, {
            params: {
                sortBy: "createdAt,DESC",
            }
        });
        setQuizzes(response.data.data);
    };

    useEffect(() => {
        if (course.id) {
            getCourseQuizzes();
        }
    }, [course.id]);

    const handleOpenQuiz = (quiz: IEdoCourseQuiz) => {
        navigate(`/courses/${course.id}/quizzes/${quiz.id}/start`);
    };        

    useEffect(() => {
        if (updateSuccess) {
            getCourseQuizzes();
        }
    }, [updateSuccess]);

    return (
        <>
        <Paper className={classes.paper}>
            <div className={classes.root}>
                <AppBar
                    position="static"
                    color="transparent"
                    className={classes.appbar}
                >
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h5">{translate('edocendoApp.edoCourseQuiz.home.title')}</Typography>
                    </Toolbar>
                </AppBar>
                {quizzes && quizzes.length > 0 ? (
                    <List dense>
                        {quizzes.map((quiz, i) => (
                            <CourseQuizRow
                                key={quiz.id}
                                courseQuiz={quiz}
                                onDelete={() => setDeleteQuizDialog({ open: true, entity: quiz })}
                                onEdit={() => setEditQuizDialog({ open: true, entity: quiz })}
                                onOpen={() => handleOpenQuiz(quiz)}
                            />
                        ))}
                    </List>
                ) : (
                    <Box p={2}>
                        <Typography variant="body1" align="center">
                                {translate('edocendoApp.edoCourseQuiz.home.notFound')}
                        </Typography>
                    </Box>
                )}
            </div>
            </Paper>
            <EditCourseQuiz
                open={editQuizDialog.open}
                onClose={() => setEditQuizDialog({ open: false, entity: null })}
                courseQuiz={editQuizDialog.entity}
            />
            <DeleteCourseQuiz
                open={deleteQuizDialog.open}
                courseQuiz={deleteQuizDialog.entity}
                onClose={() => setDeleteQuizDialog({ open: false, entity: null })}
            />
        </>
    );
};

export default CourseQuizzes;
