import {
  AllInbox as AllInboxIcon,
  AttachFile,
  Cancel as CancelIcon, ExpandMore, Publish as PublishIcon
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import React, { createRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { IEdoCoursePost } from "shared/model/edo-course-post.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoWallPost } from "shared/model/edo-wall-post.model";
import { IUser } from "shared/model/user.model";
import { mapIdList } from "shared/util/entity-utils";
import { getFileAvatar } from "shared/util/file-avatar-utils";
import { SelectFilesDialog } from "../SelectFiles/SelectFilesDialog";

interface ICreatePostProps {
  user: IUser;
  defaultValuePost: IEdoWallPost | IEdoCoursePost;
  createPost: (form: IEdoWallPost | IEdoCoursePost) => void;
  uploadFiles: (files: File[]) => Promise<IEdoFile[]>
}

const CreatePost = (props: ICreatePostProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [form, setForm] = useState<IEdoWallPost | IEdoCoursePost>({ ...props.defaultValuePost });
  const [anchorSelectFileButton, setAnchorSelectFileButton] = useState<null | HTMLElement>(null);
  const [selectFilesDialog, setSelectFilesDialog] = useState<{ open: boolean; }>({ open: false });

  const fileInput = createRef<HTMLInputElement>();

  const { defaultValuePost, createPost, uploadFiles, user } = props;

  const handleSelectedFiles = (selectedFiles: IEdoFile[]) => {
    setForm(prevState => ({ ...prevState, files: selectedFiles }));
  };

  const openSelectFilesDialog = () => {
    setSelectFilesDialog({ open: true });
  };

  const closeSelectFilesDialog = () => {
    setSelectFilesDialog({ open: false });
    handleCloseSelectFileMenu();
  };

  const handleSelectFileButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorSelectFileButton(event.currentTarget);
  };

  const handleCloseSelectFileMenu = () => {
    setAnchorSelectFileButton(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCancel = () => {
    setExpanded(false);
    setFiles([]);
    setForm({ ...defaultValuePost });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const removeFile = (index: number) => {
    setFiles(prevState => {
      const selectedFiles = Array.from(prevState);
      selectedFiles.splice(index, 1);
      return selectedFiles;
    });
  };

  const removeRepositoryFile = (index: number) => {
    setForm(prevState => {
      const selectedFiles = Array.from(form.files || []);
      selectedFiles.splice(index, 1);
      return {
        ...prevState,
        files: selectedFiles
      };
    });
  };

  const saveEntity = async () => {
    setUpdating(true);
    if (files.length) {
      const uploadedFiles = await uploadFiles(files);
      createPost({
        ...form,
        files: [...(form.files || []), ...mapIdList(uploadedFiles)],
      });
    } else {
      createPost({ ...form });
    }
    setUpdating(false);
    handleCancel();
  };

  const selectFiles = () => {
    fileInput.current?.click();
  };

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(prevState => [...prevState, ...Array.from(event.target.files || [])]);
    handleCloseSelectFileMenu();
  };

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardActionArea onClick={handleExpandClick} component="div">
        <CardHeader
          sx={{
            '& .MuiCardHeader-action': {
              marginTop: 0,
            },
          }}
          avatar={
            <Avatar aria-label="user" style={{ backgroundColor: "primary" }}>
              {user.firstName ? user.firstName[0] : "U"}
            </Avatar>
          }
          action={
            <IconButton
              sx={(theme) => ({
                transform: "rotate(0deg)",
                marginLeft: "auto",
                transition: theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shortest,
                }),
                ...(expanded ? {
                  transform: "rotate(180deg)!important",
                } : {})
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
            >
              <ExpandMore />
            </IconButton>
          }
          title={"Cosa c'è di nuovo, " + user.firstName + "?"}
        />
      </CardActionArea>
      {expanded ? <Divider /> : null}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ValidatorForm
            onSubmit={saveEntity}
            onError={(errors) => false}
            sx={{ flexGrow: 1 }}
          >
            <TextValidator
              variant="filled"
              multiline
              rows={4}
              // size="small"
              fullWidth
              // required
              label={"Cosa c'è di nuovo, " + user.firstName + "?"}
              onChange={handleChange}
              name="description"
              value={form.description || ""}
            />
          </ValidatorForm>
          <Box pt={2}>
            {files?.length ? (
              <Box pb={2}>
                <List
                  disablePadding
                  dense
                  subheader={<ListSubheader>File da caricare</ListSubheader>}
                >
                  {files.map((file, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemAvatar>
                        {getFileAvatar(file.type)}
                      </ListItemAvatar>
                      <ListItemText primary={file.name} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => removeFile(index)}
                          edge="end"
                          aria-label="Rimuovi file"
                        >
                          <CancelIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : null}
            {form.files !== null && form.files?.length ? (
              <Box pb={2}>
                <List
                  disablePadding
                  dense
                  subheader={
                    <ListSubheader>File dall&apos;archivio</ListSubheader>
                  }
                >
                  {form.files.map((file, index) => (
                    <ListItem key={file.id} disableGutters>
                      <ListItemAvatar>
                        {getFileAvatar(file.fileType || "")}
                      </ListItemAvatar>
                      <ListItemText primary={file.originalFileName} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => removeRepositoryFile(index)}
                          edge="end"
                          aria-label="Rimuovi file"
                        >
                          <CancelIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ) : null}
          </Box>
        </CardContent>
        {/* <Divider /> */}
        <CardActions style={{ paddingTop: 0 }}>
          <Box
            p={1}
            pt={0}
            sx={(theme) => ({
              display: "flex",
              flexGrow: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  marginBottom: theme.spacing(2),
                  "& > *": {
                    width: "100%",
                  },
                }
              })}>
              <Box sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  flexGrow: 1,
                },
              })}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AttachFile />}
                  fullWidth
                  onClick={handleSelectFileButtonClick}
                >
                  Aggiungi file
                </Button>
                <Menu
                  id="load-file-menu"
                  anchorEl={anchorSelectFileButton}
                  keepMounted
                  open={Boolean(anchorSelectFileButton)}
                  onClose={handleCloseSelectFileMenu}
                  disableScrollLock={true}
                >
                  <MenuItem onClick={selectFiles}>
                    <ListItemIcon>
                      <PublishIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Carica file</Typography>
                  </MenuItem>
                  <MenuItem onClick={openSelectFilesDialog}>
                    <ListItemIcon>
                      <AllInboxIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      Seleziona file dall&apos;archivio
                    </Typography>
                  </MenuItem>
                </Menu>

                {/* <Button onClick={selectFiles} variant="contained" color="secondary" startIcon={<AttachFile />} fullWidth>
                  Aggiugi file
                </Button> */}
                <SelectFilesDialog
                  open={selectFilesDialog.open}
                  handleClose={closeSelectFilesDialog}
                  selectedFiles={form.files || []}
                  setSelectedFiles={handleSelectedFiles}
                />
                <input
                  type="file"
                  ref={fileInput}
                  name="files"
                  multiple={true}
                  hidden
                  onChange={handleFiles}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              flexGrow={1}
            >
              <Box mr={2} sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  flexGrow: 1,
                },
              })}>
                <Button
                  onClick={handleCancel}
                  size="medium"
                  variant="contained"
                  color="neutral"
                  fullWidth
                >
                  Annulla
                </Button>
              </Box>
              <Box >
                <LoadingButton
                  onClick={() => {
                    saveEntity();
                  }}
                  disabled={
                    form.description == null || form.description.length === 0
                  }
                  size="medium"
                  variant="contained"
                  color="primary"
                  sx={(theme) => ({
                    [theme.breakpoints.down("sm")]: {
                      flexGrow: 1,
                    },
                  })}
                  fullWidth
                  loading={updating}>
                  Pubblica
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </CardActions>
      </Collapse>
    </Card>
  );
};

export default CreatePost;