import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;

axios.defaults.baseURL = "/api/";
axios.defaults.withCredentials = true;

const setupAxiosInterceptors = (onUnauthenticated: () => void) => {
  const onRequestSuccess = (config: AxiosRequestConfig) => {
    // const token =
    //   Storage.local.get("access_token") || Storage.session.get("access_token");
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  };
  const onResponseSuccess = (response: AxiosResponse) => response;
  const onResponseError = (err: any) => {
    const status = err.status || (err.response ? err.response.status : 0);
    // if (status === 403 || status === 401) {

    if (status === 400) {
      if (Array.isArray(err.response.data.message) && Array.of(err.response.data.message).length !== 0) {
        toast.error(err.response.data.message[0]);
      } else {
        toast.error(err.response.data.message);
      }
    } else if (status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
