import {
    Assignment as AssignmentIcon, Delete as DeleteIcon,
    Edit as EditIcon,
    MoreVert as MoreVertIcon
} from "@mui/icons-material";
import {
    Avatar, Box, IconButton, ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem, Typography
} from "@mui/material";
import { APP_DATE_FORMAT_MID } from "config/constants";
import React, { useEffect, useState } from 'react';
import { TextFormat } from "react-jhipster";
import { useNavigate } from 'react-router-dom';
import { IEdoQuiz } from "shared/model/edo-quiz.model";

interface IQuizRowProps {
    quiz: IEdoQuiz;
    openEditDialog: (quiz: IEdoQuiz) => void
    openDeleteDialog: (quiz: IEdoQuiz) => void;
}

export const QuizRow = (props: IQuizRowProps) => {
    const navigate = useNavigate();
    const [localQuiz, setLocalQuiz] = useState<IEdoQuiz>(props.quiz);
    const [anchorMoreQuiz, setAnchorMoreQuiz] =
        useState<null | HTMLElement>(null);

    const { quiz, openDeleteDialog: handleDeleteDialog, openEditDialog: handleEditDialog } = props;

    const handleOpenQuiz = () => {
        navigate(`/quizzes/${quiz.id}`);
    };

    const handleMoreQuizButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorMoreQuiz(event.currentTarget);
    };

    const handleCloseMoreQuizMenu = () => {
        setAnchorMoreQuiz(null);
    };
    
    useEffect(() => {
        if (quiz) {
            setLocalQuiz(quiz);
        }
    }, [quiz]);

    return (
        <ListItem button onClick={() => handleOpenQuiz()}>
            <ListItemAvatar>
                <Avatar alt={localQuiz.title}><AssignmentIcon/></Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={localQuiz.title}
                secondary={<TextFormat
                    type="date"
                    value={localQuiz.createdAt}
                    format={APP_DATE_FORMAT_MID}
                    locale="it"
                />}
            />
            <ListItemSecondaryAction>
                <Box display="flex" flexDirection="row">
                    <Box mr={2}>
                        <IconButton
                            edge="end"
                            onClick={handleMoreQuizButtonClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                    <Menu
                        id="more-quiz-menu"
                        anchorEl={anchorMoreQuiz}
                        keepMounted
                        open={Boolean(anchorMoreQuiz)}
                        onClose={handleCloseMoreQuizMenu}
                        disableScrollLock={true}
                    >
                        <MenuItem onClick={() => {
                            handleCloseMoreQuizMenu();
                            handleEditDialog(localQuiz);
                        }}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Modifica quiz</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleCloseMoreQuizMenu();
                            handleDeleteDialog(localQuiz);
                        }
                        }>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                Elimina quiz
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
);
};

export default QuizRow;

