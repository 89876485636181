export const ITEMS_PER_PAGE = 10;
export const ITEMS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];
export const ASC = "ASC";
export const DESC = "DESC";
export const SORT = "sort";

export interface PaginationState {
    activePage: number,
    itemsPerPage: number,
    sort: string,
    order: string,
}

export interface PaginationStateWithSearch extends PaginationState {
    searchValue: string;
}

export interface IPaginationMeta {
    itemsPerPage: number,
    totalItems: number,
    currentPage: number,
    totalPages: number,
    sortBy: string[][],
    search: string,
    filter: {
        age: string
    }
}

export interface IPaginationLinks {
    first: string,
    previous: string,
    current: string,
    next: string;
    last: string
}

export interface IPaginationResponse<T> {
    data: T[],
    meta: IPaginationMeta,
    links: IPaginationLinks
}