import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import {
    AppBar, Autocomplete, Checkbox, Chip, CircularProgress,
    Dialog, DialogContent, FormControlLabel, Grid,
    IconButton, TextField,
    TextFieldProps,
    Toolbar,
    Typography
} from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from 'axios';
import { useAppSelector } from "config/store";
import moment, { Moment } from "moment";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { translate } from 'react-jhipster';
import { ValidatorForm } from "react-material-ui-form-validator";
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { IEdoCourseQuiz, defaultValue } from 'shared/model/edo-course-quiz.model';
import { IEdoCourse } from 'shared/model/edo-course.model';
import { IEdoQuiz } from 'shared/model/edo-quiz.model';
import { AuthenticationState } from 'shared/reducers/authentication';
import { EntityState } from "shared/reducers/reducer.utils";
interface ISendQuizProps {
    quiz?: IEdoQuiz;
    open: boolean;
    onClose: () => void;
    onSuccess?: (courseQuiz: IEdoCourseQuiz) => void;
}

const SendQuiz = (props: ISendQuizProps) => {
    const { loading, updating, updateSuccess } = useAppSelector<
        EntityState<IEdoCourseQuiz>
    >((state) => state.edoCourseQuiz);

    const [form, setForm] = useState<IEdoCourseQuiz>({ ...defaultValue });
    const [availableCourses, setAvailableCourses] = useState<IEdoCourse[]>([]);
    const [selectedCourses, setSelectedCourses] = useState<IEdoCourse[]>([]);
    const [fixedCourses, setFixedCourses] = useState<IEdoCourse[]>([]);
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);

    const { open, onClose: handleClose, onSuccess: handleSuccess, quiz } = props;

    const isNew = form?.id === null || form?.id === undefined;
    const tzoffset = new Date().getTimezoneOffset() * 60000;


    useEffect(() => {
        if (quiz) {
            setForm({ ...defaultValue, quiz });
        }
    }, [quiz]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
    };

    const handleCloseDialog = () => {
        setForm({ ...defaultValue });
        handleClose();
    };

    useEffect(() => {
        if (updateSuccess) {
            handleCloseDialog();
        }
    }, [updateSuccess]);

    useEffect(() => {
        const getTeachersCourses = async () => {
            const response = await axios.get<{ data: IEdoCourse[] }>(`/teachers/${account.id}/courses`);
            setAvailableCourses(response.data.data);
        };
        if (!open) {
            setForm({ ...defaultValue });
        } else {
            getTeachersCourses();
        }
    }, [open]);

    const saveEntity = async () => {
        if (!selectedCourses.length) {
            toast.error("Selezionare almeno un corso.");
            return;
        }
        if (isNew) {
            const requests = selectedCourses.map(course => {
                return axios.post(`/quizzes/${quiz.id}/course-quizzes`, {
                    quiz,
                    course: course,
                    availableAttempts: form.availableAttempts,
                    duration: form.duration,
                    timeOpen: form.timeOpen,
                    timeClose: form.timeClose,
                });
            });
            await Promise.all(requests);
            toast.success("Quiz inviati con successo.");
            handleCloseDialog();
            handleSuccess(null);
        }
    };

    useEffect(() => {
        if (fixedCourses && availableCourses) {
            const courses = availableCourses.filter(course => fixedCourses?.find(fixedCourse => fixedCourse.id === course.id && selectedCourses.find(selectedCourse => selectedCourse.id === course.id) === undefined));
            setSelectedCourses([...selectedCourses, ...courses]);
        }
    }, [fixedCourses, availableCourses]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={true}
            fullWidth
        >
            <AppBar position="relative">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        disabled={updating}
                        sx={{ marginRight: 1 }}
                        onClick={() => handleClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" flexGrow={1}>
                        Invia il test a uno o più corsi
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        disabled={updating || !form.duration || !selectedCourses.length}
                        onClick={saveEntity}
                    >
                        <CheckIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {loading ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                    <>
                        <ValidatorForm
                            onSubmit={saveEntity}
                            onError={(errors) => false}
                            sx={{ flexGrow: 1 }}
                        >
                            <Grid container spacing={2}>
                                {isNew ? <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                        multiple
                                        options={availableCourses}
                                        disableCloseOnSelect
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    label={option.subject.name + " - " + option.class.year.year + option.class.section}
                                                    {...getTagProps({ index })}
                                                    size="small"
                                                    disabled={!fixedCourses ? false : fixedCourses.findIndex(c => c.id === option.id) !== -1}
                                                />
                                            ))
                                        }
                                        freeSolo={false}
                                        getOptionLabel={(option: IEdoCourse) =>
                                            option.subject.name + " - " + option.class.year.year + option.class.section
                                        }
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                    disabled={!fixedCourses ? false : fixedCourses.findIndex(c => c.id === option.id) !== -1}
                                                />
                                                {option.subject.name + " - " + option.class.year.year + option.class.section}
                                            </li>
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        getOptionDisabled={(option: IEdoCourse) =>
                                            !fixedCourses ? false : fixedCourses.findIndex(c => c.id === option.id) !== -1
                                        }
                                        // defaultValue={form.utentes || []}
                                        loadingText="Ricerca..."
                                        noOptionsText="Nessun risultato"
                                        value={selectedCourses || []}
                                        size="small"
                                        onChange={(event, value, reason) => {
                                            setSelectedCourses(value as IEdoCourse[]);
                                        }}
                                        renderInput={(autocompleteParams) => (
                                            <TextField
                                                {...autocompleteParams}
                                                variant="outlined"
                                                required
                                                label={"Corsi"}
                                                placeholder={"Corsi"}
                                            />
                                        )}
                                    />
                                </Grid> : null}
                                <Grid item xs={12} sm={6}>
                                    <NumericFormat
                                        fullWidth
                                        onValueChange={(values) => {
                                            setForm({ ...form, availableAttempts: values.floatValue });
                                        }}
                                        label={translate('edocendoApp.edoCourseQuiz.availableAttempts')}
                                        placeholder={translate('edocendoApp.edoCourseQuiz.availableAttempts')}
                                        name="availableAttempts"
                                        value={form.availableAttempts || ""}
                                        customInput={TextField}
                                        allowNegative={false}
                                        size="small"
                                        variant="outlined"
                                        isAllowed={(values) => {
                                            const { formattedValue, floatValue } = values;
                                            return !formattedValue.includes(".");
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <NumericFormat
                                        fullWidth
                                        onValueChange={(values) => {
                                            setForm({ ...form, duration: values.floatValue });
                                        }}
                                        label={translate('edocendoApp.edoCourseQuiz.duration') + " (min)"}
                                        placeholder={translate('edocendoApp.edoCourseQuiz.duration')}
                                        name="duration"
                                        value={form.duration || ""}
                                        required
                                        customInput={TextField}
                                        allowNegative={false}
                                        size="small"
                                        variant="outlined"
                                        isAllowed={(values) => {
                                            const { formattedValue, floatValue } = values;
                                            return !formattedValue.includes(".");
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                        <MobileDateTimePicker
                                            // cancelText="Annulla"
                                            renderInput={(inputProps: TextFieldProps) => (
                                                <TextField
                                                    {...inputProps}
                                                    name="timeOpen"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                            inputFormat="DD/MM/YYYY HH:mm"
                                            label={translate(
                                                "edocendoApp.edoCourseQuiz.timeOpen"
                                            )}
                                            value={form.timeOpen}
                                            onChange={(date: Moment) => {
                                                if (!date) {
                                                    setForm({
                                                        ...form,
                                                        timeOpen: null
                                                    });
                                                    return;
                                                }
                                                return setForm({
                                                    ...form,
                                                    timeOpen: date
                                                        .subtract(tzoffset)
                                                        .toISOString()
                                                        .slice(0, -1),
                                                });
                                            }}
                                            disablePast
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                        <MobileDateTimePicker
                                            // cancelText="Annulla"
                                            disabled={!form.timeOpen}
                                            renderInput={(inputProps: TextFieldProps) => (
                                                <TextField
                                                    {...inputProps}
                                                    name="timeClose"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                            inputFormat="DD/MM/YYYY HH:mm"
                                            label={translate(
                                                "edocendoApp.edoCourseQuiz.timeClose"
                                            )}
                                            value={form.timeClose}
                                            onChange={(date: Moment) => {
                                                if (!date) {
                                                    setForm({
                                                        ...form,
                                                        timeClose: null
                                                    });
                                                    return;
                                                }
                                                setForm({
                                                    ...form,
                                                    timeClose: date
                                                        .subtract(tzoffset)
                                                        .toISOString()
                                                        .slice(0, -1),
                                                });
                                            }}
                                            disablePast
                                            minDate={form.timeOpen ? moment(form.timeOpen) : undefined}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.allowReview}
                                                onChange={handleChange}
                                                color="primary"
                                                name="allowReview"
                                            />
                                        }
                                        label={translate("edocendoApp.edoCourseQuiz.allowReview")}
                                    />
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SendQuiz;