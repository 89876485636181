import {
    Box, Container
} from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import QuizDetailRoute from "./QuizDetail/quiz-detail";

const CourseQuizzesRoute = () => {
    const location = useLocation();

    return (
        <Box
            component="main"
            flexGrow={1}
            minHeight="100vh"
            overflow="auto"
        >
            <Container
                maxWidth="lg"
                sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingRight: 4,
                    paddingLeft: 4,
                }}
            >
                <Routes>
                    <Route path=":id" element={<QuizDetailRoute />} />
                </Routes>
            </Container>
        </Box>
    );
};

export default CourseQuizzesRoute;
