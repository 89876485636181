import {
    ListItem, ListItemText
} from "@mui/material";
import axios from "axios";
import { APP_DATE_FORMAT_EXPANDED } from "config/constants";
import { useEffect, useState } from "react";
import { TextFormat } from "react-jhipster";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { getQuizAttemptStatus } from "shared/util/quiz-utils";

interface AttemptRowProps {
    quizAttempt: IEdoQuizAttempt;
    onOpen: (quizAttempt: IEdoQuizAttempt) => void;
}

const AttemptRow = (props: AttemptRowProps) => {
    const [score, setScore] = useState<{ score: number, totalScore: number }>({ score: 0, totalScore: 0 });

    const { quizAttempt, onOpen: handleOpen } = props;

    const getQuizAttemptScore = async () => {
        const response = await axios.get<{ data: { score: number, totalScore: number } }>(`/quiz-attempts/${quizAttempt?.id}/score`);
        setScore(response.data.data);
    };

    useEffect(() => {
        if (quizAttempt?.id) {
            getQuizAttemptScore();
        }
    }, [quizAttempt?.id]);

    return (
        <ListItem sx={{ paddingLeft: 4 }} button onClick={() => handleOpen(quizAttempt)}>
            <ListItemText
                primary={<span>
                    {`Tentativo consegnato il `}
                    <TextFormat
                        value={quizAttempt.timeFinish}
                        type="date"
                        format={APP_DATE_FORMAT_EXPANDED}
                    />
                </span>}
                primaryTypographyProps={{ variant: "body2" }}
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                secondary={getQuizAttemptStatus(quizAttempt, score.score, score.totalScore)}
            />
        </ListItem>
    );
};

export default AttemptRow;