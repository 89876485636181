import {
  Cancel as CancelIcon, Check as CheckIcon, Close as CloseIcon
} from "@mui/icons-material";
import {
  AppBar, Box, Dialog, DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper, Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { TextFormat } from "react-jhipster";
import { IEdoFile } from "shared/model/edo-file.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";
import { makeStyles } from 'tss-react/mui';
import { SelectFiles } from "./SelectFiles";

const useStyles = makeStyles()(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
}));

export interface SelectFilesDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedFiles: IEdoFile[];
  setSelectedFiles: (files: IEdoFile[]) => void;
}

export const SelectFilesDialog = (props: SelectFilesDialogProps) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [selectedFilesLocal, setSelectedFilesLocal] = useState<IEdoFile[]>(
    props.selectedFiles
  );

  const { open, handleClose, selectedFiles, setSelectedFiles } = props;

  const handleSubmit = () => {
    setSelectedFiles(selectedFilesLocal);
    // setSelectedFiles([] as IEdoFile[]);
    handleClose();
  };

  const removeFile = (index: number) => {
    const selectedFilesSubLocal = Array.from(selectedFilesLocal);
    selectedFilesSubLocal.splice(index, 1);
    setSelectedFilesLocal([...selectedFilesSubLocal]);
  };

  //   useEffect(() => {
  //     if (open) {
  //       handleGetEdoFileList();
  //     }
  //   }, [open]);

  useEffect(() => {
    setSelectedFilesLocal(selectedFiles);
  }, [selectedFiles]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullScreen={true}
      fullWidth
      maxWidth="lg"
    >
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" marginLeft={2} flexGrow={1}>
            Seleziona file dall&apos;archivio
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleSubmit}>
            <CheckIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <Box pb={2} mt={2}>
          <SelectFiles
            selectedFiles={selectedFilesLocal}
            setSelectedFiles={setSelectedFilesLocal}
            enableUpload={false}
          />
        </Box>
        <Paper className={classes.paper}>
          <List
            component="div"
            disablePadding
            subheader={<ListSubheader>Documenti selezionati</ListSubheader>}
          >
            {selectedFilesLocal && selectedFilesLocal.length ? (
              selectedFilesLocal.map((file: IEdoFile, index: number) => (
                <ListItem key={file.id} button>
                  <ListItemAvatar>
                    {getFileAvatar(file.fileType)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.originalFileName}
                    primaryTypographyProps={{ variant: "body2" }}
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    secondary={
                      <TextFormat
                        value={file.createdAt}
                        type="date"
                        format={APP_LOCAL_DATE_FORMAT}
                      />
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => removeFile(index)}
                      edge="end"
                      aria-label="Remove file"
                    >
                      <CancelIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <Box p={2}>
                <Typography variant="body1">
                  Nessun file selezionato.
                </Typography>
              </Box>
            )}
          </List>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};
