import {
    GetApp as GetAppIcon, Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import { UnitRow } from "components/MaterialRow/components/UnitRow";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";

export interface ViewChapterProps {
    open: boolean;
    onClose: () => void;
    chapter: IEdoChapter;
}

export const ViewChapter = (props: ViewChapterProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [files, setFiles] = useState<IEdoFile[]>([]);
    const [units, setUnits] = useState<IEdoUnit[]>([]);
 
    const { open, onClose: handleOnClose, chapter } = props;

    useEffect(() => {
        const getFiles = async () => {
            const response = await axios.get(`/chapters/${chapter.id}/files`);
            setFiles(response.data.data);
        };

        const getUnits = async () => {
            const response = await axios.get(`/chapters/${chapter.id}/units`);
            setUnits(response.data.data);
        };

        if (chapter) {
            getFiles();
            getUnits();
        }
    }, [chapter]);

    const openFile = (file: IEdoFile) => {
        window.open(`/api/files/${file.id}/view`, "_blank");
    };

    const downloadFile = (file: IEdoFile) => {
        window.open(`/api/files/${file.id}/download`, "_blank");
    };



    return (
        <Dialog
            open={open}
            onClose={() => handleOnClose()}
            fullWidth
            fullScreen={fullScreen}
        >
            {chapter === null ? (
                <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
            ) : (
                    <>
                        <DialogTitle>{chapter.title}</DialogTitle>
                    <DialogContent>
                            <List component="div" disablePadding>
                            {!chapter || !files || !files.length ? (
                                <Box p={2}>
                                    <Typography variant="body1" align="center">
                                        <Translate contentKey="edocendoApp.edoFile.home.notFound">
                                            No Edo Files found
                                        </Translate>
                                    </Typography>
                                </Box>
                            ) : (
                                files?.map((file) => (
                                    <ListItem key={file.id} button>
                                        <ListItemAvatar>
                                            {getFileAvatar(file.fileType)}
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={file.originalFileName}
                                            primaryTypographyProps={{ variant: "body2" }}
                                            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                                            secondary={
                                                <TextFormat
                                                    value={file.createdAt}
                                                    type="date"
                                                    format={APP_LOCAL_DATE_FORMAT}
                                                />
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                onClick={() => openFile(file)}
                                                edge="end"
                                                aria-label="Open file"
                                                sx={{ marginRight: 1 }}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => downloadFile(file)}
                                                edge="end"
                                                aria-label="Download file"
                                            >
                                                <GetAppIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                                )}
                                {units.length ? (
                                        units.map((unit) => (
                                            <UnitRow
                                                unit={unit}
                                                key={unit.id}
                                            />
                                        ))
                                ) : null}
                        </List>
                    </DialogContent>
                </>
            )}
            <DialogActions>
                <Button onClick={handleOnClose} color="primary">
                    <Translate contentKey="entity.action.close">Chiudi</Translate>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
