import {
  AttachFile as AttachFileIcon,
  Cancel as CancelIcon
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import React, { createRef, useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from "react-material-ui-form-validator";
import { IEdoCourseYear } from "shared/model/edo-course-year.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoSubject } from "shared/model/edo-subject.model";
import { IEdoWikiPost, defaultValue } from "shared/model/edo-wiki-post.model";
import { EdoEducationalStage } from "shared/model/enumerations/edo-educational-stage.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { getFileAvatar } from "shared/util/file-avatar-utils";
import { getEntities as getEdoClasseList } from "../../../shared/reducers/entities/edo-class.reducer";
import { getEntities as getEdoSubjectList } from "../../../shared/reducers/entities/edo-subject.reducer";
import {
  createEntity,
  updateEntity
} from "../../../shared/reducers/entities/edo-wiki-post.reducer";

interface EditWikiPostProps {
  wikiPost?: IEdoWikiPost;
  open: boolean;
  onClose: () => void;
}

const EditWikiPost = (props: EditWikiPostProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { loading, updating, updateSuccess } = useAppSelector<EntityState<IEdoWikiPost>>((state) => state.edoWikiPost);
  const { entities: edoSubjectList, loading: loadingSubject } = useAppSelector<EntityState<IEdoSubject>>((state) => state.edoSubject);
  const { entities: edoCourseYearList } = useAppSelector<EntityState<IEdoCourseYear>>((state) => state.edoCourseYear);
  const [form, setForm] = useState<IEdoWikiPost>({ ...defaultValue, ...props.wikiPost });
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const fileInput = createRef<HTMLInputElement>();

  const isNew = !props.wikiPost;

  const { wikiPost, open, onClose: handleClose } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  useEffect(() => {
    dispatch(getEdoClasseList({ page: 0, size: 1000, sort: `id:ASC` }));
    dispatch(getEdoSubjectList({ page: 0, size: 1000, sort: `name:ASC` }));
  }, []);

  useEffect(() => {
    setForm({ ...defaultValue, ...wikiPost });
  }, [wikiPost]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (open && isNew) {
      setFiles([]);
      setForm({ ...defaultValue });
    }
  }, [open]);

  const removeFile = (index: number) => {
    setFiles((prevState) => Array.from(prevState).splice(index, 1));
  };

  const saveEntity = async () => {
    if (isNew) {
      if (files.length) {
        setUploading(true);
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i].name);
        }

        const response = await axios.post<{ data: IEdoFile[] }>("/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setUploading(false);

        dispatch(
          createEntity({
            ...form,
            file: { id: response.data.data[0].id },
          } as any)
        );
      }
    } else {
      dispatch(
        updateEntity({
          ...form,
        } as any)
      );
    }
  };

  const selectFiles = () => {
    fileInput.current.click();
  };

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setFiles([...files, ...Array.from(event.target.files)]); // if multiple
    setFiles([...Array.from(event.target.files)]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle>
        {isNew ? translate("edocendoApp.edoWikiPost.home.createLabel") : translate("edocendoApp.edoWikiPost.home.editLabel")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={2}>Inserisci le informazioni del contenuto wiki.<br />Puoi selezionare uno o piu anni e uno o piu istituti.</DialogContentText>
        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        ) : (
          <ValidatorForm
            onSubmit={saveEntity}
            onError={(errors) => false}
            sx={{ flexGrow: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label={translate("edocendoApp.edoWikiPost.title")}
                  onChange={handleChange}
                  name="title"
                  value={form.title || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectValidator
                  fullWidth
                  label="Anni"
                  name="year"
                  size="small"
                  required
                  SelectProps={{ multiple: true }}
                  variant="outlined"
                  value={form.years?.map((el) => el.id) || []}
                  onChange={(event: React.ChangeEvent<{ value: string[] }>) => {
                    setForm({
                      ...form,
                      years: event.target.value.map((el) => {
                        return {
                          id: parseInt(el, 10),
                        };
                      }) as IEdoCourseYear[],
                    });
                  }}
                >
                  {/* <MenuItem value="" key="NESSUNO">
                    Nessuno
                  </MenuItem> */}
                  {edoCourseYearList
                    ? edoCourseYearList.map((annoCorso) => (
                      <MenuItem value={annoCorso.id} key={annoCorso.id}>
                        {annoCorso.year}
                      </MenuItem>
                    ))
                    : null}
                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple={false}
                  options={edoSubjectList}
                  freeSolo={false}
                  getOptionLabel={(option: IEdoSubject) =>
                    option.name
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loadingText="Ricerca..."
                  noOptionsText="Nessun risultato"
                  value={form.subject?.id ? form.subject : null}
                  forcePopupIcon={false}
                  size="small"
                  loading={loadingSubject}
                  onChange={(event, value: IEdoSubject, reason) => {
                    if (reason === "selectOption") {
                      setForm({
                        ...form,
                        subject: value,
                      });
                    } else if (reason === "clear") {
                      setForm({
                        ...form,
                        subject: null,
                      });
                    }
                  }}
                  // onInputChange={(event, value, reason) => {
                  //   if (reason === "input") {
                  //     searchSubject(value);
                  //   } else if (reason === "clear") {
                  //     searchSubject();
                  //   }
                  // }}
                  renderInput={(autocompleteParams) => (
                    <TextField
                      {...autocompleteParams}
                      variant="outlined"
                      required
                      label={translate("edocendoApp.edoWikiPost.subject")}
                      placeholder={translate("edocendoApp.edoWikiPost.subject")}
                      InputProps={{
                        ...autocompleteParams.InputProps,
                        endAdornment: (
                          <>
                            {loadingSubject ? <CircularProgress color="inherit" size={20} /> : null}
                            {autocompleteParams.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth size="small" required>
                  <InputLabel id="educationalStage">{translate("edocendoApp.edoWikiPost.educationalStage")}</InputLabel>
                  <Select
                    fullWidth
                    labelId='educationalStage'
                    label={translate("edocendoApp.edoWikiPost.educationalStage")}
                    name="educationalStage"
                    size="small"
                    required
                    variant="outlined"
                    value={form.educationalStage || ""}
                    onChange={(event) => {
                      setForm((prevState) => ({
                        ...prevState,
                        educationalStage: event.target.value as EdoEducationalStage,
                      }));
                    }
                    }
                    renderValue={(value) => {
                      return translate(`edocendoApp.EdoEducationalStage.${value}`);
                    }}
                  >
                    <MenuItem value={EdoEducationalStage.INFANZIA}>
                      <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.INFANZIA}`} />
                    </MenuItem>
                    <MenuItem value={EdoEducationalStage.PRIMARIA}>
                      <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.PRIMARIA}`} />
                    </MenuItem>
                    <MenuItem value={EdoEducationalStage.SECONDARIA_I}>
                      <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.SECONDARIA_I}`} />
                    </MenuItem>
                    <MenuItem value={EdoEducationalStage.SECONDARIA_II}>
                      <Translate contentKey={`edocendoApp.EdoEducationalStage.${EdoEducationalStage.SECONDARIA_II}`} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isNew ? (
                <>
                  {files !== null && files.length ? (
                    <Grid item xs={12}>
                      <Box py={2}>
                        <List disablePadding dense>
                          {files.map((file, index) => (
                            <ListItem key={index} disableGutters>
                              <ListItemAvatar>
                                {getFileAvatar(file.type)}
                              </ListItemAvatar>
                              <ListItemText primary={file.name} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() => removeFile(index)}
                                  edge="end"
                                  aria-label="Rimuovi file"
                                >
                                  <CancelIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={selectFiles}
                      variant="contained"
                      color="secondary"
                      startIcon={<AttachFileIcon />}
                    >
                      Aggiugi file
                    </Button>
                    <input
                      type="file"
                      ref={fileInput}
                      name="files"
                      multiple={false}
                      hidden
                      onChange={handleFiles}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </ValidatorForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <LoadingButton
          onClick={() => saveEntity()}
          disabled={updating || !form.title || !form.subject || !form.educationalStage || !form.years || !form.years.length || !(form.file || files.length)}
          color="primary"
          loading={updating || uploading}
        // variant="contained"
        >
          {isNew ? translate("entity.action.create") : translate("entity.action.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditWikiPost;