import {
    Close as CloseIcon
} from "@mui/icons-material";
import {
    AppBar, Dialog, DialogContent, IconButton, Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect } from "react";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { EntityState } from "shared/reducers/reducer.utils";
import Units from "./Units";

export interface EditChapterUnitsProps {
    chapter?: IEdoChapter;
    open: boolean;
    handleClose: (updated: boolean) => void;
    handleOpenViewUnitDialog: (unit: IEdoUnit) => void;
    handleOpenEditUnitDialog: (unit: IEdoUnit) => void;
    handleOpenDeleteUnitDialog: (unit: IEdoUnit) => void;
}

export const EditChapterUnits = (props: EditChapterUnitsProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { loading, updating, updateSuccess } = useAppSelector<
        EntityState<IEdoChapter>
    >((state) => state.edoChapter);

    const {
        chapter,
        open,
        handleClose,
        handleOpenViewUnitDialog,
        handleOpenEditUnitDialog,
        handleOpenDeleteUnitDialog,
    } = props;

    const handleCloseDialog = (updated: boolean) => {
        handleClose(updated);
    };

    useEffect(() => {
    
    }, [chapter]);

    useEffect(() => {
        if (updateSuccess) {
            // handleCloseDialog(true);
        }
    }, [updateSuccess]);

    return (
        <Dialog open={open} onClose={handleCloseDialog} fullScreen={true} fullWidth>
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" flexGrow={1}>
                        Aggiungi unità al capitolo
                    </Typography>
                    {/* <Button autoFocus color="inherit" startIcon={<CloseIcon />}
            onClick={() => handleCloseDialog(false)}
            disabled={updating} sx={{marginRight: 1}}>
            <Translate contentKey="entity.action.close">Close</Translate>
          </Button> */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => handleCloseDialog(true)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <Button autoFocus color="inherit" startIcon={<SaveIcon />}
            onClick={saveEntity}
            disabled={updating}>
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button> */}
                </Toolbar>
            </AppBar>
            <DialogContent
                style={{ backgroundColor: theme.palette.background.default }}
            >
                    <Units
                        chapter={chapter}
                        handleOpenViewUnitDialog={handleOpenViewUnitDialog}
                        handleOpenEditUnitDialog={handleOpenEditUnitDialog}
                        handleOpenDeleteUnitDialog={handleOpenDeleteUnitDialog}
                    />
            </DialogContent>
        </Dialog>
    );
};
