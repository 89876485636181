import {
    Assignment as AssignmentIcon,
    QuestionMark as QuestionMarkIcon
} from '@mui/icons-material';
import {
    Avatar,
    Box, Container, Grid, Paper, Typography, useMediaQuery, useTheme
} from "@mui/material";
import NavTabs from 'components/NavTabs/NavTabs';
import { Outlet, Route, Routes } from "react-router-dom";
import QuizRoute from "./pages/QuizDetail/quiz";
import QuestionsRoute from './questions';
import QuizzesRoute from './quizzes';

const QuizzesRoutes = () => {
    const theme = useTheme();
    const isMediaSm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            component="main"
            flexGrow={1}
            minHeight="100vh"
            overflow="auto"
        >
            <Container
                maxWidth="lg"
                sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingRight: 4,
                    paddingLeft: 4,
                }}
            >
                <Routes>
                    <Route element={
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper sx={{ overflow: "hidden" }}>
                                    <Box sx={{
                                        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(\"/class.jpg\");",
                                        backgroundPosition: "center 86.5%",
                                        backgroundSize: "cover",
                                        height: "200px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}>
                                        <Box p={4} sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            height: "100%",
                                            // backdropFilter: "blur(15px)",
                                        }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginRight: 3 }}>
                                                <Avatar
                                                    sx={{ width: 100, height: 100 }}
                                                    alt={"Logo"}
                                                    src={`/package/logo.png`}
                                                />
                                            </Box>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}>
                                                <Typography gutterBottom variant={isMediaSm ? "body1" : "h4"} mb={1} color="white">
                                                    I miei test
                                                </Typography>
                                                <Typography variant={isMediaSm ? "body2" : "h6"} color="white">
                                                    Test e domande
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <NavTabs
                                        centered={true}
                                        tabs={[{
                                            label: "Test",
                                            icon: <AssignmentIcon />,
                                            iconPosition: "start",
                                            href: "/quizzes/"
                                        }, {
                                            label: "Domande",
                                            icon: <QuestionMarkIcon />,
                                            iconPosition: "start",
                                            href: "/quizzes/questions"
                                        }]}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Outlet />
                            </Grid>
                        </Grid>}>
                        <Route path="questions" element={<QuestionsRoute />} />
                        <Route index element={<QuizzesRoute />} />
                    </Route>
                    <Route path=":id" element={<QuizRoute />} />
                </Routes>
            </Container>
        </Box>
    );
};

export default QuizzesRoutes;
