import { Message } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Grid,
  Paper,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { IEdoChatMessage } from "shared/model/edo-chat-message.model";
import { IEdoChat } from "shared/model/edo-chat.model";
import { ChatState, getUserParticipations } from "shared/reducers/chat.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';
import ChatList from "./components/ChatList";
import { CreateGroup } from "./components/CreateGroup";
import ChatHeaderBar from "./components/HeaderBar";
import ChatDetailRoutes from "./pages/ChatDetail";
import Chat from "./pages/ChatDetail/chat";
import Info from "./pages/ChatDetail/info";
import ChatListRoutes from "./pages/ChatList";

const useStyles = makeStyles()(theme => ({
  container: {
    width: "100%",
    height: "calc(100vh - 74px)",
    maxHeight: "calc(100vh - 74px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 74px)",
      maxHeight: "calc(100vh - 74px)",
    }
  },
  chatSection: {
    height: "100%",
    maxHeight: "calc(100vh - 74px)",
    display: "grid",
    gridTemplateRows: "100% min-content",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "calc(100% - 74px) min-content",
    }
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
}));

const ChatRoutes = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const socket = useContext(SocketContext);
  const isMediaSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [editChatDialog, setEditChatDialog] = useState<{ open: boolean; entity: IEdoChat }>({ open: false, entity: null });
  const { updateSuccess: updateSuccessChat } = useAppSelector<
    EntityState<IEdoChat>
  >((state) => state.edoChat);
  const { participations } = useAppSelector<ChatState>(state => state.chat);

  const getParticipations = () => {
    dispatch(
      getUserParticipations({
        page: 0,
        size: 1000,
        sort: "id:ASC",
      })
    );
  };

  const handleOnMessageEvent = (message: IEdoChatMessage) => {
    getParticipations();
  };

  const handleChatCreatedEvent = (chat: IEdoChat) => {
    getParticipations();
    navigate(`/chat/${chat.id}`);
  };

  useEffect(() => {
    getParticipations();
  }, []);

  useEffect(() => {
    socket.on('message', handleOnMessageEvent);
    socket.on('chat-created', handleChatCreatedEvent);
    return function cleanup() {
      socket.off('message', handleOnMessageEvent);
      socket.off('chat-created', handleChatCreatedEvent);
    };
  });


  useEffect(() => {
    if (updateSuccessChat) {
      getParticipations();
    }
  }, [updateSuccessChat]);

  return (
    <>
      <Grid container component={Paper} className={classes.container}>
        {!isMediaSm && false ? (
          <Grid item xs={3} sx={{ borderRight: "1px solid #e0e0e0", maxHeight: '100%' }}>
            <Box display="flex" flexDirection="column" justifyContent="flex-start" sx={{ overflow: "hidden", height: "100%" }}>
              <Box>
                <ChatHeaderBar onEdit={(chat) => setEditChatDialog({ open: true, entity: chat })} />
              </Box>
              <Divider />
              <ChatList
                participations={participations}
                onSelectParticipation={(participation) => navigate(`/chat/${participation.chat.id}`)}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid item sm={12} xs={12} className={classes.chatSection}>
          <Routes>
            <Route
              index
              element={<ChatListRoutes openEditDialog={(chat) => setEditChatDialog({ open: true, entity: chat })} />}
            />
            <Route path=":id" element={<ChatDetailRoutes />}>
              <Route
                index
                element={<Chat />}
              />
              <Route
                path="info"
                element={<Info />}
              />
            </Route>
          </Routes>
          {isMediaSm ? (
            <Box>
              <Paper>
                <BottomNavigation
                  showLabels
                >
                  <BottomNavigationAction
                    label="Chat"
                    icon={<Message />}
                    onClick={() => navigate(`/chat`)}
                  />
                </BottomNavigation>
              </Paper>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <CreateGroup
        gruppo={editChatDialog.entity}
        open={editChatDialog.open}
        handleClose={() => setEditChatDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default ChatRoutes;
