import edoChapter from "./edo-chapter.reducer";
import edoChatMessage from "./edo-chat-message.reducer";
import edoChatParticipation from "./edo-chat-participation.reducer";
import edoChat from "./edo-chat.reducer";
import edoCircular from "./edo-circular.reducer";
import edoClassZoomLinkReducer from "./edo-class-zoom-link.reducer";
import edoClass from "./edo-class.reducer";
import edoCourseComment from "./edo-course-comment.reducer";
import edoCourseEvent from "./edo-course-event.reducer";
import edoCourseMaterial from "./edo-course-material.reducer";
import edoCoursePost from "./edo-course-post.reducer";
import edoCourseQuiz from "./edo-course-quiz.reducer";
import edoCourseYear from "./edo-course-year.reducer";
import edoCourse from "./edo-course.reducer";
import edoFile from "./edo-file.reducer";
import edoFriendship from "./edo-friendship.reducer";
import edoParentReducer from "./edo-parent.reducer";
import edoQuestionAnswer from "./edo-question-answer.reducer";
import edoQuestion from "./edo-question.reducer";
import edoQuizAttempt from "./edo-quiz-attempt.reducer";
import edoQuizQuestion from "./edo-quiz-question.reducer";
import edoQuiz from "./edo-quiz.reducer";
import edoSchool from "./edo-school.reducer";
import edoSetting from "./edo-setting.reducer";
import edoStudentReducer from "./edo-student.reducer";
import edoSubject from "./edo-subject.reducer";
import edoTeacherReducer from "./edo-teacher.reducer";
import edoUnit from "./edo-unit.reducer";
import edoUserMessageState from "./edo-user-message-state.reducer";
import edoVideostorePostReducer from "./edo-videostore-post.reducer";
import edoWallComment from "./edo-wall-comment.reducer";
import edoWallPost from "./edo-wall-post.reducer";
import edoWikiPost from "./edo-wiki-post.reducer";

const entitiesReducers = {
  edoCourseYear,
  edoFile,
  edoSchool,
  edoSubject,
  edoClass,
  edoChat,
  edoFriendship,
  edoCircular,
  edoSetting,
  edoCourse,
  edoWikiPost,
  edoWallPost,
  edoWallComment,
  edoCoursePost,
  edoCourseComment,
  edoCourseEvent,
  edoChapter,
  edoUnit,
  edoCourseMaterial,
  edoChatMessage,
  edoChatParticipation,
  edoUserMessageState,
  edoQuiz,
  edoQuestion,
  edoQuizQuestion,
  edoCourseQuiz,
  edoQuizAttempt,
  edoQuestionAnswer,
  edoStudent: edoStudentReducer,
  edoTeacher: edoTeacherReducer,
  edoParent: edoParentReducer,
  edoVideostorePost: edoVideostorePostReducer,
  edoClassZoomLink: edoClassZoomLinkReducer,
};

export default entitiesReducers;
