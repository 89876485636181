import { Cancel as CancelIcon } from "@mui/icons-material";
import {
  Box, Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid, IconButton, List, ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText, ListSubheader, useMediaQuery,
  useTheme
} from "@mui/material";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { IEdoCoursePost } from "shared/model/edo-course-post.model";
import { IEdoWallPost, defaultValue } from "shared/model/edo-wall-post.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";

export interface EditPostProps {
  post?: IEdoWallPost | IEdoCoursePost;
  open: boolean;
  onClose: () => void;
  loading: boolean;
  updating: boolean;
  updateSuccess: boolean;
  updatePost: (post: IEdoWallPost | IEdoCoursePost) => void;
}

export const EditPost = (props: EditPostProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [form, setForm] = useState<IEdoWallPost>({ ...defaultValue, ...props.post });

  const {
    post,
    open,
    onClose: handleClose,
    loading,
    updating,
    updateSuccess,
    updatePost,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  useEffect(() => {
    setForm({ ...defaultValue, ...post });
  }, [post]);

  //   useEffect(() => {
  //     if (updateSuccess) {
  //       getPost();
  //       handleCloseDialog();
  //     }
  //   }, [updateSuccess]);

  const saveEntityLocal = async () => {
    //   dispatch(updateEntity({ ...form }));
    const response = await updatePost(form);
    handleClose();
  };

  const removeFile = (index: number) => {
    const files = Array.from(form.files || []);
    files.splice(index, 1);
    setForm({ ...form, files });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>Modifica il post</DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={2}>Modifica il contenutod del post.</DialogContentText>
        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        ) : (
          <ValidatorForm
            onSubmit={saveEntityLocal}
            onError={(errors) => false}
            sx={{ flexGrow: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  multiline
                  rows={4}
                  size="small"
                  fullWidth
                  required
                  //   style={{ minWidth: 500 }}
                  label="Descrizione"
                  onChange={handleChange}
                  name="description"
                  value={form.description || ""}
                />
                </Grid>
                <Grid item xs={12}>
                  {post?.files?.length ? (
                    <Box pb={2}>
                      <List
                        disablePadding
                        dense
                        subheader={
                          <ListSubheader
                            disableGutters
                            sx={(theme) => ({ backgroundColor: "inherit" })}
                          >
                            File
                          </ListSubheader>
                        }
                      >
                        {form.files?.map((file, index) => (
                          <ListItem key={file.id} disableGutters>
                            <ListItemAvatar>
                              {getFileAvatar(file.fileType)}
                            </ListItemAvatar>
                            <ListItemText primary={file.originalFileName} />
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => removeFile(index)}
                                edge="end"
                                aria-label="Rimuovi file"
                              >
                                <CancelIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ) : null}
                  </Grid>
            </Grid>
          </ValidatorForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          onClick={() => {
            saveEntityLocal();
          }}
          color="primary"
          disabled={updating}
        >
          <Translate contentKey="entity.action.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
