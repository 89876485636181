import {
  Route, Routes
} from "react-router-dom";
import CourseDetailRoutes from "./pages/CourseDetail";
import CourseEvents from "./pages/CourseDetail/events";
import CourseMaterials from "./pages/CourseDetail/materials";
import CourseQuizzes from "./pages/CourseDetail/quizzes";
import QuizResultsRoute from "./pages/CourseDetail/Quizzes/quiz-results";
import StartQuizRoute from "./pages/CourseDetail/Quizzes/start-quiz";
import CourseStudents from "./pages/CourseDetail/students";
import CourseTeachers from "./pages/CourseDetail/teachers";
import CourseWall from "./pages/CourseDetail/wall";

export const CoursesRoutes = () => {

  return (
    <Routes>
      <Route path=":id" element={<CourseDetailRoutes />}>
        <Route index element={<CourseWall />} />
        <Route
          path="events"
          element={<CourseEvents />}
        />
        <Route
          path="students"
          element={<CourseStudents />}
        />
        <Route
          path="teachers"
          element={<CourseTeachers />}
        />
        <Route
          path="materials"
          element={<CourseMaterials />}
        />
        <Route path="quizzes">
          <Route path=":courseQuizId">
            <Route
              path="start"
              element={<StartQuizRoute />}
            />
            <Route
              path="results"
              element={<QuizResultsRoute />}
            />
          </Route>
          <Route
            index
            element={<CourseQuizzes />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default CoursesRoutes;
