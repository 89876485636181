import { AccordionProps, Accordion as MuiAccordion, styled } from '@mui/material';

const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
  // root: {
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  '&:before': {
    display: 'none',
  },
  '&$expanded': {
    margin: 'auto',
  },
  // },
  // expanded: {
  //   width: '100%',
  // },
}));

export const StyledAccordion = (props: AccordionProps) => {
  return <Accordion {...props}>{props.children}</Accordion>;
};

export default StyledAccordion;
