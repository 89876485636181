import { Avatar, Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IEdoCourse } from "shared/model/edo-course.model";

interface ICourseProps {
    course: IEdoCourse;
}

const Course = (props: ICourseProps) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const showPic = false;

    const { course } = props;

    const handleOpenCourse = () => {
        navigate(`/courses/${course.id}`);
    };

    return (
        <Paper sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "85px",
            width: "100%",
            borderRadius: 5,
            cursor: "pointer",
            '&:hover': {
                transform: 'scale(1.05)',
            },
            transition: 'transform 0.2s',
            '&:active': {
                transform: 'scale(0.95)',
            },
        }}
            onClick={handleOpenCourse}
        >
            <Box sx={{ height: "100%", paddingTop: 1.5, paddingLeft: 1.5, paddingBottom: 1.5 }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%", aspectRatio: "1" }}>
                    {showPic && course?.subject?.picPath ? (
                        <Avatar
                            sx={{ width: "100%", height: "100%" }}
                            alt={course.subject?.name}
                            src={`/api/subjects/${course?.subject?.id}/pic/main.jpeg`}
                        />
                    ) : course?.subject?.name ? (
                        <Avatar
                            sx={{ width: "100%", height: "100%" }}
                            alt={course?.subject?.name}
                        >
                            {course?.subject?.name[0]}
                        </Avatar>
                    ) : (
                        <Skeleton variant="circular" width={100} height={100} />
                    )}
                </Box>
            </Box>
            <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" p={2} sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            
            }}>
                <Typography gutterBottom variant="h6" component="p">
                    {course.subject.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {course.class.school.code} - {course.class.year.year}°{course.class.section} 
                </Typography>
            </Box>
        </Paper>
    );
};

export default Course;
