import {
    AppBar, Paper, Toolbar,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import {
    BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title,
    Tooltip
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { IEdoStudent } from 'shared/model/edo-student.model';
import { makeStyles } from 'tss-react/mui';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
);

export const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
        },
    },
    layout: {
        padding: 20
    }
};

const labels = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica",
];

interface IDataFromServer {
    day: string,
    timeSpent: string
}

interface IData {
    x: string,
    y: number
}

const useStyles = makeStyles()(theme =>
    ({
        paper: {
            color: theme.palette.text.secondary,
            overflow: "hidden",
        },
        root: {
            display: "grid",
            gridTemplateRows: "min-content min-content",
            height: "100%",
            width: "100%",
        },
        appbar: {
            boxShadow: "none",
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.divider,
            borderBottomStyle: "solid",
        },
        toolbar: {
            justifyContent: "space-between",
        },
    })
);

interface ITimingChartProps {
    student: IEdoStudent;
}

const TimingChart = (props: ITimingChartProps) => {
    const { classes } = useStyles();
    const [data, setData] = useState({
        // labels,
        datasets: [
            {
                label: 'Minuti in piattaforma',
                data: [] as IData[],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    });

    const { student } = props;

    useEffect(() => {
        const getTimeSpentByChild = async () => {
            const response = await axios.get<{ data: IDataFromServer[] }>(`/students/${student.userId}/time-spent`);
            //  1=Sunday, 2=Monday, 3=Tuesday, 4=Wednesday, 5=Thursday, 6=Friday, 7=Saturday.
            let days: Record<string, number> = {};
            for (let i = 6; i >= 0; i--) {
                const day = new Date();
                day.setDate(day.getDate() - i);
                days[day.toISOString().split("T")[0]] = 0;
            }
      
            for (const day of response.data.data) {
                const [hours, minutes, seconds] = day.timeSpent.split(":").map(Number);
                // days[(day.dayOfWeek - 2) % 8] = hours * 60 + minutes + seconds / 60;
                // days[(day.dayOfWeek - 2) % 8] = hours * 60 + minutes;
                days[day.day.split("T")[0]] = hours * 60 + minutes;
            }
            setData(prevState => ({
                ...prevState,
                datasets: [{
                    ...prevState.datasets[0],
                    data: Object.entries<number>(days).map(([day, timeSpent]) => ({ x: new Intl.DateTimeFormat('it-IT').format(new Date(day)), y: timeSpent }))
                }]
            }));
        };
        if (student?.userId) {
            getTimeSpentByChild();
        }
    }, [student?.userId]);

    return (
        <Paper className={classes.paper}>
            <Box className={classes.root}>
                <AppBar
                    position="static"
                    color="transparent"
                    className={classes.appbar}
                >
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h5">
                            Tempo in piattaforma
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Bar options={options} data={data} />
            </Box>
        </Paper>
    );
};

export default TimingChart;
