import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoCourseEvent } from "shared/model/edo-course-event.model";
import { deleteEntity } from "../../../shared/reducers/entities/edo-course-event.reducer";

export interface DeleteEventoInsegnamentoProps {
  evento: IEdoCourseEvent;
  open: boolean;
  handleClose: any;
}

export const DeleteEventoInsegnamento = (
  props: DeleteEventoInsegnamentoProps
) => {
  const dispatch = useAppDispatch();
  const {updateSuccess} = useAppSelector(
    (state) => state.edoCourseEvent
  );

  const { open, handleClose, evento } = props;

  const handleCloseDialog = () => {
    handleClose(false);
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(evento.id));
  };

  useEffect(() => {
    if (updateSuccess) {
      handleCloseDialog();
    }
  }, [updateSuccess]);

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      {evento ? (
        <DialogContent>
          <DialogContentText>
            <Translate
              contentKey="edocendoApp.edoCourseEvent.delete.question"
              interpolate={{ nome: evento.title }}
            >
              Are you sure you want to delete this EdoEventoInsegnamento?
            </Translate>
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button onClick={confirmDelete} color="primary">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
