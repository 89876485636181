import {
  GetApp as GetAppIcon, Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import axios from "axios";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import { useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getFileAvatar } from "shared/util/file-avatar-utils";

export interface ViewUnitProps {
  open: boolean;
  handleClose: () => void;
  unit: IEdoUnit;
}

export const ViewUnit = (props: ViewUnitProps) => {
  const [files, setFiles] = useState<IEdoFile[]>([]);
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );

  const { open, handleClose, unit } = props;

  useEffect(() => {

    const getUnitFiles = async () => {
      const response = await axios.get(`/units/${props.unit.id}/files`);
      setFiles(response.data.data);
    };

    if (unit) {
      getUnitFiles();
    }
  }, [unit]);

  const openFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  const downloadFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/download`, "_blank");
  };


  
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      // fullScreen={fullScreen}
      fullWidth
    >
      {unit === null ? (
        <CircularProgress
          size={24}
          style={{ marginLeft: 15, position: "relative", top: 4 }}
        />
      ) : (
        <>
          <DialogContent>
            <List component="div" disablePadding>
              {!unit || !files || !files.length ? (
                <Box p={2}>
                  <Typography variant="body1" align="center">
                    <Translate contentKey="edocendoApp.edoFile.home.notFound">
                      No Edo Files found
                    </Translate>
                  </Typography>
                </Box>
              ) : (
                files?.map((file) => (
                  <ListItem key={file.id} button disableGutters>
                    <ListItemAvatar>
                      {getFileAvatar(file.fileType)}
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.originalFileName}
                      primaryTypographyProps={{ variant: "body2" }}
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      secondary={
                        <TextFormat
                          value={file.createdAt}
                          type="date"
                          format={APP_LOCAL_DATE_FORMAT}
                        />
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => openFile(file)}
                        edge="end"
                        aria-label="Open file"
                        sx={{marginRight: 1}}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => downloadFile(file)}
                        edge="end"
                        aria-label="Download file"
                      >
                        <GetAppIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              )}
            </List>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
