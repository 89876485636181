import { Box, Container, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import ClassDetail from "./ClassDetail";
import Classes from "./classes";

const ClassesRoutes = () => {
  return (
    <Box
      component="main"
      flexGrow={1}
      minHeight="100vh"
      overflow="auto"
    >
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: 4,
          paddingBottom: 4,
          paddingRight: 3,
          paddingLeft: 3,
        }}
      >
        <Grid container spacing={3}>
          <Routes>
            <Route path=":id" element={<ClassDetail />} />
            <Route index element={<Classes />} />
          </Routes>
        </Grid>
      </Container>
    </Box>
  );
};

export default ClassesRoutes;
