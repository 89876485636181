import { Box, Divider } from "@mui/material";
import { useAppSelector } from "config/store";
import ChatList from "pages/chat/components/ChatList";
import ChatHeaderBar from "pages/chat/components/HeaderBar";
import { useNavigate } from "react-router-dom";
import { IEdoChatParticipation } from "shared/model/edo-chat-participation.model";
import { IEdoChat } from "shared/model/edo-chat.model";
import { ChatState } from "shared/reducers/chat.reducer";

interface ChatListRoutesProps {
    openEditDialog: (entity: IEdoChat) => void
}

const ChatListRoutes = (props: ChatListRoutesProps) => {
    const navigate = useNavigate();
    const { participations } = useAppSelector<ChatState>(state => state.chat);

    const { openEditDialog } = props;

    const handleSelectParticipation = (participation: IEdoChatParticipation) => {
        navigate(`/chat/${participation.chat.id}`);
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
            <Box>
                <ChatHeaderBar onEdit={openEditDialog} />
            </Box>
            <Divider />
            <ChatList
                participations={participations}
                onSelectParticipation={handleSelectParticipation}
            />
      </Box>
    );
};

export default ChatListRoutes;
