import { useAppSelector } from "config/store";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { IRole } from "shared/model/role.model";
import { AuthenticationState } from "shared/reducers/authentication";

type IOwnProps = RouteProps & {
  children?: JSX.Element
  hasAnyAuthorities?: string[];
}

export const PrivateRouteComponent = ({ children, hasAnyAuthorities = [] }: IOwnProps) => {
  const { isAuthenticated, sessionHasBeenFetched, account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const location = useLocation();
  const isAuthorized = hasAnyAuthority(account.roles, hasAnyAuthorities);

  if (!sessionHasBeenFetched) {
    return <div></div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isAuthorized) {
    return (
      <div>
        <div >
          Non sei autorizzato ad accedere a questa pagina.
        </div>
      </div>
    );
  }


  if (children) {
    return children;
  }

  return <Outlet />;
};

export const hasAnyAuthority = (roles: IRole[], hasAnyAuthorities: string[]) => {
  if (roles && roles.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }

    return hasAnyAuthorities.some((auth) =>
      roles
        .map((role) => {
          if (typeof role === "string") return role;
          else return role.name;
        })
        .includes(auth)
    );
  }
  return false;
};

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export default PrivateRouteComponent;
