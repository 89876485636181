import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import axios from "axios";
import { IPaginationResponse } from "shared/util/pagination.constants";
import {
  IEdoCoursePost,
  defaultValue
} from "../../model/edo-course-post.model";
import { cleanEntity } from "../../util/entity-utils";
import {
  EntityState,
  IQueryParamsWithFilters,
  createEntitySlice,
  serializeAxiosError
} from "../reducer.utils";

const initialState: EntityState<IEdoCoursePost> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "/course-posts";

// Actions

export const getEntities = createAsyncThunk(
  "edoCoursePost/fetch_entity_list",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `${apiUrl}`;
    } else {
      requestUrl = `${apiUrl}?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoCoursePost>>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "edoCoursePost/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEdoCoursePost>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "edoCoursePost/create_entity",
  async (entity: IEdoCoursePost, thunkAPI) => {
    const result = await axios.post<IEdoCoursePost>(
      apiUrl,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "edoCoursePost/update_entity",
  async (entity: IEdoCoursePost, thunkAPI) => {
    const result = await axios.put<IEdoCoursePost>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "edoCoursePost/partial_update_entity",
  async (entity: IEdoCoursePost, thunkAPI) => {
    const result = await axios.patch<IEdoCoursePost>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "edoCoursePost/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEdoCoursePost>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getCoursePostsByCourse = createAsyncThunk(
  "edoCoursePost/get_course_posts_by_course",
  async ({
    page,
    size,
    sort,
    filters,
    courseId,
  }: IQueryParamsWithFilters & { courseId: string }) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl = `/courses/${courseId}/course-posts`;
    if (params.toString() !== "") {
      requestUrl = `${requestUrl}?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoCoursePost>>(requestUrl);
  }
);

// slice

export const EdoCoursePostSlice = createEntitySlice({
  name: "edoCoursePost",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(
        isFulfilled(getEntities, getCoursePostsByCourse),
        (state, action) => {
          const {
            data: {
              data,
              meta: { totalItems },
            },
            headers,
          } = action.payload;

          return {
            ...state,
            loading: false,
            entities: data,
            totalItems,
          };
        }
      )
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(
        isPending(getEntities, getCoursePostsByCourse, getEntity),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = EdoCoursePostSlice.actions;

// Reducer
export default EdoCoursePostSlice.reducer;
