import { EdoEducationalStage } from "shared/model/enumerations/edo-educational-stage.model";

export interface IEdoSchool {
  id?: number;
  name?: string;
  code?: string;
  street?: string | null;
  postalCode?: string | null;
  type?: string;
  subtype?: string | null;
  educationalStage?: EdoEducationalStage;
}

export const defaultValue: Readonly<IEdoSchool> = {};
