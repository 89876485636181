import {
    Delete as DeleteIcon, Edit as EditIcon,
    GetApp as GetAppIcon, MoreVert as MoreVertIcon
} from "@mui/icons-material";
import {
    Box, IconButton, ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem, Typography
} from "@mui/material";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import React, { useState } from "react";
import { TextFormat } from "react-jhipster";
import { IEdoFile } from "shared/model/edo-file.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";

interface IEdoFileRowProps {
    file: IEdoFile;
    onEdit: (file: IEdoFile) => void;
    onDelete: (file: IEdoFile) => void;
}

const FileRow = (props: IEdoFileRowProps) => {
    const [anchorMoreFile, setAnchorMoreFile] =
        useState<null | HTMLElement>(null);

    const { file, onEdit: handleEdit, onDelete: handleDelete } = props;

    const openFile = (file: IEdoFile) => {
        // const response = await axios.get(`/files/${file.id}/download`);
        window.open(`/api/files/${file.id}/view`, "_blank");
    };

    const downloadFile = (file: IEdoFile) => {
        // const response = await axios.get(`/files/${file.id}/download`);
        window.open(`/api/files/${file.id}/download`, "_blank");
    };

    const handleMoreFileButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorMoreFile(event.currentTarget);
    };

    const handleCloseMoreFileMenu = () => {
        setAnchorMoreFile(null);
    };

    return (
        <>
            <ListItem
                key={file.id}
                //   disableGutters
                button
                onClick={() => openFile(file)}
            >
                <ListItemAvatar>
                    {getFileAvatar(file.fileType)}
                </ListItemAvatar>
                <ListItemText
                    primary={file.originalFileName}
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    secondary={
                        <TextFormat
                            value={file.createdAt}
                            type="date"
                            format={APP_LOCAL_DATE_FORMAT}
                        />
                    }
                />
                <ListItemSecondaryAction>
                    <Box display="flex" flexDirection="row">
                        <Box mr={2}>
                            <IconButton
                                edge="end"
                                onClick={handleMoreFileButtonClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Box>
                        <Menu
                            id="more-file-menu"
                            anchorEl={anchorMoreFile}
                            keepMounted
                            open={Boolean(anchorMoreFile)}
                            onClose={handleCloseMoreFileMenu}
                            disableScrollLock={true}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={() => {
                                handleCloseMoreFileMenu();
                                handleEdit(file);
                            }}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Rinomina file</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleCloseMoreFileMenu();
                                downloadFile(file);
                            }}>
                                <ListItemIcon>
                                    <GetAppIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Download file</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleCloseMoreFileMenu();
                                handleDelete(file);
                            }
                            }>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">
                                    Elimina file
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
};

export default FileRow;