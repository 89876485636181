import {
  AppBar,
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper, Toolbar,
  Typography
} from "@mui/material";
import axios from "axios";
import { useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { translate } from "react-jhipster";
import { IEdoCourse } from "shared/model/edo-course.model";
import { IEdoStudent } from "shared/model/edo-student.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme =>
  ({
    root: {
      display: "grid",
      gridTemplateRows: "min-content auto min-content",
      height: "100%",
    },
    appbar: {
      boxShadow: "none",
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: "solid",
    },
    toolbar: {
      justifyContent: "space-between",
    },
    paper: {
      color: theme.palette.text.secondary,
      overflow: "hidden",
    },
    button: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    buttonMobile: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  })
);

const CourseStudents = () => {
  const { classes } = useStyles();
  const { entity: course } = useAppSelector<EntityState<IEdoCourse>>(
    (state) => state.edoCourse
  );
  const [students, setStudents] = useState<IEdoStudent[]>([]);


  useEffect(() => {
    const getStudents = async () => {
      const response = await axios.get<{ data: IEdoStudent[] }>(`/classes/${course.class.id}/students`);
      setStudents(response.data.data);
    };

    if (course.class.id) {
      getStudents();
    }
  }, [course.class.id]);

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.appbar}
        >
          <Toolbar className={classes.toolbar}>
            <Typography variant="h5">{translate('edocendoApp.edoStudent.home.title')}</Typography>
          </Toolbar>
        </AppBar>
        {students && students.length > 0 ? (
          <List dense>
            {students.map(student => (
              <ListItem key={student.userId} button>
                <ListItemAvatar>
                  <Avatar alt={student.user.firstName + " " + student.user.lastName}>
                    {student.user.firstName[0] + student.user.lastName[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={student.user.firstName + " " + student.user.lastName}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box p={2}>
            <Typography variant="body1" align="center">
              Nessun studente trovato.
            </Typography>
          </Box>
        )}
        {/* <Box className={classes.footer}>
          <TablePagination
            component="div"
            count={totalItems}
            rowsPerPage={paginationState.itemsPerPage}
            page={paginationState.activePage - 1}
            onPageChange={(event, page) => handlePagination(page + 1)}
            className={classes.pagination}
            labelRowsPerPage="Righe per pagina:"
            nextIconButtonText="Pagina successiva"
            backIconButtonText="Pagina precedente"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count !== -1 ? count : 'more than' + to}`}
          />
        </Box> */}
      </div>
    </Paper>
  );
};

export default CourseStudents;
