import { Close as CloseIcon } from "@mui/icons-material";
import {
    AppBar,
    Box,
    CircularProgress,
    Dialog, DialogContent, IconButton, Toolbar, Typography, useMediaQuery,
    useTheme
} from "@mui/material";
import { useAppSelector } from "config/store";
import "moment/locale/it";
import ReactPlayer from "react-player";
import { IEdoVideostorePost } from "shared/model/edo-videostore-post.model";
import { EntityState } from "shared/reducers/reducer.utils";

type IViewVideoDialogProps = {
    videostorePost: IEdoVideostorePost | null;
    open: boolean;
    onClose: () => void;
};

const ViewVideoDialog = (props: IViewVideoDialogProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { loading, updating, updateSuccess } = useAppSelector<EntityState<IEdoVideostorePost>>((state) => state.edoVideostorePost);

    const { videostorePost, open, onClose: handleClose } = props;

    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {videostorePost?.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{
                padding: 0,
                overflow: "hidden"
            }}>
                {loading || !videostorePost || !videostorePost.video ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                        <ReactPlayer
                            // playing url={`${document.location.origin}/api/files/${videostorePost?.file.id}/view`}
                            playing
                            url={`${document.location.origin}/api/videostore-posts/files/${videostorePost.video.hash}/master.m3u8`}
                            controls
                            width='100%'
                            height='100%'
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                    }
                                }
                            }}
                            sx={{
                                // position: "relative",
                                margin: 0,
                                padding: 0,
                                // paddingTop: "56.25%"
                            }}
                            wrapper={Box}
                        />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ViewVideoDialog;