import {
  AppBar,
  Box,
  Checkbox,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TablePagination, Toolbar,
  Typography
} from "@mui/material";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { IPaginationBaseState, TextFormat, Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getChaptersByUser } from "shared/reducers/entities/edo-chapter.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import SearchField from "../SearchField/SearchField";

const useStyles = makeStyles()(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
}));

type SelectCapitoliProps = {
  selectedChapters: IEdoChapter[];
  setSelectedChapters: (chapters: IEdoChapter[]) => void;
  multiple: true;
} | {
  selectedChapter: IEdoChapter;
  setSelectedChapter: (chapter: IEdoChapter) => void;
  multiple: false;
}

export const SelectCapitoli = (props: SelectCapitoliProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const {
    entities: edoChapterList,
    updateSuccess,
    loading,
    totalItems,
  } = useAppSelector<EntityState<IEdoChapter>>((state) => state.edoChapter);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "id",
    order: "ASC",
  });
  const [searchValue, setSearchValue] = useState<string>("");

  const { multiple } = props;

  const handleGetEdoCapitoloList = () => {
    const params = new URLSearchParams();
    searchValue && params.append("search", searchValue);
    dispatch(
      getChaptersByUser({
        userId: account.id,
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: params.toString(),
      })
    );
  };

  useEffect(() => {
    handleGetEdoCapitoloList();
  }, [
    searchValue,
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
  ]);

  useEffect(() => {
    if (updateSuccess) {
      handleSyncList();
    }
  }, [updateSuccess]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const handleSyncList = () => {
    handleGetEdoCapitoloList();
  };

  const handleOnClick = (chapter: IEdoChapter) => {
    if (multiple === true) {
      props.setSelectedChapters([...props.selectedChapters, chapter]);
      if (
        props.selectedChapters.findIndex((el) => el.id === chapter.id) ===
        -1
      ) {
        props.setSelectedChapters([...props.selectedChapters, chapter]);
      } else {
        const selectedChaptersLocal = [...props.selectedChapters];
        selectedChaptersLocal.splice(
          props.selectedChapters.findIndex((el) => el.id === chapter.id),
          1
        );
        props.setSelectedChapters(selectedChaptersLocal);
      }
    } else {
      if (!props.selectedChapter) {
        props.setSelectedChapter(chapter);
      } else if (props.selectedChapter?.id === chapter?.id) {
        props.setSelectedChapter(null);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" flexGrow={1}>
            Capitoli
          </Typography>
          <Box>
            <SearchField
              placeholder="Cerca un capitolo"
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Box>
        {loading && (
          <LinearProgress variant="indeterminate" color="secondary" />
        )}
        {edoChapterList.length ? (
          <List dense>
            {edoChapterList.map((chapter) => (
              <ListItem
                key={chapter.id}
                //   disableGutters
                button
                onClick={() => handleOnClick(chapter)}
                selected={
                  multiple === true ? (props.selectedChapters.findIndex((el) => el.id === chapter.id) !== -1) : props.selectedChapter?.id === chapter?.id
                }
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      multiple === true ? (props.selectedChapters?.findIndex(
                        (el) => el.id === chapter.id
                      ) !== -1) : props.selectedChapter?.id === chapter?.id
                    }
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={chapter.title}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  secondary={
                    <TextFormat
                      value={chapter.createdAt}
                      type="date"
                      format={APP_LOCAL_DATE_FORMAT}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box p={2}>
            <Typography variant="body1" align="center">
              <Translate contentKey="edocendoApp.edoChapter.home.notFound">
                No Edo Chapters found
              </Translate>
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <TablePagination
        component="div"
        count={totalItems}
        rowsPerPage={paginationState.itemsPerPage}
        page={paginationState.activePage - 1}
        onPageChange={(event, page) => handlePagination(page + 1)}
        onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
        labelRowsPerPage="Righe per pagina:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
        }
      />
    </Paper>
  );
};
