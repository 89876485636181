import { List } from '@mui/material';
import { IEdoChatParticipation } from 'shared/model/edo-chat-participation.model';
import { convertDateTimeToServer } from 'shared/util/date-utils';
import ChatParticipation from './ChatParticipation';

interface ChatListProps {
    participations: readonly IEdoChatParticipation[]
    onSelectParticipation: (participation: IEdoChatParticipation) => void;
}

const ChatList = (props: ChatListProps) => {

    const { participations, onSelectParticipation: handleSelectParticipation } = props;

    return (
        <List sx={{ maxHeight: '100%', overflow: 'auto' }}>
            {Array.from(participations)
                .sort(
                    (a, b) =>
                        convertDateTimeToServer(
                            b.chat.lastActivity
                        )?.getTime() -
                        convertDateTimeToServer(a.chat.lastActivity)?.getTime()
                )
                .map(participation => (
                    <ChatParticipation
                        key={participation.id}
                        participation={participation}
                        onOpen={handleSelectParticipation}
                    />
                )
                )}
        </List>
    );
};

export default ChatList;