import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Error as ErrorIcon, LibraryAdd as LibraryAddIcon, MoreVert as MoreVertIcon, Movie as MovieIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon, ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  TablePagination,
  Tabs, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { SyntheticEvent, useEffect, useState } from "react";
import { IPaginationBaseState, Translate, translate } from "react-jhipster";
import {
  SelectValidator,
  ValidatorForm
} from "react-material-ui-form-validator";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoCourseYear } from "shared/model/edo-course-year.model";
import { IEdoVideostorePost } from "shared/model/edo-videostore-post.model";
import { EdoEducationalStage } from "shared/model/enumerations/edo-educational-stage.model";
import { IEdoVideoStatusEnum } from "shared/model/enumerations/edo-video-status.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EntityState } from "shared/reducers/reducer.utils";
import { DeleteDialogType, EditDialogType, ViewDialogType } from "shared/util/dialog-utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import { getEntities as getCourseYears } from "../../shared/reducers/entities/edo-course-year.reducer";
import { SubjectState, getSubjectsWithVideostorePosts } from "../../shared/reducers/entities/edo-subject.reducer";
import { getEntities as getVideostorePosts } from "../../shared/reducers/entities/edo-videostore-post.reducer";
import DeleteVideostorePost from "./components/DeleteVideostorePost";
import EditVideostorePost from "./components/EditVideostorePost";
import MultiUpload from "./components/MultiUpload";
import VideostoreDrafts from "./components/VideostoreDrafts";
import ViewVideoDialog from "./components/ViewVideoDialog";

const useStyles = makeStyles()(theme =>
({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
})
);

export const getVideoStatusLabel = (status?: IEdoVideoStatusEnum) => {
  if (status === IEdoVideoStatusEnum.UPLOADING) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <CircularProgress size={15} />
        <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>Caricamento in corso...</Typography>
      </Box>
    );
  } else if (status === IEdoVideoStatusEnum.UPLOADED) {
    return "Il video è stato caricato correttamente. È in attesa di elaborazione.";
  } else if (status === IEdoVideoStatusEnum.PROCESSING) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <CircularProgress size={15} />
        <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>Stiamo processando il tuo video...</Typography>
      </Box>
    );
  } else if (status === IEdoVideoStatusEnum.ERROR) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <ErrorIcon sx={{ fontSize: 17 }} />
        <Typography variant="body2" color="error" sx={{ ml: 1 }}>Si è verificato un errore.</Typography>
      </Box>
    );
  } else if (status === IEdoVideoStatusEnum.READY) {
    return "Il tuo video è pronto.";
  } else {
    return "Stato sconosciuto";
  }
};

// const educationalStages = [EdoEducationalStage.INFANZIA, EdoEducationalStage.PRIMARIA, EdoEducationalStage.SECONDARIA_I, EdoEducationalStage.SECONDARIA_II];
const educationalStages = [EdoEducationalStage.SECONDARIA_II, EdoEducationalStage.SECONDARIA_I];

export const Videostore = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { account } = useAppSelector<AuthenticationState>(state => state.authentication);
  const edoVideostorePostList = useAppSelector<EntityState<IEdoVideostorePost>>((state) => state.edoVideostorePost);
  const edoCourseYearList = useAppSelector<EntityState<IEdoCourseYear>>((state) => state.edoCourseYear);
  const { subjectsByVideostorePosts: edoSubjectList } = useAppSelector<SubjectState>((state) => state.edoSubject);
  const [stageTab, setStageTab] = useState<EdoEducationalStage>(EdoEducationalStage.SECONDARIA_II);
  const [subjectFilter, setSubjectFilter] = useState<number | null>(null);
  const [editVideostorePostDialog, setEditVideostorePostDialog] = useState<EditDialogType<IEdoVideostorePost>>({ open: false, entity: null, isNew: false });
  const [deleteVideostorePostDialog, setDeleteVideostorePostDialog] = useState<DeleteDialogType<IEdoVideostorePost>>({ open: false, entity: null });
  const [viewVideoDialog, setViewVideoDialog] = useState<ViewDialogType<IEdoVideostorePost>>({ open: false, entity: null });
  const [multiUploadDialog, setMultiUploadDialog] = useState<{ open: boolean; }>({ open: false });
  const [anchorMoreMenu, setAnchorMoreMenu] =
    useState<null | HTMLElement>(null);
  const [yearTab, setYearTab] = useState<number>(null);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "title",
    order: "ASC",
  });

  const handleChangeStageTab = (event: SyntheticEvent<Element, Event>, newValue: EdoEducationalStage) => {
    setStageTab(newValue);
  };

  const handleChangeYearTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setYearTab(newValue);
  };

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const getVideostorePostList = () => {
    if (subjectFilter === null) return;

    const params = new URLSearchParams();
    params.append("filter.educationalStage", '$eq:' + stageTab);
    params.append("filter.years.id", '$eq:' + yearTab);
    params.append("filter.draft", '$eq:0');
    params.append("filter.subject.id", '$eq:' + String(subjectFilter));

    dispatch(
      getVideostorePosts({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: params.toString(),
      })
    );
  };

  const handleMoreMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorMoreMenu(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorMoreMenu(null);
  };

  useEffect(() => {
    dispatch(getCourseYears({ page: 0, size: 1000, sort: "year:ASC" }));
    // dispatch(getSubjects({ page: 0, size: 1000, sort: "name:ASC" }));
    dispatch(getSubjectsWithVideostorePosts());
  }, []);

  useEffect(() => {
    getVideostorePostList();
  }, [
    stageTab,
    yearTab,
    subjectFilter,
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage
  ]);

  useEffect(() => {
    if (edoSubjectList.entities.length) {
      setSubjectFilter(edoSubjectList.entities[0].id || null);
    }
  }, [edoSubjectList.entities]);

  useEffect(() => {
    if (edoCourseYearList.entities.length) {
      setYearTab(edoCourseYearList.entities[0].id || null);
    }
  }, [edoCourseYearList.entities]);

  useEffect(() => {
    if (edoVideostorePostList.updateSuccess) {
      getVideostorePostList();
    }
  }, [edoVideostorePostList.updateSuccess]);

  const moreMenu = () => (
    <Menu
      id="more-menu"
      anchorEl={anchorMoreMenu}
      keepMounted
      open={Boolean(anchorMoreMenu)}
      onClose={handleCloseMoreMenu}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={() => {
        handleCloseMoreMenu();
        setEditVideostorePostDialog({ open: true, entity: null, isNew: true });
      }}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">
          Caricamento singolo
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        handleCloseMoreMenu();
        setMultiUploadDialog({ open: true });
      }}>
        <ListItemIcon>
          <LibraryAddIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">
          Caricamento multiplo
        </Typography>
      </MenuItem>
    </Menu>
  );


  return (
    <>
      <Grid item xs={12}>
        <VideostoreDrafts
          onEdit={(post) => setEditVideostorePostDialog({ open: true, entity: post, isNew: false })}
          onDelete={(post) => setDeleteVideostorePostDialog({ open: true, entity: post })}
        />
        <Paper style={{ overflow: "hidden" }}>
          <Grid container spacing={2} p={2}>
            <Grid item sm={true} xs={12} style={{ alignSelf: "center" }}>
              <Typography variant="h6">Videoteca</Typography>
            </Grid>
            <Grid item sm="auto" xs={hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_ADMIN]) ? 10 : 12}>
              <ValidatorForm
                onSubmit={() => false}
                onError={(errors) => false}
                className={classes.root}
              >
                <SelectValidator
                  sx={{ minWidth: 200 }}
                  fullWidth
                  label="Filtro materia"
                  name="subject"
                  size="small"
                  variant="outlined"
                  value={subjectFilter || "NESSUNO"}
                  onChange={(event, index, newValue) =>
                    (event.target as HTMLInputElement).value === "NESSUNO"
                      ? setSubjectFilter(null)
                      : setSubjectFilter(parseInt((event.target as HTMLInputElement).value, 10))
                  }
                >
                  <MenuItem value="NESSUNO" key="NESSUNO" disabled>
                    Nessuno
                  </MenuItem>
                  {edoSubjectList.entities.map((subject) => (
                    <MenuItem value={subject.id} key={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </SelectValidator>

              </ValidatorForm>
            </Grid>
            {hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_ADMIN]) ? (
              <Grid item sm="auto" xs={2}>
                <IconButton
                  color="inherit"
                  onClick={handleMoreMenuButtonClick}
                  disabled={edoVideostorePostList.loading}
                // edge="end"
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
          <Divider />
          <Tabs
            value={stageTab}
            onChange={handleChangeStageTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {educationalStages.map((educationalStage) => (
              <Tab
                key={educationalStage}
                value={educationalStage}
                wrapped={false}
                label={translate(`edocendoApp.EdoEducationalStage.${educationalStage}`)}
              />
            ))}
          </Tabs>
          <Divider />
          <Tabs
            value={yearTab}
            onChange={handleChangeYearTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {edoCourseYearList.entities.map((courseYear) => (
              <Tab
                key={courseYear.id}
                value={courseYear.id}
                wrapped={false}
                label={courseYear.year + " " + translate("edocendoApp.edoVideostorePost.year")}
                disabled={[4, 5].includes(courseYear.year) && stageTab === EdoEducationalStage.SECONDARIA_I}
              />
            ))}
          </Tabs>
          {edoCourseYearList.loading && edoCourseYearList.entities.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <List
                className={classes.root}
              >
                {edoVideostorePostList.entities &&
                  edoVideostorePostList.entities.length ? (
                  edoVideostorePostList.entities
                    .map((postVideostore: IEdoVideostorePost) => (
                      <ListItem
                        key={postVideostore.id}
                        disableGutters
                        disablePadding
                        secondaryAction={
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_ADMIN]) ? (
                              <>
                                <Box mr={2}>
                                  <IconButton
                                    edge="end"
                                    onClick={() => setEditVideostorePostDialog({ open: true, entity: postVideostore, isNew: false })}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                                <Box mr={2}>
                                  <IconButton
                                    onClick={() => setDeleteVideostorePostDialog({ open: true, entity: postVideostore })}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </>
                            ) : null}
                          </Box>
                        }
                      >
                        <ListItemButton onClick={() => setViewVideoDialog({ open: true, entity: postVideostore })}>
                          <ListItemIcon>
                            <Avatar>
                              <MovieIcon />
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={postVideostore.title}
                            secondary={postVideostore.subject.name}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))
                ) : (
                  <ListItem>
                    <ListItemText>
                      <Typography variant="body2">
                        <Translate contentKey="edocendoApp.edoVideostorePost.home.notFound" />
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )}
              </List>
            </Box>
          )}
          <Divider />
          <TablePagination
            component="div"
            count={edoVideostorePostList.totalItems}
            rowsPerPage={paginationState.itemsPerPage}
            page={paginationState.activePage - 1}
            onPageChange={(event, page) => handlePagination(page + 1)}
            onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
            labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
            }
          />
        </Paper>
      </Grid>

      <EditVideostorePost
        videostorePost={editVideostorePostDialog.entity}
        open={editVideostorePostDialog.open}
        onClose={() => setEditVideostorePostDialog(prevState => ({ open: false, entity: null, isNew: prevState.isNew }))}
        isNew={editVideostorePostDialog.isNew}
      />

      <DeleteVideostorePost
        videostorePost={deleteVideostorePostDialog.entity}
        open={deleteVideostorePostDialog.open}
        onClose={() => setDeleteVideostorePostDialog({ open: false, entity: null })}
      />

      <ViewVideoDialog
        videostorePost={viewVideoDialog.entity}
        open={viewVideoDialog.open}
        onClose={() => setViewVideoDialog({ open: false, entity: null })}
      />

      <MultiUpload
        open={multiUploadDialog.open}
        onClose={() => setMultiUploadDialog({ open: false })}
      />

      {moreMenu()}
    </>
  );
};

export default Videostore;
