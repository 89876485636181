import moment from "moment";
import { IEdoFile } from "shared/model/edo-file.model";
import { EdoCircularVisibility } from "shared/model/enumerations/edo-circular-visibility.model";
export interface IEdoCircular {
  id?: number;
  title?: string;
  description?: string | null;
  date?: string;
  createdAt?: string;
  visibility?: EdoCircularVisibility;
  files?: IEdoFile[] | null;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoCircular> = {
  date: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
  visibility: EdoCircularVisibility.ALL,
};
