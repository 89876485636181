import {
    CollectionsBookmark as CollectionsBookmarkIcon, Delete as DeleteIcon,
    Edit as EditIcon,
    Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@mui/material";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import { TextFormat } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";

interface ChapterRowProps {
    chapter?: IEdoChapter;
    viewChapter: (chapter: IEdoChapter) => void;
    editChapter: (chapter: IEdoChapter) => void;
    deleteChapter: (chapter: IEdoChapter) => void;
    nested: boolean;
}

export const ChapterRow = (props: ChapterRowProps) => {
    const { chapter, viewChapter, editChapter, deleteChapter, nested } = props;

    return (
        <ListItem sx={(theme) => ({
            ...(nested ? {
                paddingLeft: 4,
                [theme.breakpoints.down("sm")]: {
                    paddingLeft: 2,
                },
            } : null)
        })}>
            <ListItemAvatar>
                <Avatar>
                    <CollectionsBookmarkIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={chapter.title}
                primaryTypographyProps={{ variant: "body2" }}
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                secondary={
                    <TextFormat
                        value={chapter.createdAt}
                        type="date"
                        format={APP_LOCAL_DATE_FORMAT}
                    />
                }
            />
            <ListItemSecondaryAction>
                <IconButton
                    onClick={() => viewChapter(chapter)}
                    edge="end"
                    aria-label="View chapter"
                    sx={{ marginRight: 0.5 }}
                >
                    <VisibilityIcon />
                </IconButton>
                {editChapter !== null ? (
                    <IconButton
                        onClick={() => editChapter(chapter)}
                        edge="end"
                        aria-label="Edit chapter"
                        sx={{ marginRight: 0.5 }}
                    >
                        <EditIcon />
                    </IconButton>
                ) : null}
                {deleteChapter !== null ? (
                    <IconButton
                        onClick={() => deleteChapter(chapter)}
                        edge="end"
                        aria-label="Delete chapter"
                    >
                        <DeleteIcon />
                    </IconButton>
                ) : null}
            </ListItemSecondaryAction>
        </ListItem>
    );
};
