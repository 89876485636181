import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  TextFieldProps,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useAppDispatch, useAppSelector } from "config/store";
import moment, { Moment } from "moment";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  IEdoCourseEvent,
  defaultValue
} from "shared/model/edo-course-event.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import {
  createEntity,
  updateEntity
} from "shared/reducers/entities/edo-course-event.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { convertDateTimeToServer } from "shared/util/date-utils";

interface IEditCourseEventProps {
  open: boolean;
  courseEvent?: IEdoCourseEvent;
  course?: IEdoCourse;
  onClose: () => void;
}

const EditCourseEvent = (props: IEditCourseEventProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [form, setForm] = useState<IEdoCourseEvent>({ ...defaultValue, ...props.courseEvent });
  const { loading, updating, updateSuccess } = useAppSelector<EntityState<IEdoCourseEvent>>((state) => state.edoCourseEvent);

  const isNew = props.courseEvent === null || props.courseEvent === undefined;
  const tzoffset = new Date().getTimezoneOffset() * 60000;

  const { open, courseEvent, course, onClose: handleClose } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  useEffect(() => {
    setForm({ ...defaultValue, ...courseEvent });
  }, [courseEvent]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = () => {
    if (!courseEvent || !courseEvent.id) {
      dispatch(
        createEntity({
          ...form,
          startDate: convertDateTimeToServer(form.startDate),
          endDate: convertDateTimeToServer(form.endDate),
          course,
        } as any)
      );
    } else {
      dispatch(
        updateEntity({
          ...form,
          startDate: convertDateTimeToServer(form.startDate),
          endDate: convertDateTimeToServer(form.endDate),
        } as any)
      );
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
      <DialogTitle>
        {isNew ? translate("edocendoApp.edoCourseEvent.home.createLabel") : translate("edocendoApp.edoCourseEvent.home.editLabel")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>Aggiungi le informazioni dell'evento.</DialogContentText>
        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        ) : (
          <ValidatorForm
            onSubmit={saveEntity}
            onError={(errors) => false}
            sx={{ flexGrow: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label={translate("edocendoApp.edoCourseEvent.title")}
                  onChange={handleChange}
                  name="title"
                  value={form.title || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  label={translate(
                    "edocendoApp.edoCourseEvent.description"
                  )}
                  onChange={handleChange}
                  name="description"
                  value={form.description || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                  <MobileDateTimePicker
                    // cancelLabel="Annulla"
                    renderInput={(inputProps: TextFieldProps) => (
                      <TextField
                        {...inputProps}
                        name="startDate"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    )}
                    inputFormat="DD/MM/YYYY HH:mm"
                    label={translate(
                      "edocendoApp.edoCourseEvent.startDate"
                    )}
                    value={form.startDate}
                    onChange={(date: Moment) => {
                      return setForm({
                        ...form,
                        startDate: date
                          .subtract(tzoffset)
                          .toISOString()
                          .slice(0, -1),
                      });
                    }}
                    disablePast
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                  <MobileDateTimePicker
                    // cancelLabel="Annulla"
                    renderInput={(inputProps: TextFieldProps) => (
                      <TextField
                        {...inputProps}
                        name="endDate"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    )}
                    inputFormat="DD/MM/YYYY HH:mm"
                    label={translate(
                      "edocendoApp.edoCourseEvent.endDate"
                    )}
                    value={form.endDate}
                    onChange={(date: Moment) => {
                      return setForm({
                        ...form,
                        endDate: date
                          .subtract(tzoffset)
                          .toISOString()
                          .slice(0, -1),
                      });
                    }}
                    disablePast
                    minDateTime={form.startDate ? moment(form.startDate) : undefined}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </ValidatorForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <LoadingButton
          disabled={!form.title || !form.description || !form.startDate || !form.endDate}
          onClick={saveEntity}
          // variant="contained"
          color="primary"
          // endIcon={<SendIcon />}
          loading={updating}
        >
          {isNew ? translate("entity.action.add") : translate("entity.action.edit")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditCourseEvent;