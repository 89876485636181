
import {
    Cancel as CancelIcon, Check as CheckIcon, Close as CloseIcon
} from "@mui/icons-material";
import {
    AppBar, Box, Dialog, DialogContent, DialogContentText, IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useEffect, useState } from 'react';
import { getFileAvatar } from "shared/util/file-avatar-utils";

export interface UploadFilesProps {
    filesToUpload: File[];
    setFilesToUpload: (files: File[]) => void;
    handleUpload: () => void;
    progress: number;
    uploading: boolean;
}

export const UploadFiles = (props: UploadFilesProps) => {
    const theme = useTheme();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState<boolean>(props.filesToUpload.length > 0);

    const { filesToUpload, handleUpload, setFilesToUpload, progress, uploading } = props;

    const handleCloseDialog = () => {
        setFilesToUpload([]);
    };

    const removeFile = (index: number) => {
        const local = [...filesToUpload];
        local.splice(index, 1);
        setFilesToUpload(local);
    };

    useEffect(() => {
        setOpen(filesToUpload.length > 0);
    }, [filesToUpload]);

    return (
        <Dialog open={open} onClose={handleCloseDialog} fullScreen={breakpointDownSm} fullWidth>
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" flexGrow={1}>
                        File da caricare
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        disabled={uploading}
                        sx={{ marginRight: 1 }}
                        onClick={() => handleCloseDialog()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <IconButton
                        edge="end"
                        color="inherit"
                        disabled={uploading}
                        onClick={handleUpload}
                    >
                        <CheckIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {uploading ? <Box sx={{ width: '100%' }}>
                {progress === 100 ? <LinearProgress /> : <LinearProgress variant="determinate" value={progress} />}
            </Box> : null}
            <DialogContent
                style={{ backgroundColor: theme.palette.background.default }}
            >
                {filesToUpload !== null && filesToUpload.length ? (
                    <>
                        <List
                            disablePadding
                            dense
                        >
                            {filesToUpload.map((file, index) => (
                                <ListItem key={index} disableGutters>
                                    <ListItemAvatar>
                                        {getFileAvatar(file.type)}
                                    </ListItemAvatar>
                                    <ListItemText primary={file.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            onClick={() => removeFile(index)}
                                            edge="end"
                                            aria-label="Rimuovi file"
                                            disabled={uploading}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </>
                ) : <DialogContentText>Nessun file da caricare.</DialogContentText>}
            </DialogContent>
        </Dialog>
    );
};

export default UploadFiles;