import { createContext } from "react";
// import { SOCKET_URL } from "config";
import { io } from "socket.io-client";

export const socket = io(`${window.location.origin}`, {
  withCredentials: true,
  reconnection: true,
  path: "/api/socket.io/",
});

export const SocketContext = createContext(socket);
