import { Avatar, Grid, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { APP_DATE_FORMAT_EXPANDED } from "config/constants";
import React from "react";
import { TextFormat } from "react-jhipster";
import { IEdoChatMessage } from "shared/model/edo-chat-message.model";

export interface OtherMessageProps {
  messaggio: IEdoChatMessage;
}

export const OtherMessage = (props: OtherMessageProps) => {
  const { messaggio } = props;

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <Avatar alt={messaggio.user.firstName[0]  + messaggio.user.lastName[0]}>
          {messaggio.user.firstName[0] + messaggio.user.lastName[0]}
        </Avatar>
      </ListItemIcon>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2" color="textPrimary">
            {messaggio.user.firstName + " " + messaggio.user.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ListItemText
            primaryTypographyProps={{ align: "left" }}
            primary={messaggio.message}
          ></ListItemText>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            <TextFormat
              type="date"
              value={messaggio.createdAt}
              format={APP_DATE_FORMAT_EXPANDED}
              locale="it"
            />
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};
