import {
    Chip,
    ListItem, ListItemButton, ListItemText
} from "@mui/material";
import axios from "axios";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import { useEffect, useState } from "react";
import { TextFormat } from "react-jhipster";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { getQuizAttemptStatus } from "shared/util/quiz-utils";

interface AttemptRowProps {
    quizAttempt: IEdoQuizAttempt;
    onOpen?: (quizAttempt: IEdoQuizAttempt) => void;
}

const AttemptRow = (props: AttemptRowProps) => {
    const [score, setScore] = useState<{ score: number, totalScore: number }>({ score: 0, totalScore: 0 });

    const { quizAttempt, onOpen: handleOpen } = props;

    const getQuizAttemptScore = async () => {
        const response = await axios.get<{ data: { score: number, totalScore: number } }>(`/quiz-attempts/${quizAttempt?.id}/score`);
        setScore(response.data.data);
    };

    useEffect(() => {
        if (quizAttempt?.id) {
            getQuizAttemptScore();
        }
    }, [quizAttempt?.id]);

    const itemContent = (
        <ListItemText
            primary={<span>
                {`Consegnato il `}
                <TextFormat
                    value={quizAttempt.timeFinish}
                    type="date"
                    format={APP_LOCAL_DATE_FORMAT}
                />
                <Chip component="span" sx={{ marginLeft: 1}} size="small" label={`${quizAttempt.courseQuiz.course.subject.name} (${quizAttempt.courseQuiz.course.class.year.year}°${quizAttempt.courseQuiz.course.class.section})`} />
            </span>}
            primaryTypographyProps={{ variant: "body2" }}
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            secondary={getQuizAttemptStatus(quizAttempt, score.score, score.totalScore)}
        />
    );

    if (handleOpen) {
        return (
            <ListItem disableGutters disablePadding>
                <ListItemButton onClick={() => handleOpen(quizAttempt)} sx={{ paddingLeft: 4 }} disableGutters>
                    {itemContent}
                </ListItemButton>
            </ListItem>
        );
    }

    return (
        <ListItem sx={{ paddingLeft: 4 }}>
            {itemContent}
        </ListItem>
    );
};

export default AttemptRow;