import {
  Add as AddIcon,
  GetApp as GetAppIcon,
  Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Checkbox,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TablePagination, Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import React, { createRef, useEffect, useState } from "react";
import { IPaginationBaseState, TextFormat, Translate } from "react-jhipster";
import { toast } from "react-toastify";
import { IEdoFile } from "shared/model/edo-file.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getCurrentUserFiles } from "shared/reducers/entities/edo-file.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import SearchField from "../SearchField/SearchField";

const useStyles = makeStyles()(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
}));

interface SelectFilesProps {
  selectedFiles: IEdoFile[];
  setSelectedFiles: (files: IEdoFile[]) => void;
  enableUpload: boolean;
}

export const SelectFiles = (props: SelectFilesProps) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const fileInput = createRef<HTMLInputElement>();
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const {
    entities: edoFileList,
    updateSuccess,
    loading,
    totalItems,
  } = useAppSelector<EntityState<IEdoFile>>((state) => state.edoFile);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "id",
    order: "ASC",
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);

  const { selectedFiles, setSelectedFiles, enableUpload } = props;

  const handleGetEdoFileList = () => {
    const params = new URLSearchParams();
    searchValue && params.append("search", searchValue);
    params.append("filter.repository", "$eq:1");
    
    dispatch(
      getCurrentUserFiles({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: params.toString(),
      })
    );
  };

  useEffect(() => {
    handleGetEdoFileList();
  }, [
    searchValue,
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
  ]);

  useEffect(() => {
    if (updateSuccess) {
      handleSyncList();
    }
  }, [updateSuccess]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const handleSyncList = () => {
    handleGetEdoFileList();
  };

  const selectFiles = () => {
    fileInput.current.click();
  };

  const handleFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);

    if (files.length) {
      setUploadingFiles(true);
      const loadingToast = toast.loading("Upload del file in corso...");

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name);
      }

      try {
        const response = await axios.post<{ data: IEdoFile[] }>("/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setSelectedFiles([...selectedFiles, ...response.data.data]);
        toast.update(loadingToast, { type: "success", isLoading: false, render: "Upload completato.", autoClose: 5000 });
        handleGetEdoFileList();
      } catch (e) {
        toast.update(loadingToast, { type: "error", isLoading: false, render: "L'upload non è andato a buon fine.", autoClose: 5000 });
      }
      setUploadingFiles(false);
    }
  };

  const openFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  const downloadFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/download`, "_blank");
  };

  return (
    <Paper className={classes.paper}>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              display: 'none'
            },
            flexGrow: 1,
          })}>
            Documenti
          </Typography>
          <Box mr={breakpointDownSm ? 1 : 2}>
            <SearchField
              placeholder="Cerca un file"
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </Box>
          {enableUpload && (
            <Box>
              <IconButton edge="end" color="inherit" onClick={selectFiles}>
                <AddIcon />
              </IconButton>
              <input
                type="file"
                ref={fileInput}
                name="files"
                multiple={true}
                hidden
                onChange={(event) => {
                  handleFiles(event);
                }}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box>
        {loading && (
          <LinearProgress variant="indeterminate" color="secondary" />
        )}
        {edoFileList.length ? (
          <List dense>
            {edoFileList.map((file) => (
              <ListItem
                key={file.id}
                //   disableGutters
                button
                onClick={() => {
                  setSelectedFiles([...selectedFiles, file]);
                  if (
                    selectedFiles.findIndex((el) => el.id === file.id) === -1
                  ) {
                    setSelectedFiles([...selectedFiles, file]);
                  } else {
                    const selectedFilesLocal = [...selectedFiles];
                    selectedFilesLocal.splice(
                      selectedFiles.findIndex((el) => el.id === file.id),
                      1
                    );
                    setSelectedFiles(selectedFilesLocal);
                  }
                }}
                selected={
                  selectedFiles.findIndex((el) => el.id === file.id) !== -1
                }
              >
                {/* <ListItemAvatar>
                  <Avatar>{file.fileType.split('/')[0] === 'image' ? <ImageIcon /> : <DescriptionIcon />}</Avatar>
                    </ListItemAvatar> */}
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      selectedFiles.findIndex((el) => el.id === file.id) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    onChange={(event, checked) => {
                      if (checked) {
                        setSelectedFiles([...selectedFiles, file]);
                      } else {
                        const selectedFilesLocal = [...selectedFiles];
                        selectedFilesLocal.splice(
                          selectedFiles.findIndex((el) => el.id === file.id),
                          1
                        );
                        setSelectedFiles(selectedFilesLocal);
                      }
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={file.originalFileName}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  secondary={
                    <TextFormat
                      value={file.createdAt}
                      type="date"
                      format={APP_LOCAL_DATE_FORMAT}
                    />
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => openFile(file)}
                    edge="end"
                    aria-label="Open file"
                    sx={{ marginRight: 1 }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => downloadFile(file)}
                    edge="end"
                    aria-label="Download file"
                  >
                    <GetAppIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box p={2}>
            <Typography variant="body1" align="center">
              <Translate contentKey="edocendoApp.edoFile.home.notFound">
                No Edo Files found
              </Translate>
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <TablePagination
        component="div"
        count={totalItems}
        rowsPerPage={paginationState.itemsPerPage}
        page={paginationState.activePage - 1}
        onPageChange={(event, page) => handlePagination(page + 1)}
        onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
        labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
        }
      />
    </Paper>
  );
};
