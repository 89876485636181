import { useAppSelector } from "config/store";
import { IEdoCoursePost } from "shared/model/edo-course-post.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { EditPost } from "./EditPost";

interface IEditCoursePostProps {
    open: boolean;
    post: IEdoCoursePost;
    onClose: () => void;
    updatePost: (post: IEdoCoursePost) => void;
}

const EditCoursePost = (props: IEditCoursePostProps) => {
    const {  loading, updating, updateSuccess } = useAppSelector<EntityState<IEdoCoursePost>>((state) => state.edoCoursePost);
    
    const { open, post, updatePost, onClose: handleClose } = props;

    return (
        <EditPost
            post={post}
            onClose={handleClose}
            open={open}
            loading={loading}
            updating={updating}
            updateSuccess={updateSuccess}
            updatePost={updatePost}
        />
    );
};

export default EditCoursePost;