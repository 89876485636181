import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar, Checkbox, FormControlLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Navigate } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IUser } from "shared/model/user.model";
import { AuthenticationState, clearAccounts, getAccounts, login } from "shared/reducers/authentication";
import { makeStyles } from 'tss-react/mui';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.alest.it/edocendo-2/">
        eDocendo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/class.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(15),
    height: theme.spacing(15),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    margin: theme.spacing(0, 0, 2),
  },
}));

export const Login = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { isAuthenticated, loginError, accounts, account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const handlegetAccounts = () => {
    dispatch(getAccounts({ username, password, rememberMe }));
  };

  const handleLogin = (id: number) => {
    dispatch(login(id, password, rememberMe));
  };

  const getProfileRole = (user: IUser) => {
    if (user.roles.findIndex(role => role.name === AUTHORITIES.ROLE_ADMIN) !== -1) {
      return "Amministratore";
    } else if (user.roles.findIndex(role => role.name === AUTHORITIES.ROLE_VICE) !== -1) {
      return "Vicepreside";
    } else if (user.roles.findIndex(role => role.name === AUTHORITIES.ROLE_TEACHER) !== -1) {
      return "Docente";
    } else if (user.roles.findIndex(role => role.name === AUTHORITIES.ROLE_STUDENT) !== -1) {
      return "Studente";
    } else if (user.roles.findIndex(role => role.name === AUTHORITIES.ROLE_PARENT) !== -1) {
      return "Genitore";
    } else {
      return "Utente";
    }
  };

  const getProfilePrimary = (user: IUser) => {
    const profileRole = getProfileRole(user);

    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName} (${profileRole})`;
    } else if (user.firstName) {
      return `${user.firstName} (${profileRole})`;
    } else if (user.lastName) {
      return `${user.lastName} (${profileRole})`;
    } else {
      return profileRole;
    }
  };

  useEffect(() => {
    dispatch(clearAccounts());
  }, []);

  if (isAuthenticated && account) {
    if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_PARENT])) {
      return <Navigate to="/parents" />;
    } else if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_VICE, AUTHORITIES.ROLE_TEACHER])) {
      return <Navigate to="/feed" />;
    }
    return <Navigate to="/" />;
  }

  if (accounts?.users?.length > 0 && username && password) {
    return (
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box className={classes.paper}>
            <Avatar
              className={classes.avatar}
              src="/package/logo.png"
            >
              {/* <LockOutlinedIcon /> */}
            </Avatar>
            <Typography component="h1" variant="h5">
              Seleziona un profilo
            </Typography>
            <Box width={{ xs: "100%", md: "70%" }}>
              <List>
                {accounts.users.map((user) => (
                  <ListItem key={user.id}>
                    <ListItemButton onClick={() => handleLogin(user.id)}>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountCircleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={getProfilePrimary(user)} secondary={user.email} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Box mt={1}>
                <Copyright />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box className={classes.paper}>
          <Avatar
            className={classes.avatar}
            src="/package/logo.png"
          >
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            <Translate contentKey="login.title">Sign in</Translate>
          </Typography>
          <Box width={{ xs: "100%", md: "70%" }}>
            <ValidatorForm onSubmit={handlegetAccounts} onError={(errors) => false}>
              <TextValidator
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                label={translate("global.form.username.label")}
                onChange={(event) => setUsername((event.target as HTMLInputElement).value)}
                name="username"
                autoComplete="username"
                autoFocus
                value={username || ""}
                validators={["required"]}
                errorMessages={["This field is required."]}
              />
              <TextValidator
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                label={translate("login.form.password")}
                onChange={(event) => setPassword((event.target as HTMLInputElement).value)}
                name="password"
                type="password"
                autoComplete="current-password"
                value={password || ""}
                validators={["required"]}
                errorMessages={["This field is required."]}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    onChange={(event) => setRememberMe(event.target.checked)}
                    checked={rememberMe}
                  />
                }
                label={
                  <Translate contentKey="login.form.rememberme">
                    Remember me
                  </Translate>
                }
              />
              <LoadingButton
                type="submit"
                disabled={
                  !username || !password
                }
                size="medium"
                variant="contained"
                color="primary"
                fullWidth
                loading={accounts.loading}
                className={classes.submit}
              >
                <Translate contentKey="login.form.button">Sign in</Translate>
              </LoadingButton>
              <Grid container spacing={3}>
                <Grid item>
                  <Link href="/account/reset/request" variant="body2">
                    <Translate contentKey="login.password.forgot">
                      Did you forget your password?
                    </Translate>
                  </Link>
                </Grid>
                {/* <Grid item>
                <Link href="/account/register" variant="body2">
                  <Translate contentKey="global.messages.info.register.noaccount">You don&apos;t have an account yet?</Translate>{' '}
                  <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
                </Link>
              </Grid> */}
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </ValidatorForm>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
