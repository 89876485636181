import { IEdoQuestionChoice } from "./edo-question-choice.model";
import { IEdoQuestion } from "./edo-question.model";
import { IEdoQuizAttempt } from "./edo-quiz-attempt.model";

export interface IEdoQuestionAnswer {
  id?: number;
  description?: string;
  explanation?: string;
  choices?: IEdoQuestionChoice[];
  quizAttempt?: IEdoQuizAttempt;
  question?: IEdoQuestion;
  score?: number;
  lastReview?: string;
  updatedAt?: string;
  createdAt?: string;
}

export const defaultValue: Readonly<IEdoQuestionAnswer> = {
  choices: [],
};
