import moment from "moment";
import { IEdoCourse } from "shared/model/edo-course.model";
import { IUser } from "shared/model/user.model";
export interface IEdoCourseEvent {
  id?: number;
  title?: string;
  startDate?: string;
  endDate?: string | null;
  createdAt?: string;
  description?: string | null;
  course?: IEdoCourse;
  author?: IUser;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoCourseEvent> = {
  startDate: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
  endDate: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
  createdAt: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
};
