import {
    Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
    AppBar,
    Box,
    Checkbox,
    Divider,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    TablePagination, Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { IEdoQuestion } from "shared/model/edo-question.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getQuestionsByAccount } from "shared/reducers/entities/edo-question.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { getQuestionType } from "shared/util/question-utils";
import { makeStyles } from 'tss-react/mui';
import SearchField from "../SearchField/SearchField";

const useStyles = makeStyles()(theme => ({
    paper: {
        color: theme.palette.text.secondary,
        overflow: "hidden",
    }
}));

interface SelectQuestionsProps {
    disabledQuestions?: IEdoQuestion[];
    selectedQuestions: IEdoQuestion[];
    setSelectedQuestions: (questions: IEdoQuestion[]) => void;
}

const SelectQuestions = (props: SelectQuestionsProps) => {
    const theme = useTheme();
    const { classes } = useStyles();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useAppDispatch();
    const { account } = useAppSelector<AuthenticationState>(
        (state) => state.authentication
    );
    const {
        entities: edoQuestionList,
        updateSuccess,
        loading,
        totalItems,
    } = useAppSelector<EntityState<IEdoQuestion>>((state) => state.edoQuestion);
    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: "updatedAt",
        order: "DESC",
    });
    const [searchValue, setSearchValue] = useState<string>("");

    const { selectedQuestions, disabledQuestions, setSelectedQuestions } = props;

    const handleGetEdoQuestionList = () => {
        const params = new URLSearchParams();
        params.append("filter.author.id", '$eq:' + account.id);
        searchValue && params.append("search", searchValue);
        dispatch(
            getQuestionsByAccount({
                page: paginationState.activePage,
                size: paginationState.itemsPerPage,
                sort: `${paginationState.sort},${paginationState.order}`,
                filters: params.toString(),
            })
        );
    };

    useEffect(() => {
        handleGetEdoQuestionList();
    }, [
        searchValue,
        paginationState.activePage,
        paginationState.order,
        paginationState.sort,
        paginationState.itemsPerPage,
    ]);

    useEffect(() => {
        if (updateSuccess) {
            handleSyncList();
        }
    }, [updateSuccess]);

    const sort = (p: string) => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === "ASC" ? "DESC" : "ASC",
            sort: p,
        });
    };

    const handlePagination = (currentPage: number) =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const handleRowsPerPage = (rowsPerPage: number) =>
        setPaginationState({
            ...paginationState,
            itemsPerPage: rowsPerPage,
            activePage: 1,
        });

    const handleSyncList = () => {
        handleGetEdoQuestionList();
    };

    const viewQuestion = (question: IEdoQuestion) => {
        // const response = await axios.get(`/files/${file.id}/download`);
        // window.open(`/api/files/${file.id}/view`, "_blank");
    };

    return (
        <Paper className={classes.paper}>
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            display: 'none'
                        },
                        flexGrow: 1,
                    })}>
                        Domande
                    </Typography>
                    <Box mr={breakpointDownSm ? 1 : 2}>
                        <SearchField
                            placeholder="Cerca una domanda"
                            value={searchValue}
                            setSearchValue={setSearchValue}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box>
                {loading && (
                    <LinearProgress variant="indeterminate" color="secondary" />
                )}
                {edoQuestionList.length ? (
                    <List dense>
                        {edoQuestionList.map((question) => (
                            <ListItem
                                key={question.id}
                                //   disableGutters
                                disabled={disabledQuestions ? disabledQuestions?.findIndex((q) => q.id === question.id) !== -1 : false}
                                button
                                onClick={() => {
                                    setSelectedQuestions([...selectedQuestions, question]);
                                    if (
                                        selectedQuestions.findIndex((el) => el.id === question.id) === -1
                                    ) {
                                        setSelectedQuestions([...selectedQuestions, question]);
                                    } else {
                                        const selectedQuestionsLocal = [...selectedQuestions];
                                        selectedQuestionsLocal.splice(
                                            selectedQuestions.findIndex((el) => el.id === question.id),
                                            1
                                        );
                                        setSelectedQuestions(selectedQuestionsLocal);
                                    }
                                }}
                                selected={
                                    selectedQuestions.findIndex((el) => el.id === question.id) !== -1
                                }
                            >
                                {/* <ListItemAvatar>
                  <Avatar>{file.fileType.split('/')[0] === 'image' ? <ImageIcon /> : <DescriptionIcon />}</Avatar>
                    </ListItemAvatar> */}
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        disabled={disabledQuestions ? disabledQuestions?.findIndex((q) => q.id === question.id) !== -1 : false}
                                        checked={
                                            selectedQuestions.findIndex((el) => el.id === question.id) !== -1 || (disabledQuestions ? disabledQuestions?.findIndex((q) => q.id === question.id) !== -1 : false)
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={(event, checked) => {
                                            if (checked) {
                                                setSelectedQuestions([...selectedQuestions, question]);
                                            } else {
                                                const selectedQuestionsLocal = [...selectedQuestions];
                                                selectedQuestionsLocal.splice(
                                                    selectedQuestions.findIndex((el) => el.id === question.id),
                                                    1
                                                );
                                                setSelectedQuestions(selectedQuestionsLocal);
                                            }
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={question.description}
                                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                                    secondary={getQuestionType(question.type, true)}
                                    primaryTypographyProps={{ color: "textPrimary" }}
                                    secondaryTypographyProps={{ color: "text.secondary" }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => viewQuestion(question)}
                                        edge="end"
                                        aria-label="View question"
                                        sx={{ marginRight: 1 }}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box p={2}>
                        <Typography variant="body1" align="center">
                            <Translate contentKey="edocendoApp.edoQuestion.home.notFound" />
                        </Typography>
                    </Box>
                )}
            </Box>
            <Divider />
            <TablePagination
                component="div"
                count={totalItems}
                rowsPerPage={paginationState.itemsPerPage}
                page={paginationState.activePage - 1}
                onPageChange={(event, page) => handlePagination(page + 1)}
                onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
                labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
                }
            />
        </Paper>
    );
};

export default SelectQuestions;