import moment from "moment";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { IUser } from "shared/model/user.model";

export interface IEdoChapter {
  id?: number;
  title?: string;
  createdAt?: string;
  files?: IEdoFile[] | null;
  author?: IUser;
  units?: IEdoUnit[] | null;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoChapter> = {
  createdAt: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
};
