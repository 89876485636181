import { Add as AddIcon, Check as CheckIcon, Close, Close as CloseIcon } from '@mui/icons-material';
import {
    AppBar, Box, Button, Checkbox, CircularProgress,
    Dialog, DialogContent, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup, Select, TextField,
    Toolbar,
    Typography
} from "@mui/material";
import axios, { AxiosResponse } from 'axios';
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useMemo, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NumericFormat } from 'react-number-format';
import { IEdoQuestionChoice } from 'shared/model/edo-question-choice.model';
import { IEdoQuestion, defaultValue } from "shared/model/edo-question.model";
import { IEdoQuiz } from 'shared/model/edo-quiz.model';
import { EdoQuestionTypeEnum } from "shared/model/enumerations/edo-question-type.mode";
import {
    addQuestionToQuiz,
    createEntity, updateEntity, updateQuestionChoices
} from "shared/reducers/entities/edo-question.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

interface IEditQuestionProps {
    open: boolean;
    onClose: () => void;
    onSuccess?: (question: IEdoQuestion) => void;
    question?: IEdoQuestion;
    quiz?: IEdoQuiz;
    type?: EdoQuestionTypeEnum;
}

const EditQuestion = (props: IEditQuestionProps) => {
    const dispatch = useAppDispatch();
    const { loading, updating, errorMessage, updateSuccess, entity } = useAppSelector<
        EntityState<IEdoQuestion>
    >((state) => state.edoQuestion);

    const [form, setForm] = useState<IEdoQuestion>({ ...defaultValue, type: EdoQuestionTypeEnum.FREE_TEXT, ...props.question });
    const [questionChoices, setQuestionChoices] = useState<IEdoQuestionChoice[]>([]);

    const { question, open, onClose: handleClose, onSuccess: handleSuccess, type, quiz } = props;

    const isNew = form?.id === null || form?.id === undefined;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const change = {
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        };
        setForm(change);
    };

    useEffect(() => {
        if (question?.id) {
            setForm({ ...defaultValue, ...question });
            getAllChoices(question?.id);
        } else {
            setForm({ ...defaultValue });
            setQuestionChoices([]);
        }
    }, [question]);

    useEffect(() => {
        if (type) {
            setForm({ ...form, type });
        }
    }, [type]);

    useEffect(() => {
        if (open && updateSuccess) {
            if (entity) {
                if (!isNew) {
                    getAllChoices(form?.id);
                }
                setForm({ ...entity });
            }
        }
    }, [updateSuccess]);

    useEffect(() => {
        if (form.type === EdoQuestionTypeEnum.TRUE_FALSE) {
            setQuestionChoices([{
                id: null,
                description: `Vero`,
                isCorrect: true,
                score: 1,
            }, {
                id: null,
                description: `Falso`,
                isCorrect: false,
                score: 0,
            }]);
        } else if (form.type === EdoQuestionTypeEnum.FREE_TEXT) {
            setQuestionChoices([]);
        }
    }, [form.type]);

    const confirmSuccess = () => {
        if (isNew) {
            if (quiz) {
                dispatch(addQuestionToQuiz({ quizId: quiz.id, question: form })).then((response) => {
                    const payload = response.payload as AxiosResponse<{ data: IEdoQuestion }>;
                    dispatch(updateQuestionChoices({ question: payload.data.data, choices: questionChoices }));

                    handleClose();
                    handleSuccess(form);
                });
            } else {
                dispatch(createEntity({ ...form })).then((response) => {
                    const payload = response.payload as AxiosResponse<{ data: IEdoQuestion }>;
                    dispatch(updateQuestionChoices({ question: payload.data.data, choices: questionChoices }));

                    handleClose();
                    handleSuccess(form);
                });
            }
        } else {
            dispatch(updateEntity({ ...form })).then(() => {
                dispatch(updateQuestionChoices({ question: form, choices: questionChoices }));

                handleClose();
                handleSuccess(form);
            });
        }
    };


    const getAllChoices = async (id: number) => {
        const response = await axios.get<{ data: IEdoQuestionChoice[] }>(`/questions/${id}/choices`);
        setQuestionChoices(response.data.data);
    };

    const handleAddChoice = () => {
        if (!form.type || !form.description) {
            return;
        }
        if (form.type === EdoQuestionTypeEnum.TRUE_FALSE && questionChoices.length > 1) {
            return;
        }
        if (isNew) {
            if (quiz) {
                dispatch(addQuestionToQuiz({ quizId: quiz.id, question: form }));
            } else {
                dispatch(createEntity(form));
            }
        }
        let score = 0;
        if ([EdoQuestionTypeEnum.TRUE_FALSE, EdoQuestionTypeEnum.SINGLE_CHOICE].includes(form.type)) {
            score = 0;
        } else {
            score = 1 / (questionChoices.length + 1);
        }

        const choice: IEdoQuestionChoice = {
            id: null,
            description: `Opzione ${questionChoices.length + 1}`,
            isCorrect: false,
            score,
        };
        setQuestionChoices([...questionChoices, choice]);
    };

    const handleEditChoice = (index: number, choice: IEdoQuestionChoice) => {
        const questionChoicesCopy = Array.from(questionChoices);
        questionChoicesCopy[index] = choice;
        setQuestionChoices(questionChoicesCopy);
    };

    const handleCheckCorrectChoices = async (index: number, choice: IEdoQuestionChoice) => {
        if (form.type === EdoQuestionTypeEnum.SINGLE_CHOICE || form.type === EdoQuestionTypeEnum.TRUE_FALSE) {
            const questionChoicesCopy = Array.from(questionChoices);
            questionChoicesCopy.map((c, i) => {
                if (i === index) {
                    c.isCorrect = true;
                } else {
                    c.isCorrect = false;
                }
                return choice;
            });
            setQuestionChoices(questionChoicesCopy);
        } else {
            const questionChoicesCopy = Array.from(questionChoices);
            questionChoicesCopy[index] = { ...questionChoicesCopy[index], isCorrect: true };
            setQuestionChoices(questionChoicesCopy);
        }
    };

    const handleUncheckChoice = async (index: number, choice: IEdoQuestionChoice) => {
        const questionChoicesCopy = Array.from(questionChoices);
        questionChoicesCopy[index] = { ...questionChoicesCopy[index], isCorrect: false };
        setQuestionChoices(questionChoicesCopy);
    };

    const handleRemoveChoice = async (index: number, choice: IEdoQuestionChoice) => {
        const questionChoicesCopy = Array.from(questionChoices);
        questionChoicesCopy.splice(index, 1);
        setQuestionChoices(questionChoicesCopy);
    };

    const getTotalScore = () => {
        if (form.type === EdoQuestionTypeEnum.SINGLE_CHOICE || form.type === EdoQuestionTypeEnum.TRUE_FALSE || form.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || form.type === EdoQuestionTypeEnum.FILL_BLANK) {
            return questionChoices.reduce((acc, curr) => acc + (curr.isCorrect && curr.score > 0 ? curr.score : 0), 0);
        } else {
            return form.totalScore;
        }
    };

    const totalScore = useMemo(() => getTotalScore(), [questionChoices, form.type, form.totalScore]);

    useEffect(() => {
        if (!open) {
            setForm({ ...defaultValue });
            setQuestionChoices([]);
        }
    }, [open]);

    const getRadioChoiceOptions = () => {
        return (
            <FormControl disabled={isNew && form.type !== EdoQuestionTypeEnum.TRUE_FALSE}>
                <FormLabel id="available-choices-label" sx={{ mb: 1 }}>Risposte disponibili</FormLabel>
                <RadioGroup
                    aria-labelledby="available-choices-label"
                    name="radio-buttons-group"
                // value={questionChoices.find((c) => c.isCorrect)?.id}
                // onChange={(event, value) => console.log(value)}
                >
                    {questionChoices.map((choice, index) => (
                        <FormControlLabel
                            key={index}
                            value={index}
                            checked={choice.isCorrect}
                            onChange={(event, checked) => checked ? handleCheckCorrectChoices(index, choice) : handleUncheckChoice(index, choice)}
                            control={<Radio />}
                            label={
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Box>
                                        <TextField
                                            variant="standard"
                                            // size="small"
                                            // fullWidth
                                            required
                                            onChange={(event) => handleEditChoice(index, { ...choice, description: event.target.value })}
                                            name="description"
                                            value={choice.description || ""}
                                            disabled={isNew || form.type === EdoQuestionTypeEnum.TRUE_FALSE}
                                        />
                                    </Box>
                                    <Box sx={{ ml: 1 }}>
                                        <NumericFormat
                                            onValueChange={(values) => {
                                                handleEditChoice(index, { ...choice, score: values.floatValue });
                                            }}
                                            placeholder={translate("edocendoApp.edoQuestionChoice.score")}
                                            name="score"
                                            value={choice.score}
                                            required
                                            customInput={TextField}
                                            allowNegative
                                            decimalSeparator={','}
                                            thousandSeparator={'.'}
                                            decimalScale={2}
                                            size="small"
                                            variant="standard"
                                            disabled={isNew && form.type !== EdoQuestionTypeEnum.TRUE_FALSE}
                                        />
                                    </Box>
                                    {form.type !== EdoQuestionTypeEnum.TRUE_FALSE ? (
                                        <Box sx={{ ml: 1 }}>
                                            <IconButton
                                                size='small'
                                                onClick={() => handleRemoveChoice(index, choice)}
                                                disabled={isNew}>
                                                <Close />
                                            </IconButton>
                                        </Box>
                                    ) : null}
                                </Box>
                            } />)
                    )}
                </RadioGroup>
            </FormControl>
        );
    };

    const getCheckboxChoiceOptions = () => {
        return (
            <FormControl sx={{ mt: 2, mb: 1 }} component="fieldset" variant="standard" disabled={isNew}>
                <FormLabel component="legend">Risposte disponibili</FormLabel>
                <FormGroup>
                    {questionChoices.map((choice, index) => (<FormControlLabel
                        key={index}
                        value={index}
                        checked={choice.isCorrect}
                        onChange={(event, checked) => checked ? handleCheckCorrectChoices(index, choice) : handleUncheckChoice(index, choice)}
                        control={
                            <Checkbox />
                        }
                        label={
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Box>
                                    <TextField
                                        variant="standard"
                                        // size="small"
                                        // fullWidth
                                        required
                                        onChange={(event) => handleEditChoice(index, { ...choice, description: event.target.value })}
                                        name="description"
                                        value={choice.description || ""}
                                    />
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                    <NumericFormat
                                        onValueChange={(values) => {
                                            handleEditChoice(index, { ...choice, score: values.floatValue });
                                        }}
                                        placeholder={translate("edocendoApp.edoQuestionChoice.score")}
                                        name="score"
                                        value={choice.score}
                                        required
                                        customInput={TextField}
                                        allowNegative
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                        decimalScale={2}
                                        size="small"
                                        variant="standard"
                                    />
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                    <IconButton size='small' onClick={() => handleRemoveChoice(index, choice)}>
                                        <Close />
                                    </IconButton>
                                </Box>
                            </Box>
                        }
                    />))}
                </FormGroup>
            </FormControl>
        );
    };

    const getMultipleChoiceOptions = () => {
        if (form.type === EdoQuestionTypeEnum.SINGLE_CHOICE || form.type === EdoQuestionTypeEnum.TRUE_FALSE) {
            return getRadioChoiceOptions();
        } else {
            return getCheckboxChoiceOptions();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={true}
            fullWidth
        >
            <AppBar position="relative">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        disabled={updating}
                        sx={{ marginRight: 1 }}
                        onClick={() => handleClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" flexGrow={1}>
                        {isNew ? translate("edocendoApp.edoQuestion.home.createLabel") : translate("edocendoApp.edoQuestion.home.editLabel")}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        disabled={updating || !form.type || !form.description}
                        onClick={confirmSuccess}
                    >
                        <CheckIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {!form ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                    <ValidatorForm
                        onSubmit={confirmSuccess}
                        onError={(errors) => false}
                        sx={{ flexGrow: 1 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="type">{translate("edocendoApp.edoQuestion.type")}</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId='type'
                                        label={translate("edocendoApp.edoQuestion.type")}
                                        name="type"
                                        size="small"
                                        required
                                        variant="outlined"
                                        value={form.type || ""}
                                        onChange={(event) => {
                                            setForm((prevState) => ({
                                                ...prevState,
                                                type: event.target.value as EdoQuestionTypeEnum,

                                            }));
                                        }}
                                        renderValue={(value) => {
                                            return translate(`edocendoApp.EdoQuestionType.${value}`);
                                        }}
                                    >
                                        <MenuItem value={EdoQuestionTypeEnum.FREE_TEXT}>
                                            <Translate contentKey={`edocendoApp.EdoQuestionType.${EdoQuestionTypeEnum.FREE_TEXT}`} />
                                        </MenuItem>
                                        <MenuItem value={EdoQuestionTypeEnum.TRUE_FALSE}>
                                            <Translate contentKey={`edocendoApp.EdoQuestionType.${EdoQuestionTypeEnum.TRUE_FALSE}`} />
                                        </MenuItem>
                                        <MenuItem value={EdoQuestionTypeEnum.SINGLE_CHOICE}>
                                            <Translate contentKey={`edocendoApp.EdoQuestionType.${EdoQuestionTypeEnum.SINGLE_CHOICE}`} />
                                        </MenuItem>
                                        <MenuItem value={EdoQuestionTypeEnum.MULTIPLE_CHOICE}>
                                            <Translate contentKey={`edocendoApp.EdoQuestionType.${EdoQuestionTypeEnum.MULTIPLE_CHOICE}`} />
                                        </MenuItem>
                                        {/* <MenuItem value={EdoQuestionTypeEnum.FILL_BLANK} key={EdoQuestionTypeEnum.FILL_BLANK}>
                                        <Translate contentKey={`edocendoApp.EdoQuestionType.${EdoQuestionTypeEnum.FILL_BLANK}`} />
                                    </MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    // size="small"
                                    fullWidth
                                    required
                                    label={translate("edocendoApp.edoQuestion.description")}
                                    onChange={handleChange}
                                    name="description"
                                    value={form.description || ""}
                                    helperText={form.type === EdoQuestionTypeEnum.FILL_BLANK ? "Usare tre caratteri _ (underscore) per identificare lo spazio da completare." : ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ mt: 1 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Motivare la risposta</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={form.explanationRequired} onChange={handleChange} name="explanationRequired" />
                                            }
                                            label="(Se selezionato, lo studente dovrà scrivere la motivazione della risposta)"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="fullWidth" sx={{ marginBottom: 1 }} />
                            </Grid>
                            {form.type === EdoQuestionTypeEnum.SINGLE_CHOICE || form.type === EdoQuestionTypeEnum.TRUE_FALSE || form.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || form.type === EdoQuestionTypeEnum.FILL_BLANK ? (
                                <Grid item xs={12}>
                                    <Box mb={questionChoices.length > 0 ? 1 : 0}>
                                        {getMultipleChoiceOptions()}
                                    </Box>
                                    {form.type === EdoQuestionTypeEnum.SINGLE_CHOICE || form.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || (form.type === EdoQuestionTypeEnum.TRUE_FALSE && questionChoices.length < 2) ? (
                                        <Box>
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                onClick={handleAddChoice}
                                                startIcon={<AddIcon />}
                                                size="small"
                                                disabled={!form.type || !form.description}
                                            >
                                                Aggiungi risposta
                                            </Button>
                                        </Box>
                                    )
                                        : null}
                                    <Typography variant="body2" color="text.secondary" marginTop={1.5}>Fra le risposte, selezionare la spunta accanto alle risposte giuste.</Typography>
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <Typography variant="body1" color="text.secondary" marginBottom={2}>Punteggio totale della domanda</Typography>

                                    <NumericFormat
                                        onValueChange={(values) => {
                                            setForm({ ...form, totalScore: values.floatValue });
                                        }}
                                        label={translate("edocendoApp.edoQuestion.totalScore")}
                                        placeholder={translate("edocendoApp.edoQuestion.totalScore")}
                                        disabled={form.type === EdoQuestionTypeEnum.SINGLE_CHOICE || form.type === EdoQuestionTypeEnum.TRUE_FALSE || form.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || form.type === EdoQuestionTypeEnum.FILL_BLANK}
                                        name="totalScore"
                                        value={totalScore}
                                        required={!(form.type === EdoQuestionTypeEnum.SINGLE_CHOICE || form.type === EdoQuestionTypeEnum.TRUE_FALSE || form.type === EdoQuestionTypeEnum.MULTIPLE_CHOICE || form.type === EdoQuestionTypeEnum.FILL_BLANK)}
                                        customInput={TextField}
                                        allowNegative
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                        decimalScale={2}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Box>

                            </Grid>
                        </Grid>
                    </ValidatorForm>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditQuestion;