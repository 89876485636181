import {
    Article as ArticleIcon, Delete as DeleteIcon, Edit as EditIcon, ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    GetApp as GetAppIcon
} from "@mui/icons-material";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction,
    ListItemText, Menu,
    MenuItem, Typography
} from "@mui/material";
import axios from "axios";
import { APP_DATE_FORMAT_EXPANDED, APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import React, { useEffect, useState } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { getFileAvatar } from "shared/util/file-avatar-utils";
import { ChapterRow } from "./components/ChapterRow";
interface MaterialRowProps {
    courseMaterial?: IEdoCourseMaterial;
    isLastElement?: boolean;
    showSubject?: boolean;
    extendedDate?: boolean;
    onOpenEditMaterial?: (material: IEdoCourseMaterial) => void;
    onOpenDeleteMaterial?: (material: IEdoCourseMaterial) => void;
    onOpenViewChapter: (chapter: IEdoChapter) => void;
    onOpenEditChapterUnitsDialog?: (entity: IEdoChapter) => void;
}

const MaterialRow = (props: MaterialRowProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [files, setFiles] = useState<IEdoFile[]>([]);
    const [anchorEditChapterButton, setAnchorEditChapterButton] =
        useState<null | HTMLElement>(null);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
    const {
        courseMaterial,
        isLastElement,
        showSubject,
        extendedDate,
        onOpenEditMaterial: handleOpenEditMaterial,
        onOpenDeleteMaterial: handleOpenDeleteMaterial,
        onOpenViewChapter: handleOpenViewChapter,
        onOpenEditChapterUnitsDialog: handleOpenEditChapterUnitsDialog
    } = props;

    const loading = !courseMaterial || loadingFiles;

    const handleEditChapterButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEditChapterButton(event.currentTarget);
    };

    const handleCloseEditChapterMenu = () => {
        setAnchorEditChapterButton(null);
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    const openFile = (file: IEdoFile) => {
        window.open(`/api/files/${file.id}/view`, "_blank");
    };

    const downloadFile = (file: IEdoFile) => {
        window.open(`/api/files/${file.id}/download`, "_blank");
    };

    useEffect(() => {
        const loadFiles = async () => {
            setLoadingFiles(true);
            const response = await axios.get(`/course-materials/${courseMaterial.id}/files`);
            setFiles(response.data.data);
            setLoadingFiles(false);
        };

        if (courseMaterial && open) {
            loadFiles();
        }
    }, [courseMaterial, open]);

    const getContent = () => {
        if (loading) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                    <CircularProgress />
                </Box>
            );
        }

        if (!files.length && !courseMaterial.chapter) {
            return (<Box p={2}>
                <Typography variant="body1" align="center">
                    Il materiale non contiene file.
                </Typography>
            </Box>);
        }

        return (<>
            {files.length ? (
                <List component="div" disablePadding>
                    {files.map((file) => (
                        <ListItem key={file.id} disableGutters disablePadding>
                            <ListItemButton
                                sx={(theme) => ({
                                    paddingLeft: 4,
                                    [theme.breakpoints.down("sm")]: {
                                        paddingLeft: 2,
                                    },
                                })}
                                onClick={() => openFile(file)}
                                disableGutters
                            >
                                <ListItemAvatar>
                                    {getFileAvatar(file.fileType)}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={file.originalFileName}
                                    primaryTypographyProps={{ variant: "body2" }}
                                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                                    secondary={
                                        <TextFormat
                                            value={file.createdAt}
                                            type="date"
                                            format={APP_LOCAL_DATE_FORMAT}
                                        />
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => downloadFile(file)}
                                        edge="end"
                                        aria-label="Download file"
                                        sx={{ marginRight: 0.75 }}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            ) : null}
            {courseMaterial.chapter ? (
                <List component="div" disablePadding>
                    <ChapterRow
                        nested={true}
                        chapter={courseMaterial.chapter}
                        key={courseMaterial.chapter.id}
                        viewChapter={() => handleOpenViewChapter(courseMaterial.chapter)}
                        editChapter={null}
                        deleteChapter={null}
                    />
                </List>
            ) : null}
        </>);
    };

    return (
        <>
            <ListItem
                button
                onClick={handleOpen}
            >
                <ListItemText
                    primary={!showSubject ? courseMaterial.title : (
                        <span>
                            {courseMaterial.title}
                            <Chip component="span" sx={{ marginLeft: 1 }} size="small" label={courseMaterial?.course.subject.name} />
                        </span>
                    )}
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    secondary={
                        <TextFormat
                            value={courseMaterial.createdAt}
                            type="date"
                            format={extendedDate ? APP_DATE_FORMAT_EXPANDED : APP_LOCAL_DATE_FORMAT}
                        />
                    }
                />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>

                {getContent()}

                {/* <Box>
          <Button size="large" startIcon={<AddIcon />} onClick={() => handleOpenEditUnit(null)} fullWidth>
            Aggiungi unità didattica
          </Button>
        </Box> */}
                {handleOpenEditMaterial || handleOpenDeleteMaterial ? (
                    <>
                        <Divider />
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            p={1}
                        >
                            {handleOpenEditMaterial ? (
                                <Box >
                                    <Box >
                                        <Button
                                            color="primary"
                                            size="small"
                                            startIcon={<EditIcon />}
                                            fullWidth
                                            onClick={handleEditChapterButtonClick}
                                        >
                                            <Translate contentKey="entity.action.edit" />
                                        </Button>
                                        <Menu
                                            id="more-material-menu"
                                            anchorEl={anchorEditChapterButton}
                                            keepMounted
                                            open={Boolean(anchorEditChapterButton)}
                                            onClose={handleCloseEditChapterMenu}
                                            disableScrollLock={true}
                                        >
                                            <MenuItem onClick={() => {
                                                handleCloseEditChapterMenu();
                                                handleOpenEditMaterial(courseMaterial);
                                            }}>
                                                <ListItemIcon>
                                                    <ArticleIcon fontSize="small" />
                                                </ListItemIcon>
                                                <Typography variant="inherit">Modifica informazioni e documenti</Typography>
                                            </MenuItem>
                                        </Menu>

                                    </Box>
                                </Box>
                            ) : null}
                            {handleOpenDeleteMaterial ? (
                                <Box>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => handleOpenDeleteMaterial(courseMaterial)}
                                    >
                                        Elimina
                                    </Button>
                                </Box>
                            ) : null}
                        </Box>{" "}
                    </>
                ) : null}
                {!isLastElement ? <Divider /> : null}
            </Collapse>
        </>
    );
};

export default MaterialRow;