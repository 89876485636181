import {
    AppBar,
    Box, CircularProgress,
    Divider, Paper,
    TablePagination, Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import { useAppSelector } from "config/store";
import UserQuizAttemptsAccordion from "pages/courses/components/UserQuizAttemptsAccordion/UserQuizAttemptsAccordion";
import { useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { useNavigate, useParams } from "react-router-dom";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { IUser } from "shared/model/user.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme =>
    ({
        paper: {
            color: theme.palette.text.secondary,
            overflow: "hidden",
        },
        root: {
            display: "grid",
            gridTemplateRows: "min-content auto min-content",
            height: "100%",
        },
        appbar: {
            boxShadow: "none",
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.divider,
            borderBottomStyle: "solid",
        },
        toolbar: {
            justifyContent: "space-between",
        },
    })
);

const QuizResultsRoute = () => {
    const theme = useTheme();
    const params = useParams<{ id: string, courseQuizId: string }>();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const {
        loading: loadingMaterialeInsegnamento,
        totalItems: materialeInsegnamentoTotalItems,
        entities: courseMaterials,
        updateSuccess: updateSuccessCourseMaterial
    } = useAppSelector<EntityState<IEdoCourseMaterial>>(
        (state) => state.edoCourseMaterial
    );
    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: "order",
        order: "ASC",
    });
    const [courseMaterialToEdit, setCourseMaterialToEdit] = useState<IEdoCourseMaterial>(null);
    const [editCourseMaterialDialog, setEditCourseMaterialDialog] = useState<boolean>(false);

    const [users, setUsers] = useState<IUser[]>([]);


    const getAllEntities = async () => {
        const response = await axios.get<{ data: IUser[] }>(`/course-quizzes/${params.courseQuizId}/users`, {
            params: {
                page: paginationState.activePage,
                size: paginationState.itemsPerPage,
                sort: `${paginationState.sort},${paginationState.order}`
            }
        });
        setUsers(response.data.data);
    };

    useEffect(() => {
        getAllEntities();
    }, [
        paginationState.activePage,
        paginationState.order,
        paginationState.sort,
        paginationState.itemsPerPage,
    ]);


    const sort = (p: string) => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === "ASC" ? "DESC" : "ASC",
            sort: p,
        });
    };

    const handlePagination = (currentPage: number) =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const handleRowsPerPage = (rowsPerPage: number) =>
        setPaginationState({
            ...paginationState,
            itemsPerPage: rowsPerPage,
            activePage: 1,
        });

    const handleSyncList = () => {
        getAllEntities();
    };

    const handleOpenEditCourseMaterial = (courseMaterial: IEdoCourseMaterial) => {
        setCourseMaterialToEdit(courseMaterial);
        setEditCourseMaterialDialog(true);
    };

    const handleOpenAttempt = (quizAttempt: IEdoQuizAttempt) => {
        navigate(`/quiz-attempts/${quizAttempt.id}/review`);
    };


    return (
        <Paper className={classes.paper}>
            <div className={classes.root}>
                <AppBar
                    position="static"
                    color="transparent"
                    className={classes.appbar}
                >
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h5">
                            <Translate contentKey="edocendoApp.edoQuizAttempt.home.title" />
                        </Typography>
                        {/* <Box>
                            <IconButton
                                color="inherit"
                                onClick={handleSyncList}
                                disabled={loadingMaterialeInsegnamento}
                                sx={(theme) => ({
                                    [theme.breakpoints.up("lg")]: {
                                        display: "none",
                                    }
                                })}
                            >
                                <SyncIcon />
                            </IconButton>
                            <Button
                                color="inherit"
                                onClick={handleSyncList}
                                disabled={loadingMaterialeInsegnamento}
                                startIcon={<SyncIcon />}
                                sx={(theme) => ({
                                    [theme.breakpoints.down("lg")]: {
                                        display: "none",
                                    },
                                })}
                            >
                                <Translate contentKey="edocendoApp.edoQuizAttempt.home.refreshListLabel"/>
                            </Button>
                            <IconButton
                                color="inherit"
                                disabled={loadingMaterialeInsegnamento}
                                onClick={() => handleOpenEditCourseMaterial(null)}
                                sx={(theme) => ({
                                    [theme.breakpoints.up("lg")]: {
                                        display: "none",
                                    }
                                })}
                            >
                                <CheckIcon />
                            </IconButton>
                            <Button
                                color="inherit"
                                disabled={loadingMaterialeInsegnamento}
                                startIcon={<CheckIcon />}
                                onClick={() => handleOpenEditCourseMaterial(null)}
                                sx={(theme) => ({
                                    [theme.breakpoints.down("lg")]: {
                                        display: "none",
                                    },
                                })}
                            >
                                Correggi i test
                            </Button>
                        </Box> */}
                    </Toolbar>
                </AppBar>
                {loadingMaterialeInsegnamento ? (
                    <Box position="relative" display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {!users.length ? (
                            <Box p={2}>
                                <Typography variant="body1" align="center">
                                    <Translate contentKey="edocendoApp.edoQuizAttempt.home.notFound" />
                                </Typography>
                            </Box>
                        ) : users.map(
                            (user) => (
                                <UserQuizAttemptsAccordion
                                    key={user.id}
                                    user={user}
                                    onOpenAttempt={
                                        handleOpenAttempt
                                    }
                                    courseQuizId={parseInt(params.courseQuizId)}
                                />
                            )
                        )}
                    </Box>
                )}
                <Divider />
                <TablePagination
                    component="div"
                    count={materialeInsegnamentoTotalItems}
                    rowsPerPage={paginationState.itemsPerPage}
                    page={paginationState.activePage - 1}
                    onPageChange={(event, page) => handlePagination(page + 1)}
                    onRowsPerPageChange={(event) =>
                        handleRowsPerPage(parseInt(event.target.value, 10))
                    }
                    labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
                    }
                />
            </div>
        </Paper>
    );
};

export default QuizResultsRoute;