import { Box } from "@mui/material";
import CreateCoursePost from "components/CreatePost/CreateCoursePost";
import DeleteCoursePostDialog from "components/Post/components/DeleteCoursePostDialog";
import EditCoursePost from "components/Post/components/EditCoursePost";
import CoursePost from "components/Post/CoursePost";
import PostSkeleton from "components/Post/PostSkeleton";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState } from "react-jhipster";
import { useParams } from "react-router-dom";
import { IEdoCoursePost } from "shared/model/edo-course-post.model";
import { getCoursePostsByCourse, updateEntity } from "shared/reducers/entities/edo-course-post.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

const CourseWall = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const { entities: edoPostInsegnamentoList, loading, updating, updateSuccess } = useAppSelector<
    EntityState<IEdoCoursePost>
  >((state) => state.edoCoursePost);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: 10,
    sort: "dataCreation",
    order: "DESC",
  });
  const [editPostDialog, setEditPostDialog] = useState<{ open: boolean; entity: IEdoCoursePost }>({
    open: false,
    entity: null,
  });
  const [deletePostDialog, setDeletePostDialog] = useState<{ open: boolean; entity: IEdoCoursePost }>({
    open: false,
    entity: null,
  });

  const getAllEntities = () => {
    dispatch(
      getCoursePostsByCourse({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `dataCreation:DESC`,
        courseId: params.id
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const handlePagination = (currentPage: number) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };

  const handleRowsPerPage = (rowsPerPage: number) => {
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });
  };

  const updatePost = async (form: IEdoCoursePost) => {
    dispatch(updateEntity(form));
  };

  useEffect(() => {
    if (updateSuccess) {
      getAllEntities();
    }
  }, [updateSuccess]);

  return (
    <>
      <Box flexGrow={1}>
        <Box mb={2}>
          <CreateCoursePost courseId={parseInt(params.id)} />
        </Box>
        <Box mb={2}>
          {loading && !edoPostInsegnamentoList.length ?
            (<>
              <Box pb={2}>
                <PostSkeleton />
              </Box>
              <Box pb={2}>
                <PostSkeleton />
              </Box>
              <Box>
                <PostSkeleton />
              </Box>
            </>) : edoPostInsegnamentoList.map((post) => (
              <Box pb={2} key={post.id}>
                <CoursePost
                  post={post}
                  onEdit={(post) => setEditPostDialog({ open: true, entity: post })}
                  onDelete={(post) => setDeletePostDialog({ open: true, entity: post })}
                />
              </Box>
            ))}
        </Box>
      </Box>
      <EditCoursePost
        open={editPostDialog.open}
        post={editPostDialog.entity}
        onClose={() => setEditPostDialog({ open: false, entity: null })}
        updatePost={updatePost}
      />
      <DeleteCoursePostDialog
        open={deletePostDialog.open}
        post={deletePostDialog.entity}
        onClose={() => setDeletePostDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default CourseWall;
