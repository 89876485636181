import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import axios from "axios";
import { IPaginationResponse } from "shared/util/pagination.constants";
import { IEdoWallPost, defaultValue } from "../../model/edo-wall-post.model";
import { cleanEntity } from "../../util/entity-utils";
import {
  EntityState,
  IQueryParamsWithFilters,
  createEntitySlice,
  serializeAxiosError
} from "../reducer.utils";

const initialState: EntityState<IEdoWallPost> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "/wall-posts";

// Actions

export const getEntities = createAsyncThunk(
  "edoWallPost/fetch_entity_list",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `${apiUrl}`;
    } else {
      requestUrl = `${apiUrl}?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoWallPost>>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "edoWallPost/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEdoWallPost>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "edoWallPost/create_entity",
  async (entity: IEdoWallPost, thunkAPI) => {
    const result = await axios.post<IEdoWallPost>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "edoWallPost/update_entity",
  async (entity: IEdoWallPost, thunkAPI) => {
    const result = await axios.put<IEdoWallPost>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "edoWallPost/partial_update_entity",
  async (entity: IEdoWallPost, thunkAPI) => {
    const result = await axios.patch<IEdoWallPost>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "edoWallPost/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEdoWallPost>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getCurrentUserWallPosts = createAsyncThunk(
  "edoWallPost/get_current_user_wall_posts",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `/account/wall-posts`;
    } else {
      requestUrl = `/account/wall-posts?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoWallPost>>(requestUrl);
  }
);

// slice

export const EdoWallPostSlice = createEntitySlice({
  name: "edoWallPost",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(
        isFulfilled(getEntities, getCurrentUserWallPosts),
        (state, action) => {
          const {
            data: {
              data,
              meta: { totalItems },
            },
            headers,
          } = action.payload;
          return {
            ...state,
            loading: false,
            entities: data,
            totalItems,
          };
        }
      )
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(
        isPending(getEntities, getEntity, getCurrentUserWallPosts),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = EdoWallPostSlice.actions;

// Reducer
export default EdoWallPostSlice.reducer;
