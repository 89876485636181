import { Tab } from "@mui/material";
import { JSXElementConstructor, ReactElement } from "react";
import { Link } from "react-router-dom";

interface LinkTabProps {
    label?: string;
    icon?: string | ReactElement<any, string | JSXElementConstructor<any>>;
    iconPosition?: "bottom" | "top" | "end" | "start";
    href?: string;
}

const LinkTab = (props: LinkTabProps) => {
    return (
        <Tab
            component={Link}
            to={props.href}
            {...props}
        />
    );
};

export default LinkTab;