import { IEdoChat } from "shared/model/edo-chat.model";
import { IEdoCourseYear } from "shared/model/edo-course-year.model";
import {
  defaultValue as defaultValueSchool,
  IEdoSchool
} from "shared/model/edo-school.model";
import { IUser } from "shared/model/user.model";
import { IEdoClassZoomLink } from "./edo-class-zoom-link.model";

export interface IEdoClass {
  id?: number;
  section?: string;
  school?: IEdoSchool;
  students?: IUser[] | null;
  year?: IEdoCourseYear;
  teachersChat?: IEdoChat | null;
  zoomInviteLink?: string | null;
  zoomInviteLinks?: IEdoClassZoomLink[] | null;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IEdoClass> = {
  students: [] as IUser[],
  school: defaultValueSchool as IEdoSchool,
};
