import { Sync as SyncIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  Paper,
  TablePagination, Toolbar,
  Typography
} from "@mui/material";
import SearchField from "components/SearchField/SearchField";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { useNavigate } from "react-router-dom";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IUser } from "shared/model/user.model";
import { EditDialogType } from "shared/util/dialog-utils";
import { ITEMS_PER_PAGE, PaginationStateWithSearch } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import {
  UserManagementState,
  getUsers
} from "../../shared/reducers/user-management.reducer";
import TeacherRow from "./components/TeacherRow";
import ViewTeacherMaterial from "./components/ViewTeacherMaterial";

const useStyles = makeStyles()(theme =>
  ({
    root: {
      display: "grid",
      gridTemplateRows: "min-content auto min-content",
      height: "100%",
    },
    appbar: {
      boxShadow: "none",
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: "solid",
      position: "relative"
    },
    toolbar: {
      justifyContent: "space-between",
    },
    paper: {
      color: theme.palette.text.secondary,
      overflow: "hidden",
    },
    button: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    buttonMobile: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
  })
);

const TeachersSupervision = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [viewMaterialDialog, setViewMaterialDialog] = useState<EditDialogType<IEdoCourseMaterial>>({ open: false, entity: null });
  const [paginationState, setPaginationState] = useState<PaginationStateWithSearch>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "lastName",
    order: "ASC",
    searchValue: "",
  });
  const {
    users: userList,
    loading,
    totalItems,
    updateSuccess,
  } = useAppSelector<UserManagementState>((state) => state.userManagement);

  const getUserList = () => {
    const params = new URLSearchParams();
    params.append("filter.roles.name", "$eq:" + AUTHORITIES.ROLE_TEACHER);
    paginationState.searchValue && params.append("search", paginationState.searchValue);
    dispatch(
      getUsers({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: params.toString(),
      })
    );
  };

  useEffect(() => {
    getUserList();
  }, [
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
    paginationState.searchValue,
  ]);

  useEffect(() => {
    if (updateSuccess) {
      handleSyncList();
    }
  }, [updateSuccess]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const handleSyncList = () => {
    getUserList();
  };

  const searchUsers = (value: string) => {
    setPaginationState(prevState => ({ ...prevState, activePage: 1, searchValue: value }));
  };

  const handleOpenUser = (user: IUser) => {
    navigate("/admin/users/teachers/" + user.id);
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.appbar}
        >
          <Toolbar className={classes.toolbar}>
            <Box sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              },
              flexGrow: 1,
            })}>
              <Typography variant="h6">Controllo attività docenti</Typography>
            </Box>
            <Box sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexGrow: 1
              },
            })}>
                <IconButton
                  color="inherit"
                  onClick={handleSyncList}
                  disabled={loading}
                  className={classes.buttonMobile}
                >
                  <SyncIcon />
                </IconButton>
                <Button
                  color="inherit"
                  onClick={handleSyncList}
                  disabled={loading}
                  startIcon={<SyncIcon />}
                  className={classes.button}
                >
                  <Translate contentKey="userManagement.home.refreshListLabel" />
                </Button>
            </Box>
            <Box ml={2} sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                flexGrow: 1
              },
            })}>
              <SearchField
                placeholder="Cerca un utente"
                value={paginationState.searchValue}
                setSearchValue={searchUsers}
              />
            </Box>
          </Toolbar>
        </AppBar>
        {userList && userList.length > 0 ? (
          <List dense>
            {userList.length &&
              userList.map(user => (
                <TeacherRow
                  key={user.id}
                  teacher={user}
                  viewTeacher={(teacher) => setViewMaterialDialog({ open: true, entity: teacher })}
                />
              ))}
          </List>
        ) : (loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box p={2}>
              <Typography variant="body1" align="center">
                <Translate contentKey="userManagement.home.notFound" />
              </Typography>
            </Box>
          )
        )}
        <ViewTeacherMaterial
          teacher={viewMaterialDialog.entity}
          open={viewMaterialDialog.open}
          onClose={() => setViewMaterialDialog({ open: false, entity: null })}
        />
        <Divider />
        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={paginationState.itemsPerPage}
          page={paginationState.activePage - 1}
          onPageChange={(event, page) => handlePagination(page + 1)}
          onRowsPerPageChange={(event) =>
            handleRowsPerPage(parseInt(event.target.value, 10))
          }
          labelRowsPerPage="Righe per pagina:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
          }
        />
      </Paper>
    </Grid>
  );
};

export default TeachersSupervision;
