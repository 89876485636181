import {
  Add as AddIcon
} from "@mui/icons-material";
import {
  AppBar, Box, IconButton, LinearProgress, List, Paper,
  Tab,
  Tabs, Toolbar,
  Typography
} from "@mui/material";
import axios from "axios";
import EditCourseMaterial from "components/EditCourseMaterial/EditCourseMaterial";
import MaterialRow from "components/MaterialRow/MaterialRow";
import { ViewChapter } from "components/ViewChapter/ViewChapter";
import { useAppSelector } from "config/store";
import "moment/locale/it";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EntityState } from "shared/reducers/reducer.utils";
import DeleteCourseMaterial from "../courses/components/DeleteCourseMaterial";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box pt={1}>{children}</Box>}
    </div>
  );
}

const ArchivioMateriale = () => {
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const { updateSuccess, deleteSuccess } = useAppSelector<EntityState<IEdoCourseMaterial>>(state => state.edoCourseMaterial);
  const [tabValue, setTabValue] = useState<number | null>(null);
  const [viewChapterDialog, setViewChapterDialog] = useState<boolean>(false);
  const [chapterToView, setChapterToView] = useState<IEdoChapter>(null);
  const [courses, setCourses] = useState<IEdoCourse[]>([]);
  const [courseMaterials, setCourseMaterials] = useState<IEdoCourseMaterial[]>([]);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [courseMaterialToEdit, setCourseMaterialToEdit] = useState<IEdoCourseMaterial>(null);
  const [editCourseMaterialDialog, setEditCourseMaterialDialog] = useState<boolean>(false);
  const [deleteMaterialDialog, setDeleteMaterialDialog] = useState<boolean>(false);
  const [materialToDelete, setMaterialToDelete] = useState<IEdoCourseMaterial>(null);

  const handleChangeTab = (event: ChangeEvent<any>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpenViewChapter = (chapter: IEdoChapter) => {
    setChapterToView(chapter);
    setViewChapterDialog(true);
  };

  const handleCloseViewChapter = () => {
    setViewChapterDialog(false);
    setChapterToView(null);
  };

  const handleOpenEditCourseMaterial = (courseMaterial: IEdoCourseMaterial) => {
    setCourseMaterialToEdit(courseMaterial);
    setEditCourseMaterialDialog(true);
  };

  const handleCloseEditCourseMaterialDialog = () => {
    setEditCourseMaterialDialog(false);
    setCourseMaterialToEdit(null);
  };

  const handleOpenDeleteCourseMaterialDialog = (courseMaterial: IEdoCourseMaterial) => {
    setMaterialToDelete(courseMaterial);
    setDeleteMaterialDialog(true);
  };
  const handleCloseDeleteCourseMaterialDialog = () => {
    setDeleteMaterialDialog(false);
    setMaterialToDelete(null);
  };

  useEffect(() => {
    const handleGetCoursesByTeacher = async () => {
      setLoadingCourses(true);
      const response = await axios.get<{ data: IEdoCourse[] }>(`/teachers/${account.id}/courses`);
      setCourses(response.data.data);
      setTabValue(response.data?.data[0]?.id);
      setLoadingCourses(false);
    };

    handleGetCoursesByTeacher();
  }, []);

  useEffect(() => {
    const handleGetCourseMaterialsByCourse = async (courseId: number) => {
      const response = await axios.get(`/courses/${courseId}/course-materials`, {
        params: {
          sortBy: 'order:ASC',
          'filter.author.id': `$eq:${account.id}`
        }
      });
      setCourseMaterials(response.data.data);
    };

    if (tabValue) {
      handleGetCourseMaterialsByCourse(tabValue);
    }
  }, [tabValue, updateSuccess, deleteSuccess]);

  const getCourseMaterials = () => {
    if (!courseMaterials.length) {
      return (
        <Box p={2}>
          <Typography variant="body1" align="center">
            <Translate contentKey="edocendoApp.edoCourseMaterial.home.notFound" />
          </Typography>
        </Box>
      );
    }

    return (
      <List dense disablePadding>
        {courseMaterials.map((courseMaterial, index, list) => (
          <MaterialRow
            key={courseMaterial.id}
            courseMaterial={courseMaterial}
            isLastElement={list.length - 1 === index}
            onOpenViewChapter={handleOpenViewChapter}
            onOpenEditMaterial={handleOpenEditCourseMaterial}
            onOpenDeleteMaterial={handleOpenDeleteCourseMaterialDialog}
          />
        ))}
      </List>
    );
  };

  return (
    <>
      <Paper>
        <AppBar position="relative">
          <Toolbar>
            <Typography variant="h6" flexGrow={1}>
              Materiale classi
            </Typography>
            <IconButton edge="end" color="inherit" onClick={() => handleOpenEditCourseMaterial(null)}>
              <AddIcon />
            </IconButton>
          </Toolbar>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {courses.map((course) => (
              <Tab
                value={course.id}
                key={course.id}
                label={`${course.subject.name} - ${course.class.year.year}°${course.class.section} (${course.class?.school?.code})`}
              />
            ))}
          </Tabs>
        </AppBar>
        {loadingCourses && (
          <LinearProgress variant="indeterminate" color="secondary" />
        )}
        {courses.length ? courses.map((course) => (
          <TabPanel key={course.id} value={tabValue} index={course.id}>
            {getCourseMaterials()}
          </TabPanel>
        )) : (
          <Box p={2}>
            <Typography variant="body1" align="center">
              <Translate contentKey="edocendoApp.edoCourseMaterial.home.notFound" />
            </Typography>
          </Box>
        )}
      </Paper>
      <EditCourseMaterial courseMaterial={courseMaterialToEdit} open={editCourseMaterialDialog} onClose={handleCloseEditCourseMaterialDialog} />
      <DeleteCourseMaterial
        open={deleteMaterialDialog}
        courseMaterial={materialToDelete}
        onClose={handleCloseDeleteCourseMaterialDialog}
      />
      <ViewChapter
        open={viewChapterDialog}
        chapter={chapterToView}
        onClose={handleCloseViewChapter}
      />
    </>
  );
};

export default ArchivioMateriale;