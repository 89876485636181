import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CreateWallPost from "components/CreatePost/CreateWallPost";
import PostSkeleton from "components/Post/PostSkeleton";
import WallPost from "components/Post/WallPost";
import DeleteWallPostDialog from "components/Post/components/DeleteWallPostDialog";
import EditWallPost from "components/Post/components/EditWallPost";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState } from "react-jhipster";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoWallPost } from "shared/model/edo-wall-post.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EntityState } from "shared/reducers/reducer.utils";
import { getCurrentUserWallPosts, updateEntity } from "../../shared/reducers/entities/edo-wall-post.reducer";

export const Home = () => {
  const dispatch = useAppDispatch();
  const { entities: edoPostBachecaList, loading, updating, updateSuccess } = useAppSelector<
    EntityState<IEdoWallPost>
  >((state) => state.edoWallPost);
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: 10,
    sort: "data",
    order: "DESC",
  });
  const [editPostDialog, setEditPostDialog] = useState<{ open: boolean; entity: IEdoWallPost }>({
    open: false,
    entity: null,
  });
  const [deletePostDialog, setDeletePostDialog] = useState<{ open: boolean; entity: IEdoWallPost }>({
    open: false,
    entity: null,
  });

  const getAllEntities = () => {
    dispatch(
      getCurrentUserWallPosts({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `dataCreation:DESC`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=dataCreation:DESC`;
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const handlePagination = (currentPage: number) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };

  const updatePost = async (form: IEdoWallPost) => {
    dispatch(updateEntity(form));
  };

  useEffect(() => {
    if (updateSuccess) {
      getAllEntities();
    }
  }, [updateSuccess]);


  return (
    <>
      <Box flexGrow={1} >
        <Container maxWidth="xl" sx={(theme) => ({
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(4)
        })}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Box>
                <Box pb={2}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    Il tuo feed
                  </Typography>
                </Box>
                {hasAnyAuthority(account.roles, [
                  AUTHORITIES.ROLE_ADMIN,
                  AUTHORITIES.ROLE_VICE,
                  AUTHORITIES.ROLE_TEACHER,
                ]) ? (
                    <Box mb={2}>
                      <CreateWallPost />
                    </Box>
                  ) : null}
                <Box mb={2}>
                  {loading && !edoPostBachecaList.length ?
                    (<>
                      <Box pb={2}>
                        <PostSkeleton />
                      </Box>
                      <Box pb={2}>
                        <PostSkeleton />
                      </Box>
                      <Box>
                        <PostSkeleton />
                      </Box>
                    </>) : edoPostBachecaList.map((post) => (
                      <Box pb={2} key={post.id}>
                        <WallPost
                          post={post}
                          onEdit={(post) => setEditPostDialog({ open: true, entity: post })}
                          onDelete={() => setDeletePostDialog({ open: true, entity: post })}
                        />
                      </Box>
                    ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <EditWallPost
        open={editPostDialog.open}
        post={editPostDialog.entity}
        onClose={() => setEditPostDialog({ open: false, entity: null })}
        updatePost={updatePost}
      />
      <DeleteWallPostDialog
        open={deletePostDialog.open}
        post={deletePostDialog.entity}
        onClose={() => setDeletePostDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default Home;
