import { ReducersMapObject } from "@reduxjs/toolkit";
import activate from "pages/account/activate/activate.reducer";
import passwordReset from "pages/account/password-reset/password-reset.reducer";
import password from "pages/account/password/password.reducer";
import administration from "pages/administration/administration.reducer";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";
import accountReducer from "shared/reducers/account.reducer";
import entitiesReducers from "shared/reducers/entities/";
import settingsReducer from "shared/reducers/settings.reducer";
import userManagement from "shared/reducers/user-management.reducer";
import authentication from "./authentication";
import ChatReducer from "./chat.reducer";
import locale from "./locale";

const rootReducer: ReducersMapObject = {
  authentication,
  locale,
  administration,
  userManagement,
  activate,
  passwordReset,
  password,
  account: accountReducer,
  settings: settingsReducer,
  loadingBar,
  chat: ChatReducer,
  ...entitiesReducers,
};

export default rootReducer;
