import moment from "moment";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoFile } from "shared/model/edo-file.model";

export interface IEdoUnit {
  id?: number;
  title?: string;
  createdAt?: string;
  order?: number;
  chapter?: IEdoChapter;
  files?: IEdoFile[] | null;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoUnit> = {
  createdAt: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
};
