import { IEdoChat } from "shared/model/edo-chat.model";
import { IUser } from "shared/model/user.model";

export interface IEdoChatParticipation {
  id?: number;
  createdAt?: string;
  title?: string | null;
  user?: IUser;
  chat?: IEdoChat;
}

export const defaultValue: Readonly<IEdoChatParticipation> = {};
