import { Check as CheckIcon } from '@mui/icons-material';
import {
    AppBar, CircularProgress,
    Dialog, DialogContent, Grid,
    IconButton, Toolbar,
    Typography
} from "@mui/material";
import { AUTHORITIES } from 'config/constants';
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import { useState } from "react";
import { translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { hasAnyAuthority } from 'shared/auth/private-route';
import { IUser } from 'shared/model/user.model';
import { saveAccountSettings } from 'shared/reducers/account.reducer';
import { AuthenticationState } from 'shared/reducers/authentication';

const SetUserInfoDialog = () => {
    const dispatch = useAppDispatch();
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);
    const [form, setForm] = useState<IUser>({ ...account });

    const open = hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_PARENT]) && !account.firstName || !account.lastName || !account.email;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const change = {
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        };
        setForm(change);
    };

    const handleConfirmSuccess = () => {
        dispatch(saveAccountSettings({ firstName: form.firstName, lastName: form.lastName }));
    };

    return (
        <Dialog
            open={open}
            fullScreen={true}
            fullWidth
        >
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" flexGrow={1}>
                        Completa le informazioni del profilo
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        disabled={!form.firstName || !form.lastName || !form.email}
                        onClick={handleConfirmSuccess}
                    >
                        <CheckIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {!form ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                    <ValidatorForm
                        onSubmit={handleConfirmSuccess}
                        onError={(errors) => false}
                        sx={{ flexGrow: 1 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    // size="small"
                                    fullWidth
                                    required
                                    label={translate("userManagement.firstName")}
                                    onChange={handleChange}
                                    name="firstName"
                                    value={form.firstName || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    // size="small"
                                    fullWidth
                                    required
                                    label={translate("userManagement.lastName")}
                                    onChange={handleChange}
                                    name="lastName"
                                    value={form.lastName || ""}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SetUserInfoDialog;