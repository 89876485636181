import {
  Autocomplete, Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import { AUTHORITIES } from "config/constants";
import { useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import "moment/locale/it";
import React, { useContext, useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoChat, defaultValue } from "shared/model/edo-chat.model";
import { IUser } from "shared/model/user.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EntityState } from "shared/reducers/reducer.utils";

export interface CreateGroupProps {
  open: boolean;
  handleClose: any;
  gruppo: IEdoChat | null;
}

export const CreateGroup = (props: CreateGroupProps) => {
  const theme = useTheme();
  const socket = useContext(SocketContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { loading, updating, updateSuccess } = useAppSelector<
    EntityState<IEdoChat>
  >((state) => state.edoChat);
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const [form, setForm] = useState<IEdoChat>({ ...defaultValue, ...props.gruppo });
  const [utenteList, setUtenteList] = useState<IUser[]>([]);
  const [selectedUtenteList, setSelectedUtenteList] = useState<IUser[]>([]);
  const [utenteValue, setUtenteValue] = useState<string>("");
  const [loadingUtenteList, setLoadingUtenteList] = useState<boolean>(false);

  const { open, handleClose, gruppo } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const searchUtente = async (query: string) => {
    setLoadingUtenteList(true);
    const roleFilter = hasAnyAuthority(account.roles || [], [
      AUTHORITIES.ROLE_STUDENT,
    ])
      ? { "filter.roles.name": '$eq:' + AUTHORITIES.ROLE_TEACHER }
      : {};
    const response = await axios.get("/users", {
      params: {
        page: 0,
        size: 5,
        sortBy: "firstName:ASC",
        search: query,
        "filter.id": '$not:' + account.id,
        ...roleFilter,
      },
    });
    setUtenteList(response.data.data);
    setLoadingUtenteList(false);
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  useEffect(() => {
    setForm({
      ...form,
      users: selectedUtenteList,
    } as any);
  }, [selectedUtenteList]);

  useEffect(() => {
    if (gruppo == null) {
      setForm({ ...defaultValue });
    }
  }, [gruppo]);

  const createGroupChat = async () => {
    const response = await axios.post(
      `/chats/group`,
      {
        ...form, users: [
          ...(form as any).users,
        ],
      }
    );
    setForm({ ...defaultValue });
    socket.emit('chat-created', response.data.chat);
    handleCloseDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>Crea un gruppo</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        ) : (
          <ValidatorForm
            onSubmit={createGroupChat}
            onError={(errors) => false}
            sx={{ flexGrow: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label={translate("edocendoApp.edoChat.title")}
                  onChange={handleChange}
                  name="title"
                  value={form.title || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={utenteList}
                  getOptionLabel={(option: IUser) =>
                    option.firstName + "  " + option.lastName
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loadingText="Ricerca..."
                  noOptionsText="Nessun risultato"
                  value={selectedUtenteList}
                  filterSelectedOptions
                  filterOptions={(options, state) => {
                    return options;
                  }}
                  // open={Boolean(utenteValue)}
                  forcePopupIcon={false}
                  size="small"
                  loading={loadingUtenteList}
                  freeSolo={false}
                  onChange={(event, newValue, reason) => {
                    setSelectedUtenteList([...newValue]);
                    setUtenteValue("");
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setUtenteValue(value);
                      searchUtente(value);
                    }
                  }}
                  inputValue={utenteValue}
                  renderInput={(autocompleteParams) => (
                    <TextField
                      {...autocompleteParams}
                      variant="outlined"
                      required
                      label="Utenti"
                      placeholder="Utenti"
                    />
                  )}
                />
                {/* <AddDocente
                  setUtenteList={(utenteList: IUser[]) => {
                    setPartecipazioneList(
                      utenteList.map(docente => {
                        return {
                          user: docente.user,
                        };
                      })
                    );
                  }}
                /> */}
              </Grid>
            </Grid>
          </ValidatorForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          <Translate contentKey="entity.action.cancel" />
        </Button>
        <Button onClick={createGroupChat} color="primary" disabled={updating}>
          <Translate contentKey="entity.action.create" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
