import { Divider, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useAppDispatch, useAppSelector } from 'config/store';
import ChatList from 'pages/chat/components/ChatList';
import { CreateGroup } from 'pages/chat/components/CreateGroup';
import ChatHeaderBar from 'pages/chat/components/HeaderBar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IEdoChatParticipation } from 'shared/model/edo-chat-participation.model';
import { IEdoChat } from 'shared/model/edo-chat.model';
import { ChatState, getUserParticipations } from 'shared/reducers/chat.reducer';

interface ChatDrawerProps {
    open: boolean;
    onClose: () => void;
}

const ChatDrawer = (props: ChatDrawerProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { participations } = useAppSelector<ChatState>(state => state.chat);
    const [editDialog, setEditDialog] = useState<{ open: boolean; entity: IEdoChat | null; }>({ open: false, entity: null });

    const { open, onClose: handleClose } = props;

    const handleSelectParticipation = (participation: IEdoChatParticipation) => {
        if (participation && participation.chat) {
            navigate(`/chat/${participation.chat.id}`);
            handleClose();
        }
    };

    useEffect(() => {
        const getParticipations = () => {
            dispatch(
                getUserParticipations({
                    page: 0,
                    size: 1000,
                    sort: "id:ASC",
                })
            );
        };

        getParticipations();
    }, []);

    return (
        <>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 270,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Typography variant="h6" sx={{ p: 2 }}>Chat</Typography>
                <Divider />
                <ChatHeaderBar onEdit={(chat) => setEditDialog({ open: true, entity: chat })} showAccount={false} />
                <Divider />
                <ChatList
                    participations={participations}
                    onSelectParticipation={handleSelectParticipation}
                />
            </Drawer>
            <CreateGroup
                gruppo={editDialog.entity}
                open={editDialog.open}
                handleClose={() => setEditDialog({ open: false, entity: null })}
            />
        </>
    );
};

export default ChatDrawer;