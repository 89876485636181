import { Videocam as VideocamIcon } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography
} from "@mui/material";
import axios from "axios";
import { useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { IEdoClassZoomLink } from "shared/model/edo-class-zoom-link.model";
import { IEdoClass } from "shared/model/edo-class.model";
import { EntityState } from "shared/reducers/reducer.utils";

interface IZoomLinksBoxProps {
  class: IEdoClass;
}

const ZoomLinksBox = (props: IZoomLinksBoxProps) => {
  const [classZoomLinks, setClassZoomLinks] = useState<IEdoClassZoomLink[]>([]);
  const { updateSuccess } = useAppSelector<EntityState<IEdoClassZoomLink>>((state) => state.edoClassZoomLink);

  const { class: edoClass } = props;


  const getClassZoomLinks = async () => {
    const response = await axios.get<{ data: IEdoClassZoomLink[] }>(`/classes/${edoClass.id}/zoom-links`);
    setClassZoomLinks(response.data.data);
  };

  useEffect(() => {
    if (edoClass?.id) {
      getClassZoomLinks();
    }
  }, [edoClass?.id]);

  useEffect(() => {
    if (updateSuccess) {
      getClassZoomLinks();
    }
  }, [updateSuccess]);

  return (
    <Paper sx={{ color: "text.secondary", overflow: "hidden" }}>
      <List
        component="div"
        subheader={
          <ListSubheader component="div">
            <Translate contentKey="edocendoApp.edoClassZoomLink.home.title" />
          </ListSubheader>
        }
      >
        {classZoomLinks.length ? (
          classZoomLinks.map((classZoomLink) => (
            <ListItem
              key={classZoomLink.id}
              disablePadding
              disableGutters
            >
              <ListItemButton onClick={() => window.open(classZoomLink.zoomInviteLink, "_blank")}>
                <ListItemIcon>
                  <VideocamIcon />
                </ListItemIcon>
                <ListItemText
                  primary={classZoomLink.label}
                />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <Box p={2}>
            <Typography variant="body1" align="center">
              <Translate contentKey="edocendoApp.edoClassZoomLink.home.notFound" />
            </Typography>
          </Box>
        )}
      </List>
    </Paper>
  );
};

export default ZoomLinksBox;
