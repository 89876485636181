import { AppBar, Box, Button, Grid, Link as MaterialLink, Paper, Toolbar, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';
import { PasswordResetState, handlePasswordResetFinish, reset } from "../password-reset.reducer";

const useStyles = makeStyles()(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  content: {
    padding: '20px'
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  infoText: {
    marginBottom: '8px'
  },
  root: {
    display: "grid",
    gridTemplateRows: "min-content auto min-content",
    height: "100%",
    maxWidth: "800px",
  },
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
  appbar: {
    boxShadow: "none",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
  },
  toolbar: {
    justifyContent: "space-between",
  }
}));

const PasswordResetFinishPage = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [token] = useState<string>(searchParams.get("token") || "");
  const [userId] = useState<string>(searchParams.get("userId") || "");
  const { resetPasswordSuccess } = useAppSelector<PasswordResetState>(
    (state) => state.passwordReset
  );

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const handleValidSubmit = () => {
    dispatch(handlePasswordResetFinish({ userId, token, newPassword: password }));
  };

  const getResetForm = () => {

    const missingTokenMessageBox = () => (
      <Typography variant="body1" className={classes.infoText}>
        <Translate contentKey="reset.finish.messages.keymissing" />
      </Typography>);


    const successMessageBox = () => (
      <Typography variant="body1" className={classes.infoText}>
        <Translate contentKey="reset.finish.messages.success" />
      </Typography>);

    const passwordForm = () => (
      <>
        <Typography variant="body1" className={classes.infoText}>
          <Translate contentKey="reset.finish.messages.info" />
        </Typography>
        <ValidatorForm onSubmit={handleValidSubmit} onError={(errors) => false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                required
                fullWidth
                label={translate('global.form.newpassword.label')}
                placeholder={translate('global.form.newpassword.placeholder')}
                value={password || ""}
                onChange={(event) => setPassword((event.target as HTMLInputElement).value)}
                name="newPassword"
                type="password"
                autoComplete="new-password"
                validators={["required", "minStringLength:4", "maxStringLength:50"]}
                errorMessages={["This field is required.", "Your password is required to be at least 4 characters.", "Your password cannot be longer than 50 characters."]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                required
                fullWidth
                label={translate('global.form.confirmpassword.label')}
                placeholder={translate('global.form.confirmpassword.placeholder')}
                value={confirmPassword || ""}
                onChange={(event) => setConfirmPassword((event.target as HTMLInputElement).value)}
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                <Translate contentKey="reset.finish.form.button" />
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </>
    );

    return (
      <Box className={classes.container}>
        <Paper className={classes.paper}>
          <Box className={classes.root}>
            <AppBar
              position="static"
              color="transparent"
              className={classes.appbar}
            >
              <Toolbar className={classes.toolbar}>
                <Typography variant="h5">
                  <Translate contentKey="reset.finish.title" />
                </Typography>
              </Toolbar>
            </AppBar>
            <Box className={classes.content}>
              {!token ? missingTokenMessageBox() : (resetPasswordSuccess ? successMessageBox() : passwordForm())}
              <Box mt={2}>
                <Typography variant="body1" color="textSecondary" component="p" align="center">
                  <MaterialLink
                    to="/login"
                    color="primary"
                    component={Link}
                  >
                    Torna alla pagina principale
                  </MaterialLink>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>

      </Box>

    );
  };

  const successMessage = useAppSelector(
    (state) => state.passwordReset.successMessage
  );

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  return (
    <Box>
      {getResetForm()}
    </Box>
  );
};

export default PasswordResetFinishPage;


// validators={["required", "minStringLength:4", "maxStringLength:50"]}
// errorMessages={["This field is required.", "Your confirmation password is required to be at least 4 characters.", "Your confirmation password cannot be longer than 50 characters."]}
// eslint-disable-next-line no-lone-blocks
{/* <ValidatedField
          name="confirmPassword"
          label="New password confirmation"
          placeholder="Confirm the new password"
          type="password"
          validate={{
            required: {
              value: true,
              message: "Your confirmation password is required.",
            },
            minLength: {
              value: 4,
              message:
                "Your confirmation password is required to be at least 4 characters.",
            },
            maxLength: {
              value: 50,
              message:
                "Your confirmation password cannot be longer than 50 characters.",
            },
            validate: (v) =>
              v === password ||
              "The password and its confirmation do not match!",
          }}
          data-cy="confirmResetPassword"
        /> */}