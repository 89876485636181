import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, useMediaQuery,
    useTheme
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import {
    TextValidator,
    ValidatorForm
} from "react-material-ui-form-validator";
import { IEdoQuiz, defaultValue } from "shared/model/edo-quiz.model";
import {
    createEntity,
    updateEntity
} from "shared/reducers/entities/edo-quiz.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

interface IEditQuizProps {
    quiz?: IEdoQuiz;
    open: boolean;
    onClose: () => void;
}

const EditQuiz = (props: IEditQuizProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { loading, updating, updateSuccess } = useAppSelector<
        EntityState<IEdoQuiz>
    >((state) => state.edoQuiz);

    const [form, setForm] = useState<IEdoQuiz>({ ...defaultValue, ...props.quiz });

    const { quiz, open, onClose: handleClose } = props;

    const isNew = form?.id === null || form?.id === undefined;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        });
    };

    useEffect(() => {
        if (quiz) {
            setForm({ ...defaultValue, ...quiz });
        } else {
            setForm({ ...defaultValue });
        }
    }, [quiz]);

    useEffect(() => {
        if (open && updateSuccess) {
            handleClose();
        }
    }, [updateSuccess]);

    const saveEntity = () => {
        if (isNew) {
            dispatch(createEntity({ ...form }));
        } else {
            dispatch(updateEntity({ ...form }));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle>
                {translate("edocendoApp.edoQuiz.home.createOrEditLabel")}
            </DialogTitle>
            <DialogContent>
                {loading && !quiz ? (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                ) : (
                    <ValidatorForm
                        onSubmit={saveEntity}
                        onError={(errors) => false}
                        sx={{ flexGrow: 1 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    required
                                    label={translate("edocendoApp.edoQuiz.title")}
                                    onChange={handleChange}
                                    name="title"
                                    value={form.title || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    // size="small"
                                    fullWidth
                                    // required
                                    label={translate("edocendoApp.edoQuiz.description")}
                                    onChange={handleChange}
                                    name="description"
                                    value={form.description || ""}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button onClick={saveEntity} color="primary" disabled={updating}>
                    <Translate contentKey="entity.action.edit">Modifica</Translate>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditQuiz;