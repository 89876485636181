import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { deleteEntity } from "shared/reducers/entities/edo-course-material.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

interface DeleteCourseMaterialProps {
  courseMaterial: IEdoCourseMaterial;
  open: boolean;
  onClose?: () => void;
  onDelete?: (material: IEdoCourseMaterial) => void
}

const DeleteCourseMaterial = (
  props: DeleteCourseMaterialProps
) => {
  const dispatch = useAppDispatch();
  const { deleteSuccess } = useAppSelector<EntityState<IEdoCourse>>(
    (state) => state.edoCourseMaterial
  );

  const { open, onClose: handleOnClose, onDelete: handleOnDelete, courseMaterial } = props;

  const handleCloseDialog = () => {
    if (handleOnClose) handleOnClose();
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(courseMaterial.id)).then(() => {
      if (handleOnDelete) handleOnDelete(courseMaterial);
    });
  };

  useEffect(() => {
    if (deleteSuccess) {
      handleCloseDialog();
    }
  }, [deleteSuccess]);

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      {courseMaterial ? (
        <DialogContent>
          <DialogContentText>
            <Translate contentKey="edocendoApp.edoCourseMaterial.delete.question">
              Are you sure you want to delete this EdoSubjectleInsegnamento?
            </Translate>
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button onClick={confirmDelete} color="primary">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCourseMaterial;