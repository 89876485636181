import { createSlice } from "@reduxjs/toolkit";
import { THEME_MODE_KEY } from "shared/util/global-settings";

type ThemeType = "light" | "dark";
export interface SettingsState {
    theme: ThemeType;
}

const initialState: Readonly<SettingsState> = {
    theme: localStorage.getItem(THEME_MODE_KEY) as ThemeType || "light",
};

// Actions

export const SettingsSlice = createSlice({
    name: "settings",
    initialState: initialState as SettingsState,
    reducers: {
        invertTheme(state) {
            return { ...state, theme: state.theme === "light" ? "dark" : "light" };
        },
        setTheme(state, action) {
            return action.payload;
        }
    },
});

export const { invertTheme, setTheme } = SettingsSlice.actions;

// Reducer
export default SettingsSlice.reducer;
