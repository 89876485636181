import {
  People as PeopleIcon,
  Person as PersonIcon
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import axios from "axios";
import { useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IEdoChatParticipation } from "shared/model/edo-chat-participation.model";
import { EdoChatType } from "shared/model/enumerations/edo-chat-type.model";
import { ChatState } from "shared/reducers/chat.reducer";

const Info = () => {
  const params = useParams<{ id: string }>();
  const [participations, setParticipations] = useState<IEdoChatParticipation[]>([]);
  const { selectedParticipation } = useAppSelector<ChatState>(state => state.chat);

  useEffect(() => {
    const getAllParticipations = async () => {
      const response = await axios.get(
        '/chat-participations', {
        params: {
          page: 0,
          limit: 20,
          sortBy: "createdAt:ASC",
          'filter.chat.id': '$eq:' + params.id
        }
      }
      );
      setParticipations(response.data.data);
    };

    if (params.id) {
      getAllParticipations();
    }
  }, [params.id]);

  return (
    <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
      <Box>
        {selectedParticipation?.chat ? (
          <List>
            <ListItem key={selectedParticipation?.chat.id}>
              <ListItemIcon>
                <Avatar alt={selectedParticipation.chat.type === EdoChatType.CHAT
                  ? selectedParticipation.title
                  : selectedParticipation.chat.title}>
                  {selectedParticipation?.chat.type === EdoChatType.CHAT ? (
                    <PersonIcon />
                  ) : (
                    <PeopleIcon />
                  )}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={selectedParticipation.chat.type === EdoChatType.CHAT
                ? selectedParticipation.title
                : selectedParticipation.chat.title}></ListItemText>
            </ListItem>
          </List>
        ) : null}
      </Box>
      <Divider />
      <Box flexGrow={1} style={{ overflow: "hidden" }}>
        <List subheader={<ListSubheader>Membri della chat</ListSubheader>}>
          {participations.map((participation, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  {participation.user.firstName[0] +
                    participation.user.lastName[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  participation.user.firstName +
                  " " +
                  participation.user.lastName
                }
              />
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <Box display="flex" justifyContent="center" p={1}>
          <Button color="secondary">Elimina chat</Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Info;
