import {
    Box, Container
} from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import QuizAttemptRoute from "./QuizAttempt/quiz-attempt";
import QuizAttemptReviewRoute from "./QuizAttemptReview/quiz-attempt-review";

const QuizAttemptsRoute = () => {
    const location = useLocation();

    return (
        <Box
            component="main"
            flexGrow={1}
            minHeight="100vh"
            overflow="auto"
        >
            <Container
                maxWidth="lg"
                sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingRight: 4,
                    paddingLeft: 4,
                }}
            >
                <Routes>
                    <Route path=":id">
                        <Route
                            path="review"
                            element={<QuizAttemptReviewRoute />}
                        />
                        <Route index element={<QuizAttemptRoute />} />
                    </Route>
                </Routes>
            </Container>
        </Box>
    );
};

export default QuizAttemptsRoute;
