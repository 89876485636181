import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import axios from "axios";
import { IPaginationResponse } from "shared/util/pagination.constants";
import { IEdoChapter, defaultValue } from "../../model/edo-chapter.model";
import { cleanEntity } from "../../util/entity-utils";
import {
  EntityState,
  IQueryParamsWithFilters,
  createEntitySlice,
  serializeAxiosError
} from "../reducer.utils";

const initialState: EntityState<IEdoChapter> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "/chapters";

// Actions

export const getEntities = createAsyncThunk(
  "edoChapter/fetch_entity_list",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `${apiUrl}`;
    } else {
      requestUrl = `${apiUrl}?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoChapter>>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "edoChapter/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEdoChapter>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "edoChapter/create_entity",
  async (entity: IEdoChapter, thunkAPI) => {
    const result = await axios.post<IEdoChapter>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "edoChapter/update_entity",
  async (entity: IEdoChapter, thunkAPI) => {
    const result = await axios.put<IEdoChapter>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "edoChapter/partial_update_entity",
  async (entity: IEdoChapter, thunkAPI) => {
    const result = await axios.patch<IEdoChapter>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "edoChapter/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEdoChapter>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getChaptersByUser = createAsyncThunk(
  "edoChapter/get_chapater_by_user",
  async ({
    userId,
    page,
    size,
    sort,
    filters,
  }: { userId: string } & IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `/teachers/${userId}/chapters`;
    } else {
      requestUrl = `/teachers/${userId}/chapters?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoChapter>>(requestUrl);
  }
);

// slice

export const EdoChapterSlice = createEntitySlice({
  name: "edoChapter",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(
        isFulfilled(getEntities, getChaptersByUser),
        (state, action) => {
          const {
            data: {
              data,
              meta: { totalItems },
            },
            headers,
          } = action.payload;

          return {
            ...state,
            loading: false,
            entities: data,
            totalItems,
          };
        }
      )
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(
        isPending(getEntities, getEntity, getChaptersByUser),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = EdoChapterSlice.actions;

// Reducer
export default EdoChapterSlice.reducer;
