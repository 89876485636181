import { Box, Container, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Wiki from "./wiki";



const WikiRoutes = () => {
  return (
    <Box sx={{
      flexGrow: 1,
      minHeight: "100vh",
      overflow: "auto",
    }}>
      <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3 }}>
        <Grid container spacing={3}>
          <Routes>
            <Route index element={<Wiki />} />
          </Routes>
        </Grid>
      </Container>
    </Box>
  );
};

export default WikiRoutes;
