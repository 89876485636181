import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ChatState, getUserParticipationByChat, setSelectedParticipation } from "shared/reducers/chat.reducer";

const ChatDetailRoutes = () => {
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const { selectedParticipation } = useAppSelector<ChatState>(state => state.chat);

    useEffect(() => {
        return () => {
            dispatch(setSelectedParticipation(null));
        };
    }, []);

    useEffect(() => {
        if (params.id && (selectedParticipation === null || selectedParticipation?.id !== Number(params.id))) {
            dispatch(getUserParticipationByChat(params.id));
        }
    }, [params.id]);


    return (
        <Outlet />
    );
};

export default ChatDetailRoutes;
