import { createAsyncThunk, isFulfilled, isPending } from "@reduxjs/toolkit";
import axios from "axios";
import { IEdoQuestionChoice } from "shared/model/edo-question-choice.model";
import { IPaginationResponse } from "shared/util/pagination.constants";
import { IEdoQuestion, defaultValue } from "../../model/edo-question.model";
import { cleanEntity } from "../../util/entity-utils";
import {
  EntityState,
  IQueryParamsWithFilters,
  createEntitySlice,
  serializeAxiosError
} from "../reducer.utils";

const initialState: EntityState<IEdoQuestion> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = "/questions";

// Actions

export const getEntities = createAsyncThunk(
  "edoQuestion/fetch_entity_list",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `${apiUrl}`;
    } else {
      requestUrl = `${apiUrl}?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoQuestion>>(requestUrl);
  }
);

export const getQuestionsByAccount = createAsyncThunk(
  "edoQuestion/get_questions_by_account",
  async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
    const params = new URLSearchParams(filters);
    if (sort) {
      params.append("page", String(page));
      params.append("limit", String(size));
      if (typeof sort === "string") {
        params.append("sortBy", sort.replace(",", ":"));
      } else {
        for (let s of sort) {
          params.append("sortBy", s.replace(",", ":"));
        }
      }
    }
    let requestUrl;
    if (params.toString() === "") {
      requestUrl = `/account/questions`;
    } else {
      requestUrl = `/account/questions?${params.toString()}`;
    }
    return axios.get<IPaginationResponse<IEdoQuestion>>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "edoQuestion/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<{ data: IEdoQuestion }>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "edoQuestion/create_entity",
  async (entity: IEdoQuestion, thunkAPI) => {
    const result = await axios.post<{ data: IEdoQuestion }>(
      apiUrl,
      cleanEntity(entity)
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "edoQuestion/update_entity",
  async (entity: IEdoQuestion, thunkAPI) => {
    const result = await axios.put<{ data: IEdoQuestion }>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "edoQuestion/partial_update_entity",
  async (entity: IEdoQuestion, thunkAPI) => {
    const result = await axios.patch<{ data: IEdoQuestion }>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "edoQuestion/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEdoQuestion>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const addChoiceToQuestion = createAsyncThunk(
  "edoQuestion/add_choice",
  async (
    {
      question,
      choice,
    }: { question: IEdoQuestion; choice: IEdoQuestionChoice },
    thunkAPI
  ) => {
    const result = await axios.post<{
      data: { question: IEdoQuestion; choice: IEdoQuestionChoice };
    }>(`${apiUrl}/${question.id}/choices`, cleanEntity(choice));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateQuestionChoice = createAsyncThunk(
  "edoQuestion/update_choice",
  async (
    {
      question,
      choice,
    }: { question: IEdoQuestion; choice: IEdoQuestionChoice },
    thunkAPI
  ) => {
    const result = await axios.put<{
      data: { question: IEdoQuestion; choice: IEdoQuestionChoice };
    }>(`${apiUrl}/${question.id}/choices/${choice.id}`, cleanEntity(choice));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateCorrectChoices = createAsyncThunk(
  "edoQuestion/update_correct_choices",
  async (
    {
      question,
      choices,
    }: { question: IEdoQuestion; choices: IEdoQuestionChoice[] },
    thunkAPI
  ) => {
    const result = await axios.patch<{
      data: { question: IEdoQuestion; choices: IEdoQuestionChoice[] };
    }>(`${apiUrl}/${question.id}/correct-choices/`, {
      choices,
    });
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateQuestionChoices = createAsyncThunk(
  "edoQuestion/update_choices",
  async (
    {
      question,
      choices,
    }: { question: IEdoQuestion; choices: IEdoQuestionChoice[] },
    thunkAPI
  ) => {
    const result = await axios.put<{
      data: { question: IEdoQuestion; choices: IEdoQuestionChoice[] };
    }>(`${apiUrl}/${question.id}/choices`, { choices }, {});
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const addQuestionToQuiz = createAsyncThunk(
  "edoCourseQuiz/add_question_to__quiz",
  async (
    { quizId, question }: { quizId: string | number; question: IEdoQuestion },
    thunkAPI
  ) => {
    const requestUrl = `/quizzes/${quizId}/questions`;
    const result = await axios.post<{ data: IEdoQuestion }>(
      requestUrl,
      question
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const EdoQuestionSlice = createEntitySlice({
  name: "edoQuestion",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(
        isFulfilled(getEntities, getQuestionsByAccount),
        (state, action) => {
          const {
            data: {
              data,
              meta: { totalItems },
            },
            headers,
          } = action.payload;

          return {
            ...state,
            loading: false,
            entities: data,
            totalItems,
          };
        }
      )
      .addMatcher(
        isFulfilled(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          addQuestionToQuiz
        ),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data.data;
        }
      )
      .addMatcher(
        isFulfilled(
          addChoiceToQuestion,
          updateQuestionChoice,
          updateCorrectChoices,
          updateQuestionChoices
        ),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data.data.question;
        }
      )
      .addMatcher(
        isPending(getEntities, getQuestionsByAccount, getEntity),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity,
          addChoiceToQuestion,
          updateQuestionChoice,
          updateCorrectChoices,
          updateQuestionChoices,
          addQuestionToQuiz
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = EdoQuestionSlice.actions;

// Reducer
export default EdoQuestionSlice.reducer;
