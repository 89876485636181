import {
    Assignment as AssignmentIcon,
    Delete as DeleteIcon,
    Edit as EditIcon, Grading as GradingIcon, MoreVert as MoreVertIcon,
    Quiz as QuizIcon
} from "@mui/icons-material";
import {
    Avatar, Box, IconButton, ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem, Typography
} from "@mui/material";
import { APP_DATE_FORMAT_MID, AUTHORITIES } from "config/constants";
import { useAppSelector } from "config/store";
import React, { useEffect, useState } from 'react';
import { TextFormat } from "react-jhipster";
import { useNavigate } from 'react-router-dom';
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoCourseQuiz } from "shared/model/edo-course-quiz.model";
import { AuthenticationState } from "shared/reducers/authentication";

interface ICourseQuizRowProps {
    courseQuiz: IEdoCourseQuiz;
    onOpen: (courseQuiz: IEdoCourseQuiz) => void;
    onEdit: (quiz: IEdoCourseQuiz) => void
    onDelete: (quiz: IEdoCourseQuiz) => void;
}

export const CourseQuizRow = (props: ICourseQuizRowProps) => {
    const navigate = useNavigate();
    const [localQuiz, setLocalQuiz] = useState<IEdoCourseQuiz>(props.courseQuiz);
    const { account } = useAppSelector<AuthenticationState>((state) => state.authentication);
    const [anchorMoreQuiz, setAnchorMoreQuiz] =
        useState<null | HTMLElement>(null);

    const { courseQuiz, onDelete: handleDelete, onEdit: handleEdit, onOpen: handleOpen } = props;

    const handleEditQuestions = () => {
        navigate(`/course-quizzes/${courseQuiz.id}`);
    };

    const handleMoreQuizButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorMoreQuiz(event.currentTarget);
    };

    const handleCloseMoreQuizMenu = () => {
        setAnchorMoreQuiz(null);
    };

    useEffect(() => {
        if (courseQuiz) {
            setLocalQuiz(courseQuiz);
        }
    }, [courseQuiz]);

    return (
        <ListItem button onClick={() => handleOpen(localQuiz)}>
            <ListItemAvatar>
                <Avatar alt={localQuiz.quiz.title}><AssignmentIcon /></Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={localQuiz.quiz.title}
                secondary={<TextFormat
                    type="date"
                    value={localQuiz.createdAt}
                    format={APP_DATE_FORMAT_MID}
                    locale="it"
                />}
            />
            <ListItemSecondaryAction>
                <Box display="flex" flexDirection="row">
                    {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_TEACHER, AUTHORITIES.ROLE_VICE]) ? <Box mr={2}>
                        <IconButton
                            edge="end"
                            onClick={handleMoreQuizButtonClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box> : null}
                    <Menu
                        id="more-quiz-menu"
                        anchorEl={anchorMoreQuiz}
                        keepMounted
                        open={Boolean(anchorMoreQuiz)}
                        onClose={handleCloseMoreQuizMenu}
                        disableScrollLock={true}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleCloseMoreQuizMenu();
                            handleEdit(localQuiz);
                        }}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Modifica quiz</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleCloseMoreQuizMenu();
                            handleEditQuestions();
                        }}>
                            <ListItemIcon>
                                <QuizIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Modifica domande</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleCloseMoreQuizMenu();
                            navigate(`/courses/${localQuiz.course.id}/quizzes/${localQuiz.id}/results`);
                        }}>
                            <ListItemIcon>
                                <GradingIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Visualizza risultati</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleCloseMoreQuizMenu();
                            handleDelete(localQuiz);
                        }
                        }>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                Elimina quiz
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default CourseQuizRow;

