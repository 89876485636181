import {
    Button,
    Divider, List, ListSubheader, Paper, Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { APP_DATE_FORMAT_EXPANDED } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { useNavigate, useParams } from "react-router-dom";
import { IEdoCourseQuiz } from "shared/model/edo-course-quiz.model";
import { defaultValue, IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getEntity as getCourseQuizEntity } from "shared/reducers/entities/edo-course-quiz.reducer";
import { createEntity as createQuizAttemptEntity, getEntities as getAttempts } from "shared/reducers/entities/edo-quiz-attempt.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { makeStyles } from 'tss-react/mui';
import UserAttemptRow from "./components/UserAttemptRow";

const useStyles = makeStyles()(theme =>
    ({
        paper: {
            padding: theme.spacing(3),
            textAlign: "center",
            color: theme.palette.text.secondary,
            overflow: "hidden",
        },
    })
);

const StartQuizRoute = () => {
    const { classes } = useStyles();
    const params = useParams<{ courseQuizId: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { entity: courseQuiz } = useAppSelector<EntityState<IEdoCourseQuiz>>(state => state.edoCourseQuiz);
    const { entities: userAttempts, entity: userAttempt } = useAppSelector<EntityState<IEdoQuizAttempt>>(state => state.edoQuizAttempt);
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);

    const getUserAttempts = () => {
        const filters = new URLSearchParams();
        filters.append("filter.user.id", '$eq:' + account.id);
        filters.append("filter.courseQuiz", '$eq:' + params.courseQuizId);
        dispatch(getAttempts({
            sort: "updatedAt,DESC",
            filters: filters.toString(),
        }));
    };

    useEffect(() => {
        if (params.courseQuizId) {
            dispatch(getCourseQuizEntity(params.courseQuizId));
            getUserAttempts();
        }
    }, [params.courseQuizId]);

    const handleStartQuiz = () => {
        dispatch(createQuizAttemptEntity({
            ...defaultValue,
            courseQuiz,
        })).then((response) => {
            const id = (response.payload as AxiosResponse<{ data: IEdoCourseQuiz }>).data.data.id;
            navigate(`/quiz-attempts/${id}`);
        });
    };

    return (
        <Paper className={classes.paper}>
            <Box mb={2}>
                <Typography variant="h4" color="text.primary">{courseQuiz?.quiz?.title}</Typography>
            </Box>
            <Box mb={2}>
                <Typography variant="body1" color="text.secondary" sx={{ whiteSpace: "pre-wrap" }}>{courseQuiz?.quiz?.description}</Typography>
            </Box>
            {courseQuiz.availableAttempts ? (
                <Box mb={2}>
                    <Typography variant="body1" color="text.secondary">Tentativi a disposizione: {courseQuiz?.availableAttempts}</Typography>
                </Box>
            ) : null}
            {courseQuiz.duration ? (
                <Box mb={2}>
                    <Typography variant="body1" color="text.primary">Durata</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${Math.trunc(courseQuiz.duration / 60)} ore, ${Math.trunc(courseQuiz.duration % 60)} minuti, ${Math.trunc(courseQuiz.duration % 60 / 60)} secondi`}
                    </Typography>
                </Box>
            ) : null}
            {courseQuiz.timeOpen ? (
                <Box mb={2}>
                    <Typography variant="body1" color="text.primary">Disponibile dal</Typography>
                    <Typography variant="body2" color="text.secondary">
                        <TextFormat
                            type="date"
                            value={courseQuiz.timeOpen}
                            format={APP_DATE_FORMAT_EXPANDED}
                            locale="it"
                        />
                    </Typography>
                </Box>
            ) : null}
            {courseQuiz.timeClose ? (
                <Box mb={2}>
                    <Typography variant="body1" color="text.primary">Termina il</Typography>
                    <Typography variant="body2" color="text.secondary">
                        <TextFormat
                            type="date"
                            value={courseQuiz.timeClose}
                            format={APP_DATE_FORMAT_EXPANDED}
                            locale="it"
                        />
                    </Typography>
                </Box>
            ) : null}
            <Box mt={4}>
                {!courseQuiz.timeClose || (new Date(courseQuiz.timeClose)).getTime() > (new Date()).getTime() ? (
                    <Button variant="contained" color="primary" onClick={handleStartQuiz}>Inizia il quiz</Button>
                ) : null}
            </Box>
            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
            <List dense subheader={
                <ListSubheader sx={{ textAlign: "left" }}>
                    Tentativi
                </ListSubheader>
            }>
                {userAttempts.length ?
                    userAttempts.map((userAttempt, index) => (
                        <UserAttemptRow key={userAttempt.id} quizAttempt={{ ...userAttempt, courseQuiz }} onOpen={(quizAttempt) => navigate(`/quiz-attempts/${quizAttempt.id}`)} />
                    )) : (
                        <Box p={2}>
                            <Typography variant="body1" align="center">
                                <Translate contentKey="edocendoApp.edoQuizAttempt.home.notFound" />
                            </Typography>
                        </Box>
                    )}
            </List>
        </Paper>
    );
};

export default StartQuizRoute;