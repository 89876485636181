import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationState } from "shared/reducers/authentication";

const PasswordExpirationPopUp = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(0);
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);

    useEffect(() => {
        if (!account.nextResetDate) return;
        const nextResetDate = new Date(account.nextResetDate);
        const currentDate = new Date();
        const timeForNextReset = nextResetDate.getTime() - currentDate.getTime();

        if (timeForNextReset <= 0) {
            navigate("/logout");
            return;
        }
        setCountdown(timeForNextReset);

        const passwordExpirationCheck = localStorage.getItem('passwordExpirationCheck');
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

        const daysForNextReset = timeForNextReset / (oneDayInMilliseconds);

        if (daysForNextReset <= 30) {
            if (!passwordExpirationCheck) {
                setOpen(true);
                return;
            }

            const passwordExpirationCheckDate = new Date(passwordExpirationCheck);
            const timeSinceLastCheck = (new Date().getTime() - passwordExpirationCheckDate.getTime());

            if (daysForNextReset <= 30 && timeSinceLastCheck >= oneDayInMilliseconds * 30) {
                localStorage
                    .removeItem('passwordExpirationCheck');
                setOpen(true);
            } else if (daysForNextReset <= 7 && timeSinceLastCheck >= oneDayInMilliseconds * 7) {
                localStorage
                    .removeItem('passwordExpirationCheck');
                setOpen(true);
            } else if (daysForNextReset <= 1 && timeSinceLastCheck >= oneDayInMilliseconds) {
                localStorage
                    .removeItem('passwordExpirationCheck');
                setOpen(true);
            }
        }
    }, [account.resetDate]);

    const handleCheck = () => {
        localStorage
            .setItem('passwordExpirationCheck', new Date().toISOString());
        setOpen(false);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>
                Scadenza password
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    La tua password scadrà tra {Math.floor(countdown / (1000 * 60 * 60 * 24))} giorni.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCheck} color="primary">
                    Continua
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordExpirationPopUp;