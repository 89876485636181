import moment from "moment";
import { IEdoCoursePost } from "shared/model/edo-course-post.model";
import { IUser } from "shared/model/user.model";
export interface IEdoCourseComment {
  id?: number;
  description?: string;
  createdAt?: string;
  author?: IUser;
  post?: IEdoCoursePost;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoCourseComment> = {
  createdAt: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
};
