import moment from "moment";
import { IEdoWallPost } from "shared/model/edo-wall-post.model";
import { IUser } from "shared/model/user.model";

export interface IEdoWallComment {
  id?: number;
  description?: string;
  createdAt?: string;
  author?: IUser;
  post?: IEdoWallPost;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoWallComment> = {
  createdAt: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
};
