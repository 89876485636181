import { Send as SendIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, IconButton, TextField } from "@mui/material";
import { useAppSelector } from "config/store";
import { useState } from "react";
import { IEdoCourseComment } from "shared/model/edo-course-comment.model";
import { IEdoWallComment } from "shared/model/edo-wall-comment.model";
import { AuthenticationState } from "shared/reducers/authentication";

type CreateCommentProps = {
  reloadCommentList: () => void;
  saveComment: (comment: IEdoWallComment) => Promise<IEdoWallComment>;
  defaultValueComment: IEdoWallComment;
} | {
  reloadCommentList: () => void;
  saveComment: (comment: IEdoCourseComment) => Promise<IEdoCourseComment>;
  defaultValueComment: IEdoCourseComment;
}

const CreateComment = (props: CreateCommentProps) => {
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const [comment, setComment] = useState<IEdoWallComment | IEdoCourseComment>({ ...props.defaultValueComment });
  const [updating, setUpdating] = useState<boolean>(false);

  const { reloadCommentList, saveComment } = props;

  const clearComment = () => {
    setComment({ ...props.defaultValueComment, description: "" });
  };

  const handleSaveComment = async () => {
    setUpdating(true);
    await saveComment(comment);
    setUpdating(false);
    clearComment();
    reloadCommentList();
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        mr={2}
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "none",
          }
        })}
      >
        <Avatar style={{ backgroundColor: "primary" }} src="/profile_neutral.svg" />
      </Box>
      <Box flex="1" mr={1}>
        <TextField
          style={{ margin: 0 }}
          fullWidth
          label="Aggiungi un commento al post"
          size="small"
          placeholder="Aggiungi un commento al post"
          margin="none"
          variant="outlined"
          multiline
          maxRows={3}
          value={comment.description}
          onChange={(event) =>
            setComment({ ...comment, description: event.target.value })
          }
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <IconButton aria-label="share" size="small">
        //         <SendIcon style={{ transform: 'rotate(-90deg)' }} />
        //       </IconButton>
        //     </InputAdornment>
        //   ),
        // }}
        />
      </Box>
      <Box>
        <IconButton
          disabled={!comment.description}
          onClick={handleSaveComment}
          color="primary"
          sx={(theme) => ({
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
          })}
        >
          <SendIcon />
        </IconButton>
        <LoadingButton
          disabled={!comment.description}
          onClick={handleSaveComment}
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          loading={updating}
          sx={(theme) => ({
            margin: theme.spacing(1),
            [theme.breakpoints.down("sm")]: {
              display: "none",
            }
          })}
        >
          Invia
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CreateComment;