import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import "moment/locale/it";
import { useContext, useState } from "react";
import { Translate } from "react-jhipster";
import { useNavigate } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoChat } from "shared/model/edo-chat.model";
import { IUser } from "shared/model/user.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getParticipationsByUser } from "shared/reducers/entities/edo-chat-participation.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

interface CreateChatProps {
  
}

export const CreateChat = (props: CreateChatProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { updating } = useAppSelector<
    EntityState<IEdoChat>
  >((state) => state.edoChat);
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const [utenteList, setUtenteList] = useState<IUser[]>([]);
  const [selectedUtente, setSelectedUtente] = useState<IUser | null>(null);
  const [utenteValue, setUtenteValue] = useState<string>("");
  const [loadingUtenteList, setLoadingUtenteList] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const socket = useContext(SocketContext);

  const handleCloseDialog = () => {
    setOpen(false);
  };


  const reloadPartecipazioneList = () => {
    dispatch(
      getParticipationsByUser({
        page: 0,
        size: 1000,
        sort: "id:ASC",
      })
    );
  };

  const searchUtente = async (query: string) => {
    setLoadingUtenteList(true);
    const roleFilter = hasAnyAuthority(account.roles, [
      AUTHORITIES.ROLE_STUDENT,
    ])
      ? { "filter.roles.name": '$eq:' + AUTHORITIES.ROLE_TEACHER }
      : {};
    const response = await axios.get("/users", {
      params: {
        page: 0,
        size: 5,
        sortBy: "firstName:ASC",
        search: query,
        "filter.id": '$not:' + account.id,
        ...roleFilter,
      },
    });
    setUtenteList(response.data.data);
    setLoadingUtenteList(false);
  };

  const createChat = async () => {
    const response = await axios.post("/chats/chat", {
      user: selectedUtente,
    });
    handleCloseDialog();
    reloadPartecipazioneList();
    setSelectedUtente(null);

    const  {chat, participation}  = response.data;

    navigate(`/chat/${participation.chat.id}`, {
      state: { partecipazione: participation },
    });
    
    socket.emit('chat-created', chat);
   
  };

  return (
    <>
      <Autocomplete
        multiple={false}
        options={utenteList}
        getOptionLabel={(option: IUser) =>
          option.firstName + "  " + option.lastName
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loadingText="Ricerca..."
        noOptionsText="Nessun risultato"
        value={selectedUtente}
        filterSelectedOptions
        filterOptions={(options, state) => {
          return options;
        }}
        // open={Boolean(utenteValue)}
        forcePopupIcon={false}
        size="small"
        loading={loadingUtenteList}
        freeSolo={false}
        onChange={(event, newValue, reason) => {
          if (reason === "selectOption") {
            setSelectedUtente({ ...newValue });
            setUtenteValue("");
            setOpen(true);
          } else if (reason === "clear") {
            setSelectedUtente(null);
            setUtenteValue("");
          }
        }}
        onInputChange={(event, value, reason) => {
          if (reason === "input") {
            setUtenteValue(value);
            searchUtente(value);
          }
        }}
        inputValue={utenteValue}
        renderInput={(autocompleteParams) => (
          <TextField
            {...autocompleteParams}
            variant="outlined"
            label="Cerca altre persone"
            placeholder="Cerca altre persone"
          />
        )}
      />
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullScreen={fullScreen}
        fullWidth
      >
        <DialogTitle>Inizia una nuova chat</DialogTitle>
        {selectedUtente ? (
          <DialogContent>
            <DialogContentText>
              Vuoi iniziare una nuova chat con{" "}
              {selectedUtente.firstName + " " + selectedUtente.lastName}?
            </DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button
            onClick={() => {
              createChat();
            }}
            color="primary"
            disabled={updating}
          >
            Crea chat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
