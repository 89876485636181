import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import axios from "axios";
import { IEdoParent } from "shared/model/edo-parent.model";
import { IEdoStudent } from "shared/model/edo-student.model";
import { IUserBase } from "shared/model/user.model";
import {
    serializeAxiosError
} from "../reducer.utils";

export const initialState: {
    count: number | null;
    loadingCount: boolean;
    children: { entities: IEdoStudent[], loading: boolean; totalItems: number };
    errorMessage: string | null;
    entity: { entity: IEdoParent; loading: boolean; updating: boolean; updateSuccess: boolean };
} = {
    count: null,
    loadingCount: false,
    children: { entities: [], loading: false, totalItems: 0 },
    errorMessage: null,
    entity: {
        entity: null,
        loading: false,
        updating: false,
        updateSuccess: false
    }
};

export type EdoParentState = Readonly<typeof initialState>;

const apiUrl = "/parents";

// Actions

export const createParent = createAsyncThunk(
    "edoParent/create_parent",
    async (user: IUserBase, thunkAPI) => {
        const result = await axios.post<{ data: IEdoParent }>(apiUrl, user);
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const getParent = createAsyncThunk(
    "edoParent/get_parent",
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<{ data: IEdoParent }>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const getCount = createAsyncThunk(
    "edoParent/fetch_count",
    async () => {
        const requestUrl = `${apiUrl}/count`;
        return axios.get<{ count: number }>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const getParentChildren = createAsyncThunk(
    "edoParent/get_children",
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}/children`;
        return axios.get<{ data: IEdoStudent[] }>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

// slice

export const EdoParentSlice = createSlice({
    name: "edoParent",
    initialState: initialState as EdoParentState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(
                getParentChildren.fulfilled,
                (state, action) => {
                    const {
                        data: {
                            data,
                            // meta: { totalItems },
                        },
                        headers,
                    } = action.payload;

                    return {
                        ...state,
                        children: {
                            ...state.children,
                            entities: data,
                            // totalItems,
                            loading: false
                        }
                    };
                }
            )
            .addCase(
                getParent.fulfilled,
                (state, action) => {
                    return {
                        ...state,
                        entity: {
                            ...state.entity,
                            loading: false,
                            entity: action.payload.data.data
                        },
                    };
                }
            )
            .addCase(getCount.fulfilled, (state, action) => ({
                ...state,
                loadingCount: false,
                count: action.payload.data.count
            }))
            .addCase(
                getCount.pending,
                (state) => {
                    state.errorMessage = null;
                    state.loadingCount = true;
                }
            )
            .addMatcher(isFulfilled(createParent), (state, action) => {
                state.entity.updating = false;
                state.entity.loading = false;
                state.entity.updateSuccess = true;
                state.entity.entity = action.payload.data.data;
            })
            .addMatcher(isPending(createParent), (state) => {
                state.errorMessage = null;
                state.entity.updateSuccess = false;
                state.entity.updating = true;
            })
            .addMatcher(
                isPending(getParentChildren),
                (state) => {
                    state.errorMessage = null;
                    state.children.loading = true;
                }
            )
            .addMatcher(isPending(getParent), (state) => {
                state.errorMessage = null;
                state.entity.updateSuccess = false;
                state.entity.loading = true;
            })
            .addMatcher(
                isRejected(
                    createParent,
                ),
                (state, action) => {
                    state.entity.loading = false;
                    state.entity.updating = false;
                    state.entity.updateSuccess = false;
                    state.errorMessage = action.error.message;
                }
            )
            .addMatcher(
                isRejected(
                    getParentChildren,
                ),
                (state, action) => {
                    state.children.loading = false;
                    state.errorMessage = action.error.message;
                }
            );
    },
});

// Reducer
const EdoParentReducer = EdoParentSlice.reducer;
export default EdoParentReducer;
