import { Event as EventIcon } from "@mui/icons-material";
import {
  Box, Card, CardContent, CardHeader, Divider, List,
  ListItem,
  ListItemIcon,
  ListItemText, Typography
} from "@mui/material";
import axios from "axios";
import { ViewEventoInsegnamento } from "components/ViewEventoInsegnamento/ViewEventoInsegnamento";
import { APP_DATE_FORMAT } from "config/constants";
import { useEffect, useState } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { IEdoCourseEvent } from "shared/model/edo-course-event.model";

export const Eventi = () => {
  const [edoCourseEventList, setEdoCourseEventList] = useState<IEdoCourseEvent[]>(
    []
  );
  const [viewEvent, setViewEvent] = useState<boolean>(false);
  const [eventToView, setEventToView] = useState<IEdoCourseEvent | null>(null);

  const getEdoCourseEventList = async () => {
    const response = await axios.get("/account/events", {
      // params: {
      //   // ['edoInsegnamentoId.equals']: String(insegnamento.id),
      //   page: 0,
      //   size: 5,
      //   sort: 'dataInizio:DESC',
      //   cacheBuster: new Date().getTime(),
      // },
    });
    setEdoCourseEventList(response.data);
  };

  const openViewEvent = (event: IEdoCourseEvent) => {
    setViewEvent(true);
    setEventToView(event);
  };

  useEffect(() => {
    getEdoCourseEventList();
  }, []);

  // useEffect(() => {
  //   if (insegnamento.id) {
  //     getEdoCourseEventList();
  //   }
  // }, [insegnamento.id]);

  //   useEffect(() => {
  //     if (insegnamento.id) {
  //       getEdoCourseEventList();
  //     }
  //   }, [updateSuccess]);

  return (
    <>
      <Card
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          // boxShadow: "0px 1px 6px rgba(0,0,0,0.3)"
        })}
      >
        <CardHeader subheader="Eventi" sx={(theme) => ({ backgroundColor: theme.palette.background.paper })} />
        <Divider />
        <CardContent sx={{
          padding: 0,
          "&:last-child": {
            paddingBottom: 0
          }
        }}>
          <List>
            {edoCourseEventList && edoCourseEventList.length ? (
              edoCourseEventList.map((event) => (
                <ListItem
                  button
                  key={event.id}
                  onClick={() => openViewEvent(event)}
                >
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={event.title}
                    secondary={
                      <TextFormat
                        value={event.startDate}
                        type="date"
                        format={APP_DATE_FORMAT}
                      />
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Box p={2}>
                <Typography variant="body1" align="center">
                  <Translate contentKey="edocendoApp.edoCourseEvent.home.notFound">
                    No Edo Event Insegnamentos found
                  </Translate>
                </Typography>
              </Box>
            )}
          </List>
        </CardContent>
      </Card>

      <ViewEventoInsegnamento
        open={viewEvent}
        handleClose={() => setViewEvent(false)}
        eventId={eventToView?.id}
      />
    </>
  );
};

export default Eventi;
