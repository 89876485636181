import {
    Description as DescriptionIcon, Image as ImageIcon
} from "@mui/icons-material";
import { Avatar } from "@mui/material";

export const getFileAvatar = (mime: string | undefined | null) => {
    if (mime?.split("/")[0] === "image") {
        return (
            <Avatar sx={{ bgcolor: "#e53935" }}>
                <ImageIcon sx={{ color: "white" }} />
            </Avatar>
        );
    } else {
        return (
            <Avatar sx={{ bgcolor: "#64b5f6" }}>
                <DescriptionIcon sx={{ color: "white" }} />
            </Avatar>
        );
    }
};
