import { Add as AddIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { AUTHORITIES } from "config/constants";
import { useAppSelector } from "config/store";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoChat } from "shared/model/edo-chat.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { CreateChat } from "./CreateChat";

interface ChatHeaderBarProps {
  onEdit: (entity: IEdoChat) => void;
  showAccount?: boolean;
}

const ChatHeaderBar = (props: ChatHeaderBarProps) => {
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );

  const { onEdit: handleOnEdit, showAccount = true } = props;

  return (
    <>
      {showAccount ? (
        <>
          <List>
            <ListItem button key={account.firstName + " " + account.lastName}>
              <ListItemIcon>
                <Avatar alt={account.firstName + " " + account.lastName}>
                  {account.firstName[0] + account.lastName[0]}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={account.firstName + " " + account.lastName}
              ></ListItemText>
            </ListItem>
          </List>
          <Divider />
        </>
      ) : null}
      <Grid item xs={12} style={{ padding: "10px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pr={1} flexGrow={1}>
            <CreateChat />
          </Box>
          {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_TEACHER, AUTHORITIES.ROLE_SUPPORT_TEACHER, AUTHORITIES.ROLE_VICE]) ? (
            <Box>
              <IconButton onClick={() => handleOnEdit(null)}>
                <AddIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Grid>
    </>
  );
};

export default ChatHeaderBar;
