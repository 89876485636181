import { IEdoCourseYear } from "shared/model/edo-course-year.model";
import { IEdoSchool } from "shared/model/edo-school.model";
import { IEdoSubject } from "shared/model/edo-subject.model";
import { IEdoVideo } from "./edo-video.model";
import { EdoEducationalStage } from "./enumerations/edo-educational-stage.model";

export interface IEdoVideostorePost {
  id?: number;
  title?: string;
  video?: IEdoVideo;
  subject?: IEdoSubject;
  schools?: IEdoSchool[];
  educationalStage?: EdoEducationalStage;
  years?: IEdoCourseYear[];
  draft?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IEdoVideostorePost> = {};
