import { Add as AddIcon, Class as ClassIcon, Delete as DeleteIcon, Edit as EditIcon, GetApp as GetAppIcon, LibraryAdd as LibraryAddIcon, MoreVert as MoreVertIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  TablePagination,
  Tabs, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import DeleteWikiPost from "pages/wiki/components/DeleteWikiPost";
import EditWikiPost from "pages/wiki/components/EditWikiPost";
import { SyntheticEvent, useEffect, useState } from "react";
import { IPaginationBaseState, Translate, translate } from "react-jhipster";
import {
  SelectValidator,
  ValidatorForm
} from "react-material-ui-form-validator";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoCourseYear } from "shared/model/edo-course-year.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoWikiPost } from "shared/model/edo-wiki-post.model";
import { EdoEducationalStage } from "shared/model/enumerations/edo-educational-stage.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { EntityState } from "shared/reducers/reducer.utils";
import { DeleteDialogType, EditDialogType } from "shared/util/dialog-utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import { getEntities as getCourseYears } from "../../shared/reducers/entities/edo-course-year.reducer";
import { SubjectState, getSubjectsWithWikiPosts } from "../../shared/reducers/entities/edo-subject.reducer";
import { getEntities as getWikiPosts } from "../../shared/reducers/entities/edo-wiki-post.reducer";
import MultiUpload from "./components/MultiUpload";

const useStyles = makeStyles()(theme =>
({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
})
);

// const educationalStages = [EdoEducationalStage.INFANZIA, EdoEducationalStage.PRIMARIA, EdoEducationalStage.SECONDARIA_I, EdoEducationalStage.SECONDARIA_II];
const educationalStages = [EdoEducationalStage.SECONDARIA_II, EdoEducationalStage.SECONDARIA_I];


export const Wiki = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { account } = useAppSelector<AuthenticationState>(state => state.authentication);
  const edoPostWikiList = useAppSelector<EntityState<IEdoWikiPost>>((state) => state.edoWikiPost);
  const edoCourseYearList = useAppSelector<EntityState<IEdoCourseYear>>((state) => state.edoCourseYear);
  const { subjectsByWikiPosts: edoSubjectList } = useAppSelector<SubjectState>((state) => state.edoSubject);
  const [stageTab, setStageTab] = useState<EdoEducationalStage>(EdoEducationalStage.SECONDARIA_II);
  const [subjectFilter, setSubjectFilter] = useState<number | null>(null);
  const [editPostWikiDialog, setEditPostWikiDialog] = useState<EditDialogType<IEdoWikiPost>>({ open: false, entity: null });
  const [deletePostWikiDialog, setDeletePostWikiDialog] = useState<DeleteDialogType<IEdoWikiPost>>({ open: false, entity: null });
  const [multiUploadDialog, setMultiUploadDialog] = useState<{ open: boolean; }>({ open: false });
  const [anchorMoreMenu, setAnchorMoreMenu] =
    useState<null | HTMLElement>(null);
  const [yearTab, setYearTab] = useState<number>(null);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "title",
    order: "ASC",
  });

  const handleMoreMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorMoreMenu(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorMoreMenu(null);
  };

  const handleChangeStageTab = (event: SyntheticEvent<Element, Event>, newValue: EdoEducationalStage) => {
    setStageTab(newValue);
  };

  const handleChangeYearTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setYearTab(newValue);
  };

  const openFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  const downloadFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/download`, "_blank");
  };

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const getWikiPostList = () => {
    if (subjectFilter === null) return;

    const params = new URLSearchParams();
    params.append("filter.educationalStage", '$eq:' + stageTab);
    params.append("filter.years.id", '$eq:' + yearTab);
    params.append("filter.subject.id", '$eq:' + String(subjectFilter));

    dispatch(
      getWikiPosts({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: params.toString(),
      })
    );
  };

  useEffect(() => {
    dispatch(getCourseYears({ page: 0, size: 1000, sort: "year:ASC" }));
    // dispatch(getSubjects({ page: 0, size: 1000, sort: "name:ASC" }));
    dispatch(getSubjectsWithWikiPosts());
  }, []);

  useEffect(() => {
    getWikiPostList();
  }, [
    stageTab,
    yearTab,
    subjectFilter,
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage
  ]);

  useEffect(() => {
    if (edoSubjectList.entities.length) {
      setSubjectFilter(edoSubjectList.entities[0].id || null);
    }
  }, [edoSubjectList.entities]);

  useEffect(() => {
    if (edoCourseYearList.entities.length) {
      setYearTab(edoCourseYearList.entities[0].id || null);
    }
  }, [edoCourseYearList.entities]);

  useEffect(() => {
    if (edoPostWikiList.updateSuccess) {
      getWikiPostList();
    }
  }, [edoPostWikiList.updateSuccess]);

  const moreMenu = () => (
    <Menu
      id="more-menu"
      anchorEl={anchorMoreMenu}
      keepMounted
      open={Boolean(anchorMoreMenu)}
      onClose={handleCloseMoreMenu}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={() => {
        handleCloseMoreMenu();
        setEditPostWikiDialog({ open: true, entity: null });
      }}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">
          Caricamento singolo
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => {
        handleCloseMoreMenu();
        setMultiUploadDialog({ open: true });
      }}>
        <ListItemIcon>
          <LibraryAddIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">
          Caricamento multiplo
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Grid item xs={12}>
        <Paper style={{ overflow: "hidden", boxShadow: "0 0 5px rgba(0,0,0,0.2)" }}>
          <Grid container spacing={2} p={2}>
            <Grid item sm={true} xs={12} style={{ alignSelf: "center" }}>
              <Typography variant="h6">Dispense</Typography>
            </Grid>
            <Grid item sm="auto" xs={hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_ADMIN]) ? 10 : 12}>
              <ValidatorForm
                onSubmit={() => { }}
                onError={(errors) => false}
                className={classes.root}
              >
                <SelectValidator
                  sx={{ minWidth: 200 }}
                  fullWidth
                  label="Filtro materia"
                  name="subject"
                  size="small"
                  variant="outlined"
                  value={subjectFilter || "NESSUNO"}
                  onChange={(event, index, newValue) =>
                    (event.target as HTMLInputElement).value === "NESSUNO"
                      ? setSubjectFilter(null)
                      : setSubjectFilter(parseInt((event.target as HTMLInputElement).value, 10))
                  }
                // inputProps={{ Menu: { disableScrollLock: true } }}
                >
                  <MenuItem value="NESSUNO" key="NESSUNO" disabled>
                    Nessuno
                  </MenuItem>
                  {edoSubjectList.entities.map((subject) => (
                    <MenuItem value={subject.id} key={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </SelectValidator>

              </ValidatorForm>
            </Grid>
            {hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_ADMIN]) ? (
              <Grid item sm="auto" xs={2}>
                <IconButton
                  color="inherit"
                  onClick={handleMoreMenuButtonClick}
                  disabled={edoPostWikiList.loading}
                // edge="end"
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
          <Divider />
          <Tabs
            value={stageTab}
            onChange={handleChangeStageTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {educationalStages.map((educationalStage) => (
              <Tab
                key={educationalStage}
                value={educationalStage}
                wrapped={false}
                label={translate(`edocendoApp.EdoEducationalStage.${educationalStage}`)}
              />
            ))}
          </Tabs>
          <Divider />
          <Tabs
            value={yearTab}
            onChange={handleChangeYearTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {edoCourseYearList.entities.map((courseYear) => (
              <Tab
                key={courseYear.id}
                value={courseYear.id}
                wrapped={false}
                label={courseYear.year + " " + translate("edocendoApp.edoVideostorePost.year")}
                disabled={[4, 5].includes(courseYear.year) && stageTab === EdoEducationalStage.SECONDARIA_I}
              />
            ))}
          </Tabs>
          {edoCourseYearList.loading && edoCourseYearList.entities.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <List
                className={classes.root}
              >
                {edoPostWikiList.entities &&
                  edoPostWikiList.entities.length ? (
                  edoPostWikiList.entities
                    .map((wikiPost) => (
                      <ListItem
                        button
                        key={wikiPost.id}
                        onClick={() => openFile(wikiPost.file)}>
                        <ListItemIcon>
                          <Avatar>
                            <ClassIcon />
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          primary={wikiPost.title}
                          secondary={wikiPost.subject.name}
                        />
                        <ListItemSecondaryAction>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box mr={2}>
                              <IconButton
                                onClick={() => downloadFile(wikiPost.file)}
                                edge="end"
                                aria-label="Download file"
                              >
                                <GetAppIcon />
                              </IconButton>
                            </Box>
                            {hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_ADMIN]) ? (
                              <>
                                <Box mr={2}>
                                  <IconButton
                                    edge="end"
                                    onClick={() => setEditPostWikiDialog({ open: true, entity: wikiPost })}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={() => setDeletePostWikiDialog({ open: true, entity: wikiPost })}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </>
                            ) : null}
                          </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                ) : (
                  <ListItem>
                    <ListItemText>
                      <Typography variant="body2">
                        <Translate contentKey="edocendoApp.edoWikiPost.home.notFound" />
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )}
              </List>
            </Box>
          )}
          <Divider />
          <TablePagination
            component="div"
            count={edoPostWikiList.totalItems}
            rowsPerPage={paginationState.itemsPerPage}
            page={paginationState.activePage - 1}
            onPageChange={(event, page) => handlePagination(page + 1)}
            onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
            labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
            }
          />
        </Paper>
      </Grid>

      <EditWikiPost
        wikiPost={editPostWikiDialog.entity}
        open={editPostWikiDialog.open}
        onClose={() => setEditPostWikiDialog({ open: false, entity: null })}
      />

      <DeleteWikiPost
        wikiPost={deletePostWikiDialog.entity}
        open={deletePostWikiDialog.open}
        onClose={() => setDeletePostWikiDialog({ open: false, entity: null })}
      />

      <MultiUpload
        open={multiUploadDialog.open}
        onClose={() => setMultiUploadDialog({ open: false })}
      />

      {moreMenu()}
    </>
  );
};

export default Wiki;
