import { IUser } from "shared/model/user.model";

export interface IEdoFile {
  id?: number;
  path?: string;
  name?: string;
  originalFileName?: string;
  fileType?: string | null;
  hash?: string;
  author?: IUser;
  repository?: boolean;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export const defaultValue: Readonly<IEdoFile> = {};
