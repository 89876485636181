import {
    Box,
    Container,
    Grid, Typography, useMediaQuery,
    useTheme
} from "@mui/material";
import { useAppSelector } from "config/store";
import { Translate } from "react-jhipster";
import {
    Route, Routes, useNavigate
} from "react-router-dom";
import { EdoParentState } from "shared/reducers/entities/edo-parent.reducer";
import { makeStyles } from 'tss-react/mui';
import ChildDetail from "./ChildDetail/ChildDetail";

const useStyles = makeStyles()(theme => ({
    paper: {
        color: theme.palette.text.secondary,
        overflow: "hidden"
    },
    title: {
        flexGrow: 1,
        alignSelf: "flex-end",
        textAlign: "left",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        minHeight: "calc(100vh - 64px)",
        overflow: "auto",
    },
}));

export const ParentsRoutes = () => {
    const theme = useTheme();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const isMediaSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { children } = useAppSelector<EdoParentState>(state => state.edoParent);

    const Main = () => {

        if (children.entities.length > 0) {
            navigate(`/parents/children/${children.entities[0].userId}`);
        }

        return (
            <Box p={2}>
                <Typography variant="body1" align="center">
                    <Translate contentKey="userManagement.home.notFound" />
                </Typography>
            </Box>
        );
    };

    return (
        <Box component="main" className={classes.content}>
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Routes>
                            <Route index element={<Main />} />
                            <Route
                                path="children/:id"
                                element={<ChildDetail />}
                            />
                        </Routes>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ParentsRoutes;
