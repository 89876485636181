import {
  Description as DescriptionIcon, Folder as FolderIcon, List as ListIcon
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  Grid, Paper, useMediaQuery, useTheme
} from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import ArchivioCapitoli from "./capitoli";
import ArchivioFiles from "./files";
import ArchivioMateriale from "./materiale";

import { Typography } from "@mui/material";
import NavTabs from "components/NavTabs/NavTabs";


const ArchivioRoutes = () => {
  const theme = useTheme();
  const isMediaSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="main"
      flexGrow={1}
      minHeight="100vh"
      overflow="auto"
    >
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: 4,
          paddingBottom: 4,
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ overflow: "hidden" }}>
              <Box sx={{
                backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(\"/class.jpg\");",
                backgroundPosition: "center 86.5%",
                backgroundSize: "cover",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box p={4} sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                  // backdropFilter: "blur(15px)",
                }}>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginRight: 3 }}>
                    <Avatar
                      sx={{ width: 100, height: 100 }}
                      alt={"Logo"}
                      src={`/package/logo.png`}
                    />
                  </Box>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                    <Typography gutterBottom variant={isMediaSm ? "body1" : "h4"} mb={1} color="white">
                      Il mio archivio
                    </Typography>
                    <Typography variant={isMediaSm ? "body2" : "h6"} color="white">
                      Documenti, capitoli e materiale delle classi
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <NavTabs
                centered={isMediaSm ? false : true}
                variant={isMediaSm ? "scrollable" : "standard"}
                scrollButtons="auto"
                tabs={[{
                  label: "Documenti",
                  icon: <DescriptionIcon />,
                  iconPosition: "start",
                  href: "/repository/files"
                }, {
                  label: "Capitoli",
                  icon: <ListIcon />,
                  iconPosition: "start",
                  href: "/repository/chapters"
                }, {
                  label: "Materiale classi",
                  icon: <FolderIcon />,
                  iconPosition: "start",
                  href: "/repository/materials"
                }]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Routes>
              <Route path="materials" element={<ArchivioMateriale />} />
              <Route path="files" element={<ArchivioFiles />} />
              <Route
                path="chapters"
                element={<ArchivioCapitoli />}
              />
              <Route index element={<Navigate replace to="files" />} />
            </Routes>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ArchivioRoutes;
