import { Event as EventIcon } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography
} from "@mui/material";
import axios from "axios";
import { ViewEventoInsegnamento } from "components/ViewEventoInsegnamento/ViewEventoInsegnamento";
import { APP_DATE_FORMAT } from "config/constants";
import { useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { TextFormat, Translate } from "react-jhipster";
import { IEdoCourseEvent } from "shared/model/edo-course-event.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { EntityState } from "shared/reducers/reducer.utils";

export interface BoxEventiInsegnamentoProps {
  insegnamento: IEdoCourse;
}

export const BoxEventiInsegnamento = (props: BoxEventiInsegnamentoProps) => {
  const [courseEvents, setCourseEvents] = useState < IEdoCourseEvent[]>(
    [] 
  );
  const { updateSuccess } = useAppSelector<EntityState<IEdoCourseEvent>>(
    (state) => state.edoCourseEvent
  );
  const [viewEvento, setViewEvento] = useState<boolean>(false);
  const [eventoToView, setEventoToView] = useState<IEdoCourseEvent | null>(null);

  const { insegnamento } = props;


  const openViewEvento = (evento: IEdoCourseEvent) => {
    setViewEvento(true);
    setEventoToView(evento);
  };

  useEffect(() => {
    const getCourseEvents = async () => {
      const response = await axios.get("/course-events", {
        params: {
          page: 0,
          size: 5,
          sortBy: 'startDate:DESC',
          'filter.course.id': '$eq:' + String(insegnamento.id)
        }
      });
      setCourseEvents(response.data.data);
    };
    
    if (insegnamento.id) {
      getCourseEvents();
    }
  }, [insegnamento.id, updateSuccess]);

  return (
    <>
      <Paper sx={{ color: "text.secondary", overflow: "hidden" }}>
        <List
          component="div"
          subheader={<ListSubheader component="div">Eventi</ListSubheader>}
        >
          {courseEvents.length ? (
            courseEvents.map((evento) => (
              <ListItem
                button
                key={evento.id}
                onClick={() => openViewEvento(evento)}
              >
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText
                  primary={evento.title}
                  secondary={
                    <TextFormat
                      value={evento.startDate}
                      type="date"
                      format={APP_DATE_FORMAT}
                    />
                  }
                />
              </ListItem>
            ))
          ) : (
            <Box p={2}>
              <Typography variant="body1" align="center">
                <Translate contentKey="edocendoApp.edoCourseEvent.home.notFound">
                  No Edo Evento Insegnamentos found
                </Translate>
              </Typography>
            </Box>
          )}
        </List>
      </Paper>
      <ViewEventoInsegnamento
        open={viewEvento}
        handleClose={() => setViewEvento(false)}
        eventId={eventoToView?.id}
      />
    </>
  );
};

export default BoxEventiInsegnamento;
