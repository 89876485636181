import {
  AttachFile as AttachFileIcon,
  Cancel as CancelIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  TextFieldProps, useMediaQuery,
  useTheme
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "config/store";
import { Moment } from "moment";
import "moment/locale/it";
import React, { createRef, useEffect, useState } from "react";
import { Translate, translate } from "react-jhipster";
import {
  TextValidator,
  ValidatorForm
} from "react-material-ui-form-validator";
import { IEdoCircular, defaultValue } from "shared/model/edo-circular.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { EdoCircularVisibility } from "shared/model/enumerations/edo-circular-visibility.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { convertDateTimeToServer } from "shared/util/date-utils";
import { getFileAvatar } from "shared/util/file-avatar-utils";
import {
  createEntity,
  updateEntity
} from "../../../shared/reducers/entities/edo-circular.reducer";
import { getEntities as getEdoClasseList } from "../../../shared/reducers/entities/edo-class.reducer";
import { getEntities as getEdoMateriaList } from "../../../shared/reducers/entities/edo-subject.reducer";

interface EditCirularProps {
  circular?: IEdoCircular;
  open: boolean;
  onClose: () => void;
}

const EditCirular = (props: EditCirularProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { loading, updating, updateSuccess } = useAppSelector<
    EntityState<IEdoCircular>
  >((state) => state.edoCircular);
  const [form, setForm] = useState<IEdoCircular>({ ...defaultValue, ...props.circular });
  const fileInput = createRef<HTMLInputElement>();
  const [files, setFiles] = useState<File[]>([]);

  const isNew = !props.circular;

  const { circular, open, onClose: handleClose } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleCloseDialog = () => {
    setForm({ ...defaultValue });
    handleClose();
  };

  useEffect(() => {
    dispatch(getEdoMateriaList({}));
    dispatch(getEdoClasseList({ page: 0, size: 1000, sort: `id:ASC` }));
  }, []);

  useEffect(() => {
    setForm({ ...defaultValue, ...circular });
  }, [circular]);

  useEffect(() => {
    if (updateSuccess) {
      handleCloseDialog();
    }
  }, [updateSuccess]);

  const removeFile = (index: number) => {
    setFiles((prevState) => Array.from(prevState).splice(index, 1));
  };

  const saveEntity = async () => {
    if (isNew) {
      if (files.length) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i], files[i].name);
        }

        const response = await axios.post<{ data: IEdoFile[] }>("/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        dispatch(
          createEntity({
            ...form,
            date: convertDateTimeToServer(form.date),
            files: response.data.data.map((el) => ({ id: el.id })),
          } as any)
        );
      } else {
        dispatch(
          createEntity({
            ...form,
            date: convertDateTimeToServer(form.date),
          } as any)
        );
      }
    } else {
      dispatch(
        updateEntity({
          ...form,
          date: convertDateTimeToServer(form.date),
        } as any)
      );
    }
  };

  const selectFiles = () => {
    fileInput.current.click();
  };

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {

    // setFiles([...files, ...Array.from(event.target.files)]); // if multiple
    setFiles([...Array.from(event.target.files)]);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullScreen={fullScreen}>
      <DialogTitle>
        {isNew ? translate("edocendoApp.edoCircular.home.createLabel") : translate("edocendoApp.edoCircular.home.editLabel")}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        ) : (
          <ValidatorForm
            onSubmit={saveEntity}
            onError={(errors) => false}
            sx={{ flexGrow: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label={translate("edocendoApp.edoCircular.title")}
                  onChange={handleChange}
                  name="title"
                  value={form.title || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  size="small"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  label={translate("edocendoApp.edoCircular.description")}
                  onChange={handleChange}
                  name="description"
                  value={form.description || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="visibility">{translate("edocendoApp.edoCircular.visibility")}</InputLabel>
                  <Select
                    fullWidth
                    labelId='visibility'
                    label={translate("edocendoApp.edoCircular.visibility")}
                    name="visibility"
                    size="small"
                    required
                    variant="outlined"
                    value={form.visibility || ""}
                    onChange={(event) => {
                      setForm((prevState) => ({
                        ...prevState,
                        visibility: event.target.value as EdoCircularVisibility,
                      }));
                    }}
                    renderValue={(value) => {
                      return translate(`edocendoApp.EdoCircularVisibility.${value}`);
                    }}
                  >
                    <MenuItem value="ALL" key="ALL">
                      <Translate contentKey="edocendoApp.EdoCircularVisibility.ALL">
                        TUTTI
                      </Translate>
                    </MenuItem>
                    <MenuItem value="STUDENTS" key="STUDENTI">
                      <Translate contentKey="edocendoApp.EdoCircularVisibility.STUDENTS">
                        STUDENTI
                      </Translate>
                    </MenuItem>
                    <MenuItem value="TEACHERS" key="DOCENTI">
                      <Translate contentKey="edocendoApp.EdoCircularVisibility.TEACHERS">
                        DOCENTI
                      </Translate>
                    </MenuItem>
                  </Select>
                </FormControl>


              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                  <MobileDatePicker
                    // cancelLabel="Annulla"
                    renderInput={(inputProps: TextFieldProps) => (
                      <TextField
                        {...inputProps}
                        name="data"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    label={translate("edocendoApp.edoCircular.date")}
                    value={form.date}
                    onChange={(date: Moment) => {
                      const tzoffset = new Date().getTimezoneOffset() * 60000;
                      return setForm({
                        ...form,
                        date: date
                          .subtract(tzoffset)
                          .toISOString()
                          .slice(0, -1),
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              {isNew ? (
                <>
                  {files !== null && files.length ? (
                    <Grid item xs={12}>
                      <Box py={2}>
                        <List disablePadding dense>
                          {files.map((file, index) => (
                            <ListItem key={index} disableGutters>
                              <ListItemAvatar>
                                {getFileAvatar(file.type)}
                              </ListItemAvatar>
                              <ListItemText primary={file.name} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() => removeFile(index)}
                                  edge="end"
                                  aria-label="Rimuovi file"
                                >
                                  <CancelIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={selectFiles}
                      variant="contained"
                      color="secondary"
                      startIcon={<AttachFileIcon />}
                    >
                      Aggiugi file
                    </Button>
                    <input
                      type="file"
                      ref={fileInput}
                      name="files"
                      multiple
                      hidden
                      onChange={handleFiles}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </ValidatorForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          <Translate contentKey="entity.action.cancel" />
        </Button>
        <Button
          onClick={() => {
            saveEntity();
          }}
          color="primary"
          disabled={loading || updating || !form.title || !form.description || !form.visibility || !form.date}
        >
          {isNew ? translate("entity.action.create") : translate("entity.action.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCirular;