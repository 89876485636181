import { IRole } from "./role.model";

export interface IUserBase {
  id?: any;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface IUser extends IUserBase {
  activated?: boolean;
  roles?: IRole[];
  createdAt?: string;
  updatedAt?: string;
  password?: string | null;
  oldEdocendoId?: number | null;
  resetDate?: Date | null;
  nextResetDate?: Date | null;
}

export const defaultValue: Readonly<IUser> = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  activated: true,
  roles: [],
  password: null,
  oldEdocendoId: null,
};
