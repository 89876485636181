import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "config/store";
import { IUser } from "shared/model/user.model";
import { getSession } from "shared/reducers/authentication";
import { serializeAxiosError } from "shared/reducers/reducer.utils";

const initialState = {
  loading: false,
  errorMessage: null as string | null,
  successMessage: null as string | null,
  updateSuccess: false,
  updateFailure: false,
};

export type AccountState = Readonly<typeof initialState>;

// Actions
const apiUrl = "/account";

export const saveAccountSettings: (account: { firstName: string, lastName: string, email?: string }) => AppThunk =
  (account) => async (dispatch) => {
    await dispatch(updateAccount(account));

    dispatch(getSession());
  };

export const updateAccount = createAsyncThunk(
  "settings/update_account",
  async (account: { firstName: string, lastName: string, email?: string }) => axios.patch<{ data: IUser }>(apiUrl, account),
  {
    serializeError: serializeAxiosError,
  }
);

export const AccountSlice = createSlice({
  name: "settings",
  initialState: initialState as AccountState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateAccount.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
        state.updateSuccess = false;
      })
      .addCase(updateAccount.rejected, (state) => {
        state.loading = false;
        state.updateSuccess = false;
        state.updateFailure = true;
      })
      .addCase(updateAccount.fulfilled, (state) => {
        state.loading = false;
        state.updateSuccess = true;
        state.updateFailure = false;
        state.successMessage = "Account saved!";
      });
  },
});

export const { reset } = AccountSlice.actions;

// Reducer
export default AccountSlice.reducer;
