import {
    createAsyncThunk,
    isFulfilled,
    isPending,
    isRejected,
} from "@reduxjs/toolkit";
import axios from "axios";
import { IPaginationResponse } from "shared/util/pagination.constants";
import { IEdoSubject, defaultValue } from "../../model/edo-subject.model";
import { cleanEntity } from "../../util/entity-utils";
import {
    EntityState,
    IQueryParamsWithFilters,
    createEntitySliceY,
    serializeAxiosError,
} from "../reducer.utils";

interface SubjectStateType extends EntityState<IEdoSubject> {
    picUploadingStatus: "SUCCESS" | "FAILED" | "PENDING" | "NONE";
    subjectsByVideostorePosts: {
        entities: IEdoSubject[];
        totalItems: number;
        loading: boolean;
        errorMessage: string | null;
    };
    subjectsByWikiPosts: {
        entities: IEdoSubject[];
        totalItems: number;
        loading: boolean;
        errorMessage: string | null;
    };
}

const initialState: SubjectState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
    picUploadingStatus: "NONE",
    subjectsByVideostorePosts: {
        entities: [],
        totalItems: 0,
        loading: false,
        errorMessage: null,
    },
    subjectsByWikiPosts: {
        entities: [],
        totalItems: 0,
        loading: false,
        errorMessage: null,
    },
};

export type SubjectState = Readonly<SubjectStateType>;

const apiUrl = "/subjects";

// Actions

export const getEntities = createAsyncThunk(
    "edoSubject/fetch_entity_list",
    async ({ page, size, sort, filters }: IQueryParamsWithFilters) => {
        const params = new URLSearchParams(filters);
        if (sort) {
            params.append("page", String(page));
            params.append("limit", String(size));
            if (typeof sort === "string") {
                params.append("sortBy", sort.replace(",", ":"));
            } else {
                for (let s of sort) {
                    params.append("sortBy", s.replace(",", ":"));
                }
            }
        }
        let requestUrl;
        if (params.toString() === "") {
            requestUrl = `${apiUrl}`;
        } else {
            requestUrl = `${apiUrl}?${params.toString()}`;
        }
        return axios.get<IPaginationResponse<IEdoSubject>>(requestUrl);
    }
);

export const getSubjectsWithWikiPosts = createAsyncThunk(
    "edoSubject/get_subjects_with_wiki_posts",
    async () => {
        let requestUrl = `/wiki-posts/subjects`;
        return axios.get<{ data: IEdoSubject[] }>(requestUrl);
    }
);

export const getSubjectsWithVideostorePosts = createAsyncThunk(
    "edoSubject/get_subjects_with_videostore_posts",
    async () => {
        let requestUrl = `/videostore-posts/subjects`;
        return axios.get<{ data: IEdoSubject[] }>(requestUrl);
    }
);

export const getEntity = createAsyncThunk(
    "edoSubject/fetch_entity",
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<IEdoSubject>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    "edoSubject/create_entity",
    async (entity: IEdoSubject, thunkAPI) => {
        const result = await axios.post<IEdoSubject>(
            apiUrl,
            cleanEntity(entity)
        );
        // thunkAPI.dispatch(getEntities({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
    "edoSubject/update_entity",
    async (entity: IEdoSubject, thunkAPI) => {
        const result = await axios.put<IEdoSubject>(
            `${apiUrl}/${entity.id}`,
            cleanEntity(entity)
        );
        // thunkAPI.dispatch(getEntities({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
    "edoSubject/partial_update_entity",
    async (entity: IEdoSubject, thunkAPI) => {
        const result = await axios.patch<IEdoSubject>(
            `${apiUrl}/${entity.id}`,
            cleanEntity(entity)
        );
        // thunkAPI.dispatch(getEntities({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
    "edoSubject/delete_entity",
    async (id: string | number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete<IEdoSubject>(requestUrl);
        // thunkAPI.dispatch(getEntities({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const uploadSubjectPic = createAsyncThunk(
    "edoSubject/upload_subject_pic",
    async (data: { subjectId: number; formData: FormData }, thunkAPI) => {
        const requestUrl = `${apiUrl}/${data.subjectId}/pic`;
        await axios.put<{ message: string }>(requestUrl, data.formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        // thunkAPI.dispatch(getEntities({}));
        return;
    },
    { serializeError: serializeAxiosError }
);

// slice

export const EdoSubjectSlice = createEntitySliceY({
    name: "edoSubject",
    initialState,
    skipRejectionHandling: true,
    extraReducers(builder) {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addCase(deleteEntity.fulfilled, (state) => {
                state.updating = false;
                state.updateSuccess = true;
                state.entity = {};
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const {
                    data: {
                        data,
                        meta: { totalItems },
                    },
                    headers,
                } = action.payload;

                return {
                    ...state,
                    loading: false,
                    entities: data,
                    totalItems,
                };
            })
            .addMatcher(
                isFulfilled(getSubjectsWithWikiPosts),
                (state, action) => {
                    const {
                        data: { data },
                    } = action.payload;

                    return {
                        ...state,
                        subjectsByWikiPosts: {
                            ...state.subjectsByWikiPosts,
                            loading: false,
                            entities: data,
                            totalItems: data.length,
                        },
                    };
                }
            )
            .addMatcher(
                isFulfilled(getSubjectsWithVideostorePosts),
                (state, action) => {
                    const {
                        data: { data },
                    } = action.payload;

                    return {
                        ...state,
                        subjectsByVideostorePosts: {
                            ...state.subjectsByVideostorePosts,
                            loading: false,
                            entities: data,
                            totalItems: data.length,
                        },
                    };
                }
            )
            .addMatcher(
                isFulfilled(createEntity, updateEntity, partialUpdateEntity),
                (state, action) => {
                    state.updating = false;
                    state.loading = false;
                    state.updateSuccess = true;
                    state.entity = action.payload.data;
                }
            )
            .addMatcher(isFulfilled(uploadSubjectPic), (state, action) => {
                state.picUploadingStatus = "SUCCESS";
            })
            .addMatcher(
                isPending(
                    getEntities,
                    getEntity,
                    getSubjectsWithWikiPosts,
                    getSubjectsWithVideostorePosts
                ),
                (state) => {
                    state.errorMessage = null;
                    state.updateSuccess = false;
                    state.loading = true;
                }
            )
            .addMatcher(isPending(getSubjectsWithWikiPosts), (state) => {
                state.subjectsByWikiPosts.errorMessage = null;
                state.subjectsByWikiPosts.loading = true;
            })
            .addMatcher(isPending(getSubjectsWithVideostorePosts), (state) => {
                state.subjectsByVideostorePosts.errorMessage = null;
                state.subjectsByVideostorePosts.loading = true;
            })
            .addMatcher(
                isPending(
                    createEntity,
                    updateEntity,
                    partialUpdateEntity,
                    deleteEntity
                ),
                (state) => {
                    state.errorMessage = null;
                    state.updateSuccess = false;
                    state.updating = true;
                }
            )
            .addMatcher(isPending(uploadSubjectPic), (state) => {
                state.picUploadingStatus = "PENDING";
            })
            .addMatcher(
                isRejected(
                    getEntity,
                    createEntity,
                    updateEntity,
                    partialUpdateEntity,
                    deleteEntity,
                    getEntities,
                    getSubjectsWithWikiPosts,
                    getSubjectsWithVideostorePosts
                ),
                (state, action) => {
                    state.loading = false;
                    state.updating = false;
                    state.updateSuccess = false;
                    state.errorMessage = action.error.message;
                }
            )
            .addMatcher(
                isRejected(getSubjectsWithWikiPosts),
                (state, action) => {
                    state.subjectsByWikiPosts.loading = false;
                    state.subjectsByWikiPosts.errorMessage =
                        action.error.message;
                }
            )
            .addMatcher(
                isRejected(getSubjectsWithVideostorePosts),
                (state, action) => {
                    state.subjectsByVideostorePosts.loading = false;
                    state.subjectsByVideostorePosts.errorMessage =
                        action.error.message;
                }
            )
            .addMatcher(isRejected(uploadSubjectPic), (state, action) => {
                state.picUploadingStatus = "FAILED";
            });
    },
});

export const { reset } = EdoSubjectSlice.actions;

// Reducer
export default EdoSubjectSlice.reducer;
