import {
  GetApp as GetAppIcon, MoreVert
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Link as MaterialLink,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from "@mui/material";
import {
  APP_DATE_FORMAT_EXPANDED,
  APP_DATE_FORMAT_SHORT,
  AUTHORITIES
} from "config/constants";
import { useAppSelector } from "config/store";
import React, { useEffect, useState } from "react";
import { TextFormat } from "react-jhipster";
import Linkify from "react-linkify";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoCourseComment } from "shared/model/edo-course-comment.model";
import { IEdoCoursePost } from "shared/model/edo-course-post.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoWallComment } from "shared/model/edo-wall-comment.model";
import { IEdoWallPost } from "shared/model/edo-wall-post.model";
import { IUser } from "shared/model/user.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getFileAvatar } from "shared/util/file-avatar-utils";
import CreateComment from "./components/CreateComment";

type PostProps = {
  post: IEdoWallPost | IEdoCoursePost;
  getCommentList: () => Promise<IEdoWallComment[]>;
  saveComment: (comment: IEdoWallComment) => Promise<IEdoWallComment>;
  defaultValueComment: IEdoWallComment;
  onEdit: (post: IEdoWallPost) => void;
  onDelete: (post: IEdoWallPost) => void;
} | {
  post: IEdoCoursePost;
  getCommentList: () => Promise<IEdoCourseComment[]>;
  saveComment: (comment: IEdoCourseComment) => Promise<IEdoCourseComment>;
  defaultValueComment: IEdoCourseComment;
  onEdit: (post: IEdoCoursePost) => void;
  onDelete: (post: IEdoCoursePost) => void;
};

const Post = (props: PostProps) => {
  const theme = useTheme();
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const [commentList, setCommentList] = useState<IEdoWallComment[] | IEdoCourseComment[]>(
    []
  );
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [localPost, setLocalPost] = useState<IEdoWallPost | IEdoCoursePost>({ ...props.post });

  const {
    post,
    getCommentList,
    saveComment,
    defaultValueComment,
    onEdit,
    onDelete,
  } = props;

  const openFile = async (file: IEdoFile) => {
    // await axios.get(`/files/${file.id}/view`, { responseType: 'blob'});
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  const downloadFile = (file: IEdoFile) => {
    // const response = await axios.get(`/files/${file.id}/download`);
    window.open(`/api/files/${file.id}/download`, "_blank");
  };

  const getCommentListLocal = async () => {
    const data = await getCommentList();
    setCommentList(data);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleEdit = () => {
    closeMenu();
    onEdit(localPost);
  };

  const handleDelete = () => {
    closeMenu();
    onDelete(localPost);
  };

  useEffect(() => {
    if (post) {
      setLocalPost(post);
    }
  }, [post]);

  useEffect(() => {
    if (localPost) {
      getCommentListLocal();
    }
  }, [localPost]);

  const getAuthorRoleChip = (author: IUser) => {
    let label = "Utente";
    let color: "primary" | "secondary" = "primary";

    if (hasAnyAuthority(author.roles, [AUTHORITIES.ROLE_ADMIN])) {
      label = "Amministratore";
      color = "secondary";
    } else if (hasAnyAuthority(author.roles, [AUTHORITIES.ROLE_VICE])) {
      label = "Vicepreside";
      color = "secondary";
    } else if (hasAnyAuthority(author.roles, [AUTHORITIES.ROLE_TEACHER, AUTHORITIES.ROLE_SUPPORT_TEACHER])) {
      label = "Docente";
      color = "primary";
    } else if (hasAnyAuthority(author.roles, [AUTHORITIES.ROLE_STUDENT])) {
      label = "Studente";
      color = "primary";
    } else if (hasAnyAuthority(author.roles, [AUTHORITIES.ROLE_PARENT])) {
      label = "Genitore";
      color = "primary";
    } else {
      return null;
    }

    return (
      <Chip
        size="mini"
        label={label}
        color={color}
      />
    );
  };

  return (
    <Card
      sx={(theme) => ({
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        // boxShadow: "0px 1px 6px rgba(0,0,0,0.3)"
      })}>
      {!localPost ? (
        <Box p={3} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <CardHeader
            avatar={
                <Avatar style={{ backgroundColor: "primary" }} src="/profile_neutral.svg" />
            }
            action={
              post.author.id === account.id ? (
                <>
                  <IconButton onClick={openMenu}>
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id="more-post-menu"
                    anchorEl={anchorMenu}
                    keepMounted
                    open={Boolean(anchorMenu)}
                    onClose={closeMenu}
                    disableScrollLock={true}
                  >
                    <MenuItem onClick={handleEdit}>Modifica</MenuItem>
                    <MenuItem onClick={handleDelete}>Elimina</MenuItem>
                  </Menu>
                </>
              ) : null
            }
            title={
              <Box display="flex" alignItems="center" mb={0.5}>
                <Box mr={1}>
                  {localPost.author.firstName +
                    " " +
                    localPost.author.lastName}
                </Box>
                <Box>
                  {getAuthorRoleChip(localPost.author)}
                </Box>
              </Box>
            }
            subheader={
              localPost.createdAt ? (
                <TextFormat
                  type="date"
                  value={localPost.createdAt}
                  format={APP_DATE_FORMAT_EXPANDED}
                  locale="it"
                />
              ) : null
            }
          />
          <CardContent>
            <Box mb={1}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ whiteSpace: "pre-line" }}
              >
                <Linkify
                  componentDecorator={(decoratorHref, decoratorText, key) => (
                    <MaterialLink
                      key={key}
                      href={decoratorHref}
                      color="primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {decoratorText}
                    </MaterialLink>
                  )}
                >
                  {localPost.description}
                </Linkify>
              </Typography>
            </Box>
            {/* {localPost.files.length ? (
          <>
            <Divider />
            <Box>
              <List dense>
                {localPost.files.map(file => (
                  <ListItem key={file.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <DescriptionIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={file.date} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <GetAppIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>{' '}
          </>
        ) : null} */}
          </CardContent>
          {localPost.files.length ? (
            <>
              <Divider />
              <CardContent sx={{ p: 0 }}>
                <Box>
                  <List dense>
                    {localPost.files.map((file) => (
                      <ListItem key={file.id} button onClick={() => openFile(file)}>
                        <ListItemAvatar>
                          {getFileAvatar(file.fileType)}
                        </ListItemAvatar>
                        <ListItemText
                          primary={file.originalFileName}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => downloadFile(file)}
                            edge="end"
                            aria-label="Download file"
                          >
                            <GetAppIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </CardContent>
            </>
          ) : null}
          {commentList !== null && commentList.length ? (
            <>
              <Divider />
              <CardContent
                style={{
                  padding: theme.spacing(2),
                  // marginBottom: -theme.spacing(2),
                }}
              >
                {commentList.map((comment, i) => (
                  <Box
                    mb={i === commentList?.length - 1 ? 0 : 2}
                    pl={1}
                    key={comment.id}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box mr={2}>
                      <Avatar
                        sx={(theme) => ({
                          backgroundColor: "primary",
                          width: theme.spacing(4),
                          height: theme.spacing(4)
                        })}
                      >
                        {comment.author.firstName[0]}
                      </Avatar>
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <Box display="flex">
                        <Box mr={1}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            component="p"
                          >
                            {comment.author.firstName +
                              " " +
                              comment.author.lastName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            <TextFormat
                              type="date"
                              value={comment.createdAt}
                              format={APP_DATE_FORMAT_SHORT}
                              locale="it"
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {comment.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </CardContent>
            </>
          ) : null}
          <Divider />
          <CardContent style={{ padding: theme.spacing(2) }}>
            <CreateComment
              reloadCommentList={getCommentListLocal}
              saveComment={saveComment}
              defaultValueComment={defaultValueComment}
            />
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default Post;