import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { convertDateTimeFromServer } from "./date-utils";

export const getQuizAttemptStatus = (
    quizAttempt: IEdoQuizAttempt,
    score: number,
    totalScore: number
) => {
    if (!quizAttempt.timeFinish) {
        return "Fine quiz sconosciuta";
    } else if (new Date(quizAttempt.timeFinish).getTime() >= new Date().getTime()) {
        return "In corso";
    } else if (quizAttempt.reviewDate) {
        return `${score} / ${totalScore} ${totalScore === 1 ? "punto" : "punti"}`;
    } else if (quizAttempt.courseQuiz?.allowReview) {
        return "In attesa di revisione";
    } else {
        return `Consegnato il ${convertDateTimeFromServer(
            quizAttempt.timeFinish
        )}`;
    }
};
