import { IEdoChatMessage } from "shared/model/edo-chat-message.model";
import { EdoMessageSeen } from "shared/model/enumerations/edo-message-seen.model";
import { IUser } from "shared/model/user.model";

export interface IEdoUserMessageState {
  id?: number;
  createdAt?: string;
  seenDate?: string | null;
  seen?: EdoMessageSeen;
  message?: IEdoChatMessage;
  user?: IUser;
}

export const defaultValue: Readonly<IEdoUserMessageState> = {};
