import {
    AppBar,
    Box, CircularProgress,
    Divider, List, Paper,
    TablePagination, Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import { useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { useNavigate } from "react-router-dom";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { IEdoStudent } from "shared/model/edo-student.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { ITEMS_PER_PAGE, PaginationState } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import AttemptRow from "./AttemptRow";

const useStyles = makeStyles()(theme =>
({
    paper: {
        color: theme.palette.text.secondary,
        height: "100%",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    appbar: {
        boxShadow: "none",
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
    },
    toolbar: {
        justifyContent: "space-between",
    },
})
);

interface IQuizAttemptsProps {
    student: IEdoStudent;
}

const QuizAttempts = (props: IQuizAttemptsProps) => {
    const theme = useTheme();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const { account } = useAppSelector<AuthenticationState>((state) => state.authentication);
    const [paginationState, setPaginationState] = useState<PaginationState>({
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: "updatedAt",
        order: "ASC"
    });
    const [quizAttempts, setQuizAttempts] = useState<{ data: IEdoQuizAttempt[]; loading: boolean; totalItems: number; }>({ data: [], loading: false, totalItems: 0 });

    const { student } = props;

    const getAllQuizAttempts = async () => {
        setQuizAttempts(prevState => ({ ...prevState, loading: true }));
        const response = await axios.get<{ data: IEdoQuizAttempt[], meta: { totalItems: number } }>(`/parents/${account.id}/children/${student.userId}/quiz-attempts`, {
            params: {
                page: paginationState.activePage,
                size: paginationState.itemsPerPage,
                sort: `${paginationState.sort},${paginationState.order}`
            }
        });
        setQuizAttempts({ data: response.data.data, loading: false, totalItems: response.data.meta.totalItems });
    };

    useEffect(() => {
        if (account?.id && student?.userId) {
            getAllQuizAttempts();
        }
    }, [
        paginationState.activePage,
        paginationState.order,
        paginationState.sort,
        paginationState.itemsPerPage,
        student?.userId,
        account?.id
    ]);


    const sort = (p: string) => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === "ASC" ? "DESC" : "ASC",
            sort: p,
        });
    };

    const handlePagination = (currentPage: number) =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const handleRowsPerPage = (rowsPerPage: number) =>
        setPaginationState({
            ...paginationState,
            itemsPerPage: rowsPerPage,
            activePage: 1,
        });

    const handleSyncList = () => {
        getAllQuizAttempts();
    };

    const handleOpenAttempt = (quizAttempt: IEdoQuizAttempt) => {
        navigate(`/quiz-attempts/${quizAttempt.id}/review`);
    };


    return (
        <Paper className={classes.paper}>
            <Box className={classes.root}>
                <AppBar
                    position="static"
                    color="transparent"
                    className={classes.appbar}
                >
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h5">
                            Risultati test
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box flexGrow={1}>
                    {quizAttempts.loading ? (
                        <Box position="relative" display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    ) : (!quizAttempts.data.length ? (
                        <Box p={2}>
                            <Typography variant="body1" align="center">
                                <Translate contentKey="edocendoApp.edoQuizAttempt.home.notFound" />
                            </Typography>
                        </Box>
                    ) : (
                        <List component="div" disablePadding>
                            {quizAttempts.data.map((quizAttempt) => (
                                <AttemptRow key={quizAttempt.id} quizAttempt={quizAttempt} />
                            ))}
                        </List>
                    )

                    )}
                </Box>
                <Divider />
                <TablePagination
                    component="div"
                    count={quizAttempts.totalItems}
                    rowsPerPage={paginationState.itemsPerPage}
                    page={paginationState.activePage - 1}
                    onPageChange={(event, page) => handlePagination(page + 1)}
                    onRowsPerPageChange={(event) =>
                        handleRowsPerPage(parseInt(event.target.value, 10))
                    }
                    labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
                    }
                />
            </Box>
        </Paper>
    );
};

export default QuizAttempts;