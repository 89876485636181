import {
    ExpandMore as ExpandMoreIcon
} from "@mui/icons-material";
import {
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box, Divider, List, Typography
} from "@mui/material";
import axios from "axios";
import StyledAccordion from "components/StyledAccordion/StyledAccordion";
import { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { IUser } from "shared/model/user.model";
import AttemptRow from "./AttemptRow";

interface UserQuizAttemptsAccordionProps {
    user: IUser;
    courseQuizId: number;
    onOpenAttempt?: (quizAttempt: IEdoQuizAttempt) => void;
}

const UserQuizAttemptsAccordion = (props: UserQuizAttemptsAccordionProps) => {
    const [quizAttempts, setQuizAttempts] = useState<IEdoQuizAttempt[]>([]);

    const {
        user,
        courseQuizId,
        onOpenAttempt: handleOpenAttempt,
    } = props;

    useEffect(() => {
        const getUserQuizAttempts = async () => {
            const response = await axios.get<{ data: IEdoQuizAttempt[] }>(`/course-quizzes/${courseQuizId}/users/${user.id}/attempts`);
            setQuizAttempts(response.data.data);
        };
        if (user?.id) {
            getUserQuizAttempts();
        }
    }, [user?.id]);

    return (
        <StyledAccordion>
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon
                        color={!quizAttempts.length ? "primary" : "secondary"}
                    />
                }
            >
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        mr={2}
                    >
                        <Box mr={2}>
                            <Avatar alt={user.firstName + " " + user.lastName}>{user.firstName[0] + user.lastName[0]}</Avatar>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Box>
                                <Typography variant="body2">{user.firstName + " " + user.lastName}</Typography>
                            </Box>
                            {/* <Box>
                <Typography variant="body2">{chapterLocal.type + ' ' + chapterLocal.subtype}</Typography>
              </Box> */}
                        </Box>
                    </Box>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ marginRight: 2 }} />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
                <List component="div" disablePadding>
                    {quizAttempts?.length ?
                        quizAttempts.map((quizAttempt) => (
                            <AttemptRow key={quizAttempt.id} quizAttempt={quizAttempt} onOpen={handleOpenAttempt} />
                        )) : (
                            <Typography>
                                <Translate contentKey="edocendoApp.edoQuizAttempt.home.notFound" />
                            </Typography>
                        )}
                </List>
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default UserQuizAttemptsAccordion;