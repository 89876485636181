import "config/dayjs.ts";
import { useAppDispatch } from "config/store";
import Activate from "pages/account/activate/activate";
import PasswordResetFinish from "pages/account/password-reset/finish/password-reset-finish";
import PasswordResetInit from "pages/account/password-reset/init/password-reset-init";
import Login from "pages/login/login";
import Logout from "pages/login/logout";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "routes";
import PrivateRoute from "shared/auth/private-route";
import { getSession } from "shared/reducers/authentication";
import "./app.scss";

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
  }, []);


  return (
    <BrowserRouter>
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        className="toastify-container"
        toastClassName="toastify-toast"
      />
      <Routes>
        <Route path="/*" element={<PrivateRoute><AppRoutes /></PrivateRoute>} />
        <Route path="logout" element={<PrivateRoute><Logout /></PrivateRoute>} />
        <Route path="login" element={<Login />} />
        <Route
          path="account/activate/:key?"
          element={<Activate />}
        />
        <Route
          path="account/reset/request"
          element={<PasswordResetInit />}
        />
        <Route
          path="account/reset/finish"
          element={<PasswordResetFinish />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
