import { LoadingButton } from "@mui/lab";
import {
  AppBar, Box, Grid, Link as MaterialLink, Paper, Toolbar,
  Typography
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';
import { PasswordResetState, handlePasswordResetInit, reset } from "../password-reset.reducer";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "grid",
    gridTemplateRows: "min-content auto min-content",
    height: "100%",
    maxWidth: "800px",
    // minWidth: "500px"
  },
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
  appbar: {
    boxShadow: "none",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
  },
  toolbar: {
    justifyContent: "space-between",
  },
}));

export const PasswordResetInit = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const { successMessage, resetPasswordSuccess, loading } = useAppSelector<PasswordResetState>(
    (state) => state.passwordReset
  );

  const handleValidSubmit = () => {
    dispatch(handlePasswordResetInit(email));
  };

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );


  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  const successMessageBox = () => {
    return (
      <Typography variant="body1" marginBottom="8px">
        <Translate contentKey="reset.request.messages.success" />
      </Typography>
    );
  };

  const emailForm = () => {
    return (
      <>
        <Typography variant="body1" marginBottom="8px">
          <Translate contentKey="reset.request.messages.info" />
        </Typography>
        <ValidatorForm onSubmit={handleValidSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                label="Email"
                onChange={(event) => setEmail((event.target as HTMLInputElement).value)}
                name="email"
                autoComplete="email"
                required
                autoFocus
                value={email}
                validators={["required", "minStringLength:5", "maxStringLength:50", "isEmail"]}
                errorMessages={["Il campo non devve essere vuoto.", "L'email deve essere lunga almeno 5 caratteri.", "La tua email non può essere lunga più di 50 caratteri.", "La tua email non è valida."]}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={loading}
              >
                <Translate contentKey="reset.request.form.button" />
              </LoadingButton>
            </Grid>
          </Grid>
        </ValidatorForm>
      </>
    );
  };


  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{
      height: '100vh',
      paddingRight: 2,
      paddingLeft: 2,
    }}>
      <Paper className={classes.paper}>
        <Box className={classes.root}>
          <AppBar
            position="static"
            color="transparent"
            className={classes.appbar}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant="h5">
                <Translate contentKey="reset.request.title" />
              </Typography>
            </Toolbar>
          </AppBar>
          <Box p={2.5}>
            {resetPasswordSuccess ? successMessageBox() : emailForm()}
            <Box mt={3}>
              <Typography variant="body1" color="textSecondary" component="p" align="center">
                <MaterialLink
                  to="/login"
                  color="primary"
                  component={Link}
                >
                  Torna alla pagina principale
                </MaterialLink>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PasswordResetInit;
