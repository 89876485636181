import {
    ListItem,
    ListItemText
} from "@mui/material";
import axios from "axios";
import { APP_DATE_FORMAT_MID } from "config/constants";
import { useEffect, useMemo, useState } from "react";
import { TextFormat } from "react-jhipster";
import { useNavigate } from "react-router-dom";
import { IEdoQuizAttempt } from "shared/model/edo-quiz-attempt.model";
import { getQuizAttemptStatus } from "shared/util/quiz-utils";


interface UserAttemptRowProps {
    quizAttempt: IEdoQuizAttempt;
    onOpen: (quizAttempt: IEdoQuizAttempt) => void;
}

const UserAttemptRow = (props: UserAttemptRowProps) => {
    const navigate = useNavigate();
    const [score, setScore] = useState<{ score: number, totalScore: number }>({ score: 0, totalScore: 0 });

    const { quizAttempt, onOpen: handleOpen } = props;

    const getQuizAttemptScore = async () => {
        const response = await axios.get<{ data: { score: number, totalScore: number } }>(`/quiz-attempts/${quizAttempt?.id}/score`);
        setScore(response.data.data);
    };

    useEffect(() => {
        if (quizAttempt?.id) {
            getQuizAttemptScore();
        }
    }, [quizAttempt?.id]);

    const attemptStatus = useMemo(() => getQuizAttemptStatus(quizAttempt, score.score, score.totalScore), [quizAttempt, score.score, score.totalScore]);

    return (
        <ListItem
            button
            disabled={(new Date(quizAttempt.timeFinish)).getTime() < (new Date()).getTime()}
            key={quizAttempt.id}
            onClick={() => handleOpen(quizAttempt)}
        >
            <ListItemText
                primaryTypographyProps={{ variant: "body1", color: "text.primary" }}
                secondaryTypographyProps={{ variant: "body2" }}
                primary={
                    <>
                        {`Tentativo del `}
                        <TextFormat
                            type="date"
                            value={quizAttempt.createdAt}
                            format={APP_DATE_FORMAT_MID}
                            locale="it"
                        />
                    </>
                }
                secondary={attemptStatus}
                onClick={quizAttempt.timeFinish ? null : () => handleOpen(quizAttempt)}
            />
        </ListItem>
    );
};

export default UserAttemptRow;