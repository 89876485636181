import { Box, Container, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import CircularsManagement from "./circulars-management";

const CircularManagementRoutes = () => {
    return (
        <Box
            component="main"
            flexGrow={1}
            minHeight="100vh"
            overflow="auto"
        >
            <Container
                maxWidth="lg"
                sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingRight: 3,
                    paddingLeft: 3,
                }}
            >
                <Grid container spacing={3}>
                    <Routes>
                        <Route index element={<CircularsManagement />} />
                    </Routes>
                </Grid>
            </Container>
        </Box>
    );
};

export default CircularManagementRoutes;
