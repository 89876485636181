import { Delete as DeleteIcon, Edit as EditIcon, Movie as MovieIcon, Publish as PublishIcon } from '@mui/icons-material';
import {
    Avatar, Box,
    CircularProgress,
    Divider,
    Grid, IconButton, List,
    ListItem, ListItemIcon, ListItemText, Paper, TablePagination, Typography
} from "@mui/material";
import axios from "axios";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { SocketContext } from 'context/socket';
import { useContext, useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoVideo } from 'shared/model/edo-video.model';
import { IEdoVideostorePost } from "shared/model/edo-videostore-post.model";
import { IEdoVideoStatusEnum } from 'shared/model/enumerations/edo-video-status.model';
import { AuthenticationState } from "shared/reducers/authentication";
import { publishVideostorePost } from 'shared/reducers/entities/edo-videostore-post.reducer';
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE, ITEMS_PER_PAGE_OPTIONS } from 'shared/util/pagination.constants';
import { getVideoStatusLabel } from '../videostore';

interface IVideostoreDraftsProps {
    onEdit: (post: IEdoVideostorePost) => void;
    onDelete: (post: IEdoVideostorePost) => void;
}

const VideostoreDrafts = (props: IVideostoreDraftsProps) => {
    const dispatch = useAppDispatch();
    const socket = useContext(SocketContext);
    const { updateSuccess } = useAppSelector<EntityState<IEdoVideostorePost>>((state) => state.edoVideostorePost);
    const [videostorePosts, setVideostorePosts] = useState<{ loading: boolean; entities: IEdoVideostorePost[], totalItems: number }>({ loading: false, entities: [], totalItems: 0 });
    const { account } = useAppSelector<AuthenticationState>(state => state.authentication);
    const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: "updatedAt",
        order: "ASC",
    });

    const { onEdit: handleEdit, onDelete: handleDelete } = props;

    const getVideostorePostList = async () => {
        setVideostorePosts(prevState => ({ ...prevState, loading: true }));
        const response = await axios.get<{ data: IEdoVideostorePost[]; meta: any; links: any }>(`videostore-posts?status.equals=DRAFT`, {
            params: {
                page: paginationState.activePage,
                limit: paginationState.itemsPerPage,
                sortBy: `${paginationState.sort}:${paginationState.order}`,
                'filter.author': `$eq:${account.id}`,
                'filter.draft': '$eq:1'
            }
        });
        setVideostorePosts(prevState => ({ ...prevState, entities: response.data.data, loading: false, totalItems: response.data.meta.totalItems }));
    };

    const sort = (p: string) => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === "ASC" ? "DESC" : "ASC",
            sort: p,
        });
    };

    const handlePagination = (currentPage: number) =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const handleRowsPerPage = (rowsPerPage: number) =>
        setPaginationState({
            ...paginationState,
            itemsPerPage: rowsPerPage,
            activePage: 1,
        });

    const handlePublish = async (videostorePost: IEdoVideostorePost) => {
        if (!videostorePost.id) return;
        dispatch(publishVideostorePost(videostorePost.id));
    };

    const handleOnVideoUpdate = (video: IEdoVideo) => {
        getVideostorePostList();
    };

    useEffect(() => {
        getVideostorePostList();
    }, [
        paginationState.activePage,
        paginationState.order,
        paginationState.sort,
        paginationState.itemsPerPage,
    ]);

    useEffect(() => {
        if (account) {
            socket.on(`user:${account.id}:video-update`, handleOnVideoUpdate);

        }
        return function cleanup() {
            socket.off(`user:${account.id}:video-update`, handleOnVideoUpdate);
        };
    }, [account]);

    useEffect(() => {
        if (updateSuccess) {
            getVideostorePostList();
        }
    }, [updateSuccess]);

    if (!hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_ADMIN]) || !videostorePosts.entities.length) {
        return <></>;
    }

    return (
        <Box pb={2}>
            <Paper style={{ overflow: "hidden" }}>
                <Grid container spacing={2} p={2}>
                    <Grid item sm={true} xs={12} style={{ alignSelf: "center" }}>
                        <Typography variant="h6">Bozze</Typography>
                    </Grid>
                </Grid>
                <Divider />
                {videostorePosts.loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                        <CircularProgress />
                    </Box>
                ) : (<List>
                    {videostorePosts.entities && videostorePosts.entities.length > 0 ?
                        videostorePosts.entities.map((videostorePost) => (
                            <ListItem
                                key={videostorePost.id}
                                secondaryAction={
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >

                                        <>
                                            {videostorePost.video?.status === IEdoVideoStatusEnum.READY ? (
                                                <Box mr={2}>
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => handlePublish(videostorePost)}
                                                        disabled={videostorePosts.loading || !videostorePost.title || !videostorePost.subject || !videostorePost.schools || !videostorePost.years || !videostorePost.draft || videostorePost?.video?.status !== IEdoVideoStatusEnum.READY}
                                                    >
                                                        <PublishIcon />
                                                    </IconButton>
                                                </Box>
                                            ) : null}
                                            <Box mr={2}>
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => handleEdit(videostorePost)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Box>
                                            <Box>
                                                <IconButton
                                                    onClick={() => handleDelete(videostorePost)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </>

                                    </Box>
                                }
                            >
                                <ListItemIcon>
                                    <Avatar>
                                        <MovieIcon />
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                    primary={videostorePost.title || "Post senza titolo"}
                                    secondary={getVideoStatusLabel(videostorePost?.video?.status)}
                                    secondaryTypographyProps={{ component: "div" }}
                                />
                            </ListItem>
                        )) : (
                            <ListItem button>
                                <ListItemText>
                                    <Typography variant="body2">
                                        <Translate contentKey="edocendoApp.edoVideostorePost.home.notFound" />
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        )}
                </List>
                )}
                <Divider />
                <TablePagination
                    component="div"
                    count={videostorePosts.totalItems}
                    rowsPerPage={paginationState.itemsPerPage}
                    page={paginationState.activePage - 1}
                    onPageChange={(event, page) => handlePagination(page + 1)}
                    onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
                    labelRowsPerPage="Righe per pagina:"
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
                    }
                    rowsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
                />
            </Paper>
        </Box>
    );
};

export default VideostoreDrafts;
