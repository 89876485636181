import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Sync as SyncIcon
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TablePagination, Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState, TextFormat, Translate, translate } from "react-jhipster";
import { IEdoCircular } from "shared/model/edo-circular.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import { getEntities } from "../../shared/reducers/entities/edo-circular.reducer";
import DeleteCircular from "./components/DeleteCircular";
import EditCircular from "./components/EditCircular";

const useStyles = makeStyles()(theme =>
  ({
    root: {
      display: "grid",
      gridTemplateRows: "min-content auto min-content",
      height: "100%",
    },
    appbar: {
      boxShadow: "none",
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: "solid",
    },
    toolbar: {
      justifyContent: "space-between",
    },
    paper: {
      color: theme.palette.text.secondary,
      overflow: "hidden",
    },
    button: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    buttonMobile: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
  })
);

const CircularsManagement = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "id",
    order: "ASC",
  });
  const [editCircularDialog, setEditCircularDialog] = useState<{ open: boolean; entity: IEdoCircular; }>({ open: false, entity: null });
  const [deleteCircularDialog, setDeleteCircularDialog] = useState<{ open: boolean; entity: IEdoCircular; }>({ open: false, entity: null });
  const { updateSuccess, loading, totalItems, entities: edoCircolareList } = useAppSelector<
    EntityState<IEdoCircular>
  >((state) => state.edoCircular);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const openFile = (file: IEdoFile) => {
    window.open(`/api/files/${file.id}/view`, "_blank");
  };

  useEffect(() => {
    getAllEntities();
  }, [
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
  ]);

  useEffect(() => {
    if (updateSuccess) {
      handleSyncList();
    }
  }, [updateSuccess]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Box className={classes.root}>
            <AppBar
              position="static"
              color="transparent"
              className={classes.appbar}
            >
              <Toolbar className={classes.toolbar}>
                <Typography variant="h5">
                  <Translate contentKey="edocendoApp.edoCircular.home.title" />
                </Typography>
                <Box>
                  <IconButton
                    color="inherit"
                    onClick={handleSyncList}
                    disabled={loading}
                    className={classes.buttonMobile}
                  >
                    <SyncIcon />
                  </IconButton>
                  <Button
                    color="inherit"
                    onClick={handleSyncList}
                    disabled={loading}
                    startIcon={<SyncIcon />}
                    className={classes.button}
                  >
                    <Translate contentKey="edocendoApp.edoCircular.home.refreshListLabel" />
                  </Button>
                  <IconButton
                    color="inherit"
                    onClick={() => setEditCircularDialog({ open: true, entity: null })}
                    disabled={loading}
                    className={classes.buttonMobile}
                  >
                    <AddIcon />
                  </IconButton>
                  <Button
                    color="inherit"
                    disabled={loading}
                    startIcon={<AddIcon />}
                    onClick={() => setEditCircularDialog({ open: true, entity: null })}
                    className={classes.button}
                  >
                    <Translate contentKey="edocendoApp.edoCircular.home.createLabel" />
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
            {edoCircolareList && edoCircolareList.length > 0 ? (
              <List dense>
                {edoCircolareList.map((edoCircolare, i) => (
                  <ListItem key={edoCircolare.id} button>
                    {/* <ListItemAvatar>
                  <Avatar alt={edoCircolare.title}>{edoCircolare.title[0]}</Avatar>
                </ListItemAvatar> */}
                    <ListItemText
                      primary={edoCircolare.title}
                      secondaryTypographyProps={{ variant: "body2" }}
                      secondary={
                        <>
                          <TextFormat
                            value={edoCircolare.date}
                            type="date"
                            format={APP_LOCAL_DATE_FORMAT}
                          />
                          {" - " +
                            translate(
                              "edocendoApp.EdoCircularVisibility." +
                              edoCircolare.visibility
                            )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Box display="flex" flexDirection="row">
                        <Box mr={2}>
                          {/* <IconButton onClick={() => openFile(edoCircolare.file)} edge="end" aria-label="Open file">
                        <VisibilityIcon />
                      </IconButton> */}
                        </Box>

                        <Box mr={2}>
                          <IconButton
                            edge="end"
                            onClick={() => setEditCircularDialog({ open: true, entity: edoCircolare })}
                          >
                            <EditIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <IconButton
                            edge="end"
                            onClick={() => setDeleteCircularDialog({ open: true, entity: edoCircolare })}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              !loading && (
                <Box p={2}>
                  <Typography variant="body1" align="center">
                    <Translate contentKey="edocendoApp.edoCircular.home.notFound" />
                  </Typography>
                </Box>
              )
            )}
            <Divider />
            <TablePagination
              component="div"
              count={totalItems}
              rowsPerPage={paginationState.itemsPerPage}
              page={paginationState.activePage - 1}
              onPageChange={(event, page) => handlePagination(page + 1)}
              onRowsPerPageChange={(event) =>
                handleRowsPerPage(parseInt(event.target.value, 10))
              }
              labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
              }
            />
          </Box>
        </Paper>
      </Grid>

      <EditCircular
        circular={editCircularDialog.entity}
        open={editCircularDialog.open}
        onClose={() => setEditCircularDialog({ open: false, entity: null })}
      />
      <DeleteCircular
        circular={deleteCircularDialog.entity}
        open={deleteCircularDialog.open}
        onClose={() => setDeleteCircularDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default CircularsManagement;
