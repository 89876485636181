import { CssBaseline } from "@mui/material";
import { itIT } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, StyledEngineProvider } from "@mui/system";
import axios from "axios";
import setupAxiosInterceptors from "config/axios-interceptor";
import getStore, { useAppSelector } from "config/store";
import { socket, SocketContext } from "context/socket";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { bindActionCreators } from "redux";
import { IEdoSetting } from "shared/model/edo-setting.model";
import { EdoSettingEnum } from "shared/model/enumerations/edo-setting.model";
import { clearAuthentication } from "shared/reducers/authentication";
import { SettingsState } from "shared/reducers/settings.reducer";
import { THEME_MODE_KEY } from "shared/util/global-settings";
import AppComponent from "./App";
import { registerLocale } from "./config/translation";
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const store = getStore();
registerLocale(store);

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);

setupAxiosInterceptors(() =>
  actions.clearAuthentication("login.error.unauthorized")
);

const container = document.getElementById("root");

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    zoom: PaletteOptions['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    zoom?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    zoom: true;
  }
}

declare module '@mui/material/IconButton' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    zoom: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    mini: true;
  }
}

const ThemeWrapper = (props: PropsWithChildren) => {
  const [settings, setSettings] = useState<{ primaryColor: string; secondaryColor: string; }>({ primaryColor: null, secondaryColor: null });
  const { theme: themeMode } = useAppSelector<SettingsState>(state => state.settings);

  const prefersDarkMode = useMemo(() => {
    return themeMode === "dark";
  }, [themeMode]);

  const getSetting = async (key: string): Promise<IEdoSetting> => {
    const requestUrl = `/settings/${key}`;
    const response = await axios.get<{ data: IEdoSetting }>(requestUrl);

    return response.data.data;
  };

  const getSettings = async () => {
    const [primaryColorSetting, secondaryColorSetting] = await Promise.allSettled([getSetting(EdoSettingEnum.THEME_PRIMARY_COLOR), getSetting(EdoSettingEnum.THEME_SECONDARY_COLOR)]);

    const primaryColorValue: string | null = primaryColorSetting.status === "fulfilled" ? primaryColorSetting.value.value?.value || null : null;
    const secondaryColorValue: string | null = secondaryColorSetting.status === "fulfilled" ? secondaryColorSetting.value.value?.value || null : null;

    setSettings({
      primaryColor: primaryColorValue,
      secondaryColor: secondaryColorValue
    });

    if (primaryColorValue !== null) {
      localStorage.setItem(EdoSettingEnum.THEME_PRIMARY_COLOR, primaryColorValue);
    } else {
      localStorage.removeItem(EdoSettingEnum.THEME_PRIMARY_COLOR);
    }

    if (secondaryColorValue !== null) {
      localStorage.setItem(EdoSettingEnum.THEME_SECONDARY_COLOR, secondaryColorValue);
    } else {
      localStorage.removeItem(EdoSettingEnum.THEME_SECONDARY_COLOR);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (themeMode === "dark" || themeMode === "light") {
      localStorage.setItem(THEME_MODE_KEY, String(themeMode));
    }
  }, [themeMode]);

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: themeMode,
            primary: {
              main: settings.primaryColor || localStorage.getItem(EdoSettingEnum.THEME_PRIMARY_COLOR) || "#2196f3"
            },
            secondary: {
              main: settings.secondaryColor || localStorage.getItem(EdoSettingEnum.THEME_SECONDARY_COLOR) || "#f50057"
            },
            background: {
              // default: prefersDarkMode ? "#3a3939" : "#eaeaea",
              default: prefersDarkMode ? "#333334" : "#f0f2f5",
              paper: prefersDarkMode ? "#1e1e1f" : "#ffffff"
            },
            neutral: {
              main: '#64748B',
              contrastText: '#fff',
            },
            zoom: {
              main: "#2D8CFF",
              contrastText: '#fff',
            },
          },
          typography: {
            fontFamily: "Poppins"
          },
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                body: {
                  // ...darkScrollbar(),
                  overflowY: "auto!important",
                }
              }
            },
            MuiChip: {
              variants: [{
                props: { size: "mini" },
                style: {
                  height: 20,
                  // fontSize: 12,
                },
              }]
            }
          }
        },
        itIT
      ),
    [prefersDarkMode, settings.primaryColor, settings.secondaryColor]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Box style={{ backgroundColor: theme.palette.background.default }}>
        {props.children}
      </Box>
    </ThemeProvider>
  );
};

const root = createRoot(container as HTMLElement);

root.render(
  <>
    {/* <ErrorBoundary>*/}
    <Provider store={store}>
      <SocketContext.Provider value={socket}>
        <ThemeWrapper>
          <StyledEngineProvider injectFirst>
            {/* <React.StrictMode> */}
            <AppComponent />
            {/* </React.StrictMode> */}
          </StyledEngineProvider>
        </ThemeWrapper>
      </SocketContext.Provider>
    </Provider>
    {/* </ErrorBoundary> */}
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
