import {
    createAsyncThunk,
    createSlice,
    isFulfilled,
    isPending,
    isRejected,
} from "@reduxjs/toolkit";
import axios from "axios";
import { IEdoClass } from "shared/model/edo-class.model";
import { IEdoTeacher } from "shared/model/edo-teacher.model";
import { IUserBase } from "shared/model/user.model";
import { IPaginationResponse } from "shared/util/pagination.constants";
import { IQueryParamsWithFilters, serializeAxiosError } from "../reducer.utils";

type EdoTeacherStateType = {
    count: number | null;
    loadingCount: boolean;
    classes: IEdoClass[];
    loadingClasses: boolean;
    errorMessage: string | null;
    totalItems: number;
    entity: {
        entity: IEdoTeacher;
        loading: boolean;
        updating: boolean;
        updateSuccess: boolean;
    };
};

export const initialState: EdoTeacherStateType = {
    count: null,
    loadingCount: false,
    classes: [],
    loadingClasses: false,
    errorMessage: null,
    totalItems: 0,
    entity: {
        entity: null,
        loading: false,
        updating: false,
        updateSuccess: false,
    },
};

export type EdoTeacherState = Readonly<EdoTeacherStateType>;

const apiUrl = "/teachers";

// Actions

export const createTeacher = createAsyncThunk(
    "edoTeacher/create_teacher",
    async (
        user: IUserBase & { isVice: boolean; isSupport: boolean },
        thunkAPI
    ) => {
        const result = await axios.post<{ data: IEdoTeacher }>(apiUrl, user);
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const getTeacherClasses = createAsyncThunk(
    "edoTeacher/fetch_teacher_classes",
    async ({
        id,
        page,
        size,
        sort,
        filters,
    }: { id: number } & IQueryParamsWithFilters) => {
        const params = new URLSearchParams(filters);
        if (sort) {
            params.append("page", String(page));
            params.append("limit", String(size));

            if (typeof sort === "string") {
                params.append("sortBy", sort.replace(",", ":"));
            } else {
                for (let s of sort) {
                    params.append("sortBy", s.replace(",", ":"));
                }
            }
        }
        let requestUrl;
        if (params.toString() === "") {
            requestUrl = `${apiUrl}/${id}/classes`;
        } else {
            requestUrl = `${apiUrl}/${id}/classes?${params.toString()}`;
        }
        return axios.get<IPaginationResponse<IEdoClass>>(requestUrl);
    }
);

export const getCount = createAsyncThunk(
    "edoTeacher/fetch_count",
    async () => {
        const requestUrl = `${apiUrl}/count`;
        return axios.get<{ count: number }>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

// slice

export const EdoTeacherSlice = createSlice({
    name: "edoTeacher",
    initialState: initialState as EdoTeacherState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getTeacherClasses.fulfilled, (state, action) => {
                const {
                    data: {
                        data,
                        meta: { totalItems },
                    },
                    headers,
                } = action.payload;

                return {
                    ...state,
                    loadingClasses: false,
                    classes: data,
                    totalItems,
                };
            })
            .addCase(getCount.fulfilled, (state, action) => ({
                ...state,
                loadingCount: false,
                count: action.payload.data.count,
            }))
            .addCase(getCount.pending, (state) => {
                state.errorMessage = null;
                state.loadingCount = true;
            })
            .addMatcher(isPending(getTeacherClasses), (state) => {
                state.errorMessage = null;
                state.loadingClasses = true;
            })
            .addMatcher(isFulfilled(createTeacher), (state, action) => {
                state.entity.updating = false;
                state.entity.loading = false;
                state.entity.updateSuccess = true;
                state.entity.entity = action.payload.data.data;
            })
            .addMatcher(isPending(createTeacher), (state) => {
                state.errorMessage = null;
                state.entity.updateSuccess = false;
                state.entity.updating = true;
            })
            .addMatcher(isRejected(createTeacher), (state, action) => {
                state.entity.loading = false;
                state.entity.updating = false;
                state.entity.updateSuccess = false;
                state.errorMessage = action.error.message;
            });
    },
});

// Reducer
const EdoTeacherReducer = EdoTeacherSlice.reducer;
export default EdoTeacherReducer;
