import moment from "moment";
import { EdoChatType } from "shared/model/enumerations/edo-chat-type.model";
export interface IEdoChat {
  id?: number;
  title?: string | null;
  type?: EdoChatType;
  lastActivity?: string | null;
  systemChat?: boolean;
  createdAt?: string;
}

const date = new Date();
const tzoffset = new Date().getTimezoneOffset() * 60000;

export const defaultValue: Readonly<IEdoChat> = {
  createdAt: moment(date).subtract(tzoffset).toISOString().slice(0, -1),
};
