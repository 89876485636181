import { IEdoChat } from "shared/model/edo-chat.model";
import { IUser } from "shared/model/user.model";

export interface IEdoChatMessage {
  id?: number;
  message?: string;
  createdAt?: string;
  user?: IUser;
  chat?: IEdoChat;
}

export const defaultValue: Readonly<IEdoChatMessage> = {};
