import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Sync as SyncIcon
} from "@mui/icons-material";
import {
  AppBar,
  Box, Button, Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TablePagination, Toolbar,
  Typography
} from "@mui/material";
import { APP_DATE_FORMAT } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useState } from "react";
import { IPaginationBaseState, TextFormat, Translate, getSortState } from "react-jhipster";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IEdoCourseEvent } from "shared/model/edo-course-event.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { getEntities } from "shared/reducers/entities/edo-course-event.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { overridePaginationStateWithQueryParams } from "shared/util/entity-utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import { DeleteEventoInsegnamento } from "../../components/DeleteEventoInsegnamento";
import EditCourseEvent from "../../components/EditCourseEvent";

const useStyles = makeStyles()(theme =>
  ({
    root: {
      display: "grid",
      gridTemplateRows: "min-content auto min-content",
      height: "100%",
      overflow: 'hidden'
    },
    appbar: {
      boxShadow: "none",
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: "solid",
    },
    toolbar: {
      justifyContent: "space-between",
    },
    paper: {
      color: theme.palette.text.secondary,
      overflow: "hidden",
    },
  })
);

const CourseEvents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>(
    overridePaginationStateWithQueryParams(
      getSortState(location, ITEMS_PER_PAGE, "id"),
      location.search
    )
  );
  const [editCourseEventDialog, setEditCourseEventDialog] = useState<{ open: true; entity: IEdoCourseEvent } | { open: false;  entity: null }>({ open: false, entity: null });
  const [deleteCourseEventDialog, setDeleteCourseEventDialog] = useState<{ open: true; entity: IEdoCourseEvent } | { open: false;  entity: null }>({ open: false, entity: null });
  const { updateSuccess, loading, totalItems, entities: courseEvents } = useAppSelector<EntityState<IEdoCourseEvent>>((state) => state.edoCourseEvent);
  const { entity: edoInsegnamentoEntity } = useAppSelector< EntityState<IEdoCourse>>((state) => state.edoCourse);

  const getAllEntities = () => {
    const filters = new URLSearchParams();
    filters.append("filter.course.id", '$eq:' + params.id);
    dispatch(
      getEntities({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: filters.toString(),
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const sort = params.get("sort");
    if (page && sort) {
      const sortSplit = sort.split(",");
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  useEffect(() => {
    if (updateSuccess) {
      handleSyncList();
    }
  }, [updateSuccess]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Box className={classes.root}>
          <AppBar
            position="static"
            color="transparent"
            className={classes.appbar}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant="h5">
                <Translate contentKey="edocendoApp.edoCourseEvent.home.title" />
              </Typography>
              <Box>
                <IconButton
                  color="inherit"
                  onClick={handleSyncList}
                  disabled={loading}
                  sx={(theme) => ({
                    [theme.breakpoints.up("lg")]: {
                      display: "none",
                    }
                  })}
                >
                  <SyncIcon />
                </IconButton>
                <Button
                  color="inherit"
                  onClick={handleSyncList}
                  disabled={loading}
                  startIcon={<SyncIcon />}
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  })}
                >
                  <Translate contentKey="edocendoApp.edoCourseEvent.home.refreshListLabel" />
                </Button>
                <IconButton
                  color="inherit"
                  edge="end"
                  onClick={() => setEditCourseEventDialog({ open: true, entity: null })}
                  disabled={loading}
                  sx={(theme) => ({
                    [theme.breakpoints.up("lg")]: {
                      display: "none",
                    }
                  })}
                >
                  <AddIcon />
                </IconButton>
                <Button
                  color="inherit"
                  disabled={loading}
                  startIcon={<AddIcon />}
                  onClick={() => setEditCourseEventDialog({ open: true, entity: null })}
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  })}
                >
                  Aggiungi evento
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          {courseEvents?.length > 0 ? (
            <List dense>
              {courseEvents.map(courseEvent => (
                <ListItem key={courseEvent.id} button>
                  {/* <ListItemAvatar>
                  <Avatar alt={edoEventoInsegnamento.title}>{edoEventoInsegnamento.title[0]}</Avatar>
                </ListItemAvatar> */}
                  <ListItemText
                    primary={courseEvent.title}
                    secondary={
                      courseEvent.startDate &&
                        courseEvent.endDate ? (
                        <>
                          <TextFormat
                            value={courseEvent.startDate}
                            type="date"
                            format={APP_DATE_FORMAT}
                          />{" "}
                          {" - "}
                          <TextFormat
                            value={courseEvent.endDate}
                            type="date"
                            format={APP_DATE_FORMAT}
                          />
                        </>
                      ) : null
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box display="flex" flexDirection="row">
                      <Box mr={2}>
                        {/* <IconButton onClick={() => openFile(edoEventoInsegnamento.file)} edge="end" aria-label="Open file">
                        <VisibilityIcon />
                      </IconButton> */}
                      </Box>

                      <Box mr={2}>
                        <IconButton
                          edge="end"
                          onClick={() => setEditCourseEventDialog({ open: true, entity: courseEvent })}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          edge="end"
                          onClick={() => setDeleteCourseEventDialog({ open: true, entity: courseEvent })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            !loading && (
              <Box p={2}>
                <Typography variant="body1" align="center">
                  <Translate contentKey="edocendoApp.edoCourseEvent.home.notFound" />
                </Typography>
              </Box>
            )
          )}
          <Divider />
          <TablePagination
            component="div"
            count={totalItems}
            rowsPerPage={paginationState.itemsPerPage}
            page={paginationState.activePage - 1}
            onPageChange={(event, page) => handlePagination(page + 1)}
            onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
            labelRowsPerPage="Righe per pagina:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
            }
          />
        </Box>
      </Paper>

      <EditCourseEvent
        open={editCourseEventDialog.open}
        courseEvent={editCourseEventDialog.entity}
        course={edoInsegnamentoEntity}
        onClose={() => setEditCourseEventDialog({ open: false, entity: null })}
      />

      <DeleteEventoInsegnamento
        open={deleteCourseEventDialog.open}
        evento={deleteCourseEventDialog.entity}
        handleClose={() => setDeleteCourseEventDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default CourseEvents;
