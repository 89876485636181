import {
  Bookmark as BookmarkIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon
} from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import "moment/locale/it";
import { TextFormat } from "react-jhipster";
import { IEdoUnit } from "shared/model/edo-unit.model";

export interface UnitRowProps {
  unit?: IEdoUnit;
  viewUnit: (unit: IEdoUnit) => void;
  editUnit: (unit: IEdoUnit) => void;
  deleteUnit: (unit: IEdoUnit) => void;
  nested: boolean;
}

export const UnitRow = (props: UnitRowProps) => {
  const { unit, viewUnit, editUnit, deleteUnit, nested } = props;

  return (
    <ListItem sx={(theme) => ({
      ...(nested ? {
        paddingLeft: 4,
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 2,
        },
      } : null)
    })}>
      <ListItemAvatar>
        <Avatar>
          <BookmarkIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={unit.title}
        primaryTypographyProps={{ variant: "body2" }}
        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
        secondary={
          <TextFormat
            value={unit.createdAt}
            type="date"
            format={APP_LOCAL_DATE_FORMAT}
          />
        }
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => viewUnit(unit)}
          edge="end"
          aria-label="View unit"
          sx={{ marginRight: 0.5 }}
        >
          <VisibilityIcon />
        </IconButton>
        {editUnit !== null ? (
          <IconButton
            onClick={() => editUnit(unit)}
            edge="end"
            aria-label="Edit unit"
            sx={{ marginRight: 0.5 }}
          >
            <EditIcon />
          </IconButton>
        ) : null}
        {editUnit !== null ? (
          <IconButton
            onClick={() => deleteUnit(unit)}
            edge="end"
            aria-label="Delete unit"
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
