import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { deleteEntity } from "../../../shared/reducers/entities/edo-unit.reducer";

export interface DeleteUnitProps {
  unit: IEdoUnit;
  open: boolean;
  handleClose: (updated: boolean) => void;
}

export const DeleteUnit = (props: DeleteUnitProps) => {
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector<boolean>(
    (state) => state.edoUnit.updateSuccess
  );

  const { open, handleClose, unit } = props;

  const handleCloseDialog = () => {
    handleClose(false);
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(unit.id));
  };

  useEffect(() => {
    if (updateSuccess) {
      handleCloseDialog();
    }
  }, [updateSuccess]);

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate
            contentKey="edocendoApp.edoUnit.delete.question"
            interpolate={{ nome: unit?.title }}
          >
            Are you sure you want to delete this EdoUnit?
          </Translate>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button onClick={confirmDelete} color="primary">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
