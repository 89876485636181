import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoQuizQuestion } from "shared/model/edo-quiz-question.model";
import { deleteEntity } from "shared/reducers/entities/edo-quiz-question.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

interface IRemoveQuestionProps {
    question: IEdoQuizQuestion;
    open: boolean;
    onClose: () => void;
}

const RemoveQuestion = (props: IRemoveQuestionProps) => {
    const dispatch = useAppDispatch();
    const { updateSuccess } = useAppSelector<EntityState<IEdoQuizQuestion>>(
        (state) => state.edoQuizQuestion
    );

    const { question, open, onClose: handleClose } = props;

    const confirmRemove = () => {
        dispatch(deleteEntity(question.id));
    };

    useEffect(() => {
        if (open && updateSuccess) {
            handleClose();
        }
    }, [updateSuccess]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Translate contentKey="entity.delete.title">
                    Confirm delete operation
                </Translate>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Translate
                        contentKey="edocendoApp.edoQuizQuestion.delete.question"
                    >
                        Are you sure you want to delete this EdoQuizQuestion?
                    </Translate>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button onClick={confirmRemove} color="primary">
                    <Translate contentKey="entity.action.delete">Remove</Translate>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveQuestion;