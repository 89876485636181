import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoCircular } from "shared/model/edo-circular.model";
import { deleteEntity } from "../../../shared/reducers/entities/edo-circular.reducer";

interface DeleteCircularProps {
  circular: IEdoCircular;
  open: boolean;
  onClose: () => void;
}

const DeleteCircular = (props: DeleteCircularProps) => {
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector<boolean>(
    (state) => state.edoCircular.updateSuccess
  );

  const { open, onClose: handleClose, circular } = props;

  const confirmDelete = () => {
    dispatch(deleteEntity(circular.id));
  };
  
  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      {circular ? (
        <DialogContent>
          <DialogContentText>
            <Translate
              contentKey="edocendoApp.edoCircular.delete.question"
              interpolate={{ nome: circular.title }}
            >
              Are you sure you want to delete this EdoCircolare?
            </Translate>
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button onClick={confirmDelete} color="primary">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCircular;