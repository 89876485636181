import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { deleteEntity } from "../../../../shared/reducers/entities/edo-chapter.reducer";

interface IDeleteChapterProps {
  chapter: IEdoChapter;
  open: boolean;
  onClose: () => void;
}

const DeleteChapter = (props: IDeleteChapterProps) => {
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector<boolean>(
    (state) => state.edoChapter.updateSuccess
  );

  const { open, onClose: handleClose, chapter } = props;

  const confirmDelete = () => {
    dispatch(deleteEntity(chapter.id));
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Translate contentKey="entity.delete.title">
          Confirm delete operation
        </Translate>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate
            contentKey="edocendoApp.edoChapter.delete.question"
            interpolate={{ nome: chapter?.title }}
          >
            Are you sure you want to delete this EdoCapitolo?
          </Translate>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button onClick={confirmDelete} color="primary">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteChapter;