import {
  Cancel as CancelIcon, Check as CheckIcon, Close as CloseIcon
} from "@mui/icons-material";
import {
  AppBar, Box, CircularProgress,
  Dialog, DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper, Tab,
  Tabs, Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import { SelectFiles } from "components/SelectFiles/SelectFiles";
import { APP_LOCAL_DATE_FORMAT } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import React, { ChangeEvent, useEffect, useState } from "react";
import { TextFormat, translate } from "react-jhipster";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { IEdoChapter, defaultValue } from "shared/model/edo-chapter.model";
import { IEdoFile } from "shared/model/edo-file.model";
import {
  createEntity,
  updateEntity
} from "shared/reducers/entities/edo-chapter.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { getFileAvatar } from "shared/util/file-avatar-utils";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box pt={2}>{children}</Box>}
    </Box>
  );
}

export interface EditChapterInfoProps {
  chapter?: IEdoChapter;
  open: boolean;
  handleClose: (updated: boolean) => void;
}

export const EditChapterInfo = (props: EditChapterInfoProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { loading, updating, updateSuccess } = useAppSelector<
    EntityState<IEdoChapter>
  >((state) => state.edoChapter);
  const [isNew, setIsNew] = useState<boolean>(props.chapter === null || props.chapter === undefined);
  const [form, setForm] = useState<IEdoChapter>({ ...defaultValue, ...props.chapter });
  const [tabValue, setTabValue] = useState<number>(0);
  const [selectedFiles, setSelectedFiles] = useState<IEdoFile[]>();

  const {
    chapter,
    open,
    handleClose,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSelectedFiles = (fileList: IEdoFile[]) => {
    setSelectedFiles(fileList);
  };

  const handleChangeTab = (event: ChangeEvent<any>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCloseDialog = (updated: boolean) => {
    setForm({ ...defaultValue });
    handleClose(updated);
  };

  useEffect(() => {
    setForm({ ...defaultValue, ...chapter });
    if (chapter) setSelectedFiles([...chapter.files]);
  }, [chapter]);

  useEffect(() => {
    if (updateSuccess) {
      handleCloseDialog(true);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (!open) {
      setForm({ ...defaultValue });
      setSelectedFiles([]);
    }
  }, [open]);

  const removeFile = (index: number) => {
    const selectedFilesLocal = Array.from(selectedFiles);
    selectedFilesLocal.splice(index, 1);
    setSelectedFiles([...selectedFilesLocal]);
  };

  const saveEntity = () => {
    if (isNew) {
      dispatch(
        createEntity({
          ...form,
          files: selectedFiles,
        } as any)
      );
    } else {
      dispatch(
        updateEntity({
          ...form,
          files: selectedFiles,
        } as any)
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullScreen={true} fullWidth>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" flexGrow={1}>
            {isNew ? translate("edocendoApp.edoChapter.home.createLabel") : translate("edocendoApp.edoChapter.home.createOrEditLabel")}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            disabled={updating}
            sx={{ marginRight: 1 }}
            onClick={() => handleCloseDialog(false)}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            disabled={updating}
            onClick={saveEntity}
          >
            <CheckIcon />
          </IconButton>
        </Toolbar>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab label="Informazioni" />
          <Tab label="Documenti" />
        </Tabs>
      </AppBar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <TabPanel value={tabValue} index={0}>
          {loading ? (
            <CircularProgress
              size={24}
              style={{ marginLeft: 15, position: "relative", top: 4 }}
            />
          ) : (
            <>
              <ValidatorForm
                onSubmit={saveEntity}
                onError={(errors) => false}
                sx={{ flexGrow: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      label={translate("edocendoApp.edoChapter.title")}
                      onChange={handleChange}
                      name="title"
                      value={form.title || ""}
                    />
                  </Grid>
                </Grid>
              </ValidatorForm>
            </>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box pb={2}>
            <SelectFiles
              selectedFiles={selectedFiles}
              setSelectedFiles={handleSelectedFiles}
              enableUpload={true}
            />
          </Box>
          <Box pb={2}>
            <Paper sx={{ color: "text.secondary", overflow: "hidden" }}>
              <List
                component="div"
                disablePadding
                subheader={<ListSubheader>Documenti selezionati</ListSubheader>}
              >
                {selectedFiles && selectedFiles.length ? (
                  selectedFiles.map((file: IEdoFile, index: number) => (
                    <ListItem key={file.id} button>
                      <ListItemAvatar>
                        {getFileAvatar(file.fileType)}
                      </ListItemAvatar>
                      <ListItemText
                        primary={file.originalFileName}
                        primaryTypographyProps={{ variant: "body2" }}
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                        secondary={
                          <TextFormat
                            value={file.createdAt}
                            type="date"
                            format={APP_LOCAL_DATE_FORMAT}
                          />
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => removeFile(index)}
                          edge="end"
                          aria-label="Open file"
                        >
                          <CancelIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                ) : (
                  <Box p={2}>
                    <Typography variant="body1">
                      Nessun file selezionato.
                    </Typography>
                  </Box>
                )}
              </List>
            </Paper>
          </Box>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};
