import {
  Add as AddIcon
} from "@mui/icons-material";
import {
  AppBar, Box,
  Divider,
  IconButton,
  LinearProgress,
  List, Paper,
  TablePagination, Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios, { AxiosError } from "axios";
import SearchField from "components/SearchField/SearchField";
import { useAppDispatch, useAppSelector } from "config/store";
import "moment/locale/it";
import React, { createRef, useEffect, useState } from "react";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { toast } from "react-toastify";
import { IEdoFile } from "shared/model/edo-file.model";
import { getCurrentUserFiles } from "shared/reducers/entities/edo-file.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import DeleteFile from "./components/DeleteFile/DeleteFile";
import EditFile from "./components/EditFile/EditFile";
import FileRow from "./components/FileRow/FileRow";
import UploadFiles from "./components/UploadFiles/UploadFiles";

const useStyles = makeStyles()(theme => ({
  paper: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
  },
}));

const ArchivioFiles = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const fileInput = createRef<HTMLInputElement>();
  const {
    entities: edoFileList,
    updateSuccess,
    loading,
    totalItems,
  } = useAppSelector<EntityState<IEdoFile>>((state) => state.edoFile);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "createdAt",
    order: "DESC",
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [filesUploadProgress, setFilesUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [editFileDialog, setEditFileDialog] = useState<{ open: boolean; entity?: IEdoFile }>({ open: false, entity: null });
  const [deleteFileDialog, setDeleteFileDialog] = useState<{ open: boolean; entity?: IEdoFile }>({ open: false, entity: null });

  const handleGetEdoFileList = () => {
    const params = new URLSearchParams();
    searchValue && params.append("search", searchValue);
    params.append("filter.repository", "$eq:1");
    dispatch(
      getCurrentUserFiles({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        filters: params.toString(),
      })
    );
  };

  useEffect(() => {
    handleGetEdoFileList();
  }, [
    searchValue,
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
  ]);

  useEffect(() => {
    const handleSyncList = () => {
      handleGetEdoFileList();
    };

    if (updateSuccess) {
      handleSyncList();
    }
  }, [updateSuccess]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });


  const selectFiles = () => {
    fileInput.current.click();
  };

  const handleUpload = async () => {
    if (filesToUpload.length) {
      setUploading(true);
      const formData = new FormData();
      for (let i = 0; i < filesToUpload.length; i++) {
        formData.append("files", filesToUpload[i], filesToUpload[i].name);
      }

      try {
        await axios.post<{ data: IEdoFile[] }>("/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress(progressEvent) {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setFilesUploadProgress(percentage);
          }
        });

        setFilesToUpload([]);
        handleGetEdoFileList();
      } catch (e) {
        if (e instanceof AxiosError) {
          toast.error(e.response.data.message);
          return;
        }
        toast.error("Errore durante l'upload dei file");
      }
      setUploading(false);
    }
  };

  const handleFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);
    setFilesToUpload(files);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <AppBar position="relative">
          <Toolbar>
            <Typography variant="h6" sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              },
              flexGrow: 1,
            })}>
              Documenti
            </Typography>
            <Box mr={breakpointDownSm ? 1 : 2}>
              <SearchField
                placeholder="Cerca un file"
                value={searchValue}
                setSearchValue={setSearchValue}
              />
            </Box>
            <Box>
              <IconButton edge="end" color="inherit" onClick={selectFiles}>
                <AddIcon />
              </IconButton>
              <input
                type="file"
                ref={fileInput}
                name="files"
                multiple={true}
                hidden
                onChange={(event) => {
                  handleFiles(event);
                }}
              />
              <UploadFiles handleUpload={handleUpload} setFilesToUpload={(files: File[]) => setFilesToUpload(files)} filesToUpload={filesToUpload} progress={filesUploadProgress} uploading={uploading} />
            </Box>
          </Toolbar>
        </AppBar>
        {loading && (
          <LinearProgress variant="indeterminate" color="secondary" />
        )}
        <Box>
          {edoFileList.length ? (
            <List dense>
              {edoFileList.map((file) => (
                <FileRow
                  key={file.id}
                  file={file}
                  onEdit={() => setEditFileDialog({ open: true, entity: file })}
                  onDelete={() => setDeleteFileDialog({ open: true, entity: file })}
                />
              ))}
            </List>
          ) : (
            <Box p={2}>
              <Typography variant="body1" align="center">
                <Translate contentKey="edocendoApp.edoFile.home.notFound" />
              </Typography>
            </Box>
          )}
        </Box>
        <Divider />
        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={paginationState.itemsPerPage}
          page={paginationState.activePage - 1}
          onPageChange={(event, page) => handlePagination(page + 1)}
          onRowsPerPageChange={(event) => handleRowsPerPage(parseInt(event.target.value, 10))}
          labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
          }
        />
      </Paper>
      <EditFile
        file={editFileDialog.entity}
        open={editFileDialog.open}
        onClose={() => setEditFileDialog({ open: false, entity: null })}
      />
      <DeleteFile
        file={deleteFileDialog.entity}
        open={deleteFileDialog.open}
        onClose={() => setDeleteFileDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default ArchivioFiles;