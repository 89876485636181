import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import { useContext, useEffect, useLayoutEffect } from "react";
import { AuthenticationState, logout } from "shared/reducers/authentication";

export const Logout = () => {
  const dispatch = useAppDispatch();
  const socket = useContext(SocketContext);
  const { logoutSuccess} = useAppSelector<AuthenticationState>((state) => state.authentication);

  useLayoutEffect(() => {
    dispatch(logout());
  });

  useEffect(() => { 
    if (logoutSuccess) {
      socket.disconnect();
    }
  }, [
    logoutSuccess
  ]);

  return (
    <Box  p={2}>
      <Typography variant="h6">Logged out successfully!</Typography>
      {/* <Redirect to="/login" /> */}
    </Box>
  );
};

export default Logout;
