import { IEdoCourseYear } from "shared/model/edo-course-year.model";
import { IEdoFile } from "shared/model/edo-file.model";
import { IEdoSchool } from "shared/model/edo-school.model";
import { IEdoSubject } from "shared/model/edo-subject.model";
import { EdoEducationalStage } from "./enumerations/edo-educational-stage.model";
export interface IEdoWikiPost {
  id?: number;
  title?: string;
  file?: IEdoFile;
  subject?: IEdoSubject;
  schools?: IEdoSchool[];
  educationalStage?: EdoEducationalStage;
  years?: IEdoCourseYear[];
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IEdoWikiPost> = {};
