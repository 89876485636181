import {
    Cancel as CancelIcon, Check as CheckIcon, Close as CloseIcon
} from "@mui/icons-material";
import {
    AppBar,
    Avatar,
    Box, Dialog, DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Paper, Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { IEdoQuestion } from "shared/model/edo-question.model";
import { getQuestionIcon, getQuestionType } from "shared/util/question-utils";
import { makeStyles } from 'tss-react/mui';
import SelectQuestions from "./SelectQuestions";

const useStyles = makeStyles()(theme => ({
    paper: {
        color: theme.palette.text.secondary,
        overflow: "hidden",
    },
}));

export interface SelectQuestionsDialogProps {
    open: boolean;
    onClose: () => void;
    onSuccess: (questions: IEdoQuestion[]) => void;
    disabledQuestions?: IEdoQuestion[];
    selectedQuestions: IEdoQuestion[];
    setSelectedQuestions: (questions: IEdoQuestion[]) => void;
}

const SelectQuestionsDialog = (props: SelectQuestionsDialogProps) => {
    const theme = useTheme();
    const  {classes } = useStyles();
    const [selectedQuestionsLocal, setSelectedQuestionsLocal] = useState<IEdoQuestion[]>(
        props.selectedQuestions
    );

    const { open, onSuccess: handleSuccess, onClose: handleClose, disabledQuestions, selectedQuestions, setSelectedQuestions } = props;

    const handleSubmit = () => {
        setSelectedQuestions(selectedQuestionsLocal);
        handleSuccess(selectedQuestionsLocal);
        handleClose();
    };

    const removeQuestion = (index: number) => {
        const selectedQuestionsSubLocal = Array.from(selectedQuestionsLocal);
        selectedQuestionsSubLocal.splice(index, 1);
        setSelectedQuestionsLocal([...selectedQuestionsSubLocal]);
    };

    useEffect(() => {
        setSelectedQuestionsLocal(selectedQuestions);
    }, [selectedQuestions]);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            fullScreen={true}
            fullWidth
            maxWidth="lg"
        >
            <AppBar position="relative">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" marginLeft={2} flexGrow={1}>
                        Seleziona domande dall&apos;archivio
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={handleSubmit}>
                        <CheckIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent
                style={{ backgroundColor: theme.palette.background.default }}
            >
                <Box pb={2} mt={2}>
                    <SelectQuestions
                        disabledQuestions={disabledQuestions}
                        selectedQuestions={selectedQuestionsLocal}
                        setSelectedQuestions={setSelectedQuestionsLocal}
                    />
                </Box>
                <Paper className={classes.paper}>
                    <List
                        component="div"
                        disablePadding
                        subheader={<ListSubheader>Domande selezionati</ListSubheader>}
                    >
                        {selectedQuestionsLocal && selectedQuestionsLocal.length ? (
                            selectedQuestionsLocal.map((question: IEdoQuestion, index: number) => (
                                <ListItem key={question.id} button>
                                    <ListItemAvatar>
                                        <Avatar>
                                            {getQuestionIcon(question.type)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={question.description}
                                        primaryTypographyProps={{ variant: "body2", color: "textPrimary" }}
                                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                                        secondary={getQuestionType(question.type, true)}
                                        secondaryTypographyProps={{ color: "text.secondary" }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            onClick={() => removeQuestion(index)}
                                            edge="end"
                                            aria-label="Remove question"
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        ) : (
                            <Box p={2}>
                                <Typography variant="body1">
                                    Nessuna domanda selezionata.
                                </Typography>
                            </Box>
                        )}
                    </List>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default SelectQuestionsDialog;