import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { IUser } from "shared/model/user.model";
import { IEdoFile } from "./edo-file.model";

export interface IEdoCourseMaterial {
  id?: number;
  title?: string;
  createdAt?: string;
  order?: number;
  course?: IEdoCourse;
  chapter?: IEdoChapter;
  author?: IUser;
  files?: IEdoFile[];
}

export const defaultValue: Readonly<IEdoCourseMaterial> = {};
