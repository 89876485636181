import { IEdoClass } from "./edo-class.model";

export interface IEdoClassZoomLink {
    id?: number;
    label?: string;
    zoomInviteLink?: string | null;
    class?: IEdoClass;
    createdAt?: string;
    updatedAt?: string;
}

export const defaultValue: Readonly<IEdoClassZoomLink> = {};
