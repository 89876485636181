import {
  People as PeopleIcon,
  Person as PersonIcon
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "config/store";
import { SocketContext } from "context/socket";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IEdoChatMessage } from "shared/model/edo-chat-message.model";
import { IEdoChat } from "shared/model/edo-chat.model";
import { EdoChatType } from "shared/model/enumerations/edo-chat-type.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { ChatState } from "shared/reducers/chat.reducer";
import { checkAllMessagesByChat } from "shared/reducers/entities/edo-chat-message.reducer";
import { MyMessage } from "../../components/MyMessage";
import { OtherMessage } from "../../components/OtherMessage";

const Chat = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const messageAreaRef = useRef(null);
  const socket = useContext(SocketContext);
  const params = useParams<{ id: string }>();
  const [messageToSend, setMessageToSend] = useState<string>("");
  const [messages, setMessages] = useState<IEdoChatMessage[]>([]);
  const { account } = useAppSelector<AuthenticationState>((state) => state.authentication);
  const { selectedParticipation } = useAppSelector<ChatState>(state => state.chat);

  const sendChatMessage = (message: string, chat: IEdoChat) => {
    socket.emit('message', {
      message,
      chat
    });
  };

  const handleOnMessageEvent = (message: IEdoChatMessage) => {
    if (String(message.chat.id) === params.id) {
      setMessages([...messages, message]);
      dispatch(checkAllMessagesByChat(params.id));
    }
  };

  const sendMessage = () => {
    if (!messageToSend) return;

    sendChatMessage(messageToSend, { id: Number(params.id) });
    setMessageToSend("");
  };

  useEffect(() => {
    const getAllMessages = async () => {
      const response = await axios.get('/chat-messages', {
        params: {
          page: 0,
          limit: 20,
          sortBy: 'createdAt:ASC',
          'filter.chat.id': '$eq:' + params.id
        }
      });

      setMessages([...response.data.data]);
    };

    if (params.id) {
      getAllMessages();
      dispatch(checkAllMessagesByChat(params.id));
    }
  }, [params.id]);

  useEffect(() => {
    // const executeScroll = () => messageAreaRef.current.scrollIntoView({ behaviour: 'smooth' });
    const executeScroll = () => {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    };

    executeScroll();
  }, [messages]);

  useEffect(() => {
    socket.on('message', handleOnMessageEvent);
    return function cleanup() {
      socket.off('message', handleOnMessageEvent);
    };
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ height: "100%" }}
    >
      {selectedParticipation ? (
        <>
          <List >
            <ListItem
              key={selectedParticipation.chat.id}
              button
              onClick={() => navigate(`/chat/${params.id}/info`)}
            >
              <ListItemIcon>
                <Avatar alt={selectedParticipation.chat.type === EdoChatType.CHAT
                  ? selectedParticipation.title
                  : selectedParticipation.chat.title}>
                  {selectedParticipation.chat.type === EdoChatType.CHAT ? (
                    <PersonIcon />
                  ) : (
                    <PeopleIcon />
                  )}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={
                  selectedParticipation.chat.type === EdoChatType.CHAT
                    ? selectedParticipation.title
                    : selectedParticipation.chat.title
                }
              ></ListItemText>
            </ListItem>
          </List>

          <Divider />
        </>
      ) : null}
      <Box style={{ overflowY: "scroll" }} flexGrow={1} ref={messageAreaRef}>
        <List>
          {Array.from(messages).sort((a, b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime())
            .map((message, index) => {
              if (message.user.id === account.id) {
                return <MyMessage messaggio={message} key={message.id} />;
              } else {
                return <Box sx={{ marginBottom: 1 }} key={message.id}><OtherMessage messaggio={message} /></Box>;
              }
            })}
        </List>
      </Box>
      <Divider />
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow="initial"
      >
        <Box flexGrow={1} mr={2}>
          <TextField
            label="Scrivi un messaggio"
            value={messageToSend}
            onChange={(event) => setMessageToSend(event.target.value)}
            onKeyPress={(event) =>
              event.key === "Enter"
                ? sendMessage()
                : null
            }
            fullWidth
          />
        </Box>
        <Box alignContent="flex-end">
          <IconButton color="primary" onClick={() => sendMessage()}>
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
