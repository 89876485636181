import { Add as AddIcon, Sync as SyncIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  TablePagination, Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import axios from "axios";
import EditCourseMaterial from "components/EditCourseMaterial/EditCourseMaterial";
import MaterialRow from "components/MaterialRow/MaterialRow";
import { ViewChapter } from "components/ViewChapter/ViewChapter";
import { ViewUnit } from "components/ViewUnit/ViewUnit";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from "react-beautiful-dnd";
import { IPaginationBaseState, Translate } from "react-jhipster";
import { useParams } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoChapter } from "shared/model/edo-chapter.model";
import { IEdoCourseMaterial } from "shared/model/edo-course-material.model";
import { IEdoUnit } from "shared/model/edo-unit.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getCourseMaterialsByCourse } from "shared/reducers/entities/edo-course-material.reducer";
import { EntityState } from "shared/reducers/reducer.utils";
import { ITEMS_PER_PAGE } from "shared/util/pagination.constants";
import { makeStyles } from 'tss-react/mui';
import DeleteCourseMaterial from "../../components/DeleteCourseMaterial";

const useStyles = makeStyles()(theme =>
  ({
    paper: {
      color: theme.palette.text.secondary,
      overflow: "hidden",
    },
    root: {
      display: "grid",
      gridTemplateRows: "min-content auto min-content",
      height: "100%",
    },
    appbar: {
      boxShadow: "none",
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: "solid",
    },
    toolbar: {
      justifyContent: "space-between",
    },
  })
);

const CourseMaterials = () => {
  const theme = useTheme();
  const params = useParams<{ id: string }>();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    loading: loadingMaterialeInsegnamento,
    totalItems: materialeInsegnamentoTotalItems,
    entities: courseMaterials,
    updateSuccess: updateSuccessCourseMaterial
  } = useAppSelector<EntityState<IEdoCourseMaterial>>(
    (state) => state.edoCourseMaterial
  );
  const { account } = useAppSelector<AuthenticationState>(
    (state) => state.authentication
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [materialeToDelete, setMaterialeToDelete] = useState<IEdoCourseMaterial>(null);
  const [viewUnitDialog, setViewUnitDialog] = useState<boolean>(false);
  const [unitToView, setUnitToView] = useState<IEdoUnit>(null);
  const [viewChapterDialog, setViewChapterDialog] = useState<boolean>(false);
  const [chapterToView, setChapterToView] = useState<IEdoChapter>(null);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: "order",
    order: "ASC",
  });
  const [courseMaterialsLocal, setCourseMaterialsLocal] = useState<IEdoCourseMaterial[]>(Array.from(courseMaterials));
  const [courseMaterialToEdit, setCourseMaterialToEdit] = useState<IEdoCourseMaterial>(null);
  const [editCourseMaterialDialog, setEditCourseMaterialDialog] = useState<boolean>(false);


  const getAllEntities = () => {
    dispatch(
      getCourseMaterialsByCourse({
        courseId: params.id,
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`
      })
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [
    paginationState.activePage,
    paginationState.order,
    paginationState.sort,
    paginationState.itemsPerPage,
  ]);


  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === "ASC" ? "DESC" : "ASC",
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleRowsPerPage = (rowsPerPage: number) =>
    setPaginationState({
      ...paginationState,
      itemsPerPage: rowsPerPage,
      activePage: 1,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(courseMaterialsLocal);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCourseMaterialsLocal(Array.from(items));

    try {
      await axios.patch(
        `/course-materials/${result.draggableId}/order`,
        {
          order: result.destination.index + 1,
        }
      );
    } catch (e) {
      getAllEntities();
    }
  };

  const handleOpenEditCourseMaterial = (courseMaterial: IEdoCourseMaterial) => {
    setCourseMaterialToEdit(courseMaterial);
    setEditCourseMaterialDialog(true);
  };

  const handleCloseEditCourseMaterialDialog = () => {
    setEditCourseMaterialDialog(false);
    setCourseMaterialToEdit(null);
  };


  const handleOpenDeleteDialog = (materiale: IEdoCourseMaterial) => {
    setMaterialeToDelete(materiale);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setMaterialeToDelete(null);
  };

  const handleOpenViewUnitDialog = (unit: IEdoUnit) => {
    setUnitToView(unit);
    setViewUnitDialog(true);
  };

  const handleCloseViewUnitDialog = () => {
    setViewUnitDialog(false);
    setUnitToView(null);
  };

  const handleOpenViewChapter = (chapter: IEdoChapter) => {
    setChapterToView(chapter);
    setViewChapterDialog(true);
  };

  const handleCloseViewChapter = () => {
    setViewChapterDialog(false);
    setChapterToView(null);
  };

  const isAdmin = useMemo(
    () =>
      hasAnyAuthority(account.roles, [
        AUTHORITIES.ROLE_ADMIN,
        AUTHORITIES.ROLE_VICE,
        AUTHORITIES.ROLE_TEACHER,
      ]),
    [account.roles]
  );

  useEffect(() => {
    setCourseMaterialsLocal(Array.from(courseMaterials));
  }, [courseMaterials]);

  useEffect(() => {
    if (updateSuccessCourseMaterial) {
      getAllEntities();
    }
  }, [updateSuccessCourseMaterial]);

  const handleOnDeleteCourseMaterial = (courseMaterial: IEdoCourseMaterial) => {
    setCourseMaterialsLocal(Array.from(courseMaterialsLocal.filter(material => material.id !== courseMaterial?.id)));
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.root}>
          <AppBar
            position="static"
            color="transparent"
            className={classes.appbar}
          >
            <Toolbar className={classes.toolbar}>
              <Typography variant="h5">
                <Translate contentKey="edocendoApp.edoCourseMaterial.home.title" />
              </Typography>
              <Box>
                <IconButton
                  color="inherit"
                  onClick={handleSyncList}
                  disabled={loadingMaterialeInsegnamento}
                  sx={(theme) => ({
                    [theme.breakpoints.up("lg")]: {
                      display: "none",
                    }
                  })}
                >
                  <SyncIcon />
                </IconButton>
                <Button
                  color="inherit"
                  onClick={handleSyncList}
                  disabled={loadingMaterialeInsegnamento}
                  startIcon={<SyncIcon />}
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  })}
                >
                  <Translate contentKey="edocendoApp.edoCourseMaterial.home.refreshListLabel">
                    Refresh List
                  </Translate>
                </Button>
                {isAdmin ? (
                  <>
                    <IconButton
                      color="inherit"
                      edge="end"
                      disabled={loadingMaterialeInsegnamento}
                      onClick={() => handleOpenEditCourseMaterial(null)}
                      sx={(theme) => ({
                        [theme.breakpoints.up("lg")]: {
                          display: "none",
                        }
                      })}
                    >
                      <AddIcon />
                    </IconButton>
                    <Button
                      color="inherit"
                      disabled={loadingMaterialeInsegnamento}
                      startIcon={<AddIcon />}
                      onClick={() => handleOpenEditCourseMaterial(null)}
                      sx={(theme) => ({
                        [theme.breakpoints.down("lg")]: {
                          display: "none",
                        },
                      })}
                    >
                      Aggiungi materiale
                    </Button>
                  </>
                ) : null}
              </Box>
            </Toolbar>
          </AppBar>

          <>
            {loadingMaterialeInsegnamento ? (
              <Box position="relative" display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {!courseMaterialsLocal.length ? (
                  <Box p={2}>
                    <Typography variant="body1" align="center">
                      <Translate contentKey="edocendoApp.edoCourseMaterial.home.notFound" />
                    </Typography>
                  </Box>
                ) : (
                  <DragDropContext
                    onDragEnd={(result) => {
                      handleOnDragEnd(result);
                    }}
                  >
                    <Droppable droppableId="materiale" isDropDisabled={!isAdmin}>
                      {(provided) => (
                        <div
                          className="materiale"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {courseMaterialsLocal.map(
                            (materiale, index) => {
                              return (
                                <Draggable
                                  key={materiale.id}
                                  draggableId={String(materiale.id)}
                                  index={index}
                                  isDragDisabled={!isAdmin}
                                >
                                  {(providedEl) => (
                                    <div
                                      ref={providedEl.innerRef}
                                      {...providedEl.draggableProps}
                                      {...providedEl.dragHandleProps}
                                    >
                                      <MaterialRow
                                        key={materiale.id}
                                        courseMaterial={materiale}
                                        onOpenViewChapter={
                                          handleOpenViewChapter
                                        }
                                        onOpenEditMaterial={handleOpenEditCourseMaterial}
                                        onOpenDeleteMaterial={
                                          isAdmin
                                            ? (chapter: IEdoChapter) =>
                                              handleOpenDeleteDialog(materiale)
                                            : null
                                        }
                                        isLastElement={
                                          index ===
                                          courseMaterials.length - 1
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </Box>
            )}

          </>
          <Divider />
          <TablePagination
            component="div"
            count={materialeInsegnamentoTotalItems}
            rowsPerPage={paginationState.itemsPerPage}
            page={paginationState.activePage - 1}
            onPageChange={(event, page) => handlePagination(page + 1)}
            onRowsPerPageChange={(event) =>
              handleRowsPerPage(parseInt(event.target.value, 10))
            }
            labelRowsPerPage={breakpointDownSm ? "Righe:" : "Righe per pagina:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} di ${count !== -1 ? count : "more than" + to}`
            }
          />
        </div>
      </Paper>
      <EditCourseMaterial
        fixedCourses={[{ id: parseInt(params.id) }]}
        courseMaterial={courseMaterialToEdit}
        open={editCourseMaterialDialog}
        onClose={handleCloseEditCourseMaterialDialog} />
      <ViewUnit
        open={viewUnitDialog}
        unit={unitToView}
        handleClose={handleCloseViewUnitDialog}
      />
      <DeleteCourseMaterial
        open={openDeleteDialog}
        courseMaterial={materialeToDelete}
        onClose={handleCloseDeleteDialog}
        onDelete={handleOnDeleteCourseMaterial}
      />
      <ViewChapter
        open={viewChapterDialog}
        chapter={chapterToView}
        onClose={handleCloseViewChapter}
      />
    </>
  );
};

export default CourseMaterials;

