import { IUser } from "shared/model/user.model";
import { IEdoCourseQuiz } from "./edo-course-quiz.model";

export interface IEdoQuizAttempt {
  id?: number;
  courseQuiz?: IEdoCourseQuiz;
  user?: IUser;
  timeStart?: string;
  timeFinish?: string;
  reviewDate?: string;
  updatedAt?: string;
  createdAt?: string;
}

export const defaultValue: Readonly<IEdoQuizAttempt> = {
  timeStart: null,
};
