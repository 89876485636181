import { Announcement as AnnouncementIcon } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader, Divider, List,
  ListItem,
  ListItemIcon,
  ListItemText, Typography
} from "@mui/material";
import axios from "axios";
import ViewCircular from "components/ViewCircular/ViewCircular";
import { useAppDispatch } from "config/store";
import { useEffect, useState } from "react";
import { Translate } from "react-jhipster";
import { IEdoCircular } from "shared/model/edo-circular.model";
// import { getLoginUrl, REDIRECT_URL } from 'shared/util/url-utils';

export const Circolari = () => {
  const dispatch = useAppDispatch();
  // const { entities: circulars } = useAppSelector<
  //   EntityState<IEdoCircular>
  //   >((state) => state.edoCircular);
  const [circulars, setCirculars] = useState<IEdoCircular[]>([]);
  const [viewCircularDialog, setViewCircularDialog] = useState<{ open: boolean; entity: IEdoCircular }>({ open: false, entity: null });

  const getAllEntities = async () => {
    // dispatch(getCircolareList({ page: 0, size: 5, sort: `dataCreation:DESC` }));
    const response = await axios.get<{ data: IEdoCircular[] }>(`/account/circulars`, {
      params: {
        page: 0,
        size: 5,
        sort: `dataCreation:DESC`,
      },
    });
    setCirculars(response.data.data);
  };

  useEffect(() => {
    getAllEntities();
  }, []);

  return (
    <>
      <Card
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          // boxShadow: "0px 1px 6px rgba(0,0,0,0.3)"
        })}
      >
        <CardHeader subheader="Circolari" sx={(theme) => ({ backgroundColor: theme.palette.background.paper })} />
        <Divider />
        <CardContent sx={{
          padding: 0,
          "&:last-child": {
            paddingBottom: 0
          }
        }}>
          <List>
            {circulars.length > 0 ? (
              circulars.map((circular) => (
                <ListItem
                  button
                  key={circular.id}
                  onClick={() => setViewCircularDialog({ open: true, entity: circular })}
                >
                  <ListItemIcon>
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText primary={circular.title} />
                </ListItem>
              ))
            ) : (
              <Box p={2}>
                <Typography variant="body1" align="center">
                  <Translate contentKey="edocendoApp.edoCircular.home.notFound" />
                </Typography>
              </Box>
            )}
          </List>
        </CardContent>
      </Card>
      <ViewCircular
        open={viewCircularDialog.open}
        circular={viewCircularDialog.entity}
        onClose={() => setViewCircularDialog({ open: false, entity: null })}
      />
    </>
  );
};

export default Circolari;
