import { Class as ClassIcon, Videocam as VideocamIcon } from "@mui/icons-material";
import {
  Avatar, Box, CircularProgress, IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { useEffect } from "react";
import { Translate } from "react-jhipster";
import { Link } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { IEdoClassZoomLink } from "shared/model/edo-class-zoom-link.model";
import { IEdoClass } from "shared/model/edo-class.model";
import { IEdoCourse } from "shared/model/edo-course.model";
import { AuthenticationState } from "shared/reducers/authentication";
import { getEntities } from "shared/reducers/entities/edo-course.reducer";
import { EntityState } from "shared/reducers/reducer.utils";

interface CoursesGroupProps {
  class: IEdoClass;
  onOpenClassZoomLinksMenu: (anchorEl: HTMLElement, classZoomLinks: IEdoClassZoomLink[]) => void;
}

const CoursesGroup = (props: CoursesGroupProps) => {
  const dispatch = useAppDispatch();
  const { entities: courses, loading } = useAppSelector<
    EntityState<IEdoCourse>
  >((state) => state.edoCourse);
  const { account } = useAppSelector<AuthenticationState>(state => state.authentication);

  const { class: edoClass, onOpenClassZoomLinksMenu: handleOpenClassZoomLinksMenu } = props;

  useEffect(() => {

    const getCourses = async () => {
      const params = new URLSearchParams();
      if (!hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_STUDENT, AUTHORITIES.ROLE_SUPPORT_TEACHER]) && hasAnyAuthority(account.roles || [], [AUTHORITIES.ROLE_TEACHER])) {
        params.append("filter.teachers.userId", "$eq:" + String(account.id));
      }
      params.append("filter.class.id", "$eq:" + String(edoClass.id));
      dispatch(
        getEntities({
          page: 0,
          size: 1000,
          sort: `id:ASC`,
          filters: params.toString(),
        })
      );
    };
    if (edoClass && account.id) {
      getCourses();
    }
  }, [edoClass, account]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <List component="nav" style={{ flexGrow: 1 }} disablePadding={true}>
      {courses.length ? (
        <>
          {courses.map((course) => (
            <ListItem
              button
              key={course.id}
              component={Link}
              to={`/courses/${course.id}`}
              sx={{ padding: 2 }}
            >
              <ListItemIcon>
                <Avatar>
                  <ClassIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={course.subject.name} />
              {edoClass.zoomInviteLinks?.length > 0 ? (
                <ListItemSecondaryAction>
                  <IconButton onClick={(event) => handleOpenClassZoomLinksMenu(event.currentTarget, edoClass.zoomInviteLinks)}>
                    <VideocamIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          ))}
        </>
      ) : (
        <Box p={2}>
          <Typography textAlign="center">
            <Translate contentKey="edocendoApp.edoCourse.home.notFound" />
          </Typography>
        </Box>
      )}
    </List>
  );
};

export default CoursesGroup;